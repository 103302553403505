var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.alertError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" No fue posible la comunicación con "),_c('strong',[_vm._v("API SERVICE")]),_vm._v(" ponganse en contacto con el administrador si este mensaje persiste. ")]):_vm._e(),_c('v-toolbar',{attrs:{"title":"","elevation":"1"}},[_c('v-toolbar-title',{staticClass:"tituloMaquinaria"},[_vm._v(_vm._s(_vm.TituloTabla))]),_c('v-divider',{staticClass:"ml-3",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:" pr-4",attrs:{"outlined":"","label":"Buscar Registro","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.buscarRegistro),callback:function ($$v) {_vm.buscarRegistro=$$v},expression:"buscarRegistro"}}),_c('v-tooltip',{attrs:{"bottom":"","color":"#0019d8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"TamañoLetraBoton mx-2",attrs:{"color":"#0019d8","rounded":"","dark":"","disabled":!_vm.ActivarMenuTabs},on:{"click":function($event){return _vm.ObtenerInventarioVehiculosPorSucursal()}}},on),[_c('v-icon',[_vm._v("fas fa-sync-alt")])],1)]}}])},[_c('span',[_vm._v("Actualizar Tabla")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"TamañoLetraBoton mx-2",attrs:{"color":"error","rounded":"","dark":"","disabled":!_vm.ActivarMenuTabs},on:{"click":function($event){return _vm.AbrirModalVisualizarPDFInventario()}}},on),[_c('v-icon',[_vm._v("fas fa-file-pdf")])],1)]}}])},[_c('span',[_vm._v("Inventario de Vehículos")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.EncabezadoTablaInventarioSucursal,"items":_vm.ArrayInventarioVehiculoPorSucursal,"search":_vm.buscarRegistro,"footer-props":{itemsPerPageText: 'Filas por página'},"loading":_vm.loading,"loading-text":"Cargando Datos..."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"#3c8dbc"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permisoCrear)?_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","dark":"","fab":"","color":"#3c8dbc"},on:{"click":function($event){return _vm.AbrirModalRetornarVehiculoACentral(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-exchange-alt")])],1):_vm._e()]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v("Retornar Vehículo")])])]}},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"text-xs-center mx-10 mt-2 titulo",attrs:{"type":"warning","prominent":"","dense":"","outlined":"","border":"left"}},[_vm._v(" Vehículo no existe ")])]},proxy:true},(_vm.ArrayInventarioVehiculoPorSucursal.length === 0)?{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"text-xs-center mx-10 mt-2 titulo",attrs:{"type":"warning","prominent":"","dense":"","outlined":"","border":"left"}},[_vm._v(" No existen Vehículos registrados ")])]},proxy:true}:null],null,true)}),_c('v-overlay',{attrs:{"value":_vm.EstadoCircularProgress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":80,"width":10,"color":"#ffffff"}}),_vm._v(" Cargando datos... ")],1),_c('MensajeRetornarCentral',{attrs:{"dialogoMensajeRetornarCentral":_vm.dialogoMensajeRetornarCentral,"datosVehiculoMaquinaria":_vm.datosVehiculoMaquinaria,"idVehiculo":_vm.idVehiculo,"alertError":_vm.alertError},on:{"CerrarModalRetornarVehiculoACentral":_vm.CerrarModalRetornarVehiculoACentral,"ObtenerInventarioVehiculosPorSucursal":_vm.ObtenerInventarioVehiculosPorSucursal}}),_c('ControlladorExcepciones',{attrs:{"response":_vm.responseStatus}}),_c('VisualizarPDFInventarioGeneral',{attrs:{"dialogoVisualizarPDFInventario":_vm.dialogoVisualizarPDFInventario,"Titulo":_vm.Titulo,"URLPDF":_vm.URLPDF},on:{"CerrarModalVisualizarPDFInventario":_vm.CerrarModalVisualizarPDFInventario}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }