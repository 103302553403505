<template>
    <div>
        <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>            
        
        <v-toolbar title elevation="1">
            <v-toolbar-title class="tituloMaquinaria">{{TituloTabla}}</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <v-tooltip bottom color="#0019d8">
                <template v-slot:activator="{on}">
                    <div>
                        <v-btn rounded color="#0019d8" dark v-on="on">PDF 
                            <v-icon right>fas fa-file-pdf</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>Descargar Documento PDF</span>
            </v-tooltip>
            <v-tooltip bottom color="#0019d8">
                <template v-slot:activator="{on}">
                    <div>
                        <v-btn rounded color="#0019d8" dark  v-on="on" class="ma-2">Excel 
                            <v-icon right>fas fa-file-excel</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>Descargar Documento Excel</span>
            </v-tooltip>
            <v-tooltip bottom color="#0019d8">
                <template v-slot:activator="{on}">
                    <div>
                        <v-btn rounded color="#0019d8" dark v-on="on" @click="AbrirDialogoNuevaSolicitudHerramienta(item)">Nueva Solicitud 
                            <v-icon right>fas fa-file-alt</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>Nueva Solicitud</span>
            </v-tooltip>
        </v-toolbar>       
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>          
        <v-data-table
            :headers="EncabezadoTablaInventarioSucursal"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."                                
        >
            
        </v-data-table>
        <NuevaSolicitudDeHerramienta
            :ModalNuevaSolicitudHerramienta="ModalNuevaSolicitudHerramienta" @CerrarDialogoNuevaSolicitudHerramienta="CerrarDialogoNuevaSolicitudHerramienta"
            :DatosHerramienta="DatosHerramienta" :ArraySucursales="ArraySucursales" :EstadoPermisoEditar="EstadoPermisoEditar"
        />        
    </div>
</template>

<script>
import NuevaSolicitudDeHerramienta from '../../../components/Almacen/Herramientas/NuevaSolicitudHerramienta/NuevaSolicitudHerramienta'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'


export default {
    props: {
        tab: String,
        TituloTabla: String,
    },

    components: {
        NuevaSolicitudDeHerramienta,
    },    

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaInventarioSucursal:[
                {text: 'parametro', value: 'parametro', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'parametro', value: 'parametro', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'parametro', value: 'parametro', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'parametro', value: 'parametro', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],         
            //variables para nueva solicitud de herramienta
            ModalNuevaSolicitudHerramienta: false,       
            alertErrorPost:false,
            arrayErrorPost:[],                                        
        }
    },

    created() {

    },
    methods: {
        // metodos para nueva solicitud de herramienta
            async AbrirDialogoNuevaSolicitudHerramienta(data){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('getAllSucursales')
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.DatosHerramienta = data
                    this.ArraySucursales = response.data
                    this.EstadoCircularProgress = false
                    this.ModalNuevaSolicitudHerramienta = true
                }else{
                    this.ControlladorExcepciones(response)
                }                
            },

            CerrarDialogoNuevaSolicitudHerramienta(){
                this.ModalNuevaSolicitudHerramienta = false
            },

            // controlamos todas las excepciones http
            ControlladorExcepciones(){
                this.loading = true
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false  
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')    
                    this.EstadoCircularProgress = false        
                    this.loading = false
                }                
            }
    },
}
</script>