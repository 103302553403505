<template>
    <div>
        <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>            
        
        <v-toolbar title elevation="1">
            <v-toolbar-title class="tituloMaquinaria">{{TituloTabla}}</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <v-tooltip bottom color="#0019d8" >
                <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" rounded dark @click="ObtenerInventarioMaterialInsumosPorSucursal()"
                            v-on="on" class="TamañoLetraBoton mx-2" :disabled="!ActivarMenuTabs"
                        > 
                            <v-icon >fas fa-sync-alt</v-icon> 
                        </v-btn>
                </template>
                <span>Actualizar Tabla</span>
            </v-tooltip>                                                   
            <v-tooltip bottom color="error" v-if="ArrayInventarioMaterialInsumosPorSucursal.length > 0" >
                <template v-slot:activator="{ on }">
                        <v-btn color="error" rounded dark @click="AbrirModalVisualizarPDFInventario()"
                            v-on="on" class="TamañoLetraBoton mx-2" :disabled="!ActivarMenuTabs"
                        > 
                            <v-icon >fas fa-file-pdf</v-icon> 
                        </v-btn>
                </template>
                <span>PDF</span>
            </v-tooltip>                                                                 
        </v-toolbar>        
        <v-data-table
            :headers="EncabezadoTablaInventarioSucursal"
            :items="ArrayInventarioMaterialInsumosPorSucursal"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."                                
        >
            <template v-slot:item.actions="{item}">
                <v-tooltip left color="#3c8dbc">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#3c8dbc" v-on="on" @click="AbrirModalRetornarMaterialBodega(item)">
                            <v-icon small>fas fa-exchange-alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Retornar Material o Insumo a bodega</span>
                </v-tooltip>                                                                                            
            </template>                
            <template v-slot:no-results>    
                <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                    Material o Insumo no existe
                </v-alert>                        
            </template>
            <template v-slot:no-data v-if="ArrayInventarioMaterialInsumosPorSucursal.length === 0">
                <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo" >
                    No existen Materiales o Insumos registrados
                </v-alert>
            </template>            
        </v-data-table>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Cargando datos...
        </v-overlay>                      
        <MensajeRetornarMaterialBodega 
            :dialogoMensajeRetornarCentral="dialogoMensajeRetornarCentral" @CerrarModalRetornarMaterialBodega="CerrarModalRetornarMaterialBodega"
            :datosMaterialInsumo="datosMaterialInsumo" :alertError="alertError" @ObtenerInventarioMaterialInsumosPorSucursal="ObtenerInventarioMaterialInsumosPorSucursal"
        />
        <ControlladorExcepciones :response="responseStatus"/>
        <VisualizarPDFInventarioGeneral 
            :dialogoVisualizarPDFInventario="dialogoVisualizarPDFInventario" :Titulo="Titulo" :URLPDF="URLPDF"
            @CerrarModalVisualizarPDFInventario="CerrarModalVisualizarPDFInventario"
        />                  
    </div>
</template>

<script>
import BotonActualizarTabla from '../../../BotonActualizarTabla'
import MensajeRetornarMaterialBodega from '../MensajeRetornarMaterialBodega'
import ControlladorExcepciones from '../../../../components/ControlandoExcepcionesHTTPS'
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import VisualizarPDFInventarioGeneral from '../VisualizarPDFInventario'

export default {
    components:{
        BotonActualizarTabla,
        MensajeRetornarMaterialBodega,
        ControlladorExcepciones,
        VisualizarPDFInventarioGeneral,
    },
    props: {
        idSucursal: Number,
        tab: String,
        TituloTabla: String,
        idProyecto: Number,
        loading: Boolean,
        ArrayInventarioMaterialInsumosPorSucursal: Array,
        ActivarMenuTabs: Boolean,
    },

    data() {
        return {
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaInventarioSucursal:[
                {text: 'Nombre', value: 'materiaPrima.catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'materiaPrima.marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'materiaPrima.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega origen', value: 'materiaPrima.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                                
                {text: 'Cantidad', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad de Medida', value: 'materiaPrima.unidadDeMedida.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha ultima Transferencia', value: 'fecha_Creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'actions', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],                               
            // variables para retornar vehiculo a central
            dialogoMensajeRetornarCentral: false,
            datosMaterialInsumo: {
                TituloMaterial:'',
                Nombre:'',
                Marca:'',
                Cantidad:'',
                UnidadDeMedida:'',
                idProyecto: 0,
                idMaterial:0,                
            },
            idMaterial:0,            
            responseStatus:[],
            //variables para visualizar pdf de inventario general
            dialogoVisualizarPDFInventario: false,
            Titulo: '',
            URLPDF: '',     
            EstadoCircularProgress:false,                                   
        }
    },

    created() {

    },
    methods: {
        ObtenerInventarioMaterialInsumosPorSucursal(){
            this.$emit('ObtenerInventarioMaterialInsumosPorSucursal')
        },
        AbrirModalRetornarMaterialBodega(datosMaterialInsumo){
            this.datosMaterialInsumo.TituloMaterial = "Material o Insumo"
            this.datosMaterialInsumo.Nombre = datosMaterialInsumo.materiaPrima.catalogoMateriales.nombre
            this.datosMaterialInsumo.Marca = datosMaterialInsumo.materiaPrima.marcaMateriaPrima.nombre
            this.datosMaterialInsumo.Cantidad = datosMaterialInsumo.cantidad
            this.datosMaterialInsumo.UnidadDeMedida = datosMaterialInsumo.materiaPrima.unidadDeMedida.nombre
            this.datosMaterialInsumo.idProyecto = datosMaterialInsumo.idProyecto
            this.datosMaterialInsumo.idMaterial = datosMaterialInsumo.materiaPrima.idMateriaPrima

            this.dialogoMensajeRetornarCentral = true
        },
        CerrarModalRetornarMaterialBodega(){
            this.dialogoMensajeRetornarCentral = false
        },
        async AbrirModalVisualizarPDFInventario(){
            this.EstadoCircularProgress = true
            this.Titulo = "PDF de inventario de materiales o insumos"
            let datos = {idProyecto: this.idProyecto, idSucursal:this.idSucursal , rubro: 4}
            const response = await this.$store.dispatch('InventarioGeneralPDF', datos)
            if(response.status === 200){
                this.alertError= false
                this.loading = false       
                this.URLPDF= process.env.VUE_APP_BACKEND_HOST + '/Proyecto/pdf/'+this.idProyecto+'/rubro/'+4+'/idSucursal/'+this.idSucursal
                this.dialogoVisualizarPDFInventario = true
                this.EstadoCircularProgress = false
            }else{
                this.ControllerException(response)
                this.EstadoCircularProgress = false
            }                            
        },
        CerrarModalVisualizarPDFInventario(){
            this.URLPDF= ''
            this.dialogoVisualizarPDFInventario = false
        },                
        // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            if(typeof response === 'undefined'){
                this.alertError=true
                this.loading = false
                this.EstadoCircularProgress = false
                this.loadingEliminarTipoVehiculo = false
                return 0
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }
        },         
    },
}
</script>