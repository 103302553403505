<template>
    <v-dialog v-model="AbrirTablaDeMaterialesPorSolicitud" fullscreen scrollable>
        <v-card>
            
            <v-card-title class="AppBar" elevation="0" style="color: #ffffff">
                <v-icon color="white" left>fas fa-tools</v-icon> Materiales o Insumos de solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalMaterialesDeSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>                
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <!-- <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>             -->
                    <div class="my-3">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaterial">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaterial">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaterial">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>                        
                    <v-toolbar title elevation="1">
                        <v-chip color="blue" outlined>
                            <v-icon left>fas fa-tools</v-icon>
                            <v-toolbar-title class="tituloMaterial">Materiales o Insumos</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>                    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                                <v-btn v-if="filtrarEstadoSolicitudMateriaPrima==='1'" class="ml-2" dark color="indigo" v-on="on" :disabled="loading" @click="AutorizarTodasLasSolicitudesSeleccionadas()"
                                    :loading="loading" 
                                >Autorizar
                                    <v-icon color="white" >far fa-check-circle</v-icon>
                                </v-btn>                           
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table
                        :headers="EncabezadoTablaSolicitudes"
                        :items="MaterialesBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."             
                        item-key="sol_ProyectoMaterialId"
                    >  
                        <template v-slot:item.materiaPrima.existencia="{ item }">
                            <v-row justify="space-around">
                                <v-chip color="#00b40f" text-color="white" v-if="item.materiaPrima.existencia>item.cantidadSolicitada || item.materiaPrima.existencia===item.cantidadSolicitada"> {{item.materiaPrima.existencia}}
                                    <v-icon small right>fas fa-check</v-icon>
                                </v-chip>
                                <v-chip color="#d80000" text-color="white" v-if="item.materiaPrima.existencia<item.cantidadSolicitada"> {{item.materiaPrima.existencia}}
                                    <v-icon small right>fas fa-times-circle</v-icon>
                                </v-chip>
                            </v-row>
                        </template>                              
                        <template v-slot:item.cantidadSolicitada="{ item }" v-if="this.filtrarEstadoSolicitudMateriaPrima==='1'">
                            <v-text-field type="number"
                                :label="'Cantidad Solicitada '" 
                                v-model="item.cantidadSolicitada"
                            ></v-text-field>
                        </template>       
                        <template v-slot:no-data v-if="MaterialesBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen solicitudes registradas
                            </v-alert>
                        </template>                                     
                    </v-data-table>                       
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../../views/BotonActualizarTabla'

export default {
    props:{
        AbrirTablaDeMaterialesPorSolicitud: Boolean,
        MaterialesBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,        
        filtrarEstadoSolicitudMateriaPrima: String,
    },
    components:{
        BotonActualizarTabla,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaListadoSolicitudMaterialesEnProceso:[
                {text: 'Nombre', value: 'materiaPrima.catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'materiaPrima.marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad de Medida', value: 'materiaPrima.unidadDeMedida.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},            
                {text: 'Sucursal origen', value: 'materiaPrima.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega origen', value: 'materiaPrima.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Ubicación 1', value: 'materiaPrima.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Ubicación 2', value: 'materiaPrima.ubicacion2', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Existencia Actual', value: 'materiaPrima.existencia', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Fecha Creación', value: 'fecha_creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            EncabezadoTablaListadoSolicitudMaterialesAprobado:[
                {text: 'Nombre', value: 'materiaPrima.catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'materiaPrima.marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad de Medida', value: 'materiaPrima.unidadDeMedida.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},            
                {text: 'Sucursal origen', value: 'materiaPrima.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega origen', value: 'materiaPrima.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Ubicación 1', value: 'materiaPrima.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Ubicación 2', value: 'materiaPrima.ubicacion2', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Cantidad Autorizada', value: 'cantidadAprobada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu', width: '200px'},
            ],
            EncabezadoTablaListadoSolicitudMaterialesRechazados:[
                {text: 'ID Material', value: 'materiaPrima.idMateriaPrima', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Material o Insumo', value: 'materiaPrima.catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca Material', value: 'materiaPrima.marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad de Medida', value: 'materiaPrima.unidadDeMedida.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu', width: '200px'},
                {text: 'Bodega', value: 'materiaPrima.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Fecha Creación', value: 'fecha_creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
  
            EncabezadoTablaSolicitudes:[],
            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },

    watch: {
        AbrirTablaDeMaterialesPorSolicitud(){
            this.EstadoOpcionSolicitud()
        },
    },

    created() {        
    },

    methods: {
        //-----------------------------------------------------------------------------
        EstadoOpcionSolicitud(){
            if(this.filtrarEstadoSolicitudMateriaPrima === '1'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMaterialesEnProceso
            }else if(this.filtrarEstadoSolicitudMateriaPrima === '2'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMaterialesAprobado
            }else if(this.filtrarEstadoSolicitudMateriaPrima === '3'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMaterialesRechazados
            }        
        },        
        //-----------------------------------------------------------------------------
        MapearMaterialesoInsumos(datos){
            this.ArrayTodasLasSolicitudesAutorizar=[]
            Object.keys(datos).forEach((element,key,_array)=>{
                this.ArrayTodasLasSolicitudesAutorizar.push({
                    sol_ProyectoMaterialId:datos[element].sol_ProyectoMaterialId,
                    cantidadAutorizada:datos[element].cantidadSolicitada,
                });
            });
        },

        async AutorizarTodasLasSolicitudesSeleccionadas(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.MapearMaterialesoInsumos(this.MaterialesBySolicitud)
            var data = {materiales: this.ArrayTodasLasSolicitudesAutorizar, proyectoSolicitudId: this.DatosSolicitud.idProyecto}
            const response = await this.$store.dispatch('AutorizarSolicitudesDeMateriasPrimasSeleccionadas', data)
            if(response.status === 200){
                this.alertErrorPost=false
                this.arrayErrorPost=[]                                                          
                this.loading = false
                this.$emit('ObtenerListadoDeSolicitudesDeMateriaPrima')
                showMessageSuccess('Bien!', response.data.message)
                this.CerrarModalMaterialesDeSolicitud()
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false     
                setTimeout(() => {
                    this.alertErrorPost=false
                }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },
                
        CerrarModalMaterialesDeSolicitud(){
            this.$emit('CerrarModalMaterialesDeSolicitud')
        }
    },
}
</script>
<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }
    .tituloMaterial {
        font-family: 'PT Serif';
        font-size: 20px;        
    }   
</style>