<template>
    <div>
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarMensajeEliminarVehiculo"
            :tituloMensajeEliminar="tituloVehiculoMensajeEliminar" @Eliminar="EliminarVehiculoById"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarVehiculo" :alertError="alertError"            
        />        
        <CrearVehiculo @TodosVehiculos="TodosVehiculos" :bodega="bodega"
            @CerrarDialogoCrearVehiculo="CerrarDialogoCrearVehiculo" :DialogoCrearVehiculo="DialogoCrearVehiculo"
            :Titulo="Titulo" :EsNuevo="EsNuevo" :validarfechaobtenida="validarfecha" :ObtenerProcedencia="ObtenerProcedencia"
            :ArrayObtenerVehiculoEditar="ArrayObtenerVehiculoEditar" :EstadoPermisoEditar="EstadoPermisoEditar"      
        />
        <CambiarSucursal :NombreSucursalActual="NombreSucursalActual"
            :DialogoCambiarSucursal="DialogoCambiarSucursal" @CerrarModalCambiarSucursal="CerrarModalCambiarSucursal"
            :DatosRegistro="DatosRegistro" :NombreModulo="NombreModulo" :ArrayProyectos="ArrayProyectos"
            :EstadoPermisoEditar="EstadoPermisoEditar" @TodasMaquinarias="TodosVehiculos"
        />        
        <CambiarSucursalYBodegaMaquinaria :NombreSucursalActual="NombreSucursalActual"
            :DialogoCambiarSucursalYBodega="DialogoCambiarSucursalYBodega" @CerrarModalCambiarSucursalyBodegaMaquinaria="CerrarModalCambiarSucursalyBodegaMaquinaria"
            :DatosRegistro="DatosRegistro" :NombreModulo="NombreModulo" :sucursalesAsignadas="sucursalesAsignadas" :sucursalBodegas="sucursalBodegas"
            :EstadoPermisoEditar="EstadoPermisoEditar" @TodasMaquinarias="TodosVehiculos"
        />        
        <v-card flat>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>
            <v-toolbar-title class="titulovehiculos text-center">Vehículos</v-toolbar-title>
            <div class="marginSelect">
                <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <v-select v-model="sucursal" :items="sucursalesAsignadas" menu-props="auto"
                            label="Sucursal"
                            prepend-icon="mdi-city"
                            item-text="nombre"
                            item-value="sucursalId"
                            @change="seleccionarSucursal()"
                        ></v-select>
                    </v-col>    
                    <v-col cols="12" lg="4" md="4">
                        <v-select :disabled="!sucursal"
                            v-model="bodega"
                            :items="sucursalBodegas"
                            menu-props="auto"
                            label="Bodega"
                            prepend-icon="mdi-warehouse"
                            item-text="nombre"
                            item-value="bodegaId"
                            @change="seleccionarBodega()"
                        ></v-select>
                    </v-col> 
                    <v-col>
                        <v-btn v-if="bodega > 0" icon fab dark small color="primary" @click="ResetBodega()">
                            <v-icon dark>fas fa-backspace</v-icon>
                        </v-btn>
                    </v-col>
                </v-row> 
            </div>            

            <v-toolbar flat title>
                <v-divider vertical class="ml-3"></v-divider>    

                <v-spacer></v-spacer>      
                <v-text-field outlined class=" pr-4"
                    label="Buscar Vehículo"
                    v-model="buscarVehiculo"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>

                <v-tooltip bottom color="#0019d8">
                    <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" :disabled="activeActions" dark rounded v-on="on" @click="AbrirDialogoCrearVehiculo()" v-if="EstadoPermisoCrear">
                            <v-icon left>fas fa-save</v-icon> Crear
                        </v-btn>
                    </template>
                    <span>Nuevo Vehiculo</span>
                </v-tooltip>                      
                <!-- boton para actualizar tabla de vehiculos -->
                <v-tooltip bottom color="#0019d8"  >
                    <template v-slot:activator="{ on }">
                            <v-btn color="#0019d8" outlined dark @click="TodosVehiculos()"
                                v-on="on" class="TamañoLetraBoton mx-2" 
                            > 
                                <v-icon >fas fa-sync-alt</v-icon> 
                            </v-btn>
                    </template>
                    <span>Actualizar</span>
                </v-tooltip>                 
            </v-toolbar>
            <v-data-table
                :headers="EncabezadoTablaVehiculo"
                :items="ArrayToddosVehiculos"
                item-key=""
                :search="buscarVehiculo"
                class="elevation-1"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading="loading"
                loading-text="Cargando Datos..."
            >
                <template v-slot:item.idEstado="{ item }">
                    <v-chip color="#00b40f" text-color="white" v-if="item.idEstado">Activo
                    </v-chip>      
                    <v-chip color="#d80000" text-color="white" v-if="!item.idEstado">Inactivo
                    </v-chip>                                          
                </template>
                <template v-slot:item.actions="{ item }">
                    <FiltrosBusquedaTimeLine :DatosRegistro="item" :TituloBotonVerMovimientoTimeLine="TituloBotonVerMovimientoTimeLine"
                        :TituloComponente="TituloComponente"
                    />
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" icon outlined color="#d69b01" v-on="on" @click="AbrirDialogoEditarVehiculo(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar Vehículo</span>
                    </v-tooltip>                    
                    <v-tooltip left color="#3c8dbc">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#3c8dbc" v-on="on" @click="AbrirModalCambiarDeSucursalYBodega(item)" v-if="EstadoPermisoCrear">
                                <v-icon small>fas fa-home</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Cambiar de Sucursal y Bodega</span>
                    </v-tooltip>                                                                  
                    <v-tooltip left color="#3c8dbc">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" icon outlined color="#3c8dbc" v-on="on" @click="AbrirModalCambiarProyecto(item)" v-if="EstadoPermisoCrear">
                                <v-icon small>fas fa-exchange-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Cambiar Proyecto</span>
                    </v-tooltip>                                                                                            
                    <v-tooltip left color="#D80000">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="EstadoPermisoEliminar" small class="ml-2" icon outlined color="#D80000" v-on="on" @click="AbrirMensajeEliminarVehiculo(item)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar Vehículo</span>
                    </v-tooltip>                                              
                </template>
                <template v-slot:no-data v-if="ArrayToddosVehiculos.length === 0">
                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                    >
                        NO EXISTEN VEHICULOS REGISTRADOS
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>                                                            
                </template>
            </v-data-table>            
        </v-card>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular>
        </v-overlay>                      
    </div>
</template>

<script>
import CrearVehiculo from '../../../components/Almacen/vehiculos/CrearVehiculos'
import CambiarSucursal from '../../../components/Almacen/CambiarSucursalMaquinariaVehiculo'
import MensajeEliminar from '../../../components/MensajeEliminar'
import BotonActualizarTabla from '../../BotonActualizarTabla'
import FiltrosBusquedaTimeLine from '../../../components/Almacen/FiltroBusquedaTimeLine'
import CambiarSucursalYBodegaMaquinaria from '../../../components/Almacen/CambiarSucursalYBodegaMaquinaria'

import { mapGetters, mapActions  } from 'vuex'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
export default {
    components: {
        CrearVehiculo,
        MensajeEliminar,
        CambiarSucursal,
        BotonActualizarTabla,
        FiltrosBusquedaTimeLine,
        CambiarSucursalYBodegaMaquinaria,
    },

    data() {
        return {
            sucursalesAsignadas:[],
            sucursalBodegas:[],
            bodega:0,
            bodegaId:0,
            sucursal:-1,             
            // alerta cuando el api deja de funcionar
            alertError:false,
            // variable para mostrar un circular progress 
            EstadoCircularProgress: false,            
            
            loadingEliminarVehiculo: false,            
            loading: false,
            buscarVehiculo: '',

            idVehiculo: '',
            tituloVehiculoMensajeEliminar: '',
            DialogoMensajeEliminar: false,
            DialogoCrearVehiculo: false,
            Titulo: '',
            EsNuevo: true,
            ArrayToddosVehiculos: [],
            EncabezadoTablaVehiculo:[
                {text: 'Id', value: 'idVehiculo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Código', value: 'codigo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Nombre', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Tipo Vehículo', value: 'tipoVehiculo.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'bodega.sucursal.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'bodega.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto Actual', value: 'proyecto.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Estado', value: 'idEstado', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Acciones', value: 'actions', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],       
            ArrayObtenerVehiculoEditar:{},
            namespace:'oidcStore/',    
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },            
            EstadoPermisoCrear: true,
            EstadoPermisoEditar: true,
            EstadoPermisoEliminar: true,      
            validarfecha: '',
            ObtenerProcedencia: '',
            // variables para cambiar sucursal
            DialogoCambiarSucursal:false,
            DatosRegistro:{},
            NombreModulo:'',
            ArrayProyectos:[],    
            NombreSucursalActual:'',   
            
            // variables para filtro de busqueda de timeline
            TituloBotonVerMovimientoTimeLine:"Ver movimientos",
            TituloComponente: 'Vehiculos',    
            activeActions:true,
            DialogoCambiarSucursalYBodega:false,
        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    

    created() {
        this.obtenerSucursalesAsignadas()
        this.ObtenerPermisosSubmenuUsuario()
    },

    methods: {
        ResetBodega(){
            this.bodega = 0
            this.bodegaId = 0
            this.TodosVehiculos()
        },
        seleccionarBodega(){
            //si ha seleccionado una sucursal y proyecto
            if(this.sucursal != '' && this.bodega != ''){
                this.activeActions=false
                this.bodegaId=this.bodega
                this.TodosVehiculos()
            }
        },
        seleccionarSucursal(){
            this.bodega = 0
            this.sucursalesAsignadas.forEach(suc => {
                if(suc.sucursalId == this.sucursal){
                    this.sucursalBodegas = suc.bodegas
                }
            })
            this.TodosVehiculos()
        },        
        async obtenerSucursalesAsignadas(){
            const response  = await this.$store.dispatch('misSucursales')
            if(response === undefined){
                showMessageError('No fue posible obtener las sucursales asignadas')
                return
            }else if(response.status === 200){
                this.sucursalesAsignadas = response.data
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.sucursalesAsignadas.push({nombre: 'Todas', sucursalId:0})
                }
            }
        },        
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        
        // obtenemos los permisos del usuario a este modulo        
        ...mapActions('oidcStore', ['authenticateOidcSilent',
                'signOutOidc', 'authenticateOidc']),

        async ObtenerPermisosSubmenuUsuario(role){
           if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
               if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.permisos.crear = true
                    this.permisos.editar = true
                    this.permisos.eliminar = true

                    this.EstadoPermisoCrear = true
                    this.EstadoPermisoEditar= true
                    this.EstadoPermisoEliminar = true                
                    return 0
               }else{
                   //preguntar al backend por los permisos del submenu del usuario
                    var url = 'vehiculos'
                    const response  = await this.$store.dispatch('PermisosSubMenu', url)
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        return 0
                    }
                    if(response.status === 200){
                        this.alertError= false
                      this.EstadoPermisoCrear = response.data.crear
                      this.EstadoPermisoEditar = response.data.editar
                      this.EstadoPermisoEliminar = response.data.eliminar

                      this.permisos = response.data
                    }else if(response.status === 400){
                        showMessageError('Error 400','Petición mala')
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        this.loading = false                      
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                    }
                    return 0
               }
           }
        },

        // metodos de vehiculos para crear un vehiculo
            AbrirDialogoCrearVehiculo(){
                if(this.permisos.crear) this.EstadoPermisoEditar = true
                this.DialogoCrearVehiculo = true
                this.Titulo = 'Crear Vehiculo'
                this.EsNuevo = true
            },
            CerrarDialogoCrearVehiculo(){
                this.DialogoCrearVehiculo = false
            },
        //metodos para eliminar un vehiuclo
            AbrirMensajeEliminarVehiculo(item){
                this.tituloVehiculoMensajeEliminar = item.nombre
                this.idVehiculo = item.idVehiculo                
                this.DialogoMensajeEliminar = true                
            },
            CerrarMensajeEliminarVehiculo(){
                this.DialogoMensajeEliminar = false
            },

        // metodos de vehiculos para Editar un vehiculo
           async AbrirDialogoEditarVehiculo(item){
               this.EstadoCircularProgress = true
               const response = await this.$store.dispatch('ObtenerVehiculoById', item.idVehiculo)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }                                
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayObtenerVehiculoEditar = response.data
                    this.validarfecha = response.data.fechaCompra
                    this.ObtenerProcedencia = response.data.procedencia
                    this.EstadoCircularProgress = false
                    this.DialogoCrearVehiculo = true
                    this.Titulo = 'Editar Vehiculo'
                    this.EsNuevo = false
                    if(this.permisos.editar){
                        this.EstadoPermisoEditar = true    
                    }else{
                        this.EstadoPermisoEditar = false
                    }
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            },
        //eliminamos el vehiculo por su id
            async EliminarVehiculoById(){
                this.loadingEliminarVehiculo = true
                const response = await this.$store.dispatch('EliminarVehiculo', this.idVehiculo)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loadingEliminarVehiculo = false                    
                    return 0
                }                                 
                if(response.status === 200){
                    this.alertError= false
                    this.TodosVehiculos()
                    showMessageSuccess('¡BIEN!', 'Vehículo eliminado correctamente')
                    this.loadingEliminarVehiculo = false
                    this.CerrarMensajeEliminarVehiculo()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loadingEliminarVehiculo = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loadingEliminarVehiculo = false                    
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loadingEliminarVehiculo = false                    
                }
            },

        //obtenemos todos los vehicykis creadas y lo mostramos en la tabla ----------------------------------------------------
            async TodosVehiculos(){
                this.loading = true
                var data = {idEstado : 1, bodega:this.bodegaId, sucursal : this.sucursal} 
                const response = await this.$store.dispatch('ObtenerTodosLosVehiculos', data)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayToddosVehiculos = response.data   
                    this.loading = false  
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false  
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false  
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false  
                }
            },
        //cambiamos una sucursal a un vehiculo
            async AbrirModalCambiarProyecto(data){
                this.EstadoCircularProgress = true
                this.NombreModulo = "Vehiculo"
                this.DatosRegistro = data
                if(data.proyecto){
                    this.NombreSucursalActual = data.proyecto.nombre
                }else{
                    this.NombreSucursalActual = ''
                }

                const response = await this.$store.dispatch('getAllProyectos')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }else if(response.status === 200){
                    this.alertError= false
                    this.ArrayProyectos = response.data
                    this.EstadoCircularProgress = false
                    this.DialogoCambiarSucursal = true
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')    
                    this.EstadoCircularProgress = false        
                }                            
            },

            CerrarModalCambiarSucursal(){
                this.DialogoCambiarSucursal = false
            }, 
            
            async AbrirModalCambiarDeSucursalYBodega(data){
                this.NombreModulo = "Vehiculo"
                this.DatosRegistro = data
                this.DialogoCambiarSucursalYBodega = true
            },

            CerrarModalCambiarSucursalyBodegaMaquinaria(){
                this.DialogoCambiarSucursalYBodega = false
            },            
    },
}
</script>
<style>
    .titulovehiculos {
        font-family: 'PT Serif';
        font-size: 25px;        
    }
    .EstiloAcciones {
        max-width: 30px;   
    }    
</style>