<template>
    <div>
        <v-dialog
            v-model="AbrirTimeLine"
            fullscreen scrollable
        >
            <v-card class="FuenteTimeLine">
                <v-card-title class="AppBar" dark elevation="0" style="color: #ffffff">
                    <v-toolbar-title >
                        <v-icon large left color="white">fas fa-exchange-alt</v-icon>Movimientos de cambios de proyectos -
                        <span>Total: {{ArrayMovimientosSucursales.length}} </span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarModalTimeLine()">Salir
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Salir</span>
                    </v-tooltip>

                </v-card-title>
                <v-card-text>
                    <v-timeline dense-top class="mx-10">
                        <v-timeline-item color="#050505" fill-dot
                            v-for="(item, index) in ArrayMovimientosSucursales" :key="index"
                        >
                            <template v-slot:icon>
                                <v-icon color="white">
                                    mdi-text-box-check
                                </v-icon>
                            </template>  
                            <template v-slot:opposite>
                                    <span>Fecha: {{item.fechaAsignacion}}</span>
                                </template>                    
                            <v-card>
                                <v-card-title class="indigo TamañoLetraEncabezado">
                                    <v-icon dark size="30" class="mr-4" >fas fa-fingerprint</v-icon>
                                    <h2 class="white--text">ID del movimiento: {{item.mov_ProyectoMaquinariaId}} {{item.mov_ProyectoVehiculoId}}</h2>
                                </v-card-title>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="12" class="mt-0 pt-0 mb-0 pb-0"> 
                                            <v-list dense>
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-account</v-icon>
                                                    <v-list-item-content>Usuario Crea:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.usuarioCrea }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-account</v-icon>
                                                    <v-list-item-content>Usuario Modifica:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.usuarioModifica }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item v-if="item.proyecto">
                                                    <v-icon left color="black">mdi-home</v-icon>
                                                    <v-list-item-content>Recibe proyecto:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.proyecto.nombre }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-card-text-outline</v-icon>
                                                    <v-list-item-content>Descripción:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.descripcion }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>   
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>                    
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        AbrirTimeLine: Boolean,
        ArrayMovimientosSucursales: Array,
    },

    data() {
        return {
        }
    },

    methods: {
        CerrarModalTimeLine(){
            this.$emit('CerrarModalTimeLine')
        }
    },
}
</script>
<style>
    .FuenteTimeLine {
        font-family: 'PT Serif';
    }
    .TamañoLetraEncabezado {
        font-size: 15px;        
    }
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }    
</style>
