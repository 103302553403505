<template>
    <v-dialog v-model="AbrirTablaDeVehiculosPorSolicitud" fullscreen scrollable>
        <v-card>
            
            <v-card-title class="AppBar" elevation="0" style="color: #ffffff">
                <v-icon color="white" left>fas fa-car-side</v-icon> Vehículos de la solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalVehiculosDeSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>                
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <!-- <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>             -->
                    <div class="my-3">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaterial">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaterial">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaterial">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>                        
                    <v-toolbar title elevation="1">
                        <v-chip color="blue" outlined>
                            <v-icon left>fas fa-car-side</v-icon>
                            <v-toolbar-title class="tituloMaterial">Vehículos solicitados</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>                    

                        <v-spacer></v-spacer>      
                        <v-text-field flat class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            prepend-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                                <v-btn v-if="filtrarEstadoSolicitudesVehiculos==='1'"  class="ml-2" dark color="indigo" v-on="on" :disabled="loading || !activarBotonAutorizar" @click="AutorizarTodasLasSolicitudesSeleccionadas()"
                                    :loading="loading" 
                                >Autorizar vehículos
                                    <v-icon color="white" >far fa-check-circle</v-icon>
                                </v-btn>
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table v-if="this.filtrarEstadoSolicitudesVehiculos==='1'"
                        :headers="EncabezadoTablaListadoSolicitudVehiculos"
                        :items="VehiculosBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                        v-model="selected"         
                        :single-select="singleSelect"
                        show-select
                        item-key="vehiculo.idVehiculo"
                    >
                        <template v-slot:item.estado="{ item }">
                            <v-row justify="space-around">
                                <v-chip color="blue" text-color="white" v-if="item.estado === 1"> 
                                    <span class="fontButton">En proceso</span>
                                </v-chip>
                                <v-chip color="green" text-color="white" v-if="item.estado === 2"> 
                                    <span class="fontButton">Autorizada</span>
                                </v-chip> 
                                <v-chip color="red" text-color="white" v-if="item.estado === 3"> 
                                    <span class="fontButton">Rechazada</span>
                                </v-chip>                 
                            </v-row>
                        </template>       
                        <template v-slot:no-data v-if="VehiculosBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen vehículos en esta solicitud
                            </v-alert>
                        </template>                                     
                    </v-data-table>                                           
                    <v-data-table v-if="this.filtrarEstadoSolicitudesVehiculos!=='1'"
                        :headers="EncabezadoTablaListadoSolicitudVehiculos"
                        :items="VehiculosBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                        item-key="vehiculo.idVehiculo"
                    >
                        <template v-slot:item.estado="{ item }">
                            <v-row justify="space-around">
                                <v-chip color="blue" text-color="white" v-if="item.estado === 1"> 
                                    <span class="fontButton">En proceso</span>
                                </v-chip>
                                <v-chip color="green" text-color="white" v-if="item.estado === 2"> 
                                    <span class="fontButton">Autorizada</span>
                                </v-chip> 
                                <v-chip color="red" text-color="white" v-if="item.estado === 3"> 
                                    <span class="fontButton">Rechazada</span>
                                </v-chip>                 
                            </v-row>
                        </template>        
                        <template v-slot:no-data v-if="VehiculosBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen vehículos en esta solicitud
                            </v-alert>
                        </template>                                     
                    </v-data-table>                       
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../../views/BotonActualizarTabla'
import AutorizarSolicitudDeHerramienta from '../../../components/bodega/AutorizarSolicitudHerramienta/FormularioAutorizarSolicitud'
import VerMotivoRechazoSolicitud from '../../../views/Bodegas/VerMotivoRechazoSolicitud'

export default {
    props:{
        AbrirTablaDeVehiculosPorSolicitud: Boolean,
        VehiculosBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,        
        filtrarEstadoSolicitudesVehiculos: String,
    },
    components:{
        BotonActualizarTabla,
        AutorizarSolicitudDeHerramienta,
        VerMotivoRechazoSolicitud,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaListadoSolicitudVehiculos:[
                {text: 'Código', value: 'vehiculo.idVehiculo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Código', value: 'vehiculo.codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'vehiculo.placa', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'vehiculo.color', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculo.vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Combustible', value: 'vehiculo.tipoCombustible.nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo', value: 'vehiculo.tipoVehiculo.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal Origen', value: 'vehiculo.bodega.sucursal.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega Origen', value: 'vehiculo.bodega.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto actual', value: 'vehiculo.proyecto.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Estado', value: 'estado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
            ],
  
            EncabezadoTablaSolicitudes:[],        

            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],    
                    
            singleSelect: false,
            selected: [],
            activarBotonAutorizar: false,
        }
    },

    watch: {
        selected(){
            // console.log('selected: ', this.selected.length)
            if(this.selected.length > 0){
                this.activarBotonAutorizar = true
            }else{
                this.activarBotonAutorizar = false
            }
        }
    },

    created() {        
    },

    methods: {
        //-----------------------------------------------------------------------------
        MapearMaquinarias(datos){
            this.ArrayTodasLasSolicitudesAutorizar=[]
            Object.keys(datos).forEach((element,key,_array)=>{
                this.ArrayTodasLasSolicitudesAutorizar.push({
                    sol_ProyectoVehiculoId:datos[element].sol_ProyectoVehiculoId,
                    autorizado: true,
                });
            });
        },

        async AutorizarTodasLasSolicitudesSeleccionadas(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.MapearMaquinarias(this.selected)
            // console.log('ArrayTodasLasSolicitudesAutorizar: ', this.ArrayTodasLasSolicitudesAutorizar)
            var data = {vehiculos: this.ArrayTodasLasSolicitudesAutorizar, proyectoSolicitudId: this.DatosSolicitud.idProyecto}
            const response = await this.$store.dispatch('AutorizarSolicitudesDeVehiculosSeleccionadas', data)
            if(response.status === 200){
                this.selected=[]
                this.alertErrorPost=false
                this.arrayErrorPost=[]                                                          
                this.loading = false
                this.$emit('ObtenerListadoDeSolicitudesDeVehiculos')
                showMessageSuccess('Bien!', response.data.message)
                this.CerrarModalVehiculosDeSolicitud()
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false     
                setTimeout(() => {
                    this.alertErrorPost=false
                }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },
                
        CerrarModalVehiculosDeSolicitud(){
            this.$emit('CerrarModalVehiculosDeSolicitud')
        }
    },
}
</script>
<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
    .tituloMaterial {
        font-family: 'PT Serif';
        font-size: 20px;        
    }      
</style>