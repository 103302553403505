<template>
    <div>
        <v-dialog
            v-model="AbrirDialogoNuevaSucursal"
            persistent :overlay="false"
            max-width="1000px" scrollable
            transition="dialog-transition"
        >
            <v-card>
                <!-- barra principal del dialogo -->
                <v-app-bar color="#3c8dbc" flat dark>
                    <v-toolbar-title class="titulo">{{TituloFormulario}} Proyecto</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- boton registrar sucursal -->
                    <v-tooltip top color="success" v-if="this.esNuevo ">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" color="success" rounded class="mr-4" @click="GuardarProyecto()"
                                :loading="loading" :disabled="loading || desactivarBotonGuardar" 
                            >
                                <v-icon left>fas fa-save</v-icon> Guardar
                            </v-btn>                
                        </template>
                        <span>Registrar Proyecto</span>
                    </v-tooltip>
                    <!-- boton editar sucursal -->
                    <v-tooltip top color="success" v-if="!this.esNuevo">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" color="success" rounded class="mr-4" @click="GuardarCambios()"
                                :loading="loading" :disabled="loading  || !EstadoPermisoEditar || desactivarBotonGuardar" 
                            >
                                <v-icon left>fas fa-save</v-icon> Guardar
                            </v-btn>                
                        </template>
                        <span>Cambios Proyecto</span>
                    </v-tooltip>
                    <v-btn large color="error" rounded dark @click="CerrarModalNuevaSucursal()">
                        <v-icon left>fas fa-window-close</v-icon> Cancelar
                    </v-btn>                                                                       
                </v-app-bar>             
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>  
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                     
                <v-card-text class="TipoFuente">
                    <v-alert dense outlined type="error" v-if="alertEncargadoGestorIguales">
                        El encargado y el gestor no pueden ser los mismos para un proyecto.
                    </v-alert>                                      
                    <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                        <v-row wrap>
                            <!-- nombre sucursal -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >                    
                                <v-text-field :disabled="!EstadoPermisoEditar"
                                    outlined
                                    label="Nombre"
                                    v-model="modeloCrearSucursal.nombre"
                                    prepend-inner-icon="fas fa-building"
                                ></v-text-field>
                            </v-col>
                            <!-- direccion -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >                    
                                <v-text-field :disabled="!EstadoPermisoEditar"
                                    outlined
                                    label="Dirección"
                                    v-model="modeloCrearSucursal.direccion"
                                    prepend-inner-icon="fas fa-map-marker-alt"
                                ></v-text-field>
                            </v-col>
                            <!-- telefono -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >                    
                                <v-text-field :disabled="!EstadoPermisoEditar"
                                    outlined type="number"
                                    label="Teléfono"
                                    v-model="modeloCrearSucursal.telefono"
                                    prepend-inner-icon="fas fa-phone"
                                ></v-text-field>
                            </v-col>
                            <!-- encargado -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >
                                <v-select :disabled="!EstadoPermisoEditar "
                                    outlined
                                    :items="UsuariosPorSucursal"
                                    item-value="email"                                    
                                    item-text="nombre"
                                    label="Seleccionar Encargado"
                                    prepend-inner-icon="fas fa-user"
                                    v-model="modeloCrearSucursal.usuarioEncargado"
                                    v-on:change="selectSucursal()"
                                ></v-select>                                
                            </v-col>
                            <!-- seleccionar gestor -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >
                                <v-select :disabled="!EstadoPermisoEditar "
                                    outlined
                                    :items="UsuariosPorSucursal"
                                    item-value="email"                                    
                                    item-text="nombre"
                                    label="Seleccionar Gestor"
                                    prepend-inner-icon="fas fa-user"
                                    v-model="modeloCrearSucursal.usuarioGestor"
                                    v-on:change="selectSucursalGestor()"
                                ></v-select>                                
                            </v-col>
                            <!-- estadoproyecto -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >                    
                                <v-select :disabled="!EstadoPermisoEditar"
                                    outlined
                                    :items="EstadoDelProyecto"
                                    item-value="estado"                                    
                                    item-text="estado"
                                    label="Estado Proyecto"
                                    v-model="modeloCrearSucursal.estadoProyecto"
                                    prepend-inner-icon="fas fa-question-circle"
                                ></v-select>
                            </v-col>
                            <!-- fecha Inicio, cuando es nuevo -->
                            <v-col cols="12" lg="6" md="5" sm="6" class="pt-0 pb-0" > 
                                <v-menu
                                    v-model="menuFechaInicio"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                            v-model="modeloCrearSucursal.fechaInicio"
                                            label="Fecha Inicio"
                                            prepend-inner-icon="far fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker locale="es-GT" v-model="modeloCrearSucursal.fechaInicio" @input="menuFechaInicio = false"></v-date-picker>
                                </v-menu>
                            </v-col>                                    
                            <!-- fecha fin, cuando es nuevo -->
                            <v-col cols="12" lg="6" md="5" sm="6" class="pt-0 pb-0" > 
                                <v-menu
                                    v-model="menuFechaFin"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                            v-model="modeloCrearSucursal.fechaFin"
                                            label="Fecha Finalización"
                                            prepend-inner-icon="far fa-calendar-alt"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker locale="es-GT" v-model="modeloCrearSucursal.fechaFin" @input="menuFechaFin = false"></v-date-picker>
                                </v-menu>
                            </v-col>                                    
                            <!-- observaciones -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 mt-0 pb-0 mb-0" >                    
                                <v-textarea :disabled="!EstadoPermisoEditar"
                                    outlined
                                    label="Observaciones"
                                    v-model="modeloCrearSucursal.observaciones"
                                    prepend-inner-icon="fas fa-file-signature"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'

export default {
    props: {
        esNuevo: Boolean,
        TituloFormulario: String,
        AbrirDialogoNuevaSucursal: Boolean,
        DatosEditarSucursal: Object,
        EstadoPermisoEditar: Boolean,
        UsuariosPorSucursal: Array,
    },

    data() {
        return {
            desactivarBotonGuardar:false,
            alertEncargadoGestorIguales:false,
            alertErrorPost:false,
            arrayErrorPost:[],
            alertError:false,
            loading: false,
            valid: false,
            lazy: false,                  
            EstadoDelProyecto: [
                {id: 1, estado: 'Activo'},
                {id: 2, estado: 'Suspendido'},
                {id: 3, estado: 'Finalizado'},
            ],
            modeloCrearSucursal:{
                idSucursal:0,
                nombre:'',
                direccion:'',
                telefono:'',
                usuarioEncargado:'',
                nombreEncargado:'',
                usuarioGestor:'',
                nombreGestor:'',
                estadoProyecto:'',
                fechaInicio: new Date().toISOString().substr(0, 10),
                fechaFin: new Date().toISOString().substr(0, 10),
                observaciones:'',
            },
            menuFechaInicio: false,
            menuFechaFin: false,
        }
    },
    watch: {
        AbrirDialogoNuevaSucursal(){
            if(this.TituloFormulario === "Editar") this.modeloCrearSucursal = this.DatosEditarSucursal
            else this.modeloCrearSucursal.idSucursal = 0
        }
    },
    methods: {
        selectSucursal(){
            this.UsuariosPorSucursal.forEach(per => {
                if(per.email === this.modeloCrearSucursal.usuarioEncargado){
                    this.modeloCrearSucursal.nombreEncargado = per.nombre
                    // console.log('per: ',per)
                    // console.log('usuarioEncargado: ',this.modeloCrearSucursal.usuarioEncargado)
                }

                if(this.modeloCrearSucursal.usuarioEncargado === this.modeloCrearSucursal.usuarioGestor){
                    this.alertEncargadoGestorIguales = true
                    this.desactivarBotonGuardar = true
                }else{
                    this.alertEncargadoGestorIguales =false
                    this.desactivarBotonGuardar = false
                }
            });
        },
        selectSucursalGestor(){
            this.UsuariosPorSucursal.forEach(per => {
                if(per.email === this.modeloCrearSucursal.usuarioGestor){
                    this.modeloCrearSucursal.nombreGestor = per.nombre
                }
                if(this.modeloCrearSucursal.usuarioEncargado === this.modeloCrearSucursal.usuarioGestor){
                    this.alertEncargadoGestorIguales = true
                    this.desactivarBotonGuardar = true
                }else{
                    this.alertEncargadoGestorIguales =false
                    this.desactivarBotonGuardar = false
                }                
            });
        },
        CerrarModalNuevaSucursal(){
            this.alertEncargadoGestorIguales =false
            this.desactivarBotonGuardar = false            
            this.alertErrorPost=false
            this.arrayErrorPost=[]
            this.$refs.form.reset()            
            this.$emit("CerrarModalNuevaSucursal")
        },

        // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            // this.loading = true
            if(typeof response === 'undefined'){
                this.alertError=true
                setTimeout(() => { this.alertError=false }, 20000);
                this.loading = false
                return 0
            }else if(response.status === 201){
                if(this.TituloFormulario === "Crear"){
                    showMessageSuccess('¡BIEN!', 'Sucursal registrada correctamente')
                    this.alertError= false
                    this.loading = false
                    this.$emit("ObtenerTodasSucursales")
                    this.CerrarModalNuevaSucursal()
                }
            }else if(response.status === 200){
                if(this.TituloFormulario === "Editar"){
                    showMessageSuccess('¡BIEN!', 'Sucursal actualizado correctamente')
                    this.modeloCrearSucursal.idSucursal= ''
                    this.modeloCrearSucursal.nombre =''
                    this.modeloCrearSucursal.direccion=''
                    this.modeloCrearSucursal.telefono=''
                    this.modeloCrearSucursal.usuarioEncargado=''
                    this.modeloCrearSucursal.nombreEncargado=''
                    this.modeloCrearSucursal.estadoProyecto=''
                    this.modeloCrearSucursal.fechaInicio=''
                    this.modeloCrearSucursal.fechaFin=''
                    this.modeloCrearSucursal.observaciones=''
                    this.alertError= false
                    this.loading = false
                    this.$emit("ObtenerTodasSucursales")
                    this.$emit("CerrarModalNuevaSucursal")
                    // this.CerrarModalNuevaSucursal()
                }
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }
        },        
        
        // Guardamos el nuevo proyecto
        async GuardarProyecto(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            if(this.$refs.form.validate()){
                if(this.TituloFormulario === "Crear"){
                    const response = await this.$store.dispatch('SaveProyecto', this.modeloCrearSucursal)
                    this.ControllerException(response)
                }
            }else{
                this.loading = false                
            }            
        },
        // Guardamos los cambios de una sucursal
        async GuardarCambios(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            if(this.TituloFormulario === "Editar"){
                if(this.$refs.form.validate()){
                    const response = await this.$store.dispatch('SaveChangesProyecto', this.modeloCrearSucursal)
                    this.ControllerException(response)
                }else{
                    this.loading = false                
                }                        
            }
        }

    },
}
</script>