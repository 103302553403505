<template>
    <div>
        <v-card flat>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>            
            <v-toolbar flat title>
                <v-toolbar-title class="tituloMaquinaria">Unidades de Medida</v-toolbar-title>
                <v-divider vertical class="ml-3"></v-divider>    

                <v-spacer></v-spacer>      
                <v-text-field outlined class=" pr-4"
                    label="Buscar Unidad de Medida"
                    v-model="buscarUnidadMedida"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
                <v-tooltip bottom color="#0019d8" v-if="permisos.crear">
                    <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" @click="AbrirModalRegistrarUnidadMedida()" dark rounded v-on="on">
                            <v-icon left>fas fa-save</v-icon> Crear
                        </v-btn>
                    </template>
                    <span>Nueva Unidad de Medida</span>
                </v-tooltip>                                      
            </v-toolbar>
            <v-data-table
                :headers="EncabezadoTablaUnidadDeMedida"
                :items="ArrayUnidadDeMedida"
                class="elevation-1"
                :search="buscarUnidadMedida"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading="loading"
                loading-text="Cargando Datos..."                                
            >
                <template v-slot:item.action="{ item }">
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#d69b01" v-on="on" @click="AbrirModalEditarUnidadMedida(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar Unidad de Medida</span>
                    </v-tooltip>                                              
                    <v-tooltip left color="#D80000" v-if="permisos.eliminar">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#D80000" v-on="on" @click="AbrirModalEliminarUnidadMedida(item)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar Unidad de Medida</span>
                    </v-tooltip>                                              
                </template>         
                <template v-slot:no-results>
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                        Unidad de Medida no existe
                    </v-alert>                        
                </template>                                   
                <template v-slot:no-data v-if="ArrayUnidadDeMedida.length === 0">
                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError">
                        No existen Unidades de Medidas registradas
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>
                </template>                                
            </v-data-table>
        </v-card>
        <!-- mostramos el circular progress cuando carguen los datos para editar una unidad de medida -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular>
        </v-overlay>                      
        <RegistrarUnidadDeMedida 
            :AbrirDialogoRegistrarUnidadMedida="AbrirDialogoRegistrarUnidadMedida" @CerrarModalRegistrarUnidadMedida="CerrarModalRegistrarUnidadMedida"
            :esNuevo="esNuevo" :TituloDialogo="TituloDialogo" @GetAllUnitsMeasure="GetAllUnitsMeasure"
            :DatosEditarUnidadMedida="DatosEditarUnidadMedida" :EstadoPermisoEditar="EstadoPermisoEditar"
        />
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminar"
            :tituloMensajeEliminar="tituloMensajeEliminar" @Eliminar="EliminarUnidadMedida"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarUnidadMedida" :alertError="alertError"
        />        
    </div>    
</template>

<script>
import { mapGetters, mapActions  } from 'vuex'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import RegistrarUnidadDeMedida from '../../../components/IngresoNuevaInformacion/UnidadDeMedida/RegistrarUnidadDeMedida'
import MensajeEliminar from '../../../components/MensajeEliminar'

export default {
    components: {
        RegistrarUnidadDeMedida,
        MensajeEliminar,
    },
    data() {
        return {
            EncabezadoTablaUnidadDeMedida:[
                {text: 'Unidad de Medida', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],                   
            buscarUnidadMedida:'',
            ArrayUnidadDeMedida:[],
            loading:false,
            alertError:false,
            //variables para los permisos del colaborador
            namespace:'oidcStore/',
            EstadoPermisoEditar: false,
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },
            // nueva unidad de medida
            esNuevo: false,
            TituloDialogo:'',
            AbrirDialogoRegistrarUnidadMedida: false,
            // eliminar unidad de medida
            DialogoMensajeEliminar:false,
            tituloMensajeEliminar:'',
            loadingEliminarUnidadMedida: false,
            IdUnidadMedida: '',
            // editar unidad de medida
            DatosEditarUnidadMedida:{},
            EstadoCircularProgress:false,
        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    

    created() {
        this.GetAllUnitsMeasure()
        this.ObtenerPermisosSubmenuUsuario()
    },

    methods: {
        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),
            async ObtenerPermisosSubmenuUsuario(role){
                if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                    if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                            this.permisos.crear = true
                            this.permisos.editar = true
                            this.permisos.eliminar = true
                            return 0            
                    }else{
                        //preguntar al backend por los permisos del submenu del usuario
                            var url = 'unidadesdemedida'
                            const response  = await this.$store.dispatch('PermisosSubMenu', url)
                            if(typeof response === 'undefined'){
                                this.alertError=true
                                this.loading=false
                                return 0
                            }                                                 
                            if(response.status === 200){
                                this.alertError= false
                                this.permisos = response.data         
                            }else if(response.status === 400){
                                showMessageError('Error 400','Petición mala')
                            }else if(response.status === 404){
                                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                            }else if(response.status === 500){
                                showMessageError('Error 500','Error en el servidor')            
                            }
                            return 0
                    }
                }
            },                    
        // controlamos las excepciones de las peticiones al api
            ControllerException(response){
                if(typeof response === 'undefined'){
                    this.alertError=true
                    this.loading = false
                    this.EstadoCircularProgress = false
                    this.loadingEliminarUnidadMedida = false
                    return 0
                }if(response.status === 200){
                    this.alertError= false
                    this.loading = false                    
                    if(this.TituloDialogo === "eliminar"){
                        showMessageSuccess('¡BIEN!', 'Unidad de Medida eliminado correctamente')
                        this.TituloDialogo = ''
                        this.GetAllUnitsMeasure()
                        this.loadingEliminarUnidadMedida = false
                        this.CerrarDialogoMensajeEliminar()
                    }else if(this.TituloDialogo === "Editar"){
                        if(this.permisos.editar) this.EstadoPermisoEditar = this.permisos.editar
                        else this.EstadoPermisoEditar = this.permisos.editar
                        this.DatosEditarUnidadMedida = response.data
                        this.EstadoCircularProgress = false
                        this.AbrirDialogoRegistrarUnidadMedida = true
                    }else{
                        this.ArrayUnidadDeMedida = response.data
                    }
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                    this.EstadoCircularProgress = false
                }
            },

        // obtenemos todos las unidades de medida
            async GetAllUnitsMeasure(){
                this.loading = true
                const response = await this.$store.dispatch('getAllUnitsMeasure')
                // controlamos las excepciones
                this.ControllerException(response)            
            },
        //metodos para registrar una nueva unidad de medida
            AbrirModalRegistrarUnidadMedida(){
                if(this.permisos.crear) this.EstadoPermisoEditar = this.permisos.crear
                this.esNuevo = true
                this.TituloDialogo = "Crear"
                this.AbrirDialogoRegistrarUnidadMedida = true
            },
            CerrarModalRegistrarUnidadMedida(){
                this.TituloDialogo = ''
                this.AbrirDialogoRegistrarUnidadMedida = false
            },
        //metodos para eliminar una nueva unidad de medida
            AbrirModalEliminarUnidadMedida(item){
                this.TituloDialogo = "eliminar"
                this.tituloMensajeEliminar = item.nombre
                this.IdUnidadMedida = item.idUnidadDeMedida
                this.DialogoMensajeEliminar = true
            },
            CerrarDialogoMensajeEliminar(){
                this.DialogoMensajeEliminar = false
            },
            async EliminarUnidadMedida(){
                this.loadingEliminarUnidadMedida = true
                const response = await this.$store.dispatch('DeleteUnitMeasure', this.IdUnidadMedida)
                this.ControllerException(response)
            },
        // metodos para editar una unidad de medida
            async AbrirModalEditarUnidadMedida(item){
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('GetUnitsMeasureById', item.idUnidadDeMedida)
                this.esNuevo = false
                this.TituloDialogo = "Editar"
                this.ControllerException(response)
            }
    },
}
</script>