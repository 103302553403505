<template>
    <div>
        <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>            
        
        <v-toolbar title elevation="1">
            <v-toolbar-title class="tituloMaquinaria">{{TituloTabla}}</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerInventarioVehiculosPorProyecto" />
        </v-toolbar>        
        <v-data-table
            :headers="EncabezadoTablaInventarioSucursal"
            :items="ArrayInventarioVehiculo"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."                                
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom color="#0019d8">
                    <template v-slot:activator="{ on }">
                        <v-btn small color="#0019d8" dark fab v-on="on" @click="AbrirDialogoRegistroDeIncidentes(item)" v-if="EstadoPermisoCrear">
                            <v-icon>fas fa-car-crash</v-icon>
                        </v-btn>
                    </template>
                    <span>Registrar Incidente</span>
                </v-tooltip>                                          
            </template>            
            <template v-slot:no-data v-if="ArrayInventarioVehiculo.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    No existen vehículos registrados.
                </v-alert>
            </template>                                                 
        </v-data-table>
        <RegistrarIncidenteDeVehiculo 
            :DialogoRegistroDeIncidentes="DialogoRegistroDeIncidentes" @CerrarDialogo="CerrarDialogoRegistroDeIncidentes"
            :Titulo="Titulovehiculos" :DataVehiculo="DataVehiculo"
        />
    </div>
</template>

<script>
// import NuevaSolicitudDeHerramienta from '../../../../components/Almacen/Herramientas/NuevaSolicitudHerramienta/NuevaSolicitudHerramienta'
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import RegistrarIncidenteDeVehiculo from '../../../../components/Mantenimiento/Vehiculos/RegistrarIncidenteDeVehiculo'
import BotonActualizarTabla from '../../../BotonActualizarTabla'

export default {
    components: {
        RegistrarIncidenteDeVehiculo,
        BotonActualizarTabla,
    },

    props: {
        tab: String,
        TituloTabla: String,
        ArrayInventarioVehiculo: Array,
        loading: Boolean,
        EstadoPermisoCrear: Boolean,
    },

    data() {
        return {
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaInventarioSucursal:[
                {text: 'Código', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca Vehículo', value: 'vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Combustible', value: 'tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Vehículo', value: 'tipoVehiculo.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega origen', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Transferencia', value: 'mov_ProyectoVehiculo.fechaAsignacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],         

            DialogoRegistroDeIncidentes: false,
            DataVehiculo:{
                idVehiculo:'',
                tipo: '',
                marca:'',
                placa:'',
                propietario:''
            },
            Titulovehiculos:'',

        }
    },

    created() {

    },
    methods: {
        ObtenerInventarioVehiculosPorProyecto(){
            this.$emit('ObtenerInventarioVehiculosPorProyecto')
        },

        //abrimos el dialogo con los incidente del vehiculo seleccionado
        AbrirDialogoRegistroDeIncidentes(item){
            // if(this.permisos.crear) this.EstadoPermisoEditar = true
            this.Titulovehiculos = 'Vehículo'
            this.EsNuevo = true
            this.DataVehiculo.idVehiculo = item.idVehiculo
            this.DataVehiculo.tipo = item.tipoVehiculo.nombre
            this.DataVehiculo.marca = item.vehiculoMarca.nombre
            this.DataVehiculo.placa = item.placa
            this.DataVehiculo.propietario = item.propietario
            this.DialogoRegistroDeIncidentes = true
        },

        CerrarDialogoRegistroDeIncidentes(){
            this.DialogoRegistroDeIncidentes = false
        },
    },
}
</script>