<template>
    <div>
        <v-card flat>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>            
            <v-toolbar title elevation="1">
                <v-toolbar-title class="tituloMaquinaria">Marcas Materia Prima</v-toolbar-title>
                <v-divider vertical class="ml-3"></v-divider>    

                <v-spacer></v-spacer>      
                <v-text-field outlined class=" pr-4"
                    label="Buscar Marca"
                    v-model="buscarMarca"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
                <v-tooltip bottom color="#0019d8" v-if="permisos.crear">
                    <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" @click="AbrirModalRegistrarMarca()" dark rounded v-on="on">
                            <v-icon left>fas fa-save</v-icon> Crear
                        </v-btn>
                    </template>
                    <span>Nueva Marca</span>
                </v-tooltip>                                      
            </v-toolbar>
            <v-data-table
                :headers="EncabezadoTablaMarcas"
                :items="ArrayMarcasMateriaPrima"
                class="elevation-1"
                :search="buscarMarca"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading="loading"
                loading-text="Cargando Datos..."                                
            >
                <template v-slot:item.action="{ item }">
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#d69b01" v-on="on" @click="AbrirModalEditarMarca(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar Marca</span>
                    </v-tooltip>                                              
                    <v-tooltip left color="#D80000" v-if="permisos.eliminar">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#D80000" v-on="on" @click="AbrirModalEliminarMarca(item)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar Marca</span>
                    </v-tooltip>                                              
                </template>            
                <template v-slot:no-results>
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                        Marca no existe
                    </v-alert>                        
                </template>                
                <template v-slot:no-data v-if="ArrayMarcasMateriaPrima.length === 0">
                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError">
                        No existen marcas registradas
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>
                </template>                                
            </v-data-table>
        </v-card>
        <!-- mostramos el circular progress cuando carguen los datos para editar una marca -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular>
        </v-overlay>                      
        <RegistrarMarca 
            :AbrirDialogoRegistrarMarca="AbrirDialogoRegistrarMarca" @CerrarModalRegistrarMarca="CerrarModalRegistrarMarca"
            :esNuevo="esNuevo" :TituloDialogo="TituloDialogo" @GetAllRawMaterial="GetAllRawMaterial"
            :DatosEditarMarca="DatosEditarMarca" :EstadoPermisoEditar="EstadoPermisoEditar"
        />
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminar"
            :tituloMensajeEliminar="tituloMensajeEliminar" @Eliminar="DeleteMarca"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarMarca" :alertError="alertError"
        />        
    </div>    
</template>

<script>
import { mapGetters, mapActions  } from 'vuex'
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import RegistrarMarca from '../../../../components/IngresoNuevaInformacion/MarcasMateriaPrima/RegistrarMateriaPrima'
import MensajeEliminar from '../../../../components/MensajeEliminar'

export default {
    props:{
        tab: String,
    },
    components: {
        RegistrarMarca,
        MensajeEliminar,
    },
    data() {
        return {
            EncabezadoTablaMarcas:[
                {text: 'Marca Materia Prima', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                // {text: 'Descripción', value: 'descripcion', sortable: false, align: 'justify', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],                   
            buscarMarca:'',
            ArrayMarcasMateriaPrima:[],
            loading:false,
            alertError:false,
            //variables para los permisos del colaborador
            namespace:'oidcStore/',
            EstadoPermisoEditar: false,
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },
            // nueva marca
            esNuevo: false,
            TituloDialogo:'',
            AbrirDialogoRegistrarMarca: false,
            // eliminar Marca
            DialogoMensajeEliminar:false,
            tituloMensajeEliminar:'',
            loadingEliminarMarca: false,
            IdMarcaMateriaPrima: '',
            // editar Marca
            DatosEditarMarca:{},
            EstadoCircularProgress:false,
        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    
    watch: {
        tab(){
            if(this.tab==="tab-3")this.GetAllRawMaterial()          
        }
    },
    created() { 
        this.GetAllRawMaterial()
        this.ObtenerPermisosSubmenuUsuario()
    },

    methods: {
        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),
            async ObtenerPermisosSubmenuUsuario(role){
                if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                    if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                            this.permisos.crear = true
                            this.permisos.editar = true
                            this.permisos.eliminar = true
                            return 0            
                    }else{
                        //preguntar al backend por los permisos del submenu del usuario
                            var url = 'marca'
                            const response  = await this.$store.dispatch('PermisosSubMenu', url)
                            if(typeof response === 'undefined'){
                                this.alertError=true
                                this.loading=false
                                return 0
                            }                                                 
                            if(response.status === 200){
                                this.alertError= false
                                this.permisos = response.data         
                            }else if(response.status === 400){
                                showMessageError('Error 400','Petición mala')
                            }else if(response.status === 404){
                                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                            }else if(response.status === 500){
                                showMessageError('Error 500','Error en el servidor')            
                            }
                            return 0
                    }
                }
            },                    
        // controlamos las excepciones de las peticiones al api
            ControllerException(response){
                if(typeof response === 'undefined'){
                    this.alertError=true
                    this.loading = false
                    this.EstadoCircularProgress = false
                    this.loadingEliminarMarca = false
                    return 0
                }if(response.status === 200){
                    this.alertError= false
                    this.loading = false                    
                    if(this.TituloDialogo === "eliminar"){
                        showMessageSuccess('¡BIEN!', 'Marca eliminado correctamente')
                        this.TituloDialogo = ''
                        this.GetAllRawMaterial()
                        this.loadingEliminarMarca = false
                        this.CerrarDialogoMensajeEliminar()
                    }else if(this.TituloDialogo === "Editar"){
                        if(this.permisos.editar) this.EstadoPermisoEditar = this.permisos.editar
                        else this.EstadoPermisoEditar = this.permisos.editar
                        this.DatosEditarMarca = response.data
                        this.EstadoCircularProgress = false
                        this.AbrirDialogoRegistrarMarca = true
                    }else{
                        this.ArrayMarcasMateriaPrima = response.data
                    }
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loadingEliminarMarca = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loadingEliminarMarca = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loadingEliminarMarca = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loadingEliminarMarca = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }
            },

        // obtenemos todas las marcas de herramientas
            async GetAllRawMaterial(){
                this.loading = true
                const response = await this.$store.dispatch('getAllRawMaterial')
                // controlamos las excepciones
                this.ControllerException(response)            
            },
        //metodos para registrar una nueva Marca
            AbrirModalRegistrarMarca(){
                if(this.permisos.crear) this.EstadoPermisoEditar = this.permisos.crear
                this.esNuevo = true
                this.TituloDialogo = "Crear"
                this.AbrirDialogoRegistrarMarca = true
            },
            CerrarModalRegistrarMarca(){
                this.TituloDialogo = ''
                this.AbrirDialogoRegistrarMarca = false
            },
        //metodos para eliminar una Marca
            AbrirModalEliminarMarca(item){
                this.TituloDialogo = "eliminar"
                this.tituloMensajeEliminar = item.nombre
                this.IdMarcaMateriaPrima = item.idMarcaMateriaPrima
                this.DialogoMensajeEliminar = true
            },
            CerrarDialogoMensajeEliminar(){
                this.DialogoMensajeEliminar = false
            },
            async DeleteMarca(){
                this.loadingEliminarMarca = true
                const response = await this.$store.dispatch('DeleteRawMaterial', this.IdMarcaMateriaPrima)
                this.ControllerException(response)
            },
        // metodos para editar una Marca
            async AbrirModalEditarMarca(item){
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('GetRawMaterialById', item.idMarcaMateriaPrima)
                this.esNuevo = false
                this.TituloDialogo = "Editar"
                this.ControllerException(response)
            }
    },
}
</script>