var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-toolbar',{attrs:{"title":"","elevation":"1"}},[_c('v-icon',{attrs:{"color":"black ","left":""}},[_vm._v("fas fa-car-side")]),_c('v-toolbar-title',{staticClass:"tituloMaquinaria"},[_vm._v("Inventario de vehículos disponibles")]),_c('v-divider',{staticClass:"ml-3",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:" pr-4",attrs:{"outlined":"","label":"Buscar Registro","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.buscarRegistro),callback:function ($$v) {_vm.buscarRegistro=$$v},expression:"buscarRegistro"}}),_c('BotonActualizarTabla',{on:{"Actualizartabla":_vm.ObtenerInventarioDeVehiculosDisponibles}})],1),_c('v-alert',{attrs:{"value":_vm.alertErrorPost,"type":"error","outlined":"","transition":"scale-transition","color":"deep-orange"}},[_c('h4',[_vm._v("Errores:")]),_c('ul',_vm._l((_vm.arrayErrorPost),function(i){return _c('li',{key:i.value,attrs:{"type":"disc"}},[_c('h6',[_vm._v(_vm._s(i.description))])])}),0)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.EncabezadoTablaInventarioVehiculosDisponibles,"items":_vm.ArrayTablaInventarioVehiculosDisponibles,"item-key":"idHerramienta","search":_vm.buscarRegistro,"footer-props":{itemsPerPageText: 'Filas por página'},"loading":_vm.loading,"loading-text":"Cargando Datos..."},scopedSlots:_vm._u([{key:"item.puedeSolicitar",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"space-around"}},[(item.puedeSolicitar)?_c('v-tooltip',{attrs:{"left":"","color":"#00b40f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"#00b40f","text-color":"white"}},on),[_c('v-icon',[_vm._v("fas fa-check")])],1)]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v("sí puede realizar solicitud")])]):_vm._e(),(!item.puedeSolicitar)?_c('v-tooltip',{attrs:{"left":"","color":"#d80000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"#d80000","text-color":"white"}},on),[_c('v-icon',[_vm._v("fas fa-times-circle")])],1)]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v(_vm._s(item.motivo))])]):_vm._e()],1)]}},(_vm.ArrayTablaInventarioVehiculosDisponibles.length === 0)?{key:"no-data",fn:function(){return [(!_vm.alertError)?_c('v-alert',{staticClass:"text-xs-center textoalerta",attrs:{"dense":"","outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" No hay vehículos disponibles en inventario ")]):_vm._e()]},proxy:true}:null,{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.puedeSolicitar)?_c('v-tooltip',{attrs:{"left":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","dark":"","fab":"","color":"#000000"},on:{"click":function($event){return _vm.AgregarVehiculoASolicitud(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus-circle")])],1)]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v("Agregar Solicitud")])]):_vm._e()]}}],null,true)}),_c('TablaDeSolicitudesDeVehiculosAEnviar',{attrs:{"ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar":_vm.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar,"sucursal":_vm.sucursal,"bodega":_vm.bodega,"proyecto":_vm.proyecto},on:{"ObtenerInventarioDeVehiculosDisponibles":_vm.ObtenerInventarioDeVehiculosDisponibles}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }