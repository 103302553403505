<template>
    <div >
        <v-dialog
            v-model="DialogoIngreseCantidadSolicitarVehiculo"
            scrollable 
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-file-import</v-icon>
                    <v-toolbar-title class="TituloDialogo">Solicitud de Vehículo de Inventario</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="AgregarSolicitudDeVehiculoDeInventario()"
                                :loading="loading" :disabled="loading"                        
                            >Guardar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Agregar Solicitud</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarModalIngreseCantidadASolicitarVehiculos()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar Solicitud</span>
                    </v-tooltip>                    
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Tipo de vehículo:</strong> {{ObjectDatosSolicitudDeVehiculoInventario.tipoHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Vehículo:</strong> {{ObjectDatosSolicitudDeVehiculoInventario.nombreHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Marca de vehículo:</strong> {{ObjectDatosSolicitudDeVehiculoInventario.marcaHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Existencia:</strong> {{ObjectDatosSolicitudDeVehiculoInventario.existencia}}</span>
                </div>
                <hr>
                <v-alert v-if="cantidadMayor"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Alerta:</h4>
                        <ul>
                            <li type="disc">
                                <h6>{{errorAgregarSol}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                                
                <v-card-text class="TituloDialogo">
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- cantidad -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="mt-0 pb-0">
                                <v-text-field outlined 
                                    label="Ingrese cantidad a solicitar de inventario" type="number"
                                    v-model="ObjectDatosSolicitudDeVehiculoInventario.cantidadSolicitada"
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        DialogoIngreseCantidadSolicitarVehiculo: Boolean,
        ObjectDatosSolicitudDeVehiculoInventario: Object,
        ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar: Array,
    },

    data() {
        return {
            alertError:false,                          
            loading: false,
            valid: true,
            lazy: false,
            cantidadMayor:false,
            errorAgregarSol:'Existencia insuficiente',
            rules: [
                value => !!value || 'Campo requerido',
                value => (value && value > 0) || 'Debe ingresar un número mayor que 0',
            ],
        }
    },
    watch:{
        DialogoIngreseCantidadSolicitarVehiculo: function (val){
            if(val === true){  
                this.ObjectDatosSolicitudDeVehiculoInventario.cantidadSolicitada = 0
                this.valid=true
            }
        }
    },

    methods: {
        CerrarModalIngreseCantidadASolicitarVehiculos(){
            this.valid=true
            this.$emit('CerrarModalIngreseCantidadASolicitarVehiculos')
        },

        AgregarSolicitudDeVehiculoDeInventario(){
            var val = this.$refs.form.validate();
            if (val ===true){
                var registra = true
                if(this.ObjectDatosSolicitudDeVehiculoInventario.cantidadSolicitada>this.ObjectDatosSolicitudDeVehiculoInventario.existencia){
                    this.alertCantidad('Existencia insuficiente')
                }else{
                    this.cantidadMayor = false
                    this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.forEach(element => {
                        if(element.idHerramienta === this.ObjectDatosSolicitudDeVehiculoInventario.idHerramienta){
                            this.alertCantidad('Ya agregó una solicitud de esta vehículo');
                            registra=false
                            return 0
                        }
                    });
                    if(registra === true){
                        this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.push(this.ObjectDatosSolicitudDeVehiculoInventario)            
                        this.valid=true
                        this.CerrarModalIngreseCantidadASolicitarVehiculos()
                    }
                }
            }
        },
        alertCantidad(msg){
            this.cantidadMayor = true
            this.errorAgregarSol=msg
            setTimeout(() => {
                this.cantidadMayor =false
            }, 5000);
        }
    },
}
</script>

<style>
    .fuenteTitulo {
        font-family: 'PT Serif';
        font-size: 15px;        
    }
</style>