<template>
    <v-dialog v-model="DialogoVerVehiculosPorSolicitud" fullscreen scrollable  persistent :overlay="false" transition="dialog-transition">
        <v-card>
            
            <v-card-title class="AppBar tituloMaquinaria" dark elevation="0" style="color: #ffffff">
                <v-icon color="white" class="ml-2" left>fas fa-car-side</v-icon>
                Vehículos por solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalVehiculosPorSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <div class="my-5">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>
                    <v-toolbar title elevation="1">
                        <v-chip color="black" outlined>
                            <v-icon left>fas fa-car-side</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Vehículos</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>      
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table
                        :headers="EncabezadoTablaListadoSolicitudHerramientasEnProceso"
                        :items="VehiculosBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."             
                        item-key="sol_SucursalHerramientaId"
                    >
                        <template v-slot:no-data v-if="VehiculosBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen vehículos en esta solicitud.
                            </v-alert>
                        </template>     
                        <template v-slot:item.estado="{ item }">
                            <v-row justify="space-around">
                                <v-chip  color="blue" text-color="white" v-if="item.estado === 1"> 
                                    <span class="fontButton">En proceso</span>
                                </v-chip>
                                <v-chip color="green" text-color="white" v-if="item.estado === 2"> 
                                    <span class="fontButton">Autorizada</span>
                                </v-chip> 
                                <v-chip color="red" text-color="white" v-if="item.estado === 3"> 
                                    <span class="fontButton">Rechazada</span>
                                </v-chip>                 
                            </v-row>
                        </template>  
                        <template v-slot:item.action="{ item }" v-if="filtrarEstadoSolicitudVehiculos=='1'">
                            <v-tooltip left color="#d80000" >
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="ml-2" dark fab color="#d80000" v-on="on" @click="AbrirMensajeEliminarItem(item)" >
                                        <v-icon small>fas fa-times-circle</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Eliminar Vehículo</span>
                            </v-tooltip>                                
                        </template>                                                                                                                                                                              
                    </v-data-table>                       
                </div>
                <div class="pt-10" v-if="filtrarEstadoSolicitudVehiculos=='1'">
                    <v-toolbar title elevation="1">
                        <v-chip color="black" outlined>
                            <v-icon color="black " left>fas fa-car-side</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Vehículos disponibles</v-toolbar-title>
                        </v-chip>                                              
                        <v-divider vertical class="ml-3"></v-divider>    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistroVehiculosDisponibles"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-toolbar>        
                    <v-data-table
                        :headers="EncabezadoTablaInventarioVehiculosDisponibles"
                        :items="ArrayTablaInventarioVehiculosDisponibles"
                        item-key="idHerramienta"
                        class="elevation-1"
                        :search="buscarRegistroVehiculosDisponibles"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                    >
                        <template v-slot:no-data v-if="ArrayTablaInventarioVehiculosDisponibles.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                            >
                                No hay vehículos disponibles en inventario
                            </v-alert>
                        </template>                    
                        <template v-slot:item.action="{ item }">
                            <v-tooltip left color="#000000">
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AgregarNuevoVehiculoASolicitud(item)" >
                                        <v-icon small>fas fa-plus-circle</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Agregar Nuevo Vehículo a Solicitud</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>                    
                </div>
            </v-card-text>
        </v-card>
        <ComponentNuevoItemSolicitud :AgregarNuevoItemASolicitud="AgregarNuevoItemASolicitud" :datosVehiculo="datosVehiculo"
            @cerrar="cerrar" :idVehiculo="idVehiculo" :ProyectoSolicitudId="ProyectoSolicitudId"
            @ObtenerListadoDeSolicitudesDeVehiculos="ObtenerListadoDeSolicitudesDeVehiculos" @CerrarModalVehiculosPorSolicitud="CerrarModalVehiculosPorSolicitud"
        />                
        <ComponentEliminarItem 
            :DialogoEliminarItem="DialogoEliminarItem" :TituloEliminarItem="TituloEliminarItem" :LoadingEliminarItem="LoadingEliminarItem"
            @CerrarMensajeEliminarItem="CerrarMensajeEliminarItem" @EliminarItemDeLaSolicitud="EliminarItemDeLaSolicitud" 
        />                        
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import ComponentNuevoItemSolicitud from '../../../../components/bodega/AgregarNuevoItemASolicitudVehiculos'
import ComponentEliminarItem from '../../../../components/bodega/QuitarItemDeLaSolicitud'

export default {
    props:{
        DialogoVerVehiculosPorSolicitud: Boolean,
        VehiculosBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,
        proyecto: Number,
        sucursal: Number,
        bodega: Number,
        ProyectoSolicitudId: Number,
        filtrarEstadoSolicitudVehiculos: String
    },
    components:{
        ComponentNuevoItemSolicitud,
        ComponentEliminarItem,
    },    
    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            ArrayHerramientasPorSolicitudEnviada: [],
            EncabezadoTablaListadoSolicitudHerramientasEnProceso:[
                {text: 'Id', value:'vehiculo.idVehiculo', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Código', value: 'vehiculo.codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'vehiculo.nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculo.vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Vehículo', value: 'vehiculo.tipoVehiculo.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'vehiculo.placa', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'vehiculo.color', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                //{text: 'Modelo', value: 'vehiculo.modelo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega Origen', value: 'vehiculo.bodega.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto actual', value: 'vehiculo.proyecto.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Estado', value: 'estado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            TituloOpcion:'',
            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],            
            // agregar nuevo vehiculo
            buscarRegistroVehiculosDisponibles:'',
            ArrayTablaInventarioVehiculosDisponibles: [],
            EncabezadoTablaInventarioVehiculosDisponibles:[
                {text: 'Id', value:'idVehiculo', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Código', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Vehículo', value: 'tipoVehiculo.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Modelo', value: 'modelo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            AgregarNuevoItemASolicitud:false,
            datosVehiculo:{
                tipoVehiculo:'',
                nombreVehiculo:'',
                marcaVehiculo:'',
            },
            idVehiculo:0,            
            sol_ProyectoVehiculoId:0,
            // eliminar item de material
            DialogoEliminarItem: false,
            TituloEliminarItem: '',
            LoadingEliminarItem: false,                
        }
    },

    watch:{
        DialogoVerVehiculosPorSolicitud(){
            if(this.DialogoVerVehiculosPorSolicitud) this.ObtenerInventarioDeVehiculosDisponibles();
        }
    }, 

    methods: {
        //-----------------------------------------------------------------------------
        async ObtenerInventarioDeVehiculosDisponibles (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            var data = {proyecto: this.proyecto, sucursal: this.sucursal, bodega: this.bodega}
            const response = await this.$store.dispatch('TodosVehiculosSolicitud', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayTablaInventarioVehiculosDisponibles = response.data
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },        
        AgregarNuevoVehiculoASolicitud(item){
            this.idVehiculo = item.idVehiculo
            this.datosVehiculo.tipoVehiculo = item.tipoVehiculo.nombre
            this.datosVehiculo.nombreVehiculo = item.nombre
            this.datosVehiculo.marcaVehiculo = item.vehiculoMarca.nombre
            this.AgregarNuevoItemASolicitud = true
        },
        cerrar(){
            this.AgregarNuevoItemASolicitud = false
        },        
        //eliminamos un item de la solicitud x
        AbrirMensajeEliminarItem(item){
            console.log('itemVehiculo: ', item)
            this.sol_ProyectoVehiculoId = item.sol_ProyectoVehiculoId
            this.DialogoEliminarItem = true
            this.TituloEliminarItem = "Vehículo: " + item.vehiculo.nombre
        },
        CerrarMensajeEliminarItem(){
            this.DialogoEliminarItem = false
        },
        async EliminarItemDeLaSolicitud(){
            this.LoadingEliminarItem = true
            var data = {solicitudProyectoId: this.ProyectoSolicitudId, sol_ProyectoHerramientaId: 0,
                sol_ProyectoMaterialId:0, sol_ProyectoVehiculoId: this.sol_ProyectoVehiculoId, sol_ProyectoMaquinariaId: 0
            }
            const response = await this.$store.dispatch('DeleteItemRequest', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ObtenerListadoDeSolicitudesDeVehiculos()
                //this.CerrarModalVehiculosPorSolicitud()
                showMessageSuccess('BIEN!','Vehículo eliminado de la solicitud.')
                this.CerrarMensajeEliminarItem()
                this.LoadingEliminarItem = false
            }else{
                this.ControlladorExcepciones(response)
            }                                                        
        },                                        
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
            this.LoadingEliminarItem = false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                // this.alertErrorPost=true
                // var object = response.data.errors
                // for (var property in object){
                //     this.arrayErrorPost.push({description: `${object[property]}`})
                // }
                showMessageError('Oops!', response.data.errors[0])            
                this.loading = false     
                this.LoadingEliminarItem = false
                // this.ObtenerListadoDeSolicitudesDeHerramientas()
                // setTimeout(() => {
                //     this.alertErrorPost=false
                // }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false             
                this.LoadingEliminarItem = false         
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.LoadingEliminarItem = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.LoadingEliminarItem = false
            }                
        },
                
        CerrarModalVehiculosPorSolicitud(){
            this.$emit('CerrarModalVehiculosPorSolicitud')
        },
        ObtenerListadoDeSolicitudesDeVehiculos(){
            this.$emit('ObtenerListadoDeSolicitudesDeVehiculos')
        },

    },
}
</script>

<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
</style>