<template>
    <v-card>
        <div class="text-center pt-2 pb-2">
            <v-toolbar-title class="tituloMaquinaria">Marcas</v-toolbar-title>
        </div>

        <v-tabs
            v-model="tab"
            background-color="#01abe6"
            centered
            dark
            icons-and-text
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
                Vehículo / Maquinaria
                <v-icon>mdi-car</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
                Herramientas
                <v-icon>mdi-tools</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
                Materia Prima
                <v-icon>mdi-tools</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1"
            >
                <v-card flat>
                    <v-card-text><MarcasVehiculoMaquinaria :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-2"
            >
                <v-card flat>
                    <v-card-text><MarcasHerramientas :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-3"
            >
                <v-card flat>
                    <v-card-text><MarcasMateriaPrima :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import MarcasVehiculoMaquinaria from './MarcasVehiculoMaquinaria'
import MarcasHerramientas from './MarcasHerramientas'
import MarcasMateriaPrima from './MarcasMateriaPrima'

    export default {
        components: {
            MarcasVehiculoMaquinaria,
            MarcasHerramientas,
            MarcasMateriaPrima,
        },

        data () {
            return {
                tab: null,
            }
        },
    }
</script>