var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"titulovehiculos text-center"},[_vm._v("Registro de Incidentes de Vehículos")]),_c('v-toolbar',{attrs:{"flat":"","title":""}},[_c('v-divider',{staticClass:"ml-3",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:" pr-4",attrs:{"outlined":"","label":"Buscar Vehículo","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.buscarVehiculo),callback:function ($$v) {_vm.buscarVehiculo=$$v},expression:"buscarVehiculo"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.EncabezadoTablaVehiculo,"items":_vm.ArrayToddosVehiculos,"search":_vm.buscarVehiculo,"item-key":"","footer-props":{itemsPerPageText: 'Filas por página'},"loading":_vm.loading,"loading-text":"Cargando Datos..."},scopedSlots:_vm._u([{key:"item.idEstado",fn:function(ref){
var item = ref.item;
return [(item.idEstado)?_c('v-chip',{attrs:{"color":"#00b40f","text-color":"white"}},[_vm._v("Activo ")]):_vm._e(),(!item.idEstado)?_c('v-chip',{attrs:{"color":"#d80000","text-color":"white"}},[_vm._v("Inactivo ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.permisos.crear)?_c('v-tooltip',{attrs:{"bottom":"","color":"#0019d8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"#0019d8","dark":"","outlined":"","icon":""},on:{"click":function($event){return _vm.AbrirDialogoRegistroDeIncidentes(item)}}},on),[_c('v-icon',[_vm._v("fas fa-car-crash")])],1)]}}],null,true)},[_c('span',[_vm._v("Registrar Incidente")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":"","color":"#d69b01"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","dark":"","outlined":"","icon":"","color":"#d69b01"},on:{"click":function($event){return _vm.MostrarIncidenteVehiculo(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Incidente del Vehículo")])])]}},(_vm.ArrayToddosVehiculos.length === 0)?{key:"no-data",fn:function(){return [(!_vm.alertError)?_c('v-alert',{staticClass:"text-xs-center textoalerta",attrs:{"dense":"","outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" NO EXISTEN VEHICULOS REGISTRADOS ")]):_vm._e(),(_vm.alertError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" No fue posible la comunicación con "),_c('strong',[_vm._v("API SERVICE")]),_vm._v(" ponganse en contacto con el administrador si este mensaje persiste. ")]):_vm._e()]},proxy:true}:null],null,true)})],1),_c('RegistrarIncidenteDeVehiculo',{attrs:{"DialogoRegistroDeIncidentes":_vm.DialogoRegistroDeIncidentes,"Titulo":_vm.Titulo,"DataVehiculo":_vm.DataVehiculo},on:{"CerrarDialogo":_vm.CerrarDialogoRegistroDeIncidentes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }