<template>
    <div>
        <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>            
        
        <v-toolbar title elevation="1">
            <v-toolbar-title class="tituloMaquinaria">{{TituloTabla}}</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <v-tooltip bottom color="#0019d8" >
                <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" rounded dark @click="ObtenerInventarioMaquinariaPorSucursal()"
                            v-on="on" class="TamañoLetraBoton mx-2" :disabled="!ActivarMenuTabs"
                        > 
                            <v-icon >fas fa-sync-alt</v-icon> 
                        </v-btn>
                </template>
                <span>Actualizar Tabla</span>
            </v-tooltip>                           
            <v-tooltip bottom color="error" >
                <template v-slot:activator="{ on }">
                        <v-btn color="error" rounded dark @click="AbrirModalVisualizarPDFInventario()"
                            v-on="on" class="TamañoLetraBoton mx-2" :disabled="!ActivarMenuTabs"
                        > 
                            <v-icon >fas fa-file-pdf</v-icon> 
                        </v-btn>
                </template>
                <span>Inventario de Maquinarias</span>
            </v-tooltip>                                                                 
        </v-toolbar>        
        <v-data-table
            :headers="EncabezadoTablaInventarioSucursal"
            :items="ArrayInventarioMaquinariaPorSucursal"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."                                
        >
            <template v-slot:item.actions="{item}">
                <v-tooltip left color="#3c8dbc">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#3c8dbc" v-on="on" @click="AbrirModalRetornarMaquinariaACentral(item)" v-if="permisoCrear">
                            <v-icon small>fas fa-exchange-alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Retornar Maquinaria</span>
                </v-tooltip>                                                                                            
            </template>        
            <template v-slot:no-results>    
                <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                    Maquinaria no existe
                </v-alert>                        
            </template>
            <template v-slot:no-data v-if="ArrayInventarioMaquinariaPorSucursal.length === 0">
                <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo" >
                    No existen Maquinarias registradas
                </v-alert>
            </template>                                    
        </v-data-table>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Cargando datos...
        </v-overlay>                      
        <MensajeRetornarCentral 
            :dialogoMensajeRetornarCentral="dialogoMensajeRetornarCentral" @CerrarModalRetornarMaquinariaACentral="CerrarModalRetornarMaquinariaACentral"
            :datosVehiculoMaquinaria="datosVehiculoMaquinaria" :idMaquinaria="idMaquinaria" @ObtenerInventarioMaquinariaPorSucursal="ObtenerInventarioMaquinariaPorSucursal"
            :alertError="alertError"
        />
        <ControlladorExcepciones :response="responseStatus"/>
        <VisualizarPDFInventarioGeneral 
            :dialogoVisualizarPDFInventario="dialogoVisualizarPDFInventario" :Titulo="Titulo" :URLPDF="URLPDF"
            @CerrarModalVisualizarPDFInventario="CerrarModalVisualizarPDFInventario"
        />                  
    </div>
</template>

<script>
import BotonActualizarTabla from '../../../BotonActualizarTabla'
import MensajeRetornarCentral from '../MensajeRetornarMaquinariaCentral'
import ControlladorExcepciones from '../../../../components/ControlandoExcepcionesHTTPS'
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import VisualizarPDFInventarioGeneral from '../VisualizarPDFInventario'

export default {
    components:{
        BotonActualizarTabla,
        MensajeRetornarCentral,
        ControlladorExcepciones,
        VisualizarPDFInventarioGeneral,
    },
    props: {
        idSucursal: Number,
        alertError: Boolean,
        permisoCrear: Boolean,
        tab: String,
        TituloTabla: String,
        idProyecto: Number,
        loading: Boolean,
        ArrayInventarioMaquinariaPorSucursal: Array,
        ActivarMenuTabs: Boolean,
    },

    data() {
        return {
            buscarRegistro:'',
            EncabezadoTablaInventarioSucursal:[
                {text: 'Id', value: 'idMaquinaria', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Código', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo', value: 'tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Nombre', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Serie', value: 'serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Combustible', value: 'tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Transaferencia', value: 'mov_ProyectoMaquinaria.fechaAsignacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},  
                {text: 'Sucursal Origen', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega Orign', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Acciones', value: 'actions', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],           
            // variables para retornar vehiculo a central
            dialogoMensajeRetornarCentral: false,
            datosVehiculoMaquinaria: {
                VehiculoMaquinaria:'',
                codigo:0,
                Tipo:'',
                Marca:'',
                Placa:'',
                nombre:''
            },
            idMaquinaria:0,
            responseStatus:[],
            //variables para visualizar pdf de inventario general
            dialogoVisualizarPDFInventario: false,
            Titulo: '',
            URLPDF: '',              
            EstadoCircularProgress:false,               
        }
    },

    created() {

    },
    methods: {
        ObtenerInventarioMaquinariaPorSucursal(){
            this.$emit('ObtenerInventarioMaquinariaPorSucursal')
        },
        AbrirModalRetornarMaquinariaACentral(datosMaquinaria){
            this.datosVehiculoMaquinaria.VehiculoMaquinaria = "Maquinaria"
            this.datosVehiculoMaquinaria.codigo = datosMaquinaria.codigo
            this.datosVehiculoMaquinaria.Tipo = datosMaquinaria.tipoMaquinaria.nombre
            this.datosVehiculoMaquinaria.Marca = datosMaquinaria.vehiculoMarca.nombre
            this.datosVehiculoMaquinaria.Placa = datosMaquinaria.placa
            this.datosVehiculoMaquinaria.nombre = datosMaquinaria.nombre
            this.idMaquinaria = datosMaquinaria.idMaquinaria
            this.dialogoMensajeRetornarCentral = true
        },
        CerrarModalRetornarMaquinariaACentral(){
            this.dialogoMensajeRetornarCentral = false
        },

        async AbrirModalVisualizarPDFInventario(){
            this.EstadoCircularProgress = true
            this.Titulo = "PDF de inventario de maquinarias"
            let datos = {idProyecto: this.idProyecto, idSucursal:this.idSucursal , rubro: 1}
            const response = await this.$store.dispatch('InventarioGeneralPDF', datos)
            if(response.status === 200){
                this.alertError= false
                this.loading = false       
                this.URLPDF= process.env.VUE_APP_BACKEND_HOST + '/Proyecto/pdf/'+this.idProyecto+'/rubro/'+1+'/idSucursal/'+this.idSucursal
                this.dialogoVisualizarPDFInventario = true
                this.EstadoCircularProgress = false
            }else{
                this.ControllerException(response)
                this.EstadoCircularProgress = false
            }                                        
        },
        CerrarModalVisualizarPDFInventario(){
            this.URLPDF= ''
            this.dialogoVisualizarPDFInventario = false
        }, 
        // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            if(typeof response === 'undefined'){
                this.alertError=true
                this.loading = false
                this.EstadoCircularProgress = false
                this.loadingEliminarTipoVehiculo = false
                return 0
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }
        },                       
    },
}
</script>