<template>
    <div>  
        <hr class="mt-2">
            <v-radio-group class="text-center" v-model="filtrarEstadoSolicitudMateriaPrima" row @change="ObtenerListadoDeSolicitudesDeMateriasPrimas()">
                <v-icon left color="blue">fas fa-sync-alt</v-icon>
                <v-radio label="En Proceso" value="1"></v-radio>
                <v-divider vertical class="mr-4"></v-divider>

                <v-icon left color="green">fas fa-check</v-icon>
                <v-radio label="Aprobado" value="2"></v-radio>
                <v-divider vertical class="mx-4"></v-divider>

                <v-icon left color="red">fas fa-times-circle</v-icon>
                <v-radio label="Rechazado" value="3"></v-radio>
            </v-radio-group>                
        <hr>            
        <v-toolbar title elevation="1">
            <v-chip color="black" outlined >
                <v-icon class="ml-2" left>fas fa-file-alt</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes</v-toolbar-title>
            </v-chip>                        
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerListadoDeSolicitudesDeMateriasPrimas"/>            
        </v-toolbar>        
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>                                                                                        
        <v-data-table
            :headers="EncabezadoTablaSolicitudes"
            :items="ArrayListadoDeSolicitudDeMateriasPrimas"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."             
            item-key="sol_SucursalMaterialId"
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip left color="#4baef3">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#4baef3" v-on="on" @click="AbrirModalHerramientasDeSolicitud(item)">
                            <v-icon small>fas fa-tools</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver materiales de la solicitud</span>
                </v-tooltip>                
                <v-tooltip left color="#d80000" v-if="filtrarEstadoSolicitudMateriaPrima==='1'">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#d80000" v-on="on" @click="AbrirFormularioCancelarSolicitud(item)" >
                            <v-icon small>fas fa-times-circle</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Cancelar Solicitud</span>
                </v-tooltip>                
                <v-tooltip left color="#000000" v-if="filtrarEstadoSolicitudMateriaPrima==='3'">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirDialogoVerMotivoRechazoSolicitud(item)">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver Motivo de Rechazo</span>
                </v-tooltip>
            </template>        
            <template v-slot:no-data v-if="ArrayListadoDeSolicitudDeMateriasPrimas.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    No existen solicitudes registradas
                </v-alert>
            </template>                                     
        </v-data-table>  
        <TablaVerTodasLosMaterialesPorSolicitud 
            :filtrarEstadoSolicitudMateriaPrima="filtrarEstadoSolicitudMateriaPrima"
            :AbrirTablaDeMaterialesPorSolicitud="AbrirTablaDeMaterialesPorSolicitud" @CerrarModalMaterialesDeSolicitud="CerrarModalMaterialesDeSolicitud"
            :MaterialesBySolicitud="MaterialesBySolicitud" :codigoSolicitud="codigoSolicitud" :proyecto="idProyecto" :DatosSolicitud="DatosSolicitud"
            :sucursal="idSucursal" :bodega="idBodega" :ProyectoSolicitudId="ProyectoSolicitudId" @ObtenerListadoDeSolicitudesDeMateriasPrimas="ObtenerListadoDeSolicitudesDeMateriasPrimas"
        />                       
        <VerMotivoRechazoSolicitud 
            :DatosSolictudRechazado="DatosSolictudRechazado" :AbrirModalVerMotivoRechazo="AbrirModalVerMotivoRechazo" @CerrarDialogoVerMotivoRechazoSolicitud="CerrarDialogoVerMotivoRechazoSolicitud"
        />
        <MensajeCancelarSolicitud
            :DialogoCancelarSolicitud="DialogoCancelarSolicitud" :tituloCancelarSolicitud="tituloCancelarSolicitud" :loadingCancelarSolicitud="loadingCancelarSolicitud"
            @CerrarFormularioCancelarSolicitud="CerrarFormularioCancelarSolicitud" @EliminarSoliciutd="EliminarSoliciutd"
        />
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../BotonActualizarTabla'
import VerMotivoRechazoSolicitud from '../VerMotivoRechazoSolicitud'
import TablaVerTodasLosMaterialesPorSolicitud from '../VerMaterialesPorSolicitud'
import MensajeCancelarSolicitud from '../MensajeCancelarSolicitud'

export default {
    components:{
        BotonActualizarTabla,
        VerMotivoRechazoSolicitud,
        TablaVerTodasLosMaterialesPorSolicitud,
        MensajeCancelarSolicitud,
    },
    props:{
        proyecto: Number,
        sucursal:Number,
        bodega: Number,
        tab: String,
    },
    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            filtrarEstadoSolicitudMateriaPrima: '1',
            ArrayListadoDeSolicitudDeMateriasPrimas: [],
            EncabezadoTablaListadoSolicitudMateriaPrimaEnProceso:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaListadoSolicitudMateriaPrimaAprobado:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Aprobación', value: 'fechaAprobacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaListadoSolicitudMateriaPrimaRechazados:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Rechazo', value: 'fechA_MODIFICACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaSolicitudes:[],            

            TituloOpcion:'',
            // abrimos el modal donde se mostraran todas los materiales por solicitud
            MaterialesBySolicitud: [],
            AbrirTablaDeMaterialesPorSolicitud: false,
            codigoSolicitud: "",
            DatosSolicitud: {proyecto:'', sucursal:''},            
            // ver motivo de rechazo de la solicitud
            DatosSolictudRechazado:{},
            AbrirModalVerMotivoRechazo:false,

            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],        
            //cancelar solicitud
            DialogoCancelarSolicitud:false,    
            tituloCancelarSolicitud:'',            
            loadingCancelarSolicitud: false,
            ProyectoSolicitudId:0,

            // obtenemos los valores para obtener todos los materiales
            idProyecto:0, idSucursal:0, idBodega:0,
        }
    },
    watch: {
        filtrarEstadoSolicitudMateriaPrima(){
            this.EstadoOpcionSolicitud()
        },
        sucursal(){
            //this.ObtenerListadoDeSolicitudesDeMateriasPrimas()
        },
        tab(){
            // console.log(this.tab)
            if(this.tab === 'tab-2'){
                this.ObtenerListadoDeSolicitudesDeMateriasPrimas()
            }  
                
        }
    },
    props:{
        proyecto:Number,
        sucursal:Number,
        bodega:Number,
        tab:String
    },

    created() {        
        this.ObtenerListadoDeSolicitudesDeMateriasPrimas();
        this.EstadoOpcionSolicitud()
    },

    methods: {
        //-----------------------------------------------------------------------------
        AbrirDialogoVerMotivoRechazoSolicitud(datos){
            this.DatosSolictudRechazado = datos
            this.AbrirModalVerMotivoRechazo = true
        },

        CerrarDialogoVerMotivoRechazoSolicitud(){
            this.AbrirModalVerMotivoRechazo = false
        },

        EstadoOpcionSolicitud(){
            if(this.filtrarEstadoSolicitudMateriaPrima === '1'){
                this.TituloOpcion = 'en proceso'
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMateriaPrimaEnProceso
            }else if(this.filtrarEstadoSolicitudMateriaPrima === '2'){
                this.TituloOpcion = 'aprobadas'
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMateriaPrimaAprobado
            }else if(this.filtrarEstadoSolicitudMateriaPrima === '3'){
                this.TituloOpcion = 'rechazadas'
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMateriaPrimaRechazados
            }        
        },

        async ObtenerListadoDeSolicitudesDeMateriasPrimas (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            var data = {estado : this.filtrarEstadoSolicitudMateriaPrima, Proyecto:this.proyecto}
            const response = await this.$store.dispatch('ListadoSolicitudesMaterialesByEncargado', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayListadoDeSolicitudDeMateriasPrimas = response.data
                if(this.codigoSolicitud != ''){
                    this.ActualizarMaterialesDeSolicitud()
                }
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        ActualizarMaterialesDeSolicitud(){
            this.ArrayListadoDeSolicitudDeMateriasPrimas.forEach(sl => {
                if(sl.codigo == this.codigoSolicitud){
                    this.MaterialesBySolicitud = sl.sol_ProyectoMateriales
                }
            })
        },
        //mostramos el dialogo con todas los materiales de cada solicitud
        AbrirModalHerramientasDeSolicitud(MaterialesPorSolicitud){
            this.idProyecto = MaterialesPorSolicitud.proyecto.idProyecto
            this.idSucursal = MaterialesPorSolicitud.sucursal.sucursalId
            this.idBodega = MaterialesPorSolicitud.sol_ProyectoMateriales[0].materiaPrima.bodegaId

            this.ProyectoSolicitudId = MaterialesPorSolicitud.solicitudProyectoId
            this.MaterialesBySolicitud = MaterialesPorSolicitud.sol_ProyectoMateriales
            this.codigoSolicitud = MaterialesPorSolicitud.codigo
            this.DatosSolicitud.proyecto = MaterialesPorSolicitud.proyecto.nombre
            this.DatosSolicitud.sucursal = MaterialesPorSolicitud.sucursal.nombre
            this.AbrirTablaDeMaterialesPorSolicitud = true
        },

        CerrarModalMaterialesDeSolicitud(){
            this.AbrirTablaDeMaterialesPorSolicitud = false
        },        
        //cancelar una solicitud
        AbrirFormularioCancelarSolicitud(item){
            // console.log('item: ', item)
            this.ProyectoSolicitudId = item.solicitudProyectoId
            this.tituloCancelarSolicitud = "Solicitud Materiales: "+ item.codigo
            this.DialogoCancelarSolicitud = true
        },
        CerrarFormularioCancelarSolicitud(){
            this.DialogoCancelarSolicitud = false
        },        
        async EliminarSoliciutd(){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loadingCancelarSolicitud = true
            const response = await this.$store.dispatch('CancelarSolicitudABodega', this.ProyectoSolicitudId)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                showMessageSuccess('BIEN!','Solicitud de Materiales o Insumos cancelada correctamente.')
                this.ObtenerListadoDeSolicitudesDeMateriasPrimas()
                this.loadingCancelarSolicitud = false
                this.CerrarFormularioCancelarSolicitud()
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },

        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
            this.loadingCancelarSolicitud =false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false        
                this.loadingCancelarSolicitud =false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
                this.loadingCancelarSolicitud =false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.loadingCancelarSolicitud =false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.loadingCancelarSolicitud =false
            }                
        },
    },
}
</script>