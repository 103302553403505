import Vue from 'vue'

const store = {
    actions: {
        getAllLicencias : async ({}, data) => {
            try{
                const response = await Vue.axios.get('/TipoLicencias')
                return response
            }catch(e){
                return e.response
            }
        },
        // tipos de licencias 
        // guardamos un tipo de licencia
            SaveLicenseType: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/TipoLicencias', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // eliminar un tipo de licencia
            DeleteLicenseType: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.delete('/TipoLicencias/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // obtener un tipo de licencia por su id
            GetLicenseType: async ({}, idtiipo) => {
                try {
                    const response = await Vue.axios.get('/TipoLicencias/'+ idtiipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // editar un tipo de licencia
            EditLicenseType: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/TipoLicencias', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
            
    }
}

export default store