import axios from 'axios'
const api = axios.create({
    baseURL: process.env.VUE_APP_OAUTH_AUTHORITY,
});


const store = {
    actions: {
        //add here all acctions api
        createUser : async ({},data) => {
            return await api.post('/api/usuarios/createUser',data)
            .then(response => {
                return response
            }).catch(error => {
                return error.response
            });
        },
        updatePassword : async ({}, data) => {
            return await api.put('api/usuarios/updatePasswordUser',data)
            .then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        UpdatePasswordUser : async ({}, data) => {
            return await api.put('api/usuarios/changeMePass',data)
            .then(response => {
                return response
            }).catch(error => {
                return error.response
            })
        },
        getAllUsers : async ({},dataa) => {
            return await api.get('api/usuarios/readUsers/estado/'+dataa)
                .then(response => {
                    return response
                }).catch(err => {
                    return err.response
                })
        },
        getUserById : async ({}, data) => {
            return await api.get('api/usuarios/byId/'+data)
            .then(data => {
                return data
            }).catch(err => {
                return err.response
            });
        },
        updateUser: async ({}, data )=>{
            return await api.put('api/usuarios', data)
            .then(data => {
                return data
            }).catch(err => {
                return err.response
            });
        },
        getAllRoles : async ({}, data ) => {
            try{
                const response = await api.get('api/usuarios/readRoles')
                return response
            }catch(e){
                return e.response
            }
        }, 
        ActualizarInfoUsuario: async ({}, data )=>{
            return await api.put('api/usuarios/userinfo', data)
            .then(data => {
                return data
            }).catch(err => {
                return err.response
            });
        },
        ActualizarInfoPersonal: async ({}, data )=>{
            return await api.put('api/usuarios/updatePersonalInfo', data)
            .then(data => {
                return data
            }).catch(err => {
                return err.response
            });
        },
        GuardarNuevaLicenciaUsuario: async ({}, data )=>{
            return await api.post('api/usuarios/licencias/new', data)
            .then(data => {
                return data
            }).catch(err => {
                return err.response
            });
        },
        GuardarLicenciaEditadaUsuario: async ({}, data )=>{
            return await api.put('api/usuarios/licencias/update', data)
            .then(data => {
                return data
            }).catch(err => {
                return err.response
            });
        },
        CalcularEdad: async ({}, fechaNacimiento )=>{
            try {
                const response = await api.post('api/usuarios/edad?fechaNacimiento='+ fechaNacimiento)
                return response
            } catch (error) {
                return error.response
                // return console.log('Error al calcular la edad de usuario', error)
            }
        },
        // obtenemos los usuarios asignados a una sucursal
        GetUserBySucursal: async ({}, sucursal )=>{
            try {
                const response = await api.get('api/usuarios/avaibleUsers')
                return response
            } catch (error) {
                return error.response
                // return console.log('Error al obtener los usuarios por sucursal', error.response)
            }
        },
    }
}

export default store