import Swal from 'sweetalert2'

const showMessageError = (title, message) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        allowOutsideClick: false
    })
}


const showMessageSuccess = (title, message) => {
    Swal.fire({
        title: title,
        text: message,
        //type: "success",
        icon: 'success',
        allowOutsideClick: false
    })
}
const showMessageWarning = (title, message) => {
    Swal.fire({
        title: title,
        text: message,
        //type: "warning",
        icon: 'warning',
        allowOutsideClick: false
    })
}

export { showMessageError, showMessageSuccess, showMessageWarning }