import Vue from 'vue'

const store = {
    actions: {
        //add here all acctions api        
        //obtenemos todas las marcas
        ObtenerMarcas: async({}) => {
            try {
                const response = await Vue.axios.get('/Herramientas/marcasherramientas')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener marcas', e)
            }
        },
        //obtenemos tipos de herramientas
        ObtenerTiposHerramientas: async({}) => {
            try {
                const response = await Vue.axios.get('/Herramientas/TiposHerramientas')
                return response
            } catch (error) {
                return error.response
                    // return console.log('Error al obtener los tipos de herramientas', error)
            }
        },
        //obtiene todas las herramientas registradas
        ObtenerTodasHerramientas: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Herramientas/byBodega/' + data.bodega + '/sucursal/' + data.sucursal)
                return response
            } catch (error) {
                return error.response
                    // return console.log('Error al obtener todas las herramientas registradas', error)
            }
        },

        //obtiene una herramienta por su id
        ObtenerHerramientaById: async({}, idHerramienta) => {
            try {
                const response = await Vue.axios.get('/Herramientas/byId/' + idHerramienta)
                return response
            } catch (error) {
                return error.response
                    // return console.log("Error al obtener una herramienta por su id")
            }
        },
        //registramos una herramienta
        RegistrarHerramienta: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas', data)
                return response
            } catch (error) {
                return error.response
                    // return console.log('Error al registrar una herramienta', error)
            }
        },

        //actualizamos una herramienta
        ActualizarHerramienta: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Herramientas', data)
                return response
            } catch (error) {
                return error.response
                    // return console.log('Error al actualizar la herramienta', error)
            }
        },

        // eliminar una herramienta por su id
        EliminarHerramientaById: async({}, idHerramienta) => {
            try {
                const response = await Vue.axios.delete('/Herramientas/' + idHerramienta)
                return response
            } catch (error) {
                return error.response
                    // console.log("Error al eliminar la herramienta", error)
            }
        },
        // guardar movimiento de compra de herramienta
        GuardarMovimientoInventarioCompra: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas/movimiento/compra', data)
                return response
            } catch (error) {
                return error.response
                    // console.log("Error al eliminar la herramienta", error)
            }
        },
        // guardar movimiento de salida de herramienta
        GuardarMovimientoInventarioSalida: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas/movimiento/salida', data)
                return response
            } catch (error) {
                return error.response
                    // console.log("Error al eliminar la herramienta", error)
            }
        },
        // transferimos una herramienta a una sucursal
        TransferirHerramientaSucursal: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas/transferir', data)
                return response
            } catch (error) {
                return error.response
                    // console.log("Error al eliminar la herramienta", error)
            }
        },
        //obtener todos los movimientos de las sucursales de un vehiculo
        MovimientosPorHerramienta: async({}, idHerramienta) => {
            try {
                const response = await Vue.axios.get('/Herramientas/movimientos/' + idHerramienta)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //generar nueva solicitud de herramienta
        EnviarSolicitudDeHerramienta: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas/solicitud', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //obtiene todas las herramientas y la información se comportara dependiendo del estado del puedeSolicitar
        TodasHerramientasSolicitud: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Herramientas/byEstado/solicitud/proyecto/' + data.proyecto + '/sucursal/' + data.sucursal)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //reversa un movimiento de una herramienta
        ReversarMovimientoHerramientaById: async({}, idMov) => {
            try {
                const response = await Vue.axios.post('/Herramientas/movimiento/reversa?idMov=' + idMov)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        ObtenerMovimientoHerramientaById: async({}, idMov) => {
            try {
                const response = await Vue.axios.get('/Herramientas/movimiento/byId/' + idMov)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //retornamos una herramienta a la sucursal central
        RetornarHerramientaBodega: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas/movToCentral', data)
                return response
            } catch (e) {
                return e.response
            }
        },
        //obtener todos los movimientos de las sucursales de una herramienta
        TimeLineMovimientosPorHerramientas: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Herramientas/movimientos/' + data.idHerramienta + '/fechaInicio/' + data.fechaInicio + '/fechaFin/' + data.fechaFin)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        getAllCatalogoHerramientas: async({}) => {
            try {
                const response = await Vue.axios.get('/Herramientas/catalogo')
                return response
            } catch (e) {
                return e.response
            }
        },
        newCatalogoHerramienta: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Herramientas/catalogo', data)
                return response
            } catch (e) {
                return e.response
            }
        },
        updateCatalogoHerramienta: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Herramientas/catalogo', data)
                return response
            } catch (e) {
                return e.response
            }
        }
    }
}

export default store