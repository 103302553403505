import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB6FhTBvxaNMO1BuII-zjsxN4P-ceVYaEg",
    libraries: "places" //necessary for places input
  }
});

Vue.use(VueAxios, axios)

new Vue({
  el: "#app",
  components: { App },
  template: "<App/>",
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
