<template>
    <div class="text-center">
        <v-toolbar color="blue" class="mt-8" dark flat title>
            <v-toolbar-title class="titulo">Reversar Movimiento Herramienta</v-toolbar-title>
            <v-divider class="ml-3" vertical></v-divider>
        </v-toolbar>     

        <v-container grid-list-xs>
            <div class="mt-6" justify="center" align="center">
                <v-card max-width="600px">
                    <v-card-title primary-title>
                        <div justify="center" align="center">
                            <div justify="center" align="center">Ingrese Id del Movimiento de la Herramienta</div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                            <v-text-field outlined type="number" required :rules="ReglasFormulario"
                                label="Id Movimiento"
                                v-model="IdMovimientoHerramienta"
                            ></v-text-field>                                
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn rounded color="primary" :loading="loading" :disabled="loading || ActivarBotonRevertirMov === false"
                            @click="MostrarMovimientoDeHerramienta()"
                        >Ver Movimiento</v-btn>
                    </v-card-actions>
                            <!-- @click="RevertirMovimientoDeHerramienta()" -->
                </v-card>            
            </div>
        </v-container>
        <MensajeEliminar :ModalMensajeEliminarDetallado="ModalMensajeEliminarDetallado" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminar" 
            :DatosMovimiento="DatosMovimiento" @RevertirMovimientoDeHerramienta="RevertirMovimientoDeHerramienta" :loading="loading"
        />
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import MensajeEliminar from '../../../components/MensajeEliminarDetallado'

export default {
    components:{
        MensajeEliminar,
    },

    data() {
        return {
            ActivarBotonRevertirMov: false,
            IdMovimientoHerramienta: Number,
            loading: false,
            alertErrorPost: false,
            valid: false,
            lazy: false,
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],
            // variables de mensaje eliminar
            ModalMensajeEliminarDetallado: false,
            DatosMovimiento:{
                tipoMovimientoInventarioId: 0,
                nombre: "",
                usuario: "",
                proveedorOTienda:'',
                existenciaActual:0,
                cantidad:0,
                precioUnitario:0,
                precioTotal:0,
                descripcion:'',
            },
        }
    },

    watch:{
        IdMovimientoHerramienta(){
            if(this.IdMovimientoHerramienta === '') this.ActivarBotonRevertirMov = false
            else this.ActivarBotonRevertirMov = true
        }
    },

    methods: {
        // metodos de mensaje eliminar
        CerrarDialogoMensajeEliminar(){
            this.ModalMensajeEliminarDetallado = false
            this.IdMovimientoHerramienta = ''
        },

        async MostrarMovimientoDeHerramienta(){
            this.loading = true
            if(this.$refs.form.validate()){
                const response = await this.$store.dispatch('ObtenerMovimientoHerramientaById', this.IdMovimientoHerramienta)
                if(response.status === 200){
                    this.DatosMovimiento.tipoMovimientoInventarioId = response.data.mov_inventarioHerramientasId
                    this.DatosMovimiento.nombre = response.data.tipoMovimientoInventario.nombre
                    this.DatosMovimiento.usuario = response.data.usuario
                    this.DatosMovimiento.proveedorOTienda = response.data.proveedorOTienda
                    this.DatosMovimiento.existenciaActual = response.data.existenciaActual
                    this.DatosMovimiento.cantidad = response.data.cantidad
                    this.DatosMovimiento.precioUnitario = response.data.precioUnitario
                    this.DatosMovimiento.precioTotal = response.data.precioTotal
                    this.DatosMovimiento.descripcion = response.data.descripcion
                    this.ModalMensajeEliminarDetallado = true
                    this.loading = false
                }else{
                    this.ControlladorExcepciones(response)
                }                        
            }else{
                this.loading = false
            }
        },
        async RevertirMovimientoDeHerramienta(){
            this.loading = true
            if(this.$refs.form.validate()){
                const response = await this.$store.dispatch('ReversarMovimientoHerramientaById', this.IdMovimientoHerramienta)
                if(response.status === 200){
                    this.IdMovimientoHerramienta = ''
                    this.loading = false
                    showMessageSuccess('¡BIEN!', 'Movimiento de Herramienta revertido correctamente')
                    this.CerrarDialogoMensajeEliminar()
                }else{
                    this.ControlladorExcepciones(response)
                }                        
            }else{
                this.loading = false
            }
        },

        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.alertErrorPost = false
                return 0
            }else if(response.status === 400){
                showMessageError('Transacción no encontrada, Movimiento de Herramienta no existe.')
                this.loading = false       
                this.alertErrorPost = false
                this.ModalMensajeEliminarDetallado = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false       
                this.alertErrorPost = false               
                this.ModalMensajeEliminarDetallado = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.alertErrorPost = false
                this.ModalMensajeEliminarDetallado = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.ModalMensajeEliminarDetallado = false
                this.alertErrorPost = false
            }                
        }        
    },
}
</script>