<template>
    <div>
        <v-dialog
            v-model="ModalNuevaSolicitudHerramienta"
            scrollable 
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <!-- barra principal del dialogo -->
                <v-app-bar color="#3c8dbc" flat dark>
                    <v-toolbar-title class="titulo">Nueva Solicitud de Herramienta</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- boton registrar herramienta -->
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" color="success" rounded dark class="mr-4" @click="GenerarSolicitudHerramienta()"
                                :loading="loading" :disabled="loading" 
                            >
                                <v-icon left>fas fa-save</v-icon> Generar
                            </v-btn>                
                        </template>
                        <span>Solicitud</span>
                    </v-tooltip>
                    <v-btn large color="error" rounded dark @click="CerrarDialogoNuevaSolicitudHerramienta()">
                        <v-icon left>fas fa-window-close</v-icon> Cancelar
                    </v-btn>                
                </v-app-bar>
                <v-card-text>
                    <!-- <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>      -->
                    <v-alert :value="alertErrorPost"
                            type="error"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                        <v-row wrap>
                        <!-- sucursales -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0">
                                <v-select outlined 
                                    :items="ArraySucursales"
                                    item-text="nombre"
                                    item-value="idSucursal"
                                    v-model="modeloCrearNuevaSolicitudHerramienta.idSucursal"
                                    label="Sucursal"
                                ></v-select>
                            </v-col>                            
                            <!-- cantidad                         -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >
                                <v-text-field outlined 
                                    label="Cantidad" type="number"
                                    v-model="modeloCrearNuevaSolicitudHerramienta.cantidadSolicitada"                                
                                ></v-text-field>
                            </v-col>                
                            <!-- informacion -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 mt-0 pb-0 mb-0">
                                <v-textarea 
                                    outlined
                                    label="Información"
                                    v-model="modeloCrearNuevaSolicitudHerramienta.informacion"
                                >
                                </v-textarea>
                            </v-col>                        
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>            
        </v-dialog>
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../../services/sweetAlert'
export default {
    props:{
        ModalNuevaSolicitudHerramienta: Boolean,
        DatosHerramienta: Object,
        ArraySucursales: Array,
        EstadoPermisoEditar: Boolean,
    },

    data() {
        return {
            valid: false,
            lazy: false,    
            loading:false,              
            modeloCrearNuevaSolicitudHerramienta:{
                idSucursal:0,
                idHerramienta:0,
                cantidadSolicitada:0,
                informacion:'',
            },
            ObtenerTipoMovimiento:'',
            // errores registros duplicados y vacíos
            alertErrorPost:false,
            arrayErrorPost:[],            
                        
        }
    },

    watch: {
        ModalNuevaSolicitudHerramienta(){
            // console.log(this.DatosHerramienta)
            this.modeloCrearNuevaSolicitudHerramienta.idHerramienta = this.DatosHerramienta.idHerramienta    

        },
    },

    methods: {
        CerrarDialogoNuevaSolicitudHerramienta(){
            this.alertErrorPost = false
            this.$refs.form.reset()
            this.$emit('CerrarDialogoNuevaSolicitudHerramienta')
        },

        async GenerarSolicitudHerramienta(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            const response = await this.$store.dispatch('EnviarSolicitudDeHerramienta', this.modeloCrearNuevaSolicitudHerramienta)
            if(response.status === 200){
                this.loading = false
                this.$emit('ActualizarTabla')
                showMessageSuccess('¡BIEN!', 'Solicitud de Herramienta enviada correctamente')
                this.CerrarDialogoNuevaSolicitudHerramienta()
            }else{
                this.ControlladorExcepciones(response)
            }
        },

        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.alertErrorPost = false
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false                        
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false       
                this.alertErrorPost = false               
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.alertErrorPost = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.alertErrorPost = false
            }                
        }        
    },
}
</script>