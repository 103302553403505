<template>
    <div>
        <v-dialog
            v-model="FormularioAutorizarSolicitudHerramienta"
            scrollable
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-file-import</v-icon>
                    <v-toolbar-title class="TituloDialogo">Autorización de Solicitud de Herramienta</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="AgregarSolicitudHerramienta()"
                                :loading="loading" :disabled="loading"                        
                            >Guardar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Agregar solicitud a Autorizar</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarModalAutorizarSolicitudDeHerramienta()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar Solicitud</span>
                    </v-tooltip>                    
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Herramienta:</strong> {{ObtenerSolicitudDeHerramienta.nombreHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Cantidad Solicitada:</strong> {{ObtenerSolicitudDeHerramienta.cantidadSolicitada}}</span> <br>
                    <span class="fuenteTitulo"><strong>Existencia Actual:</strong> {{ObtenerSolicitudDeHerramienta.existenciaActual}}</span>
                </div>
                <hr>
                <v-alert v-if="cantidadMayor"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Alerta:</h4>
                        <ul>
                            <li type="disc">
                                <h6>{{MensajeError}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                                
                <v-card-text class="TituloDialogo">
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- cantidad -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="mt-0 pb-0">
                                <v-text-field outlined required :rules="nameRules"
                                    label="Cantidad a Autorizar" type="number"
                                    v-model="ObtenerSolicitudDeHerramienta.cantidadAutorizada"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>                
            </v-card>            
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        FormularioAutorizarSolicitudHerramienta: Boolean,
        ObtenerSolicitudDeHerramienta: Object,
        ArrayTodasLasSolicitudesAutorizar: Array,
    },

    data() {
        return {
            alertError:false,                          
            loading: false,
            valid: false,
            lazy: false,
            cantidadMayor:false,
            MensajeError:'',
            nameRules: [
                v => !!v || 'Campo es requerido',
            ],            
        }
    },

    methods: {
        CerrarModalAutorizarSolicitudDeHerramienta(){
            this.cantidadMayor = false
            this.MensajeError = ""
            // this.$refs.form.reset()
            this.$emit('CerrarModalAutorizarSolicitudDeHerramienta')
        },

        AgregarSolicitudHerramienta(){
            if(this.$refs.form.validate()){
                if(this.ObtenerSolicitudDeHerramienta.cantidadAutorizada > this.ObtenerSolicitudDeHerramienta.existenciaActual){
                    this.cantidadMayor = true
                    this.MensajeError = "Existencia Insuficiente"
                }else if(this.ObtenerSolicitudDeHerramienta.cantidadAutorizada > this.ObtenerSolicitudDeHerramienta.cantidadSolicitada){
                    this.cantidadMayor = true
                    this.MensajeError = "Cantidad ingresada mayor a Cantidad Solicitada"
                }else{
                    this.ArrayTodasLasSolicitudesAutorizar.push(this.ObtenerSolicitudDeHerramienta)            
                    // this.CerrarModalAutorizarSolicitudDeHerramienta()
                    // console.log('ArrayTodasLasSolicitudesAutorizar', this.ArrayTodasLasSolicitudesAutorizar)
                }
            }else{
                // console.log('entro')
            }
        },

    },
}
</script>