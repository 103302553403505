<template>
    <div class="text-center pt-2 pb-2">
        <v-toolbar-title class="tituloMaquinaria">
            <v-icon>fas fa-scroll</v-icon>
            Inventario de mi Proyecto
        </v-toolbar-title>
        <v-container fluid>
            <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                    <v-select outlined class="ml-6"
                        :items = "ArrayProyectos"
                        item-text ="nombre"
                        item-value="idProyecto"
                        v-model = "seleccionarProyecto"
                        label="Seleccionar proyecto"
                        single-line
                        hide-details
                        @change="ObtenerProyectosPorId"
                    ></v-select>   
                </v-col>
            </v-row>
        </v-container>
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>         
        <Menu 
            :sucursal="idProyecto"
            :ActivarMenuTabs="ActivarMenuTabs"
            :proyecto="proyecto"        
            :ArrayInventarioVehiculo="ArrayInventarioVehiculo"
            :ArrayInventarioMaquinaria="ArrayInventarioMaquinaria"
            :ArrayInventarioHerramienta="ArrayInventarioHerramienta"
            :ArrayInventarioMaterialOInsumo="ArrayInventarioMaterialOInsumo"
            :loading="loading" :EstadoPermisoCrear="permisos.crear"
            @ObtenerInventarioVehiculosPorProyecto="ObtenerInventarioVehiculosPorProyecto"
            @ObtenerInventarioMaquinariaPorProyecto="ObtenerInventarioMaquinariaPorProyecto"
            @ObtenerInventarioHerramientaPorProyecto="ObtenerInventarioHerramientaPorProyecto"
            @ObtenerInventarioMaterialOInsumoPorProyecto="ObtenerInventarioMaterialOInsumoPorProyecto"
        />
    </div>
</template>

<script>
import Menu from './menu'
import { mapGetters, mapActions  } from 'vuex'

    export default {
        components: {
            Menu,
        },

        data () {
            return {
                ActivarMenuTabs:false,
                proyecto:{},
                ArrayProyectos:[],
                ArrayInventarioVehiculo:[],
                ArrayInventarioMaquinaria:[],
                ArrayInventarioHerramienta:[],
                ArrayInventarioMaterialOInsumo:[],
                loading: false,
                alertError:false,
                SucursalUsuario:'',
                seleccionarProyecto:0,
                idProyecto:0,
                //variables para los permisos del colaborador
                namespace:'oidcStore/',    
                permisos:{
                    crear:false,
                    editar:false,
                    eliminar:false
                },         
                EstadoPermisoEditar: true,
                alertErrorPost:false,
                arrayErrorPost:[],                 
            }
        },

        computed: {
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcUser'
            ]),      
        },    

        created() {
            this.ObtenerPermisosSubmenuUsuario()            
        },
        mounted() {
            this.ObtenerProyectosDelUsuario()
        },   
        watch:{
            seleccionarProyecto(){
               this.ActivarMenuTabs=true
            },
        },    
        
        methods: {           
            async ObtenerProyectosPorId(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayProyectos.forEach(x => {
                    if(x.idProyecto === this.seleccionarProyecto){
                        this.idProyecto = x.idProyecto
                    }
                })
                this.loading = true
                const response = await this.$store.dispatch('GetProyectoById', this.idProyecto)
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.alertError= false
                    this.loading = false        
                    this.proyecto= response.data
                    //this.ObtenerInventarioVehiculosPorSucursal()
                }else{
                    this.ControllerException(response)
                }
            },
            // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
            ControllerException(){
                this.alertError=true
                this.loading=false
            },                
        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),        

            async ObtenerPermisosSubmenuUsuario(role){
                this.alertErrorPost=false;this.arrayErrorPost=[];
            if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.permisos.crear = true
                        this.permisos.editar = true
                        this.permisos.eliminar = true
                        return 0            
                }else{
                    //preguntar al backend por los permisos del submenu del usuario
                        var url = 'InventarioSucursalPorEncargado'
                        const response  = await this.$store.dispatch('PermisosSubMenu', url)
                        if(response.status === 200){
                            this.alertErrorPost=false;this.arrayErrorPost=[];
                            this.alertError= false
                            this.permisos = response.data
                        }else{
                            this.ControlladorExcepciones(response)
                        }
                        return 0
                }
            }
            },
            //obtenemos todo el inventario de vehiculos de una sucursal
            async ObtenerInventarioVehiculosPorProyecto(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                if(this.seleccionarProyecto > 0){
                    this.loading = true
                    var datos = {idProyecto : this.seleccionarProyecto, idSucursal: 0}
                    const response = await this.$store.dispatch('InventarioVehiculosPorProyectoAdmin', datos)
                    if(response.status === 200){
                        this.alertErrorPost=false;this.arrayErrorPost=[];
                        this.ArrayInventarioVehiculo = response.data
                        this.loading = false
                    }else{
                        this.ControlladorExcepciones(response)
                    }   
                }                                
            },
            // devuelve sucursal del usuario
            async ObtenerProyectosDelUsuario(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                const response = await this.$store.dispatch('DevuelveProyectoUSuario')
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.ArrayProyectos = response.data
                }else{
                    this.ControlladorExcepciones(response)
                }                                
            },

            //obtenemos todo el inventario de maquinaria de una sucursal
            async ObtenerInventarioMaquinariaPorProyecto(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.loading = true
                var datos = {idProyecto : this.seleccionarProyecto, idSucursal: 0}
                const response = await this.$store.dispatch('InventarioMaquinariaPorProyectoAdmin', datos)
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.ArrayInventarioMaquinaria = response.data
                    this.loading = false
                }else{
                    this.ControlladorExcepciones(response)
                }                                
            },

            //obtenemos todo el inventario de herramienta de una sucursal
            async ObtenerInventarioHerramientaPorProyecto(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.loading = true
                var datos = {idProyecto : this.seleccionarProyecto, idSucursal: 0}
                const response = await this.$store.dispatch('InventarioHerramientaPorProyectoAdmin',datos)
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.ArrayInventarioHerramienta = response.data
                    this.loading = false
                }else{
                    this.ControlladorExcepciones(response)
                }                                
            },
            //obtenemos todo el inventario de material o insumos de una sucursal
            async ObtenerInventarioMaterialOInsumoPorProyecto(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.loading = true
                var datos = {idProyecto : this.seleccionarProyecto, idSucursal: 0}
                const response = await this.$store.dispatch('InventarioMaterialInsumosPorProyectoAdmin', datos)
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.ArrayInventarioMaterialOInsumo = response.data
                    this.loading = false
                }else{
                    this.ControlladorExcepciones(response)
                }                                
            },

            // controlamos todas las excepciones http
            ControlladorExcepciones(){
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false  
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')    
                    this.EstadoCircularProgress = false        
                    this.loading = false
                }                
            }      
        }
    }
</script>