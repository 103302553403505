<template>
    <v-tooltip left color="error"  v-if="filtrarEstadoSolicitudes==='2'">
        <template v-slot:activator="{ on }">
            <v-btn small class="ml-2" fab color="error" v-on="on" @click="DescargarPDF()"
                :loading="loading" :disabled="loading"
            >
                <v-icon >fas fa-file-download</v-icon>
            </v-btn>                        
        </template>
        <span class="fontButton">Descargar PDF de solicitud</span>
    </v-tooltip> 
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../services/sweetAlert'
export default {
    props:{
        filtrarEstadoSolicitudes: String,
        TituloComponente: String,
        DatosSolicitud: Object,
    },

    data() {
        return {
            loading:false,
        }
    },

    methods: {
        async DescargarPDF(){
            this.loading = true
            var idSolicitud = ''
            console.log('idsolicitud: ',this.DatosSolicitud )
            idSolicitud = this.DatosSolicitud.solicitudProyectoId
            const response = await this.$store.dispatch('DescargarSolicitudPDF', idSolicitud)
            if(response.status === 200){
                if(this.TituloComponente === 'Maquinaria'){
                    this.forceFileDownload(this.DatosSolicitud.codigo+'.pdf',response)
                }else if(this.TituloComponente === 'Vehiculo'){
                    this.forceFileDownload(this.DatosSolicitud.codigo+'.pdf',response)    
                }else if(this.TituloComponente === 'Herramienta'){
                    this.forceFileDownload(this.DatosSolicitud.codigo+'.pdf',response)                        
                }else if(this.TituloComponente === 'Material'){
                    this.forceFileDownload(this.DatosSolicitud.codigo+'.pdf',response)                            
                }
                this.loading = false
            }else{
                this.ControllerException(response)
            }                
        },
        forceFileDownload(fileName, response) {
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = window.URL.createObjectURL(file);
            const link = document.createElement('a')
            link.href = fileURL;
            link.download = fileName;
            link.click();
        },     
        // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }
        },             
    },
}
</script>