<template>
    <v-card>
        <v-form>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>                            
            <v-row wrap class="mx-2 pt-4">
                <v-col cols="12" lg="3" md="3" sm="6" class="pb-0">
                    <v-select :disabled="EstadoLicencia"
                        prepend-inner-icon="far fa-id-badge" 
                        :items="TodasLasLicencias" 
                        item-value="tipo"  
                        item-text="tipo" 
                        v-model="tipoLicencia" 
                        label="Licencia" 
                        outlined 
                    ></v-select>                        
                </v-col>
                <v-col cols="12" lg="3" md="3" sm="6" class="pb-0">
                    <v-menu 
                        v-model="menuFechaVencimientoLicencia" 
                        :close-on-content-click="false" 
                        :nudge-right="40"
                        transition="scale-transition" 
                        offset-y 
                        min-width="290px" 
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                outlined 
                                v-model="dateFechaVencimientoLicencia" 
                                label="Fecha de Vencimiento Licencia" 
                                prepend-inner-icon="far fa-calendar-alt"
                                readonly v-bind="attrs"
                                v-on="on" persistent-hint
                                :hint="MostrarMensaje"
                            ></v-text-field>
                        </template>
                        <v-date-picker 
                            :disabled="EstadoLicenciaFecha" 
                            v-model="dateFechaVencimientoLicencia" 
                            @input="menuFechaVencimientoLicencia = false"
                            locale="es-GT"    
                        >
                        </v-date-picker>
                    </v-menu>
                            <v-progress-linear
                                :active="loading"
                                :indeterminate="loading"
                                bottom
                                color="deep-purple accent-4"
                            ></v-progress-linear>                                                
                </v-col>       
                <v-col cols="12" lg="3" md="3" sm="6" class="pb-0">
                    <v-btn large class="mt-2" color="success" @click="GuardarNuevaLicencia()" v-if="!EstadoLicenciaGuardar"
                        :loading="loadingbutton" :disabled="loadingbutton"
                    >Agregar Licencia
                        <v-icon right dark small>fas fa-save</v-icon>
                    </v-btn>                                                  
                    <v-btn large class="mt-2" color="success" @click="GuardarModificacionLicencia()" v-if="!EstadoLicenciaEditar"
                        :loading="loadingbutton" :disabled="loadingbutton"
                    >Guardar Cambios
                        <v-icon right dark small>fas fa-edit</v-icon>
                    </v-btn>                                                  
                </v-col>          
                <v-col cols="12" lg="3" md="3" sm="6" class="pb-0">
                    <v-btn large class="mt-2" color="#d80000" dark @click="CancelarCambios()" v-if="!EstadoLicenciaEditar">Cancelar
                        <v-icon right dark small>fas fa-times</v-icon>
                    </v-btn>                                                                  
                </v-col>
            </v-row>
            <v-row wrap class="mx-2">
                <v-col cols="12" lg="12" md="12" sm="12" >
                    <v-toolbar color="primary" flat dark>
                        <v-toolbar-title class="tituloTabla">Licencias del colaborador</v-toolbar-title>                    
                    </v-toolbar>                    
                    <v-data-table
                        :headers="HeadersTableLicences"
                        :items="TodasLasLicenciasTabla"
                        class="elevation-1"
                        item-key="tipo"
                        hide-default-footer
                    >
                        <template v-slot:no-data v-if="TodasLasLicenciasTabla.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                NO EXISTEN LICENCIAS ASIGNADAS
                            </v-alert>
                        </template>                    
                        <template v-slot:item.action="{ item }">                                
                            <v-tooltip bottom color="#d69b01">
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="ml-2" dark fab color="#d69b01" v-on="on" @click="ModificarLicencia(item)">
                                        <v-icon small>fas fa-edit</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Editar Licencia</span>
                            </v-tooltip>                                                                          
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>

<script>
  import { mapGetters, mapActions  } from 'vuex'
  import {showMessageError,showMessageSuccess} from '../../services/sweetAlert';

export default {
    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,      
            loadingbutton: false,
            loading: false,
            MostrarMensaje:'',
            idUsuario: '',
            tipoLicencia: '',
            EstadoLicenciaGuardar: true,
            EstadoLicenciaEditar: true,
            EstadoLicenciaFecha: true,
            EstadoLicencia: false,
            TodasLasLicencias:[],
            TodasLasLicenciasTabla: [],
            modeloLicencia: {
                tipo: '',
                fechaVencimiento: '',
                idLicencia: '',
                userId: '',
            },
            HeadersTableLicences: [
                {text: 'Tipo Licencia', value: 'tipo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Fecha de Vencimiento', value: 'fechaVencimiento', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                            
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
            ],
            dateFechaVencimientoLicencia: new Date().toISOString().substr(0, 10),
            menuFechaVencimientoLicencia: false,
        }
    },

    computed: {      
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),
    },

    created() {
        this.ObtenerLicencias()        
    },

    watch: {
        tipoLicencia : function (params) {
            if(params){
                this.EstadoLicenciaGuardar = false
                this.EstadoLicenciaFecha = false
                this.EstadoLicenciaEditar = true
            }
            else{
            }
        },        
    },

    mounted() {
        var user = this.$store.getters['oidcStore/'+'oidcUser']
        this.idUsuario = user.sub
        this.ObtenerUsuario(user)
    },

    methods: {
    // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
    ControllerException(){
        this.alertError=true
        this.loadingbutton=false
        // this.loading=false
    },                        
        // obtenemos las licencias para mostrarlo en el select y agregar una nueva licencia
        async ObtenerLicencias(){
            const response = await this.$store.dispatch('getAllLicencias')
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                                       
            if(response.status === 200){
                this.alertError= false
                this.TodasLasLicencias = response.data
                // console.log('licencias => ', this.TodasLasLicencias)
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },

        // obtenemos las licencias del usuario para mostrarlo en la tabla
        async ObtenerUsuario(){
            const response = await this.$store.dispatch('getUserById', this.idUsuario)
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                                       
            if(response.status === 200){   
                this.alertError= false                 
                this.TodasLasLicenciasTabla = response.data.userLicencias           
                // console.log('obtener usuario => ', this.modeloLicencia)
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },
        async GuardarNuevaLicencia(){
            this.loadingbutton = true
            this.modeloLicencia.tipo = this.tipoLicencia
            this.modeloLicencia.fechaVencimiento = this.dateFechaVencimientoLicencia
            this.modeloLicencia.userId = this.idUsuario

            const response  = await this.$store.dispatch('GuardarNuevaLicenciaUsuario', this.modeloLicencia)
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                                       
            if(response.status === 200){
                this.alertError= false
                this.loadingbutton = false
                // console.log('modeloLicencia =>', this.modeloLicencia)
                this.ObtenerUsuario()
                this.tipoLicencia = ''
                this.dateFechaVencimientoLicencia = new Date().toISOString().substr(0, 10)
                this.EstadoLicenciaGuardar = true
                this.EstadoLicenciaFecha = true
                showMessageSuccess('Bien!', 'Licencia Creado con Éxito')
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingbutton = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                this.loadingbutton = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingbutton = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingbutton = false
            }            
        },

        ModificarLicencia(item){
            this.loading = true
            this.MostrarMensaje="Ingrese La Nueva Fecha de Vencimiento"
            this.modeloLicencia.idLicencia = item.idLicencia
            this.EstadoLicenciaEditar = false
            this.EstadoLicenciaGuardar = true
            this.EstadoLicenciaFecha = false
            this.EstadoLicencia = true
            this.tipoLicencia = ''
            // this.dateFechaVencimientoLicencia = item.fechaVencimiento
            // console.log('item', item)
        },

        async GuardarModificacionLicencia(){
            this.loadingbutton = true
            this.modeloLicencia.fechaVencimiento = this.dateFechaVencimientoLicencia            
            const response = await this.$store.dispatch('GuardarLicenciaEditadaUsuario', this.modeloLicencia)
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                           
            if(response.status === 200){
                this.loadingbutton = false
                this.alertError= false
                this.CancelarCambios()
                this.ObtenerUsuario()
                showMessageSuccess('Actualizado!', 'La Licencia se ha Actualizado con Éxito')
                // console.log('fecha vencimiento', this.modeloLicencia)
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },
        CancelarCambios(){
                this.MostrarMensaje=""
                this.loading = false
                this.EstadoLicencia = false
                this.EstadoLicenciaFecha = true
                this.EstadoLicenciaEditar = true
                this.dateFechaVencimientoLicencia = new Date().toISOString().substr(0, 10)
        }
    },
}
</script>