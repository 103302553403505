<template>
    <div>
        <v-dialog
            v-model="dialogoVisualizarTablaCortes"
            scrollable fullscreen 
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title class="AppBarTitle" dark elevation="0">
                    <v-icon left color="black"> mdi-file-pdf</v-icon> {{Titulo}}
                    <v-spacer></v-spacer>

                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="TituloDialogo" color="error" @click="CerrarModalVisualizarTablaCortes()">Salir
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Salir</span>
                    </v-tooltip>                    
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="EncabezadoTabla"
                        :items="ArrayBitacoraDeCortes"
                        item-key="corteInventarioSucursalId"
                        :search="buscarBitacoraDeCortes"
                        class="elevation-1"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."
                    > 
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip left color="error">
                            <template v-slot:activator="{ on }">
                                <v-btn small class="ml-2" dark fab color="error" v-on="on" @click="DescargarBitacorasDeCortePDF(item)">
                                    <v-icon >fas fa-file-download</v-icon>
                                </v-btn>                        
                            </template>
                            <span class="fontButton">Descargar bitácoras de corte</span>
                        </v-tooltip>                                              
                    </template>
                    <template v-slot:no-results>
                        <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                            Bitácora no existe
                        </v-alert>                        
                    </template>
                    <template v-slot:no-data v-if="ArrayBitacoraDeCortes.length === 0">
                        <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo" v-if="!alertError">
                            No existen bitácoras registradas
                        </v-alert>
                    </template>                    
                </v-data-table>
                </v-card-text>
            </v-card>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Descargando bitácora de corte ...
        </v-overlay>                  
        </v-dialog>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    props:{
        dialogoVisualizarTablaCortes: Boolean,
        Titulo: String,
        ArrayBitacoraDeCortes: Array,
    },

    data() {
        return {
            buscarBitacoraDeCortes: '',
            loading: false,
            EncabezadoTabla: [
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Usuario crea', value:'usuarioCrea', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Fecha', value: 'fechaCreado', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'actions', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
            ],  
            EstadoCircularProgress:false          
        }
    },

    methods: {
        CerrarModalVisualizarTablaCortes(){
            this.$emit('CerrarModalVisualizarTablaCortes')
        },

        async DescargarBitacorasDeCortePDF(datoBitacora){
            this.EstadoCircularProgress = true
            const response = await this.$store.dispatch('DescargarBitacoraDeCorte', datoBitacora.corteInventarioProyectoId)
            if(response.status === 200){
                this.alertError= false
                this.loading = false       
                this.forceFileDownload('Bitácora_Corte.pdf',response)
            }else{
                this.ControllerException(response)
            }                
        },
        forceFileDownload(fileName, response) {
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = window.URL.createObjectURL(file);
            this.EstadoCircularProgress = false
            const link = document.createElement('a')
            link.href = fileURL;
            link.download = fileName;
            link.click();
        },        
        // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            if(typeof response === 'undefined'){
                this.alertError=true
                this.loading = false
                this.EstadoCircularProgress = false
                this.loadingEliminarTipoVehiculo = false
                return 0
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingEliminarTipoVehiculo = false
                this.loading = false
                this.EstadoCircularProgress = false
            }
        },        
    },
}
</script>

<style>
    .AppBarTitle {
        border: 2px black outset;
    }    
</style>
