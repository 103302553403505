<template>
    <div class="text-center">
        <!-- <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>             -->               
        <v-toolbar title elevation="1">
            <v-icon color="black " left>fas fa-car-side</v-icon>
            <v-toolbar-title class="tituloMaquinaria">Inventario de vehículos disponibles</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerInventarioDeVehiculosDisponibles"/>
        </v-toolbar>        
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert> 
        <v-data-table
            :headers="EncabezadoTablaInventarioVehiculosDisponibles"
            :items="ArrayTablaInventarioVehiculosDisponibles"
            item-key="idHerramienta"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."    
        >
            <template v-slot:item.puedeSolicitar="{ item }">
                <v-row justify="space-around">
                    <v-tooltip left color="#00b40f" v-if="item.puedeSolicitar">
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" color="#00b40f" text-color="white" > 
                                <v-icon>fas fa-check</v-icon>
                            </v-chip>
                        </template>
                        <span class="fontButton">sí puede realizar solicitud</span>
                    </v-tooltip>                    
                    <v-tooltip left color="#d80000" v-if="!item.puedeSolicitar">
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" color="#d80000" text-color="white" >
                                <v-icon >fas fa-times-circle</v-icon>
                            </v-chip>
                        </template>
                        <span class="fontButton">{{item.motivo}}</span>
                    </v-tooltip>                    
                </v-row>
            </template>               
            <template v-slot:no-data v-if="ArrayTablaInventarioVehiculosDisponibles.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                >
                    No hay vehículos disponibles en inventario
                </v-alert>
            </template>                    
            <template v-slot:item.action="{ item }">
                <v-tooltip left color="#000000" v-if="item.puedeSolicitar">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AgregarVehiculoASolicitud(item)" >
                            <v-icon small>fas fa-plus-circle</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Agregar Solicitud</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <TablaDeSolicitudesDeVehiculosAEnviar :ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar="ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar" 
            :sucursal="sucursal" :bodega="bodega" :proyecto="proyecto"
            @ObtenerInventarioDeVehiculosDisponibles="ObtenerInventarioDeVehiculosDisponibles"
        />
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess, showMessageWarning } from '../../../../services/sweetAlert'
import TablaDeSolicitudesDeVehiculosAEnviar from './TablaDeSolicitudesDeVehiculosAEnviar'
import ModalIngreseCantidadASolicitarVehiculos from './ModalIngresarCantidadASolicitarVehiculos'
import BotonActualizarTabla from '../../../../views/BotonActualizarTabla'

export default {
    components:{
        TablaDeSolicitudesDeVehiculosAEnviar,
        ModalIngreseCantidadASolicitarVehiculos,
        BotonActualizarTabla,
    },
    props:{
        proyecto:Number,
        sucursal:Number,
        bodega:Number,
        tab: String,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            ArrayTablaInventarioVehiculosDisponibles: [],
            EncabezadoTablaInventarioVehiculosDisponibles:[
                {text: 'Id', value:'idVehiculo', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Código', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Vehículo', value: 'tipoVehiculo.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto actual', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: '¿Puede Solicitar?', value: 'puedeSolicitar', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            ArraySolicitudesHerramientasAEnviar:[],
            PuedeSolicitar: false,

            ObjectDatosSolicitudDeVehiculoInventario:{
                idVehiculo:0,
                codigo:0,
                nombreVehiculo:'',
                vehiculoMarca:'',
                tipoVehiculo:'',
                placa:'',
                color:'',
                modelo:'',
            },
            ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar:[],
            // varibles para el dialogo ingresar cantidad a solicitar de vehiculos
            PuedeSolicitar: false,
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },
    watch: {
        tab(){
            this.ObtenerInventarioDeVehiculosDisponibles()
        },
        sucursal(){
            this.ArrayTablaInventarioVehiculosDisponibles = []
            this.ObtenerInventarioDeVehiculosDisponibles()
        },
        bodega(){
            this.ObtenerInventarioDeVehiculosDisponibles()
        },
        proyecto(){
            this.ObtenerInventarioDeVehiculosDisponibles()
        }
    },
    created() {
        this.ObtenerInventarioDeVehiculosDisponibles()
    },

    methods: {
        ValidarVehiculo(datos){
            var existe = false
            this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.forEach(x =>{
                if(x.idVehiculo === datos.idVehiculo){
                    existe = true;                    
                }
            })
            if(existe){
                showMessageWarning('Oops!','Vehículo ya está agregado a la solicitud')
            }else{
                this.PushVehiculos(datos)
            }            
        },

        PushVehiculos(datos){
            this.ObjectDatosSolicitudDeVehiculoInventario = {}
            this.ObjectDatosSolicitudDeVehiculoInventario.idVehiculo = datos.idVehiculo
            this.ObjectDatosSolicitudDeVehiculoInventario.codigo = datos.codigo
            this.ObjectDatosSolicitudDeVehiculoInventario.nombreVehiculo = datos.nombre
            this.ObjectDatosSolicitudDeVehiculoInventario.vehiculoMarca = datos.vehiculoMarca.nombre            
            this.ObjectDatosSolicitudDeVehiculoInventario.tipoVehiculo = datos.tipoVehiculo.nombre
            this.ObjectDatosSolicitudDeVehiculoInventario.placa = datos.placa            
            this.ObjectDatosSolicitudDeVehiculoInventario.color = datos.color            
            this.ObjectDatosSolicitudDeVehiculoInventario.modelo = datos.modelo            
            this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.push(this.ObjectDatosSolicitudDeVehiculoInventario)            
        },        
        AgregarVehiculoASolicitud(datos){   
            if(this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.length === 0){
                this.PushVehiculos(datos)
            }else{
                this.ValidarVehiculo(datos)
            }
        },        
        // metodos para el formulario ingresar cantidad a solicitar de vehiculos

        async ObtenerInventarioDeVehiculosDisponibles (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            var data = {proyecto: this.proyecto, sucursal: this.sucursal, bodega: this.bodega}
            const response = await this.$store.dispatch('TodosVehiculosSolicitud', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar = []
                this.ArrayTablaInventarioVehiculosDisponibles = response.data
                this.PuedeSolicitar = response.data.puedeSolicitar
                // alert(this.PuedeSolicitar)
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false 
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        }              
    },
}
</script>