<template>
    <v-dialog
        v-model="DialogoEditarPermiso"
        persistent 
        max-width="550px"
    >
        <v-card>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>                        
            <v-card-title primary-title>
                Editar Permisos
            </v-card-title>
            <v-card-text>
                <h3 class="text-center">Permisos</h3>
                <v-row justify="space-around">
                    <v-switch label="Crear" v-model="ObjetoEditarSubmenu.crear" color="primary" ></v-switch>
                    <v-switch label="Editar" v-model="ObjetoEditarSubmenu.editar"  color="primary"></v-switch>
                    <v-switch label="Eliminar" v-model="ObjetoEditarSubmenu.eliminar" color="primary"></v-switch>                
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="blue" @click="GuardarCambios()" outlined
                    :loading="EstadoProgressCircularEditarSubMenu"
                >
                    <v-icon left >fas fa-save</v-icon> Guardar Cambios 
                </v-btn>
                <v-btn dark color="red" @click="CerrarDialogoEditarPermiso()">
                    <v-icon left>fas fa-times-circle</v-icon> Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>   
    </v-dialog>
</template>

<script>
import {showMessageError,showMessageSuccess} from '../../../services/sweetAlert';

export default {

    props: {
        DialogoEditarPermiso: Boolean,
        ObjetoEditarSubmenu: Object,
    },
    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,                        
            EstadoProgressCircularEditarSubMenu: false
        }
    },

    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.EstadoProgressCircularEditarSubMenu = false
        },                

        CerrarDialogoEditarPermiso(){
            this.$emit('CerrarDialogoEditarPermiso')
        },

        async GuardarCambios(){
            this.EstadoProgressCircularEditarSubMenu = true
            // console.log('GuardarCambio', this.ObjetoEditarSubmenu)
            const response = await this.$store.dispatch('UpdateMenu', this.ObjetoEditarSubmenu)
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.loadingEliminarMenu = false
                return 0
            }                                    
            if(response.status === 200){
                this.alertError= false
                this.$emit('GetAllSubMenus')
                this.CerrarDialogoEditarPermiso()
                showMessageSuccess('Bien!', 'SubMenú Actualizado con Éxito')
                this.EstadoProgressCircularEditarSubMenu = false
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.EstadoProgressCircularEditarSubMenu = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                this.EstadoProgressCircularEditarSubMenu = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.EstadoProgressCircularEditarSubMenu = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.EstadoProgressCircularEditarSubMenu = false
            }            
        }
    },
}
</script>