<template>
    <!-- <div > -->
        <v-menu v-model="menu" :close-on-content-click="false"  left>
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left color="#000000">
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn small class="ml-2" outlined icon color="#000000" v-on="{...tooltip, ...menu}" v-bind="attrs" @click="AbrirFiltros()">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton"> {{TituloBotonVerMovimientoTimeLine}} </span>
                </v-tooltip>                                                    
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-icon left>fas fa-search</v-icon>
                        <v-list-item-content>
                            <v-list-item-title>Filtros</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item >
                        <v-list-item-action class="mt-0 mb-0 pt-0 pb-0">
                            <v-menu v-model="menuFechaInicio" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined v-model="dateFechaInicio" label="Fecha Inicio" 
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="Es-es" v-model="dateFechaInicio" @input="menuFechaInicio = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-action class="mt-0 mb-0 pt-0 pb-0">
                            <v-menu v-model="menuFechaFinal" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined v-model="dateFechaFinal" label="Fecha Final" 
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="Es-es"  v-model="dateFechaFinal" @input="menuFechaFinal = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded @click="AbrirModalTimeLine()">
                        Buscar
                    </v-btn>
                    <v-btn color="error" rounded @click="CerrarFiltros()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
            <v-overlay :value="EstadoCircularProgressMovimientos">
                <v-progress-circular indeterminate :size="50" :width="5" color="#ffffff"></v-progress-circular>Buscando movimientos...
            </v-overlay>    
            <TimeLineHerramientas 
                :AbrirTimeLine="AbrirTimeLine" @CerrarModalTimeLine="CerrarModalTimeLine"
                :ArrayMovimientosComponente="ArrayMovimientosHerramientas" :NombreComponente="NombreComponente"               
            />    
            <TimeLineCambiosSucursales 
                :AbrirTimeLine="AbrirTimeLineVehiculos" @CerrarModalTimeLine="CerrarModalTimeLineVehiculos"
                :ArrayMovimientosSucursales="ArrayMovimientosVehiculos"
            />            
            <TimeLineCambiosSucursales 
                :AbrirTimeLine="AbrirTimeLineMaquinaria" @CerrarModalTimeLine="CerrarModalTimeLineMaquinaria"
                :ArrayMovimientosSucursales="ArrayMovimientosMaquinaria"
            />            
            <ControlladorExcepciones :response="responseStatus"/>
        </v-menu>
    <!-- </div> -->
</template>

<script>
import TimeLineHerramientas from '../../components/Almacen/Herramientas/TimeLineMovimientosHerramienta'
import TimeLineCambiosSucursales from '../../components/Almacen/TimeLineCambiosSucursales'
import ControlladorExcepciones from '../ControlandoExcepcionesHTTPS'
import { showMessageError,showMessageSuccess } from '../../services/sweetAlert'

export default {
    components:{
        ControlladorExcepciones,
        TimeLineHerramientas,
        TimeLineCambiosSucursales,
    },

    props:{
        DatosRegistro: Object,
        TituloBotonVerMovimientoTimeLine: String,
        TituloComponente: String,
    },

    data() {
        return {
            menu: false,
            //timeline
            NombreComponente:'',
            ArrayMovimientosHerramientas: [],
            AbrirTimeLine: false,
            dateFechaInicio: new Date().toISOString().substr(0, 10),
            menuFechaInicio:false,
            dateFechaFinal: new Date().toISOString().substr(0, 10),
            menuFechaFinal:false,
            EstadoCircularProgressMovimientos:false,
            responseStatus:[],
            // timeline vehiculos
            AbrirTimeLineVehiculos: false,
            ArrayMovimientosVehiculos:[],
            // timeline maquinaria
            AbrirTimeLineMaquinaria: false,
            ArrayMovimientosMaquinaria:[],
        }
    },

    methods: {
        AbrirFiltros(){
            this.menu = true
            this.CerrarFiltros()
        },
        CerrarFiltros(){
            this.menu = false
            this.dateFechaInicio = new Date().toISOString().substr(0, 10), this.dateFechaFinal = new Date().toISOString().substr(0, 10)
        },
        //abrimos el modal para ver el timeline
            async AbrirModalTimeLine(){
                if(this.TituloComponente === "Vehiculos"){
                    this.NombreComponente = this.DatosRegistro.nombre
                    let datos = {idVehiculo: this.DatosRegistro.idVehiculo, fechaInicio: this.dateFechaInicio, fechaFin: this.dateFechaFinal}
                    this.EstadoCircularProgressMovimientos= true
                    const response = await this.$store.dispatch('TimeLineMovimientosPorVehiculos', datos)
                    if(response.status === 200){    
                        if(response.data.length === 0){
                            this.EstadoCircularProgressMovimientos= false
                            showMessageError('No se encontraron movimientos')
                        }else{
                            this.alertError= false
                            this.ArrayMovimientosVehiculos = response.data
                            this.EstadoCircularProgressMovimientos= false
                            this.AbrirTimeLineVehiculos = true 
                            this.menu = false
                        }
                    }else{
                        this.responseStatus = response
                    }                                            
                }else if(this.TituloComponente === "Maquinarias"){
                    this.NombreComponente = this.DatosRegistro.nombre
                    let datos = {idMaquinaria: this.DatosRegistro.idMaquinaria, fechaInicio: this.dateFechaInicio, fechaFin: this.dateFechaFinal}
                    this.EstadoCircularProgressMovimientos= true
                    const response = await this.$store.dispatch('TimeLineMovimientosPorMaquinarias', datos)
                    if(response.status === 200){    
                        if(response.data.length === 0){
                            this.EstadoCircularProgressMovimientos= false
                            showMessageError('No se encontraron movimientos')
                        }else{
                            this.alertError= false
                            this.ArrayMovimientosMaquinaria = response.data
                            this.EstadoCircularProgressMovimientos= false
                            this.AbrirTimeLineMaquinaria = true 
                            this.menu = false
                        }
                    }else{
                        this.responseStatus = response
                    }                                            

                }else if(this.TituloComponente === "Herramientas"){
                    this.NombreComponente = this.DatosRegistro.nombre
                    let datos = {idHerramienta: this.DatosRegistro.idHerramienta, fechaInicio: this.dateFechaInicio, fechaFin: this.dateFechaFinal}
                    this.EstadoCircularProgressMovimientos= true
                    const response = await this.$store.dispatch('TimeLineMovimientosPorHerramientas', datos)
                    if(response.status === 200){    
                        if(response.data.length === 0){
                            this.EstadoCircularProgressMovimientos= false
                            showMessageError('No se encontraron movimientos')
                        }else{
                            this.alertError= false
                            this.ArrayMovimientosHerramientas = response.data
                            this.EstadoCircularProgressMovimientos= false
                            this.AbrirTimeLine = true 
                            this.menu = false
                        }
                    }else{
                        this.responseStatus = response
                    }                                            
                }
            },
            CerrarModalTimeLine(){
                this.AbrirTimeLine = false
                this.dateFechaInicio = new Date().toISOString().substr(0, 10), this.dateFechaFinal = new Date().toISOString().substr(0, 10)
            },        
            CerrarModalTimeLineVehiculos(){
                this.AbrirTimeLineVehiculos = false
                this.dateFechaInicio = new Date().toISOString().substr(0, 10), this.dateFechaFinal = new Date().toISOString().substr(0, 10)
            },        
            CerrarModalTimeLineMaquinaria(){
                this.AbrirTimeLineMaquinaria = false
                this.dateFechaInicio = new Date().toISOString().substr(0, 10), this.dateFechaFinal = new Date().toISOString().substr(0, 10)
            },        
    },
}
</script>