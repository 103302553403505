<template> 
    <v-dialog 
        v-model="DialogoMensajeEliminar"
        persistent 
        max-width="500px"
    >
        <v-card  style="border: 2px solid black">
            <div class="text-center py-6">
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>                
                <v-icon size="50" color="red">fas fa-times</v-icon>
                <h2 class="titulomensaje">¿Esta seguro de que desea eliminar?</h2>
                <h2 class="titulomensaje">{{tituloMensajeEliminar}}</h2>
            </div>
            <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                <v-btn large rounded class="mx-2" color="primary" @click="Eliminar()"
                    :loading="loadingEliminarHerramientaMateriaPrima" :disabled="loadingEliminarHerramientaMateriaPrima"
                >Si, Eliminar
                    <v-icon right dark>fas fa-save</v-icon>
                </v-btn>            
                <v-btn dark large rounded class="mx-2" color="error" @click="CerrarDialogoMensajeEliminar()">Cancelar
                    <v-icon right dark>far fa-window-close</v-icon>
                </v-btn>                        </v-card-actions>
        </v-card>        
    </v-dialog>        
</template>

<script>
import { showMessageSuccess } from '../services/sweetAlert'
export default {
    props: {
        DialogoMensajeEliminar: Boolean,
        tituloMensajeEliminar: String,
        loadingEliminarHerramientaMateriaPrima: Boolean,
        alertError: Boolean,
    },
    data() {
        return {
            
        }
    },
    methods: {
        CerrarDialogoMensajeEliminar(){
            this.$emit('CerrarDialogoMensajeEliminar')
        },
        Eliminar(){
            this.$emit('Eliminar')
            // this.CerrarDialogoMensajeEliminar()
        }
    },
}
</script>

<style >
    .borders{
        border-style: 6px solid red;
    }
    .titulomensaje{
        font-family: 'PT Serif';
        font-size: 25px;  
    }
</style>