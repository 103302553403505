<template>
    <div class="text-center">
        <hr class="mt-2">
            <v-radio-group class="text-center" v-model="filtrarEstadoSolicitudVehiculos" row @change="ObtenerListadoDeSolicitudesDeVehiculos()">
                <v-icon left color="blue">fas fa-sync-alt</v-icon>
                <v-radio label="En Proceso" value="1"></v-radio>
                <v-divider vertical class="mr-4"></v-divider>

                <v-icon left color="green">fas fa-check</v-icon>
                <v-radio label="Aprobado" value="2"></v-radio>
                <v-divider vertical class="mx-4"></v-divider>

                <v-icon left color="red">fas fa-times-circle</v-icon>
                <v-radio label="Rechazado" value="3"></v-radio>
            </v-radio-group>                
        <hr>            
        <v-toolbar title elevation="1">
            <!-- <v-chip color="black" outlined >
                <v-icon class="ml-2" left>fas fa-file-alt</v-icon> -->
                <!-- <v-toolbar-title class="tituloMaquinaria">Solicitudes</v-toolbar-title> -->
            <v-chip color="blue" text-color="white" v-if="filtrarEstadoSolicitudVehiculos === '1'">
                <v-icon left>fas fa-sync-alt</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes {{TituloOpcion}}</v-toolbar-title>
            </v-chip>            
            <v-chip color="green" text-color="white" v-if="filtrarEstadoSolicitudVehiculos === '2'">
                <v-icon left>fas fa-check</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes {{TituloOpcion}}</v-toolbar-title>
            </v-chip>            
            <v-chip color="red" text-color="white" v-if="filtrarEstadoSolicitudVehiculos === '3'">
                <v-icon left>fas fa-times-circle</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes {{TituloOpcion}}</v-toolbar-title>
            </v-chip>
            <!-- </v-chip>             -->
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerListadoDeSolicitudesDeVehiculos"/>            
        </v-toolbar>        
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>                                                                                        
        <v-data-table
            :headers="EncabezadoTablaSolicitudesVehiculosPorEstado"
            :items="ArrayListadoDeSolicitudDeVehiculos"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."             
            item-key="sol_SucursalHerramientaId"
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip left color="#4baef3">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#4baef3" v-on="on" @click="AbrirModalDeVehiculosPorSolicitud(item)">
                            <v-icon small>fas fa-car-side</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver vehículos de la solicitud</span>
                </v-tooltip>
                <v-tooltip left color="#000000" v-if="filtrarEstadoSolicitudVehiculos==='3'">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirDialogoVerMotivoRechazoSolicitud(item)" >
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver Motivo de Rechazo</span>
                </v-tooltip>                
                <v-tooltip left color="#d80000" v-if="filtrarEstadoSolicitudVehiculos==='1'">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#d80000" v-on="on" @click="AbrirFormularioCancelarSolicitud(item)" >
                            <v-icon small>fas fa-times-circle</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Cancelar Solicitud</span>
                </v-tooltip>                            
            </template>        
            <template v-slot:no-data v-if="ArrayListadoDeSolicitudDeVehiculos.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    No existen solicitudes de vehículos registrados.
                </v-alert>
            </template>                                     
        </v-data-table>                
        <VerTablaDeVehiculosPorSolicitud
            :filtrarEstadoSolicitudVehiculos="filtrarEstadoSolicitudVehiculos"
            :DialogoVerVehiculosPorSolicitud="DialogoVerVehiculosPorSolicitud" @CerrarModalVehiculosPorSolicitud="CerrarModalVehiculosPorSolicitud"
            :VehiculosBySolicitud="VehiculosBySolicitud" :codigoSolicitud="codigoSolicitud" :proyecto="idProyecto" :DatosSolicitud="DatosSolicitud"
            :ProyectoSolicitudId="ProyectoSolicitudId" :sucursal="idSucursal" :bodega="idBodega"
            @ObtenerListadoDeSolicitudesDeVehiculos="ObtenerListadoDeSolicitudesDeVehiculos"
        /> 
        <VerMotivoRechazoSolicitud 
            :DatosSolictudRechazado="DatosSolictudRechazado" :AbrirModalVerMotivoRechazo="AbrirModalVerMotivoRechazo" @CerrarDialogoVerMotivoRechazoSolicitud="CerrarDialogoVerMotivoRechazoSolicitud"
        />               
        <MensajeCancelarSolicitud
            :DialogoCancelarSolicitud="DialogoCancelarSolicitud" :tituloCancelarSolicitud="tituloCancelarSolicitud" :loadingCancelarSolicitud="loadingCancelarSolicitud"
            @CerrarFormularioCancelarSolicitud="CerrarFormularioCancelarSolicitud" @EliminarSoliciutd="EliminarSoliciutd"
        />        
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import BotonActualizarTabla from '../../../../views/BotonActualizarTabla'
import VerTablaDeVehiculosPorSolicitud from './VerTablaDeVehiculosPorSolicitud'
import VerMotivoRechazoSolicitud from '../../../../views/Bodegas/VerMotivoRechazoSolicitud'
import MensajeCancelarSolicitud from '../../../../views/Bodegas/MensajeCancelarSolicitud'

export default {
    components:{
        BotonActualizarTabla,
        VerTablaDeVehiculosPorSolicitud,
        VerMotivoRechazoSolicitud,
        MensajeCancelarSolicitud,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            filtrarEstadoSolicitudVehiculos: '1',
            ArrayListadoDeSolicitudDeVehiculos: [],
            EncabezadoTablaListadoSolicitudVehiculosEnProceso:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaListadoSolicitudVehiculosAprobado:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Aprobación', value: 'fechaAprobacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaListadoSolicitudVehiculosRechazados:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Rechazo', value: 'fechA_MODIFICACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaSolicitudesVehiculosPorEstado:[],            

            TituloOpcion:'',
            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],
            // abrimos el modal donde se mostraran todas los vehiculos por solicitud
            VehiculosBySolicitud: [],
            DialogoVerVehiculosPorSolicitud: false,
            codigoSolicitud: "",
            DatosSolicitud: {proyecto:'', sucursal:''},
            // ver motivo de rechazo de la solicitud
            DatosSolictudRechazado:{},
            AbrirModalVerMotivoRechazo:false,         
            //cancelar solicitud
            DialogoCancelarSolicitud:false,    
            tituloCancelarSolicitud:'',            
            loadingCancelarSolicitud: false,
            ProyectoSolicitudId:0,      
            // obtenemos los valores para obtener todos los materiales
            idProyecto:0, idSucursal:0, idBodega:0,                       
        }
    },
    props:{
        proyecto: Number,
        sucursal:Number,
        bodega: Number,
        tab: String,
    },
    watch: {
        tab(){
            this.ObtenerListadoDeSolicitudesDeVehiculos()
        },
        filtrarEstadoSolicitudVehiculos(){
            this.EstadoOpcionSolicitud()
        },
        sucursal(){
            this.ObtenerListadoDeSolicitudesDeVehiculos()
        },
        proyecto(){
            this.ObtenerListadoDeSolicitudesDeVehiculos()
        }
    },
    created() {        
        this.ObtenerListadoDeSolicitudesDeVehiculos();
        this.EstadoOpcionSolicitud()
    },

    methods: {
        //-----------------------------------------------------------------------------

        EstadoOpcionSolicitud(){
            if(this.filtrarEstadoSolicitudVehiculos === '1'){
                this.TituloOpcion = 'en proceso'
                this.EncabezadoTablaSolicitudesVehiculosPorEstado = this.EncabezadoTablaListadoSolicitudVehiculosEnProceso
            }else if(this.filtrarEstadoSolicitudVehiculos === '2'){
                this.TituloOpcion = 'aprobadas'
                this.EncabezadoTablaSolicitudesVehiculosPorEstado = this.EncabezadoTablaListadoSolicitudVehiculosAprobado
            }else if(this.filtrarEstadoSolicitudVehiculos === '3'){
                this.TituloOpcion = 'rechazadas'
                this.EncabezadoTablaSolicitudesVehiculosPorEstado = this.EncabezadoTablaListadoSolicitudVehiculosRechazados
            }        
        },

        async ObtenerListadoDeSolicitudesDeVehiculos (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            var data = {estado: this.filtrarEstadoSolicitudVehiculos, Proyecto: this.proyecto}
            // var data = {estado: this.filtrarEstadoSolicitudVehiculos, Proyecto: this.proyecto}
            const response = await this.$store.dispatch('ListadoSolicitudesVehiculosProceso', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayListadoDeSolicitudDeVehiculos = response.data
                if(this.codigoSolicitud != ''){
                    this.ActualizarTablaSolicitudSeleccionada()
                }
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        ActualizarTablaSolicitudSeleccionada(){
            this.ArrayListadoDeSolicitudDeVehiculos.forEach(s => {
                if(this.codigoSolicitud == s.codigo){
                    this.VehiculosBySolicitud = s.sol_ProyectoVehiculos
                }
            })
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
            this.loadingCancelarSolicitud = false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false        
                this.loadingCancelarSolicitud = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
                this.loadingCancelarSolicitud = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.loadingCancelarSolicitud = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.loadingCancelarSolicitud = false
            }                
        },
        //mostramos el dialogo con todos los vehiculos de cada solicitud
        AbrirModalDeVehiculosPorSolicitud(VehiculosPorSolicitud){
            this.idProyecto = VehiculosPorSolicitud.proyecto.idProyecto
            this.idSucursal = VehiculosPorSolicitud.sucursal.sucursalId
            this.idBodega = VehiculosPorSolicitud.sol_ProyectoVehiculos[0].vehiculo.bodega.bodegaId
                        
            this.ProyectoSolicitudId = VehiculosPorSolicitud.solicitudProyectoId
            this.codigoSolicitud = VehiculosPorSolicitud.codigo
            this.VehiculosBySolicitud = VehiculosPorSolicitud.sol_ProyectoVehiculos
            this.DatosSolicitud.proyecto = VehiculosPorSolicitud.proyecto.nombre
            this.DatosSolicitud.sucursal = VehiculosPorSolicitud.sucursal.nombre
            this.DialogoVerVehiculosPorSolicitud = true
        },

        CerrarModalVehiculosPorSolicitud(){
            this.DialogoVerVehiculosPorSolicitud = false
        },
        //-----------------------------------------------------------------------------
        AbrirDialogoVerMotivoRechazoSolicitud(datos){
            this.DatosSolictudRechazado = datos
            this.AbrirModalVerMotivoRechazo = true
        },

        CerrarDialogoVerMotivoRechazoSolicitud(){
            this.AbrirModalVerMotivoRechazo = false
        },  
        //cancelar una solicitud
        AbrirFormularioCancelarSolicitud(item){
            this.ProyectoSolicitudId = item.solicitudProyectoId
            this.tituloCancelarSolicitud = "Solicitud Vehículo: "+ item.codigo
            this.DialogoCancelarSolicitud = true
        },
        CerrarFormularioCancelarSolicitud(){
            this.DialogoCancelarSolicitud = false
        },        
        async EliminarSoliciutd(){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loadingCancelarSolicitud = true
            const response = await this.$store.dispatch('CancelarSolicitudABodega', this.ProyectoSolicitudId)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                showMessageSuccess('BIEN!','Solicitud de Vehículo cancelada correctamente.')
                this.ObtenerListadoDeSolicitudesDeVehiculos()
                this.loadingCancelarSolicitud = false
                this.CerrarFormularioCancelarSolicitud()
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },                  
    },
}
</script>