<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialogClave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Cambiar contraseña</span>
        </v-card-title>
        <v-alert :value="alertError"
                    :type=typeAlert
                    transition="scale-transition"
                    :color=typeAlert
                    outlined>
                    <h4>{{titleAlert}}</h4>
                <ul>
                  <li type="circle" v-for="(i) in arrayErrorPost" :key="i.id">
                      <h5>{{i.value}}</h5>
                  </li>
              </ul>
            </v-alert>
        <v-card-text>
          <v-form v-model="valid" ref="form" :lazy-validation="lazy">
                <v-container>
                      <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12">
                          <v-text-field  @keypress="activeButton = true" :type="show1 ? 'text' : 'password'" v-model="model.contraseñaActual" 
                          @click:append="show1 = !show1" 
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="ContraseñaRules" label="Contraseña Actual" required ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" md="12" sm="12">
                          <v-text-field  @keypress="activeButton = true" :type="show1 ? 'text' : 'password'" v-model="model.password" 
                          @click:append="show1 = !show1" 
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="ContraseñaRules" label="Nueva contraseña" required ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" md="12" sm="12">
                          <v-text-field @keypress="activeButton = true" :type="show1 ? 'text' : 'password'" v-model="model.confirmPassword" 
                          @click:append="show1 = !show1" 
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="ContraseñaRules" label="Escriba de nuevo la contraseña" required ></v-text-field>
                        </v-col>
                      </v-row>                        
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="activeButton" color="success"  @click="validate()" rounded :loading="loading" :disabled="loading" >
                <v-icon left> fas fa-save</v-icon> <strong>Cambiar Contraseña</strong>

          </v-btn>
          <v-btn color="#d80000" dark @click="cancelar()" rounded >
                <v-icon left> fas fa-times</v-icon> <strong>{{textButton}}</strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { StringDecoder } from 'string_decoder';
import { mapGetters, mapActions  } from 'vuex'
import store from '@/store'
  export default {
    data: () => ({
        loading: false,
        show1: false,
        titleAlert:'',
        typeAlert:'error',
        textButton: 'Cancelar',
        arrayErrorPost:[],
        activeButton:true,
        alertError:false,
        idUser: '',
        model:{
            password:'',
            confirmPassword:'',
            userID:'',
            contraseñaActual: ''
        },
        valid: true,
        lazy: false,
        ValidarContraseñaMessage: 'Las contraseñas no coinciden',
        ContraseñaRules: [
            v => !!v || 'contraseña es requerida',
            v => (v && v.length >= 8) || 'La contraseña debe cotener al menos 8 caracteres',
        ],
    }),
    props:{
        dialogClave:Boolean,
        id:String
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
        hasAccess: function() {
            return this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']
        },
        idUserComputed: function () {
            if(this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']){
                var user = this.$store.getters['oidcStore/'+'oidcUser']
                // return user.sub
                return user.sub
            }
        }        
    },

    methods:{
        lanzar_alerta(Type,Title,Text,Footer){
        this.$swal.fire({
          type: Type,
          title: Title,
          text: Text,
          footer: Footer,
           allowOutsideClick: false
        });
      },
        claveActualizada(){
            this.model.password=''; this.model.confirmPassword='', this.model.contraseñaActual='';
            this.lanzar_alerta('success', '¡ BIEN !', 'Contraseña Actualizada');
            this.$emit('claveActualizada');
        },

        pushError(error){
          // console.log('error => ',error)
          Object.keys(error).forEach((element,key,_array)=>{
            //  console.log('element => ', error[element])   
             this.parseErrorsGeneric(error[element])      
          });
        },

      parseErrorsGeneric(error){
        // console.log('error ==> ',error)
        Object.keys(error).forEach((element,key,_array)=> {
          this.arrayErrorPost.push({ value: error[element] });
        });
      },
      reset (){
          this.$refs.form.reset()
      },

      validate(){
        this.$refs.form.validate()
        if(this.$refs.form.validate() === true){
          this.guardar()
        }
      }, 

      async guardar(){
        this.loading = true
        this.arrayErrorPost = []
        // this.activeButton = false
        this.valid = true
        this.model.userID = this.idUserComputed
        if(this.model.password === this.model.confirmPassword){
          // console.log('model', this.model)
          const response = await this.$store.dispatch('UpdatePasswordUser',this.model)
          if(response.status === 200){
            this.loading = false
            this.reset()
            this.alertError =true
            this.typeAlert='success'
            this.titleAlert='Contraseña Actualizada con Éxito'
            this.textButton='Cerrar'
          }
          if(response.status == 400){
            this.loading = false
            this.alertError = true
            this.typeAlert='error'
            this.titleAlert='La Contraseña Actual es Incorrecta'
            this.reset()
          }
          // console.log('contraseñan coinciden')
        }else{
            this.reset()
            this.alertError = true
            this.typeAlert='error'
            this.titleAlert='Las Contraseñas no coinciden, vuelva a intentarlo'          
        }
      },

        cancelar(){          
          this.model.contraseñaActual = ''
          this.model.password = ''
          this.model.confirmPassword = ''
            this.alertError =false
            this.$emit('cancelClave')
        }
    }
  }
</script>