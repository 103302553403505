<template>
    <v-dialog v-model="AbrirTablaDeMaquinariasPorSolicitud" fullscreen scrollable>
        <v-card>
            
            <v-card-title class="AppBar" elevation="0" style="color: #ffffff">
                <v-icon color="white" left>fas fa-snowplow</v-icon> Maquinarias de solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalMaquinariasDeSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>                
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <!-- <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>             -->
                    <div class="my-3">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaterial">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaterial">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaterial">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>                        
                    <v-toolbar title elevation="1">
                        <v-chip color="blue" outlined>
                            <v-icon left>fas fa-snowplow</v-icon>
                            <v-toolbar-title class="tituloMaterial">Maquinarias solicitadas</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>                    

                        <v-spacer></v-spacer>      
                        <v-text-field flat class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            prepend-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                       
                                <v-btn v-if="filtrarEstadoSolicitudesMaquinarias==='1'" class="ml-2" dark color="indigo" v-on="on" :disabled="loading || !activarBotonAutorizar" @click="AutorizarTodasLasSolicitudesSeleccionadas()"
                                    :loading="loading" 
                                >Autorizar maquinarias
                                    <v-icon color="white" >far fa-check-circle</v-icon>
                                </v-btn>                        
                            
                              
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table v-if="this.filtrarEstadoSolicitudesMaquinarias==='1'"
                        :headers="EncabezadoTablaSolicitudes"
                        :items="MaquinariasBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                        v-model="selected"         
                        :single-select="singleSelect"
                        show-select
                        item-key="maquinaria.idMaquinaria"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-tooltip left color="#000000" v-if="this.filtrarEstadoSolicitudesMaquinarias==='3'">
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirDialogoVerMotivoRechazoSolicitud(item)" >
                                        <v-icon small>fas fa-eye</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Ver Motivo de Rechazo</span>
                            </v-tooltip>
                        </template>  
                        <template v-slot:item.estado="{ item }">
                            <v-row justify="space-around">
                                <v-chip color="blue" text-color="white" v-if="item.estado === 1"> 
                                    <span class="fontButton">En proceso</span>
                                </v-chip>
                                <v-chip color="green" text-color="white" v-if="item.estado === 2"> 
                                    <span class="fontButton">Autorizada</span>
                                </v-chip> 
                                <v-chip color="red" text-color="white" v-if="item.estado === 3"> 
                                    <span class="fontButton">Rechazada</span>
                                </v-chip>                 
                            </v-row>
                        </template>       
                        <template v-slot:no-data v-if="MaquinariasBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen maquinarias en esta solicitud
                            </v-alert>
                        </template>                                     
                    </v-data-table>                                           
                    <v-data-table v-if="this.filtrarEstadoSolicitudesMaquinarias!=='1'"
                        :headers="EncabezadoTablaSolicitudes"
                        :items="MaquinariasBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                        item-key="maquinaria.idMaquinaria"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-tooltip left color="#000000" v-if="this.filtrarEstadoSolicitudesMaquinarias==='3'">
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirDialogoVerMotivoRechazoSolicitud(item)" >
                                        <v-icon small>fas fa-eye</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Ver Motivo de Rechazo</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.estado="{ item }">
                            <v-row justify="space-around">
                                <v-chip  color="blue" text-color="white" v-if="item.estado === 1"> 
                                    <span class="fontButton">En proceso</span>
                                </v-chip>
                                <v-chip color="green" text-color="white" v-if="item.estado === 2"> 
                                    <span class="fontButton">Autorizada</span>
                                </v-chip> 
                                <v-chip color="red" text-color="white" v-if="item.estado === 3"> 
                                    <span class="fontButton">Rechazada</span>
                                </v-chip>                 
                            </v-row>
                        </template>        
                        <template v-slot:no-data v-if="MaquinariasBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen maquinarias en esta solicitud
                            </v-alert>
                        </template>                                     
                    </v-data-table>                       
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../../views/BotonActualizarTabla'
import AutorizarSolicitudDeHerramienta from '../../../components/bodega/AutorizarSolicitudHerramienta/FormularioAutorizarSolicitud'
import VerMotivoRechazoSolicitud from '../../../views/Bodegas/VerMotivoRechazoSolicitud'

export default {
    props:{
        AbrirTablaDeMaquinariasPorSolicitud: Boolean,
        MaquinariasBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,        
        filtrarEstadoSolicitudesMaquinarias: String,
    },
    components:{
        BotonActualizarTabla,
        AutorizarSolicitudDeHerramienta,
        VerMotivoRechazoSolicitud,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaListadoSolicitudHerramientasEnProceso:[
                {text: 'Código', value: 'maquinaria.codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Serie', value: 'maquinaria.serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'maquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'maquinaria.vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Combustible', value: 'maquinaria.tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo', value: 'maquinaria.tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Sucursal Origen', value: 'maquinaria.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega Origen', value: 'maquinaria.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Proyecto actual', value: 'maquinaria.proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Estado', value: 'estado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
            ],
            EncabezadoTablaListadoSolicitudHerramientasAprobado:[
                {text: 'Código', value: 'maquinaria.codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Serie', value: 'maquinaria.serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'maquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'maquinaria.vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Combustible', value: 'maquinaria.tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo', value: 'maquinaria.tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Sucursal Origen', value: 'maquinaria.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega Origen', value: 'maquinaria.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Proyecto actual', value: 'maquinaria.proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Estado', value: 'estado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
            ],
            EncabezadoTablaListadoSolicitudHerramientasRechazados:[
                {text: 'Código', value: 'maquinaria.codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Serie', value: 'maquinaria.serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'maquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'maquinaria.vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Combustible', value: 'maquinaria.tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo', value: 'maquinaria.tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Sucursal Origen', value: 'maquinaria.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega Origen', value: 'maquinaria.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Estado', value: 'estado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
            ],
  
            EncabezadoTablaSolicitudes:[],
            // variables para autorizacion de solicitud de herramientas
            FormularioAutorizarSolicitudHerramienta:false,           

            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],    
                    
            singleSelect: false,
            selected: [],
            activarBotonAutorizar: false,
        }
    },

    watch: {
        AbrirTablaDeMaquinariasPorSolicitud(){
            this.EstadoOpcionSolicitud()
        },
        selected(){
            // console.log('selected: ', this.selected.length)
            if(this.selected.length > 0){
                this.activarBotonAutorizar = true
            }else{
                this.activarBotonAutorizar = false
            }
        }
    },

    created() {        
    },

    methods: {
        //-----------------------------------------------------------------------------
        EstadoOpcionSolicitud(){
            if(this.filtrarEstadoSolicitudesMaquinarias === '1'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudHerramientasEnProceso
            }else if(this.filtrarEstadoSolicitudesMaquinarias === '2'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudHerramientasAprobado
            }else if(this.filtrarEstadoSolicitudesMaquinarias === '3'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudHerramientasRechazados
            }        
        },        
        //-----------------------------------------------------------------------------
        AbrirDialogoVerMotivoRechazoSolicitud(datos){
            this.DatosSolictudRechazado = datos
            this.AbrirModalVerMotivoRechazo = true
        },

        CerrarDialogoVerMotivoRechazoSolicitud(){
            this.AbrirModalVerMotivoRechazo = false
        },

        MapearMaquinarias(datos){
            this.ArrayTodasLasSolicitudesAutorizar=[]
            Object.keys(datos).forEach((element,key,_array)=>{
                this.ArrayTodasLasSolicitudesAutorizar.push({
                    sol_ProyectoMaquinariaId:datos[element].sol_ProyectoMaquinariaId,
                    autorizado: true,
                });
            });
        },

        async AutorizarTodasLasSolicitudesSeleccionadas(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.MapearMaquinarias(this.selected)
            // console.log('ArrayTodasLasSolicitudesAutorizar: ', this.ArrayTodasLasSolicitudesAutorizar)
            var data = {maquinas: this.ArrayTodasLasSolicitudesAutorizar, proyectoSolicitudId: this.DatosSolicitud.idProyecto}
            const response = await this.$store.dispatch('AutorizarSolicitudesDeMaquinariasSeleccionadas', data)
            if(response.status === 200){
                this.alertErrorPost=false
                this.arrayErrorPost=[]   
                this.selected=[]                                                       
                this.loading = false
                this.$emit('ObtenerListadoDeSolicitudesDeMaquinarias')
                showMessageSuccess('Bien!', response.data.message)
                this.CerrarModalMaquinariasDeSolicitud()
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false     
                setTimeout(() => {
                    this.alertErrorPost=false
                }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },
        AbrirModalAutorizarSolicitudDeHerramienta(datos){
            this.FormularioAutorizarSolicitudHerramienta = true
        },
        CerrarModalAutorizarSolicitudDeHerramienta(){
            this.FormularioAutorizarSolicitudHerramienta = false
        },
                
        CerrarModalMaquinariasDeSolicitud(){
            this.$emit('CerrarModalMaquinariasDeSolicitud')
        }
    },
}
</script>
<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
    .tituloMaterial {
        font-family: 'PT Serif';
        font-size: 20px;        
    }      
</style>