<template>
  <v-card outlined class="mx-auto">
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <template v-slot:extension>
        <v-tabs color="#0C0A17" v-model="tabs" fixed-tabs >
          <v-tab href="#mobile-tabs-5-1" >
            <v-icon color="#0C0A17" class="mr-2">fas fa-user</v-icon>
            Configuración de usuario
          </v-tab>

          <v-tab href="#mobile-tabs-5-2" >
            <v-icon color="#0C0A17" class="mr-2">fas fa-newspaper</v-icon>
            Información personal
          </v-tab>

          <v-tab href="#mobile-tabs-5-3" >
            <v-icon color="#0C0A17" class="mr-2">fas fa-id-badge</v-icon>
            Licencias
          </v-tab>

        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <!-- Tap 1-->
      <v-tab-item  :value="'mobile-tabs-5-' + 1">
        <v-card flat>
              <configurationForm/>
        </v-card>
      </v-tab-item>

      <!-- Tap 2-->
      <v-tab-item  :value="'mobile-tabs-5-' + 2">
        <v-card flat>
          <userInformation />
        </v-card>
      </v-tab-item>

      <!-- Tap 3-->
      <v-tab-item  :value="'mobile-tabs-5-' + 3">
        <v-card flat>
          <UpdateLicences />
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import configurationForm from './configurationForm.vue'
import userInformation from './UserInformation.vue'
import UpdateLicences from './UpdateLicences'

  export default {
    data () {
      return {
        tabs: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
    components:{
      configurationForm,
      userInformation,
      UpdateLicences,
    }
  }
</script>