
import axios from 'axios';


const apiAuthority =  axios.create({
  baseURL: `${process.env.VUE_APP_OAUTH_AUTHORITY}`+'/api',
  withCredentials: false,
  headers: {
    //'Accept':'aplication/json',
    //Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
    }
});

const AcoplaApi =  axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_HOST}`,
  withCredentials: false,
  headers: {
    //'Accept':'aplication/json',
    //Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
    }
});

export {apiAuthority, AcoplaApi}