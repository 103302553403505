<template>
    <div class="text-center">
        <!-- <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>             -->               
        <v-toolbar title elevation="1">
            <v-icon left color="#1976d2">fas fa-dolly</v-icon>
            <v-toolbar-title >Materiales disponibles</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerTodasLasMateriales"/>
        </v-toolbar>        
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>  
        <v-data-table
            :headers="EncabezadoTablaMateriales"
            :items="ArrayTodasMateriales"
            item-key="idMateriaPrima"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."    
        >
            <template v-slot:item.puedeSolicitar="{ item }">
                <v-row justify="space-around">
                    <v-tooltip left color="#00b40f" v-if="item.puedeSolicitar">
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" color="#00b40f" text-color="white" > 
                                <v-icon>fas fa-check</v-icon>
                            </v-chip>
                        </template>
                        <span class="fontButton">sí puede realizar solicitud</span>
                    </v-tooltip>                    
                    <v-tooltip left color="#d80000" v-if="!item.puedeSolicitar">
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" color="#d80000" text-color="white" >
                                <v-icon >fas fa-times-circle</v-icon>
                            </v-chip>
                        </template>
                        <span class="fontButton">Ya tiene una solicitud en proceso</span>
                    </v-tooltip>                    
                </v-row>
            </template>       
            <template v-slot:no-data v-if="ArrayTodasMateriales.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                >
                    No existen solicitudes registradas
                </v-alert>
            </template>                    
            <template v-slot:item.action="{ item }">
                <v-tooltip left color="#000000">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirModalFormularioSolicitud(item)" v-if="item.puedeSolicitar">
                            <v-icon small>fas fa-plus-circle</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Agregar Solicitud</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <TablaSolicitudesEnviarMateriales :ArrayTodasLasSolicitudesAEnviar="ArrayTodasLasSolicitudesAEnviar" 
            :proyecto="proyecto" :sucursal="sucursal" :bodega="bodega"
            @ObtenerTodasLasMateriales="ObtenerTodasLasMateriales"
        />

        <FormularioSolicitudMateriales 
            :DialogoFormularioSolicitud="DialogoFormularioSolicitud" @CerrarModalFormularioSolicitud="CerrarModalFormularioSolicitud"
            :ObtenerSolicitudDeMaterial="ObtenerSolicitudDeMaterial" :ArrayTodasLasSolicitudesAEnviar="ArrayTodasLasSolicitudesAEnviar"            
        />
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import TablaSolicitudesEnviarMateriales from '../../../components/Inventario/EnviarSolicitudDeMateriales/TablaSolicitudesEnviarMateriales'
import FormularioSolicitudMateriales from '../../../components/Inventario/EnviarSolicitudDeMateriales/FormularioSolicitudMateriales'
import BotonActualizarTabla from '../../BotonActualizarTabla'

export default {
    components:{
        TablaSolicitudesEnviarMateriales,
        FormularioSolicitudMateriales,
        BotonActualizarTabla,
    },

    props:{
        proyecto:Number,
        sucursal:Number,
        bodega:Number,
        tab:String
    },
    watch:{
        proyecto(val){
            this.ObtenerTodasLasMateriales()
        },
        tab(){
            if(this.tab === 'tab-1'){
                this.ObtenerTodasLasMateriales()
            }
        },
        sucursal(val){
            this.ArrayTodasMateriales=[]
             if(this.tab === 'tab-1' && val != 0){
                this.ObtenerTodasLasMateriales()
            }
        }
    },
    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            ArrayTodasMateriales: [],
            EncabezadoTablaMateriales:[
                {text: 'Id Material o Insumo', value:'catalogoMateriales.catalogoMaterialesId', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Material o Insumo', value:'catalogoMateriales.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value:'marcaMateriaPrima.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Unidad de Medida', value:'unidadDeMedida.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 1', value: 'ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 2', value: 'ubicacion2', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia', value: 'existencia', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: '¿Puede Solicitar?', value: 'puedeSolicitar', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            ArraySolicitudesHerramientasAEnviar:[],
            PuedeSolicitar: false,

            ObtenerSolicitudDeMaterial:{
                idMateriaPrima:0,
                nombreMaterial:'',
                marcaMaterial:'',
                unidadDeMedida:'',
                cantidadSolicitada:0,
                existencia:0,
            },
            ArrayTodasLasSolicitudesAEnviar:[],
            // varibles para el dialogo del formulario de solicitud
            DialogoFormularioSolicitud: false,

            alertErrorPost:false,
            arrayErrorPost:[],             
        }
    },

    created() {
        this.ObtenerTodasLasMateriales()
    },

    methods: {
        // metodos para el formulario de solicitud de material
        AbrirModalFormularioSolicitud(datos){
            console.log(datos)
            this.ObtenerSolicitudDeMaterial.idMateriaPrima = datos.idMateriaPrima
            this.ObtenerSolicitudDeMaterial.nombreMaterial = datos.catalogoMateriales.nombre
            this.ObtenerSolicitudDeMaterial.marcaMaterial = datos.marcaMateriaPrima.nombre
            this.ObtenerSolicitudDeMaterial.unidadDeMedida = datos.unidadDeMedida.nombre            
            this.ObtenerSolicitudDeMaterial.existencia = datos.existencia            
            
            this.DialogoFormularioSolicitud = true
        },
        CerrarModalFormularioSolicitud(){
            this.ObtenerSolicitudDeMaterial = {}
            this.DialogoFormularioSolicitud = false
        },

        async ObtenerTodasLasMateriales (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            this.ArrayTodasMateriales=[]
            var data = {proyecto: this.proyecto, sucursal: this.sucursal}
            const response = await this.$store.dispatch('TodasMaterialesSolicitud', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayTodasLasSolicitudesAEnviar = []
                this.ArrayTodasMateriales = response.data
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false 
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        }              
    },
}
</script>