<template>
    <div>
        <!-- <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>             -->
        
        <v-toolbar title elevation="1">
            <v-toolbar-title class="tituloMaquinaria">{{TituloTabla}}</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerInventarioMaterialOInsumoPorProyecto" />
        </v-toolbar>        
        <v-data-table
            :headers="EncabezadoTablaInventarioSucursal"
            :items="ArrayInventarioMaterialOInsumo"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."                                
        >
            <template v-slot:no-data v-if="ArrayInventarioMaterialOInsumo.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    No existen materiales o insumos registrados.
                </v-alert>
            </template>                 
        </v-data-table>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import BotonActualizarTabla from '../../../BotonActualizarTabla'

export default {
    props: {
        tab: String,
        TituloTabla: String,
        ArrayInventarioMaterialOInsumo: Array,
        loading: Boolean,
    },

    components: {
        BotonActualizarTabla,
    },    

    data() {
        return {
            buscarRegistro:'',
            EncabezadoTablaInventarioSucursal:[
                {text: 'Nombre', value: 'materiaPrima.catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'materiaPrima.marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Cantidad', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad de Medida', value: 'materiaPrima.unidadDeMedida.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'materiaPrima.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega origen', value: 'materiaPrima.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                
                {text: 'Fecha Ultima Transferencia', value: 'fecha_Creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                // {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],         
        }
    },

    created() {
    },
    methods: {
        ObtenerInventarioMaterialOInsumoPorProyecto(){
            this.$emit('ObtenerInventarioMaterialOInsumoPorProyecto')
        },
    },
}
</script>