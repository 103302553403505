<template>
    <div>
        <!-- <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>             -->
        
        <v-toolbar title elevation="1">
            <v-toolbar-title class="tituloMaquinaria">{{TituloTabla}}</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerInventarioMaquinariaPorProyecto" />            
        </v-toolbar>        
        <v-data-table
            :headers="EncabezadoTablaInventarioSucursal"
            :items="ArrayInventarioMaquinaria"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."                                
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom color="#0019d8" >
                    <template v-slot:activator="{ on }">
                        <v-btn small color="#0019d8" dark fab v-on="on" @click="AbrirDialogoRegistroDeIncidentes(item)" v-if="EstadoPermisoCrear">
                            <v-icon>fas fa-car-crash</v-icon>
                        </v-btn>
                    </template>
                    <span>Registrar Incidente</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data v-if="ArrayInventarioMaquinaria.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    No existen maquinarias registradas.
                </v-alert>
            </template>                                                             
        </v-data-table>
        <RegistrarIncidenteDeMaquinaria
            :DialogoRegistroDeIncidentes="DialogoRegistroDeIncidentes" @CerrarDialogo="CerrarDialogoRegistroDeIncidentes"
            :Titulo="TituloMaquinaria" :DataMaquinaria="DataMaquinaria"
        />
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../../services/sweetAlert'
import RegistrarIncidenteDeMaquinaria from '../../../../components/Mantenimiento/Maquinarias/RegistrarIncidenteDeMaquinaria'
import BotonActualizarTabla from '../../../BotonActualizarTabla'

export default {
    props: {
        tab: String,
        TituloTabla: String,
        ArrayInventarioMaquinaria: Array,
        loading: Boolean,
        EstadoPermisoCrear: Boolean,
    },

    components: {
        RegistrarIncidenteDeMaquinaria,
        BotonActualizarTabla,
    },    

    data() {
        return {
            buscarRegistro:'',
            EncabezadoTablaInventarioSucursal:[
                {text: 'Código', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo', value: 'tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Serie', value: 'serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Combustible', value: 'tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega origen', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Transferencia', value: 'mov_ProyectoMaquinaria.fechaAsignacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],         
            DialogoRegistroDeIncidentes: false,
            DataMaquinaria:{
                idMaquinaria:'',
                tipo: '',
                marca:'',
                placa:'',
                propietario:''
            },
            TituloMaquinaria:'',
        }
    },

    created() {
    },
    methods: {
            ObtenerInventarioMaquinariaPorProyecto(){
                this.$emit('ObtenerInventarioMaquinariaPorProyecto')
            },

            AbrirDialogoRegistroDeIncidentes(item){
                // if(this.permisos.crear) this.EstadoPermisoEditar = true
                this.TituloMaquinaria = 'Maquinaria'
                this.EsNuevo = true
                this.DataMaquinaria.idMaquinaria = item.idMaquinaria
                this.DataMaquinaria.tipo = item.tipoMaquinaria.nombre
                this.DataMaquinaria.marca = item.vehiculoMarca.nombre
                this.DataMaquinaria.placa = item.placa
                this.DataMaquinaria.propietario = item.propietario
                this.DialogoRegistroDeIncidentes = true
            },

            CerrarDialogoRegistroDeIncidentes(){
                this.DialogoRegistroDeIncidentes = false
            },        
    },
}
</script>