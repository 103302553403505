import Vue from 'vue'

const store = {
    actions: {
        //add here all acctions api
    //obtenemos todos los menus del usuario para mostrarlo en la tabla tipo body
        getAllMenus : async ({}, userId ) => {
            try{
                const response = await Vue.axios.get('/Menu/catalogos/menus/asignados?userId='+userId)
                return response
            }catch(e){
                return e.response
                // return console.log('ErrorMenu', e)
            }
        },

    // obtenemos todos los submenus del usuario para mostrarlo en la tabla tipo path
        getAllSubMenus : async ({}, data ) => {
            try{
                // console.log('data', data)
                const response = await Vue.axios.get('/Menu/submenus/asignados/'+data.idMenu+'/user/'+data.idUser)
                return response
            }catch(e){
                return e.response
                // return console.log('ErrorMenu', e)
            }
        },
    // obtenemos todos los submenus del usuario para mostrarlo en la tabla para asignar tipo path
        getAllSubMenusParaAsignar : async ({}, data ) => {
            try{
                // console.log('data', data)
                const response = await Vue.axios.get('/Menu/submenus/porAsignar/'+data.idMenu+'/user/'+data.idUser)
                return response
            }catch(e){
                return e.response
                // return console.log('ErrorMenu', e)
            }
        },

    // obtenemos los menus que el usuario tiene por asignar tipo query
        getMenusCatalogs : async ({}, userId ) => {
            try{
                const response = await Vue.axios.get('/Menu/catalogos/menus/porAsignar?userId='+userId)
                return response
            }catch(e){
                return e.response
                // return console.log('ErrorMenu', e)
            }
        },
        // se obtiene los submenus que el usuario tiene por asignar tipo path
            getSubMenusCatalogsByidModule : async ({}, id  ) => {
                try{
                    const response = await Vue.axios.get('/Menu/catalogos/menu/byId/'+id)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('ErrorSubmenus', e)
                }
            },
        // obtenemos el menu seleccionado para guardarlo tipo path
            getSelectedSubMenu : async ({}, id  ) => {
                try{
                    const response = await Vue.axios.get('/Menu/catalogos/subMenu/byId/'+id)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('ErrorSubmenus', e)
                }
            },
            
        // guardamos el menu tipo body
            NewMenu : async ({},data) => {
                try{
                    // console.log('GuardarMenudata', data)
                    const response = await Vue.axios.post('/Menu',data)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('ErrorGuardarMenu', e)
                }
            },
            // eliminamos un menu tipo query
            DeleteMenu : async ({},id) => {
                try{
                    const response = await Vue.axios.delete('/Menu/deleteMenu?id='+id)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('Error al borrar un menu', e)
                }
            },
            
        // guardamos el submenu tipo body
            addSubMenuToMenu : async ({},data) => {
                // console.log('addSubMenuToMenu', data)
                try{
                    // console.log('addSubMenuToMenuAdentro', data)
                    const response = await Vue.axios.post('/Menu/addSubMenuToMenu',data)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('ErrorGuardarSubMenu', e)
                }
            },
        // Eliminamos el submenu tipo query
            DeleteSubMenuAsignado : async ({},id) => {
                try{
                    const response = await Vue.axios.delete('/Menu/deleteSubmenu?id='+id)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('ErrorEliminarSubMenu', e)
                }
            },
        //actualizamos la informacion del submenu asignado al menu al usuario tipo body
            UpdateMenu : async ({},data) => {
                try{
                    const response = await Vue.axios.put('/Menu',data)
                    return response
                }catch(e){
                    return e.response
                    // return console.log('ErrorUpdateSubMenu', e)
                }
            },
        //obtiene el menu del usuario
            GetMyMenu : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Menu')
                    return response
                }catch(e){
                    return e.response
                }
            },
        //obtiene los permisos del submenu del usuario
            PermisosSubMenu : async ({}, url) => {
                try{
                    const response = await Vue.axios.get('/Menu/permisos/'+url)
                    return response
                }catch(e){
                    return e.response
                }
            }
            
    }
}

export default store