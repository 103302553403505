var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[(_vm.alertError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" No fue posible la comunicación con "),_c('strong',[_vm._v("API SERVICE")]),_vm._v(" ponganse en contacto con el administrador si este mensaje persiste. ")]):_vm._e(),_c('v-toolbar',{attrs:{"title":"","elevation":"1"}},[_c('v-toolbar-title',{staticClass:"tituloMaquinaria"},[_vm._v("Marcas Materia Prima")]),_c('v-divider',{staticClass:"ml-3",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:" pr-4",attrs:{"outlined":"","label":"Buscar Marca","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.buscarMarca),callback:function ($$v) {_vm.buscarMarca=$$v},expression:"buscarMarca"}}),(_vm.permisos.crear)?_c('v-tooltip',{attrs:{"bottom":"","color":"#0019d8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#0019d8","dark":"","rounded":""},on:{"click":function($event){return _vm.AbrirModalRegistrarMarca()}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-save")]),_vm._v(" Crear ")],1)]}}],null,false,2803592126)},[_c('span',[_vm._v("Nueva Marca")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.EncabezadoTablaMarcas,"items":_vm.ArrayMarcasMateriaPrima,"search":_vm.buscarMarca,"footer-props":{itemsPerPageText: 'Filas por página'},"loading":_vm.loading,"loading-text":"Cargando Datos..."},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"#d69b01"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","dark":"","fab":"","color":"#d69b01"},on:{"click":function($event){return _vm.AbrirModalEditarMarca(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v("Editar Marca")])]),(_vm.permisos.eliminar)?_c('v-tooltip',{attrs:{"left":"","color":"#D80000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","dark":"","fab":"","color":"#D80000"},on:{"click":function($event){return _vm.AbrirModalEliminarMarca(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash-alt")])],1)]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v("Eliminar Marca")])]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"text-xs-center mx-10 mt-2 titulo",attrs:{"type":"warning","prominent":"","dense":"","outlined":"","border":"left"}},[_vm._v(" Marca no existe ")])]},proxy:true},(_vm.ArrayMarcasMateriaPrima.length === 0)?{key:"no-data",fn:function(){return [(!_vm.alertError)?_c('v-alert',{staticClass:"text-xs-center textoalerta",attrs:{"dense":"","outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" No existen marcas registradas ")]):_vm._e(),(_vm.alertError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" No fue posible la comunicación con "),_c('strong',[_vm._v("API SERVICE")]),_vm._v(" ponganse en contacto con el administrador si este mensaje persiste. ")]):_vm._e()]},proxy:true}:null],null,true)})],1),_c('v-overlay',{attrs:{"value":_vm.EstadoCircularProgress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":80,"width":10,"color":"#ffffff"}})],1),_c('RegistrarMarca',{attrs:{"AbrirDialogoRegistrarMarca":_vm.AbrirDialogoRegistrarMarca,"esNuevo":_vm.esNuevo,"TituloDialogo":_vm.TituloDialogo,"DatosEditarMarca":_vm.DatosEditarMarca,"EstadoPermisoEditar":_vm.EstadoPermisoEditar},on:{"CerrarModalRegistrarMarca":_vm.CerrarModalRegistrarMarca,"GetAllRawMaterial":_vm.GetAllRawMaterial}}),_c('MensajeEliminar',{attrs:{"DialogoMensajeEliminar":_vm.DialogoMensajeEliminar,"tituloMensajeEliminar":_vm.tituloMensajeEliminar,"loadingEliminarHerramientaMateriaPrima":_vm.loadingEliminarMarca,"alertError":_vm.alertError},on:{"CerrarDialogoMensajeEliminar":_vm.CerrarDialogoMensajeEliminar,"Eliminar":_vm.DeleteMarca}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }