import Vue from 'vue'

const store = {
    actions: {
        //add here all acctions api
        //obtenemos todos los vehiculos para mostrarlo en la tabla
        ObtenerTodosLosVehiculos: async({}, datos) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/byEstado/' + datos.idEstado + '/bodega/' + datos.bodega + '/sucursal/' + datos.sucursal)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener vehiuclo', e)
            }
        },
        //creamos un nuevo vehiculo
        GuardarVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Vehiculos', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al Crear Vehículo', e)
            }
        },
        //actualizamos un vehiculo
        ActualizarVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Vehiculos', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al Actualizar Vehículo', e)
            }
        },
        //obtenemos todos los tipos de vehiculos para mostrarlo en el select
        ObtenerTipoVehiculso: async({}) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/tipo')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener vehiculo', e)
            }
        },
        //obtenemos el vehiculo por su id
        ObtenerVehiculoById: async({}, idVehiculo) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/byId/' + idVehiculo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener vehiculo', e)
            }
        },
        //eliminamos el vehiculo por su id
        EliminarVehiculo: async({}, idVehiculo) => {
            try {
                const response = await Vue.axios.delete('/Vehiculos/' + idVehiculo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar el vehiculo', e)
            }
        },
        //obtenemos todos los incidentes
        TodosIncidentes: async({}) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/tipoIncidentes')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al obtener todos los incidentes para un vehiculo', e)
            }
        },
        //Registramos un incidente
        RegistrarIncidente: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Vehiculos/incidente', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar un incidente', e)
            }
        },
        //obtenemos los incidentes de un vehiculo
        ObtenerIncidentesVehiculo: async({}, idVehiculo) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/incidentes/' + idVehiculo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al obtener los incidentes de un vehiculo', e)
            }
        },
        //generamos PDF
        generarPDF: async({}, idIncidente) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/pdf/incidente/' + idIncidente)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al generar pdf', e)
            }
        },
        //generamos PDF de vehiculo general
        GenerarPDFIncidentesVehiculosGeneral: async({}, idVehiculo) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/pdf/incidentes/' + idVehiculo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al generar pdf de incidentes de vehiculos general', e)
            }
        },
        //Actualizar incidente de un vehiculo
        ActualizarIncidenteVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Vehiculos/incidente', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al actualizar incidente de vehiculo', e)
            }
        },
        //Actualizar insumo de incidente de un vehiculo
        ActualizarInsumoDeIncidenteVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Vehiculos/incidente/insumo', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al actualizar insumo de incidente de un vehiculo', e)
            }
        },
        //Eliminar insumo de incidente de un vehiculo
        EliminarInsumoDeIncidenteVehiculo: async({}, idInsumo) => {
            try {
                const response = await Vue.axios.delete('/Vehiculos/incidente/insumo/' + idInsumo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar insumo de incidente de un vehiculo', e)
            }
        },
        //Eliminar incidente de un vehiculo
        EliminarIncidenteVehiculo: async({}, idIncidente) => {
            try {
                const response = await Vue.axios.delete('/Vehiculos/incidente/' + idIncidente)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar incidente de un vehículo', e)
            }
        },
        //Registrar insumo de incidente
        RegistrarInsumoIncidenteVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Vehiculos/incidente/insumo', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //Cambiar sucursal y bodega de un vehiculo
        CambiarSucursalyBodegaDeVehiculos: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Vehiculos/movSucursal', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //Cambiar sucursal de un vehiculo
        CambiarSucursalDelVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Vehiculos/movProyecto', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //obtener todos los movimientos de las sucursales de un vehiculo
        MovimientoSucursalesPorVehiculo: async({}, idVehiculo) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/movsSucursales/' + idVehiculo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //retornamos un vehiculo a la sucursal central
        RetornarVehiculoSucursalCentralByVehiculo: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Vehiculos/movToOrigen', data)
                return response
            } catch (e) {
                return e.response
            }
        },
        //obtener todos los movimientos de las sucursales de un vehiculo
        TimeLineMovimientosPorVehiculos: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/movsProyecto/' + data.idVehiculo + '/fechaInicio/' + data.fechaInicio + '/fechaFin/' + data.fechaFin)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //obtiene todas las maquinarias y la información se comportara dependiendo del estado del puedeSolicitar
        TodosVehiculosSolicitud: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Vehiculos/forSolicitud/proyecto/' + data.proyecto + '/sucursal/' + data.sucursal)
                    // const response = await Vue.axios.get('/Maquinaria/forSolicitud/'+data.bodega+'/proyecto/' + data.proyecto + '/sucursal/' + data.sucursal)
                return response
            } catch (e) {
                return e.response
            }
        },
    }
}

export default store