<template>
    <div>
        <v-dialog
            v-model="ModalMovimientoDeCompra"
            scrollable 
            persistent :overlay="false"
            max-width="700px"
            transition="dialog-transition"
        >
            <v-card>
                <!-- barra principal del dialogo -->
                <v-app-bar color="#3c8dbc" flat dark>
                    <v-toolbar-title class="titulo">Realizar Movimiento</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- boton registrar movimiento de compra o salida -->
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" color="success" rounded dark class="mr-4" @click="GuardarRegistro()"
                                :loading="loading" :disabled="loading" 
                            >
                                <v-icon left>fas fa-save</v-icon> Guardar
                            </v-btn>                
                        </template>
                        <span>Movimiento</span>
                    </v-tooltip>
                    <v-btn large color="error" rounded dark @click="CerrarDialogoMovimientoCompra()">
                        <v-icon left>fas fa-window-close</v-icon> Cancelar
                    </v-btn>                
                </v-app-bar>
                <v-card-text>
                    <!-- <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>      -->
                    <v-alert :value="alertErrorPost"
                            type="error"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-container fluid>
                        <span>Seleccione tipo de movimiento para Material o Insumo</span>
                        <hr>
                        <v-radio-group v-model="ObtenerTipoMovimiento" row>
                            <v-radio label="Compra o Entrada" value= "1" ></v-radio>
                            <v-radio label="Salida" value= "2" ></v-radio>
                        </v-radio-group>
                        <hr>
                    </v-container>                    
                    <v-form v-model="valid" ref="form" lazy-validation autocomplete="off">
                        <v-row wrap>
                            <!-- factura                         -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" v-if="ObtenerTipoMovimiento==='1'">
                                <v-text-field outlined :disabled="!EstadoPermisoEditar" 
                                    label="Factura"
                                    v-model="modeloCrearMovimientoCompraSalida.factura"                                
                                ></v-text-field>
                            </v-col>                
                            <!-- cantidad                         -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >
                                <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                    label="Cantidad" type="number"
                                    v-model="modeloCrearMovimientoCompraSalida.cantidad"                                
                                ></v-text-field>
                            </v-col>                
                            <!-- precio Unitario                         -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" v-if="ObtenerTipoMovimiento==='1'">
                                <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                    label="Precio Unitario" type="number"
                                    v-model="modeloCrearMovimientoCompraSalida.precioUnitario"                                
                                ></v-text-field>
                            </v-col>                
                            <!-- pproveedor O Tienda                         -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" v-if="ObtenerTipoMovimiento==='1'">
                                <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                    label="Proveedor o Tienda"
                                    v-model="modeloCrearMovimientoCompraSalida.proveedorOTienda"                                
                                ></v-text-field>
                            </v-col>                
                            <!-- descripcion -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 mt-0 pb-0 mb-0">
                                <v-textarea :disabled="!EstadoPermisoEditar"
                                    outlined
                                    label="Descripción"
                                    v-model="modeloCrearMovimientoCompraSalida.descripcion"
                                >
                                </v-textarea>
                            </v-col>                        
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>            
        </v-dialog>
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'

export default {
    props:{
        ModalMovimientoDeCompra: Boolean,
        TituloDialogo: String,
        DatosMaterialInsumo: Object,
        EstadoPermisoEditar: Boolean,
    },

    data() {
        return {
            valid: false,
            lazy: false,    
            loading:false,              
            modeloCrearMovimientoCompraSalida:{
                idMateriaPrima:0,
                tipoMovimientoInventarioId:0,
                factura:'',
                cantidad:0,
                precioUnitario:0,
                proveedorOTienda:'',
                descripcion:'',
            },
            ObtenerTipoMovimiento:'',
            // errores registros duplicados y vacíos
            alertErrorPost:false,
            arrayErrorPost:[],            
                        
        }
    },

    watch: {
        ModalMovimientoDeCompra(){
            this.ObtenerTipoMovimiento = "1"
            this.modeloCrearMovimientoCompraSalida.idMateriaPrima = this.DatosMaterialInsumo.idMateriaPrima    
            this.modeloCrearMovimientoCompraSalida.tipoMovimientoInventarioId = this.ObtenerTipoMovimiento
        },

        ObtenerTipoMovimiento(){
            // this.$refs.form.reset()
            this.alertErrorPost = false
            this.modeloCrearMovimientoCompraSalida.tipoMovimientoInventarioId = this.ObtenerTipoMovimiento
        }
    },

    methods: {
        CerrarDialogoMovimientoCompra(){
            this.alertErrorPost = false
            this.$refs.form.reset()
            this.$emit('CerrarDialogoMovimientoCompra')
        },

        async GuardarRegistro(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            if(this.ObtenerTipoMovimiento === '1'){
                const response = await this.$store.dispatch('GuardarMovimientoCompraMateriaInsumo', this.modeloCrearMovimientoCompraSalida)
                if(response.status === 200){
                    this.loading = false
                    this.$emit('ActualizarTabla')
                    showMessageSuccess('¡BIEN!', 'Movimiento de Compra registrada correctamente')
                    this.CerrarDialogoMovimientoCompra()
                }else{
                    this.ControlladorExcepciones(response)
                }
            }else{
                const response = await this.$store.dispatch('GuardarMovimientoSalidaMateriaInsumo', this.modeloCrearMovimientoCompraSalida)
                if(response.status === 200){
                    this.loading = false
                    this.$emit('ActualizarTabla')
                    this.alertErrorPost = false
                    showMessageSuccess('¡BIEN!', 'Movimiento de Salida registrada correctamente')
                    this.CerrarDialogoMovimientoCompra()
                }else{
                    this.ControlladorExcepciones(response)
                }
            }
        },

        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.alertErrorPost = false
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false                        
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false       
                this.alertErrorPost = false               
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.alertErrorPost = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.alertErrorPost = false
            }                
        }        
    },
}
</script>