<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent :overlay="false" fullscreen transition="dialog-transition" scrollable>
      <v-card>
          <v-app-bar flat dark color="#3c8dbc">
            <v-icon x-large class="mx-2" >fas fa-users-cog</v-icon>
            <v-toolbar-title>{{titulo}} colaborador</v-toolbar-title>
            <v-spacer></v-spacer>

              <v-btn v-if="EstadoPermisoEditar" class="mx-2" color="success" rounded large
                  v-on:click="guardarUsuario()" :loading="loading" :disabled="loading"
              >Guardar
                  <v-icon right dark>fas fa-save</v-icon>
              </v-btn>
              <v-btn class="mx-2" color="error"  @click="cancelar()" rounded large>Cancelar
                  <v-icon right dark>far fa-window-close</v-icon>
              </v-btn>
            
          </v-app-bar>
          <v-card>
              <v-alert :value="alertErrorPost"
                      type="error"
                      outlined
                      transition="scale-transition"
                      color="deep-orange">
                      <h4>Errores:</h4>
                      <ul>
                          <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                              <h6>{{i.description}}</h6>
                          </li>
                      </ul>
              </v-alert>
              <v-card-text>
              <v-alert dense outlined type="error" class="mt-2"  v-if="AlertErrorAPI">
                  No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
              </v-alert>                                                
              <v-form >
                    <v-row wrap >
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.nombre" prepend-inner-icon="fas fa-user-tie" label="Nombre"/>
                      </v-col>
                      <!-- fecha de nacimiento nuevo -->
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="isNew">
                          <v-menu
                              v-model="menuFechaNacimiento"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field outlined 
                                      v-model="modeloUsuario.fechaNacimiento"
                                      label="Fecha Nacimiento"
                                      prepend-inner-icon="far fa-calendar-alt"
                                      readonly                                
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                              </template>
                          <v-date-picker locale="es-GT" v-model="modeloUsuario.fechaNacimiento" @input="menuFechaNacimiento = false"></v-date-picker>
                          </v-menu>
                      </v-col>          
                      <!-- fecha de nacimiento editar -->
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="!isNew">
                          <v-menu
                              v-model="menuFechaNacimientoEditar"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field outlined :disabled="!EstadoPermisoEditar" 
                                      v-model="fechaNacimientoEditar"
                                      label="Fecha Nacimiento"
                                      prepend-inner-icon="far fa-calendar-alt"
                                      readonly                                
                                      v-bind="attrs"
                                      v-on="on"
                                        :hint="'Fecha Ingreso Actual = '+modeloUsuario.fechaNacimiento"
                                        persistent-hint                                      
                                  ></v-text-field>
                              </template>
                          <v-date-picker locale="es-GT" v-model="fechaNacimientoEditar" @input="menuFechaNacimientoEditar = false"></v-date-picker>
                          </v-menu>
                      </v-col>                
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <div class="text-center mb-2 mt-2">
                          <v-chip> 
                            <v-avatar left>
                              <v-icon>fas fa-user-tie</v-icon>
                            </v-avatar>
                            Edad: {{eddadUsuario}} años</v-chip>                                        
                        </div>                          
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.direccion" prepend-inner-icon="fas fa-street-view" label="Dirección" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                          <v-select outlined :disabled="!EstadoPermisoEditar" 
                              :items="EstadoCivil"
                              item-value="nombre"
                              item-text="nombre"
                              v-model="modeloUsuario.estadoCivil"
                              label="Estado Civil"
                              prepend-inner-icon="fas fa-user-tie"                              
                          ></v-select>                            
                      </v-col>                                          
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.noDependientes" prepend-inner-icon="fas fa-users" label="No. Dependientes" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field type="number" :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.noCuentaBancaria" prepend-inner-icon="fas fa-money-bill-alt" label="No. Cuenta Bancaria" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <!-- <v-select :disabled="!EstadoPermisoEditar" :items="itemsPuestos" v-model="modeloUsuario.puesto" item-value="name" prepend-inner-icon="fas fa-user-tag"  item-text="name" label="Puesto" outlined ></v-select> -->
                        <v-combobox :disabled="!EstadoPermisoEditar" :items="itemsPuestos" item-text="name" item-value="name"  v-model="puestoUsuario" prepend-inner-icon="fas fa-user-tag" label="Puesto" outlined ></v-combobox>
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-4">
                        <v-text-field :disabled="!EstadoPermisoEditar" type="number" outlined v-model="modeloUsuario.salario" prepend-inner-icon="fas fa-money-bill-alt" label="Salario" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-4" >
                        <v-select :disabled="!EstadoPermisoEditar" :items="itemsSucursales" v-model="modeloUsuario.sucursal" item-value="nombre" prepend-inner-icon="far fa-building"  item-text="nombre" label="Sucursal" outlined ></v-select>
                      </v-col>
                      <!-- fecha ingreso crear -->
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-4" v-if="isNew">
                        <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :disabled="!EstadoPermisoEditar" outlined v-model="computedDateFormatted" label="Fecha de ingreso" prepend-inner-icon="far fa-calendar-alt" readonly v-bind="attrs"
                              v-on="on" 
                            ></v-text-field>
                          </template>
                          <v-date-picker locale="es-GT" v-model="date" no-title @input="menu2 = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- fecha ingreso editar -->
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-4" v-if="!isNew">
                            <v-menu
                                v-model="menufechaIngresoEditar"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                        v-model="fechaIngresoEditar" 
                                        label="Fecha de Ingreso"
                                        prepend-inner-icon="far fa-calendar-alt"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :hint="'Fecha Ingreso Actual = '+modeloUsuario.fechaIngreso"
                                        persistent-hint
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="es-GT" v-model="fechaIngresoEditar" @input="menufechaIngresoEditar = false"></v-date-picker>
                            </v-menu>
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-4">
                        <v-text-field :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.certificadoMedico" prepend-inner-icon="fas fa-file-medical" label="Afiliación IGSS" />
                      </v-col>

                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field type="number" :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.dpi" prepend-inner-icon="far fa-id-card" label="DPI" />
                      </v-col>
                      <v-col cols="12" lg="2" md="2" sm="6" class="pt-0 pb-0">
                        <v-text-field type="number" :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.phoneNumber" prepend-inner-icon="fas fa-mobile-alt" label="Teléfono" />
                      </v-col>
                      <v-col cols="12" lg="2" md="2" sm="6" class="pt-0 pb-0">
                        <v-text-field type="number" :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.contactoDeEmergencia" prepend-inner-icon="fas fa-hospital" label="Teléfono de emergencia" />
                      </v-col>
                      <v-col cols="12" lg="5" md="5" sm="6" class="pt-0 pb-0">
                        <v-text-field :disabled="!EstadoPermisoEditar" outlined v-model="modeloUsuario.nombreContactoEmergencia" prepend-inner-icon="fas fa-user-tag" label="Nombre de contacto de emergencia" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="!EstadoEditar">
                        <v-menu v-model="menuFechaEgreso" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :disabled="!BajaEmpleado" outlined v-model="computedDateFormattedFechaEgreso" label="Fecha de Egreso" prepend-inner-icon="far fa-calendar-alt" readonly v-bind="attrs"
                              v-on="on" 
                            ></v-text-field>
                          </template>
                          <v-date-picker locale="es-GT" v-model="dateFechaEgreso" no-title @input="menuFechaEgreso = false"></v-date-picker>
                        </v-menu>
                      </v-col>                      
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="!EstadoEditar && EstadoBajaEmpleado">
                        <v-switch :disabled="!EstadoPermisoEditar"
                          v-model="BajaEmpleado"
                          label="Dar de Baja"
                          color="info"
                        ></v-switch>
                      </v-col>                      
                    </v-row>
                    <v-subheader wrap>Licencias del Colaborador</v-subheader>
                    <v-divider></v-divider>
                    <v-row wrap class="mt-3">
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-select v-if="!EstadoLicencia" 
                            prepend-inner-icon="far fa-id-badge" 
                            :items="itemsLicencias" 
                            item-value="tipo"  
                            item-text="tipo" 
                            v-model="modeloUsuario.licencia" 
                            label="Licencia" 
                            outlined 
                        ></v-select>                        
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="!EstadoLicencia">
                        <v-menu v-model="menuFechaVencimientoLicencia" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field  outlined v-model="computedDateFormattedFechaVencimientoLicencia" label="Fecha de Vencimiento Licencia" prepend-inner-icon="far fa-calendar-alt" readonly v-bind="attrs"
                              v-on="on" @blur="dateFechaVencimientoLicencia = parseDateFechaVencimientoLicencia(dateFormattedFechaVencimientoLicencia)" 
                            ></v-text-field>
                          </template>
                          <v-date-picker locale="es-GT" v-model="dateFechaVencimientoLicencia" no-title @input="menuFechaVencimientoLicencia = false"></v-date-picker>
                        </v-menu>
                      </v-col>                      
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                          <v-btn large class="mt-1" color="indigo" dark @click="GuardarLicenciasSeleccionadas()" v-if="!EstadoLicencia">Agregar Licencia
                              <v-icon right dark small>fas fa-save</v-icon>
                          </v-btn>                                                  
                      </v-col>                      
                      <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">    
                        <v-container fluid>
                          <v-toolbar color="primary" dense flat dark>
                            <v-toolbar-title class="tituloTabla">Licencias del colaborador</v-toolbar-title>
                          </v-toolbar>
                          <v-data-table
                            :headers="EncabezadoLicencias"
                            :items="ArrayLicenciasSeleccionadas"
                            hide-default-footer
                          >
                            <template v-slot:item.action="{ item }">
                              <v-tooltip v-if="!EstadoLicencia" bottom color="#D80000">
                                  <template v-slot:activator="{ on }">
                                      <v-btn v-if="!EstadoLicencia" small class="ml-2" dark fab color="#D80000" v-on="on" @click="QuitarLicencia(item)">
                                          <v-icon small>fas fa-trash-alt</v-icon>
                                      </v-btn>                        
                                  </template>
                                  <span class="fontButton">Quitar Licencia</span>
                              </v-tooltip>                                              
                            </template>
                            <template v-slot:no-data v-if="ArrayLicenciasSeleccionadas.length === 0">
                                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                                >
                                  {{MensajeTabla}}
                                </v-alert>
                            </template>
                          </v-data-table>                          
                        </v-container>               
                      </v-col>
                    </v-row>                    
                    <v-subheader wrap>Información de usuario</v-subheader>
                    <v-divider></v-divider>
                    <v-row wrap >
                      <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined v-model="modeloUsuario.email" :disabled="!isNew" 
                        hint="Utilizado para iniciar sesion"
                        persistent-hint
                        prepend-inner-icon="fas fa-envelope" label="Correo electrónico" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined v-model="modeloUsuario.alias"  :disabled="!isNew" 
                        hint="Utilizado para iniciar sesion"
                        persistent-hint
                        prepend-inner-icon="fas fa-user-friends" label="Alias" />
                      </v-col>
                      <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined v-model="modeloUsuario.password" 
                        hint="Contraseña por defecto, el usuario podra cambiarla al iniciar sesion"
                        persistent-hint
                        v-if="isNew"
                        prepend-inner-icon="fas fa-key" label="Contraseña" />
                      </v-col>
                      <v-col cols="12" lg="2" md="2" sm="6" class="pt-0 pb-0">
                        <v-switch color="success" v-model="modeloUsuario.emailConfirmed" :disabled="!EstadoPermisoEditar">
                          <template v-slot:label>
                            Usuario activo
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                  
                </v-form>
                <small>*Indica Campos Requeridos</small>
              </v-card-text>
          </v-card>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {showMessageError,showMessageSuccess} from '../../services/sweetAlert';
import { throws } from 'assert';
import Vue from 'vue'
import store from '@/store'

  export default {
    data: vm => ({     
      calcularNuevaFechaNacimiento:'',
      // alerta cuando el api deja de funcionar
      AlertErrorAPI:false,                          
      loading: false,
      // Fecha ingreso, editar
      menufechaIngresoEditar: false,
      fechaIngresoEditar: new Date().toISOString().substr(0, 10),
      // Fecha nacimiento, editar
      menuFechaNacimientoEditar: false,
      fechaNacimientoEditar: new Date().toISOString().substr(0, 10),

      BajaEmpleado: false,
      EstadoBajaEmpleado: false,
      arrayErrorPost:[],
      alertErrorPost:false,
      isNew:false,
      menu2: false,
      menuFechaVencimientoLicencia: false,

      menuFechaEgreso: false,
      dateFechaEgreso: new Date().toISOString().substr(0, 10),

      date: new Date().toISOString().substr(0, 10),
      dateFechaVencimientoLicencia: new Date().toISOString().substr(0, 10),      

      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateFormattedFechaVencimientoLicencia: vm.formatDate2(new Date().toISOString().substr(0, 10)),
      dateFormattedFechaEgreso: vm.formatDate3(new Date().toISOString().substr(0, 10)),
    
      //dialog: false,
      picker2: new Date().toISOString().substr(0, 10),
      menuFechaNacimiento: false,
      EstadoCivil: [
        {nombre: 'Soltero(a)'},
        {nombre: 'Comprometido(a)'},
        {nombre: 'Casado(a)'},
        {nombre: 'Separado(a)'},
        {nombre: 'Divorciado(a)'},
        {nombre: 'Viudo(a)'},
      ],
      modeloUsuario: {
        email: '',
        nombre:'',
        fechaNacimiento: new Date().toISOString().substr(0, 10),
        estadoCivil: '',
        noDependientes:0,
        noCuentaBancaria:'',
        password: 'Remon2020@',
        confirmPassword: '',
        alias: '',
        phoneNumber: '',
        direccion: '',
        puesto:'',
        dpi: '',
        licencia: '',
        licencias:[
        ],
        certificadoMedico: '',
        salario: '',
        sucursal: '',
        contactoDeEmergencia: '',
        nombreContactoEmergencia: '',
        fechaIngreso: '',
        fechaEgreso: '',
        isAdmin: true,
        idUser:'',
        emailConfirmed:true,
        estaActivo: true,
      },
      puestoUsuario: '',
      eddadUsuario:0,
      itemsSucursales: [],
      itemsLicencias:[],
      EncabezadoLicencias: [
        {text: 'Tipo Licencia', value: 'tipo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
        {text: 'Fecha Vencimiento', value: 'fechaVencimiento', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
        {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
      ],
      ObjetoLicenciaSeleccionada:{
        'tipo': '',
        'fechaVencimiento':'',   
      },
      ArrayLicenciasSeleccionadas:[],
      itemsPuestos:[],
      switchMe: false,
      // variables para fechas editar
      validarfechaIngresoobtenida: '',
      validarfechaIngreso:  new Date().toISOString().substr(0, 10),
      
    }),
    props:{
      dialog:Boolean,
      idColaborador:String,
      titulo:String,
      MensajeTabla: String,
      EstadoLicencia: Boolean,
      EstadoEditar: Boolean,
      EstadoPermisoEditar: Boolean,      
    },
    watch:{ 
      menuFechaNacimiento(){
        if(this.menuFechaNacimiento === false){
          this.CalcularEdadUsuario(this.modeloUsuario.fechaNacimiento) 
        }
      },
      menuFechaNacimientoEditar(){
        if(this.menuFechaNacimientoEditar === false){
          // alert
          this.CalcularEdadUsuario(this.fechaNacimientoEditar) 
        }
      },
      dialog(val) {
        if(val){        
          this.getlAllTipoLicencias()
          this.getAllProyectos()
          this.getAllroles()
          this.EstadoBajaEmpleado = true
          if(this.idColaborador != '0'){
            this.isNew=false
            this.getUuserById(this.idColaborador)
          }else{
            this.isNew=true
            this.modeloUsuario.fechaIngreso=this.date
            this.modeloUsuario.fechaNacimiento = new Date().toISOString().substr(0, 10)
          }
        }
      },
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
      dateFechaVencimientoLicencia (val) {
        this.dateFormattedFechaVencimientoLicencia = this.formatDate2(this.dateFechaVencimientoLicencia)
      },

      dateFechaEgreso (val){
        this.dateFormattedFechaEgreso = this.formatDate3(this.dateFechaEgreso)
      }
    },
    computed: {

      computedDateFormatted () {
        //return this.formatDate(this.date)
        return this.modeloUsuario.fechaIngreso=this.date
      },
      computedDateFormattedFechaVencimientoLicencia () {
        //return this.formatDate(this.date)
        return this.ObjetoLicenciaSeleccionada.fechaVencimiento = this.dateFechaVencimientoLicencia
      },
      computedDateFormattedFechaEgreso(){
        return this.modeloUsuario.fechaEgreso = this.dateFechaEgreso
      },
    },
    created()  {     
      },
    mounted() {
      
    },
    methods: {
       splitDate (date) {
         var arr1 = date.split("/");
         var finalDate = arr1[2] + "-" + arr1[1] + "-" + arr1[0];
         return finalDate;
      },      
       splitDateCambiarFormatoFechaCalcularEdad (date) {
         var arr1 = date.split("/");
         var finalDate = arr1[2] + "/" + arr1[1] + "/" + arr1[0];
         return finalDate;
      },      
      async CalcularEdadUsuario(fechaNacimiento){
          var cambiarFormatoFechaNacimiento = ''
          this.fechaNacimientoEditar = this.splitDate(this.modeloUsuario.fechaNacimiento)
          if(this.isNew){
            cambiarFormatoFechaNacimiento = fechaNacimiento          
          }else{
            if(this.menuFechaNacimientoEditar === false){
              cambiarFormatoFechaNacimiento = fechaNacimiento          
            }else{
              cambiarFormatoFechaNacimiento = this.splitDateCambiarFormatoFechaCalcularEdad(fechaNacimiento)
            }
            // cambiarFormatoFechaNacimiento = this.splitDateCambiarFormatoFechaCalcularEdad(fechaNacimiento)
          }
          this.alertErrorPost=false;this.arrayErrorPost=[];
          const response = await this.$store.dispatch('CalcularEdad', cambiarFormatoFechaNacimiento)
          if(typeof response === 'undefined'){
              this.ControllerException()
              return 0
          }                                     
          if(response.status === 200){
            this.AlertErrorAPI= false
            this.eddadUsuario = response.data.edad
          }else if(response.status === 400){
              this.alertErrorPost=true
              var object = response.data.errors
              for (var property in object){
                  this.arrayErrorPost.push({description: `${object[property]}`})
              }
          }else if(response.status === 401){
              showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
          }else if(response.status === 404){
              showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
          }else if(response.status === 500){
              showMessageError('Error 500','Error en el servidor')            
          }
      },      
      // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
      ControllerException(){
          this.AlertErrorAPI=true
          this.loading=false
      },              

      async getAllroles(){
        const response = await this.$store.dispatch('getAllRoles')
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                                   
        if(response.status && response.status === 200){
          this.AlertErrorAPI= false
          this.itemsPuestos= response.data
        }else if(response.status === 400){
            showMessageError('Error 400','Petición mala')
        }else if(response.status === 401){
            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
        }else if(response.status === 404){
            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
        }else if(response.status === 500){
            showMessageError('Error 500','Error en el servidor')            
        }
      },
      async getlAllTipoLicencias(){
        const response = await this.$store.dispatch('getAllLicencias');
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                                   
        if(response.status && response.status === 200){
          this.AlertErrorAPI= false
          this.itemsLicencias=response.data
        }else if(response.status === 400){
            showMessageError('Error 400','Petición mala')
        }else if(response.status === 401){
            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
        }else if(response.status === 404){
            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
        }else if(response.status === 500){
            showMessageError('Error 500','Error en el servidor')            
        }
      },
      async getAllProyectos(){
        const response = await this.$store.dispatch('AllSucursales')
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                                   
        if(response.status && response.status === 200){
          this.AlertErrorAPI= false
          this.itemsSucursales = response.data
        }else if(response.status === 400){
            showMessageError('Error 400','Petición mala')
        }else if(response.status === 401){
            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
        }else if(response.status === 404){
            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
        }else if(response.status === 500){
            showMessageError('Error 500','Error en el servidor')            
        }
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      formatDate2 (dateFechaVencimientoLicencia) {
        if (!dateFechaVencimientoLicencia) return null

        const [year, month, day] = dateFechaVencimientoLicencia.split('-')
        return `${month}/${day}/${year}`
      },
      formatDate3 (dateFechaEgreso) {
        if (!dateFechaEgreso) return null

        const [year, month, day] = dateFechaEgreso.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      parseDateFechaVencimientoLicencia (dateFechaVencimientoLicencia) {
        if (!dateFechaVencimientoLicencia) return null

        const [month, day, year] = dateFechaVencimientoLicencia.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      parseDateFechaEgreso (dateFechaEgreso) {
        if (!dateFechaEgreso) return null

        const [month, day, year] = dateFechaEgreso.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      async getUuserById(id){
        const response = await this.$store.dispatch('getUserById',id)
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                           
        if (response.status === 200){
          this.AlertErrorAPI= false
          this.modeloUsuario = response.data
          this.puestoUsuario = response.data.puesto
          this.EstadoBajaEmpleado = response.data.estaActivo
          this.ArrayLicenciasSeleccionadas = response.data.userLicencias
          this.validarfechaIngresoobtenida = response.data.fechaIngreso
          this.ValidarFechaNacimientoObtenida = response.data.fechaNacimiento
          this.CalcularEdadUsuario(this.modeloUsuario.fechaNacimiento)
        }else{
          this.$emit('cancelar')
          showMessageError('Error al cargar el colaborador')
        }
      },

      cancelar(){
        // this.$refs.form.reset()
        this.reset()
        this.$emit('cancelar')
        this.alertErrorPost=false;
        // console.log('cancelar => ', this.idColaborador)
      },

      lanzar_alerta(Type,Title,Text,Footer){
        this.$swal.fire({
          type: Type,
          title: Title,
          text: Text,
          footer: Footer,
           allowOutsideClick: false
        });
      },
      pushError(error){
        // console.log('error ==> ', error)
        Object.keys(error).forEach((element,key,_array)=>{
           if(error[element].hasOwnProperty('ExistAlias'))
            this.arrayErrorPost.push({ id: error[element].ExistAlias.errors[0].errorMessage });

          if(error[element].hasOwnProperty('description'))
            this.arrayErrorPost.push({ id: error[element].description });
          
          if(error[element].hasOwnProperty('email'))
            this.recorrerErroresGenerico(error[element].email)


          if(error[element].hasOwnProperty('confirmPassword'))
            this.recorrerErroresGenerico(error[element].confirmPassword)            

          if(error[element].hasOwnProperty('Password'))
            this.recorrerErroresGenerico(error[element].Password)
          
          if(error[element].hasOwnProperty('Alias'))
            this.recorrerErroresGenerico(error[element].Alias)
          if(error[element].hasOwnProperty('Nombre'))
            this.recorrerErroresGenerico(error[element].Nombre)
          if(error[element].hasOwnProperty('Salario'))
            this.recorrerErroresGenerico(error[element].Salario)
          if(error[element].hasOwnProperty('certificadoMedico'))
            this.recorrerErroresGenerico(error[element].certificadoMedico)
          if(error[element].hasOwnProperty('contactoDeEmergencia'))
            this.recorrerErroresGenerico(error[element].contactoDeEmergencia)
          if(error[element].hasOwnProperty('direccion'))
            this.recorrerErroresGenerico(error[element].direccion)
          if(error[element].hasOwnProperty('dpi'))
            this.recorrerErroresGenerico(error[element].dpi)
          if(error[element].hasOwnProperty('licencia'))
            this.recorrerErroresGenerico(error[element].licencia)
          if(error[element].hasOwnProperty('nombreContactoLicencia'))
            this.recorrerErroresGenerico(error[element].nombreContactoEmergencia)
          if(error[element].hasOwnProperty('sucursal'))
            this.recorrerErroresGenerico(error[element].sucursal)
          if(error[element].hasOwnProperty('phoneNumber'))
            this.recorrerErroresGenerico(error[element].phoneNumber)
          if(error[element].hasOwnProperty('puesto'))
            this.recorrerErroresGenerico(error[element].puesto)
          if(error[element].hasOwnProperty('NoCuentaBancaria'))
            this.recorrerErroresGenerico(error[element].NoCuentaBancaria)
        });
      },

      recorrerErroresGenerico(error){
        Object.keys(error).forEach((element,key,_array)=> {
          this.arrayErrorPost.push({ id: error[element] });
        });
      },

      guardarUsuario() {
        // if(this.$refs.form.validate()){
            this.loading = true
            if( this.isNew===true ){
              this.CreateNewUser()          
            }else{
              this.updateUser()
              this.fechaNacimientoEditar = new Date().toISOString().substr(0, 10)
              this.fechaIngresoEditar = new Date().toISOString().substr(0, 10)
            }
        // }
      },
      async updateUser(){
        console.log('actualizar informacion: ', this.modeloUsuario)
            // validamos la fecha de ingreso para editar
            if(this.validarfechaIngreso === this.fechaIngresoEditar){
              let ObtenerFechaIngreso = this.validarfechaIngresoobtenida.split('/');
              let FechaIngresoOrdenado = ObtenerFechaIngreso[2]+'-'+ObtenerFechaIngreso[1]+'-'+ObtenerFechaIngreso[0]
              this.modeloUsuario.fechaIngreso = FechaIngresoOrdenado
            }else if(this.fechaIngresoEditar === undefined){
              let ObtenerFechaIngreso = this.validarfechaIngresoobtenida.split('/');
              let FechaIngresoOrdenado = ObtenerFechaIngreso[2]+'-'+ObtenerFechaIngreso[1]+'-'+ObtenerFechaIngreso[0]              
              this.modeloUsuario.fechaIngreso = FechaIngresoOrdenado
            }else{
              this.modeloUsuario.fechaIngreso = this.fechaIngresoEditar                    
            }         
            // validamos la fecha de nacimiento para editar
            if(this.validarfechaIngreso === this.fechaNacimientoEditar){
                this.modeloUsuario.fechaNacimiento = this.ValidarFechaNacimientoObtenida
            }else if(this.fechaNacimientoEditar === undefined){
              this.modeloUsuario.fechaNacimiento = this.ValidarFechaNacimientoObtenida
            }else{
              this.modeloUsuario.fechaNacimiento = this.fechaNacimientoEditar                    
            }         

        if(this.BajaEmpleado === true){          
          this.modeloUsuario.estaActivo = false //marca como despedido 
          this.modeloUsuario.emailConfirmed=false //bloquea el usuario
        }else{
          this.modeloUsuario.estaActivo = true
        }

        this.arrayErrorPost=[]
        this.alertErrorPost=false
        if(this.puestoUsuario !==null) this.modeloUsuario.puesto = this.puestoUsuario.name;
        else if(this.puestoUsuario.name === null) this.modeloUsuario.puesto = this.puestoUsuario.name;
        else this.modeloUsuario.puesto = this.puestoUsuario
        this.modeloUsuario.idUser = this.idColaborador;
        this.modeloUsuario.puesto = this.puestoUsuario
        const response = await this.$store.dispatch('updateUser',this.modeloUsuario)
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                           
        if (response.status === 200) {
          this.alertErrorPost=false;this.arrayErrorPost=[];
          this.AlertErrorAPI= false
          // console.log(this.modeloUsuario)
          this.alertErrorPost=false;
          this.loading = false
          showMessageSuccess('Bien !','Registro actualizado')
          this.reset();
          this.$emit('usuarioAgregado')  
          this.computedDateFormattedFechaVencimientoLicencia   
          this.computedDateFormattedFechaEgreso
        }else{
          this.arrayErrorPost=[]
          this.alertErrorPost=true
          this.loading = false
          // this.pushError(response.data)
          if(typeof response === 'undefined'){
              this.ControllerException()
              return 0
          }else if(response.status === 400){
              this.alertErrorPost=true
              var object = response.data.errors
              for (var property in object){
                  this.arrayErrorPost.push({description: `${object[property]}`})
              }
              this.loadingMensajeRetornarCentral = false 
          }else if(response.status === 401){
              showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
              this.loadingMensajeRetornarCentral = false                      
          }else if(response.status === 404){
              showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
              this.loadingMensajeRetornarCentral = false
          }else if(response.status === 500){
              showMessageError('Error 500','Error en el servidor')            
              this.loadingMensajeRetornarCentral = false
          }              
      }
      },
      async CreateNewUser(){
        this.alertErrorPost=false;this.arrayErrorPost=[];
        this.arrayErrorPost=[]
        this.alertErrorPost=false
        this.modeloUsuario.puesto = this.puestoUsuario.name
        this.modeloUsuario.licencias = this.ArrayLicenciasSeleccionadas
        const response = await this.$store.dispatch('createUser',this.modeloUsuario)
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                   
        if (response.status === 200) {
          this.alertErrorPost=false;this.arrayErrorPost=[];
          this.AlertErrorAPI= false
          this.alertErrorPost=false;
          this.loading = false
          showMessageSuccess('Bien !','Registro agregado')
          this.reset();
          this.$emit('usuarioAgregado')          
        }else if(response.status === 400){
          this.arrayErrorPost=[]
          this.alertErrorPost=true
          // this.pushError(response.data)
          this.loading = false        
          this.alertErrorPost=true
          var object = response.data.errors
          for (var property in object){
              this.arrayErrorPost.push({description: `${object[property]}`})
          }          
        }else{
          this.arrayErrorPost=[]
          this.alertErrorPost=true
          this.pushError(response.data)
          this.loading = false
        }
      },

      reset(){
        this.alertErrorPost=false
        this.modeloUsuario.email= '',
        this.modeloUsuario.nombre='',
        this.modeloUsuario.estadoCivil= '',
        this.eddadUsuario = 0,
        this.modeloUsuario.noDependientes ="",
        this.modeloUsuario.noCuentaBancaria="",
        this.modeloUsuario.password= 'Remon2020@',
        this.modeloUsuario.confirmPassword= '',
        this.modeloUsuario.alias= '',
        this.modeloUsuario.phoneNumber= '',
        this.modeloUsuario.direccion= '',
        this.modeloUsuario.puesto='',
        this.modeloUsuario.dpi= '',
        this.modeloUsuario.licencia= '',
        this.modeloUsuario.certificadoMedico= '',
        this.modeloUsuario.salario= '',
        this.modeloUsuario.sucursal= '',
        this.modeloUsuario.contactoDeEmergencia= '',
        this.modeloUsuario.nombreContactoEmergencia= '',
        this.modeloUsuario.fechaIngreso= '',
        this.modeloUsuario.fechaEgreso = '',
        this.puestoUsuario = '',
        this.ArrayLicenciasSeleccionadas = []
        this.BajaEmpleado=false
        this.EstadoBajaEmpleado = false
      } ,

      //seleccionamos las licencias que deseamos guardar al usuario
      GuardarLicenciasSeleccionadas(){
        this.ObjetoLicenciaSeleccionada.tipo = this.modeloUsuario.licencia
        // console.log('tipo licencia', this.ObjetoLicenciaSeleccionada.tipo)
        this.ArrayLicenciasSeleccionadas.push(this.ObjetoLicenciaSeleccionada)
        // console.log('tipo licencia', this.ArrayLicenciasSeleccionadas)
        this.ObjetoLicenciaSeleccionada = {}
      },

      //quitamos la licencia que no quiere agregar el usuario
      QuitarLicencia(item){
        const index =this.ArrayLicenciasSeleccionadas.indexOf(item)
        this.ArrayLicenciasSeleccionadas.splice(index,1)
        // console.log('quitar licencia', this.ArrayLicenciasSeleccionadas)
      },

    },
  }
</script>

<style>
    .tituloTabla{
        font-family: 'PT Serif';
        font-size: 20px;
    }  
</style>