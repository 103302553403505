<template>
  <div>
        <div class="text-center mb-4">
            <v-progress-circular :size="70"
                             :width="7"
                             color="#3c8dbc"
                             indeterminate></v-progress-circular>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions('oidcStore', [
                'oidcSignInCallback'
            ])
  },
  created () {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath)
      })
      .catch((err) => {
        console.error(err)
        this.$router.push('/oidc-callback-error') // Handle errors any way you want
      })
  }
}
</script>

<style >
    .v-progress-circular {
        margin: 1rem
    }
</style>