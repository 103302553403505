<template>
    <div>
        <userProfileComponent/>
    </div>
</template>

<script>
import userProfileComponent from '../../components/UserProfile/profile.vue'

export default {
    name:'userProfile',
    data() {
        return {
            
        }
    },
    components:{
        userProfileComponent
    },
    methods: {
        
    },
}
</script>