import Vue from 'vue'

const store = {
    actions: {
        //add here all acctions api
        //obtenemos todos los tipos de maquinarias
        ObtenerTiposMaquinarias: async({}) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/tipos')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener maquinarias', e)
            }
        },
        //obtenemos todos los proveedores
        ObtenerTodosProveedores: async({}) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/proveedores')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener Proveedores', e)
            }
        },

        //obtenemos todas las marcas
        ObtenerTodasMarcas: async({}) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/marcas')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener marcas', e)
            }
        },

        //guardamos una nueva marca-----------------------------------------------------------------------------------------
        GuardarNuevaMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Maquinaria', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al guardar la nueva maquinaria', e)
            }
        },
        //obtenemos todas las maquinarias con idEstado = 1
        ObtenerTodasMaquinarias: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/byEstado/' + data.idEstado + "/bodega/" + data.bodega + '/sucursal/' + data.sucursal)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al obtener todas las maquinarias', e)
            }
        },
        //borramos una maquinaria por us id
        BorrarMaquinariaById: async({}, idMaquinaria) => {
            try {
                const response = await Vue.axios.delete('/Maquinaria/' + idMaquinaria)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al borrar maquinaria', e)
            }
        },
        //obtenemos una maquinaria por su id
        ObtenerMaquinariaById: async({}, id) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/byId/' + id)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al obtener la maquinaria', e)
            }
        },
        //actualizamos la maquinaria por su id
        ActualizarMaquinaria: async({}, data) => {
            try {
                // console.log(data)
                const response = await Vue.axios.put('/Maquinaria', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al obtener la maquinaria', e)
            }
        },
        //Registramos un incidente
        RegistrarIncidenteMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Maquinaria/incidente', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar un incidente', e)
            }
        },
        //obtenemos los incidentes de una maquinaria
        ObtenerIncidentesMaquinaria: async({}, idMaquinaria) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/incidentes/' + idMaquinaria)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al obtener los incidentes de una maquinaria', e)
            }
        },
        //Actualizar incidente de una Maquinaria
        ActualizarIncidenteMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Maquinaria/incidente', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al actualizar incidente de Maquinaria', e)
            }
        },
        //Actualizar insumo de incidente de una maquinaria
        ActualizarInsumoDeIncidenteMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Maquinaria/incidente/insumo', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al actualizar insumo de incidente de una maquinaria', e)
            }
        },
        //Eliminar insumo de incidente de una maquinaria
        EliminarInsumoDeIncidenteMaquinaria: async({}, idInsumo) => {
            try {
                const response = await Vue.axios.delete('/Maquinaria/incidente/insumo/' + idInsumo)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar insumo de incidente de una maquinaria', e)
            }
        },
        //Eliminar incidente de una maquinaria
        EliminarIncidenteMaquinaria: async({}, idIncidente) => {
            try {
                const response = await Vue.axios.delete('/Maquinaria/incidente/' + idIncidente)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar incidente de una maquinaria', e)
            }
        },
        //Registrar insumo de incidente
        RegistrarInsumoIncidenteMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Maquinaria/incidente/insumo', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de maquinaria', e)
            }
        },
        //Cambiar sucursal y bodega de una maquinaria
        CambiarSucursalyBodegaDeMaquinarias: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Maquinaria/movSucursal', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //Cambiar sucursal de una maquinaia
        CambiarSucursalDeMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Maquinaria/movProyecto', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //obtener todos los movimientos de las sucursales de una maquinaria
        MovimientoSucursalPorMaquinaria: async({}, idMaquinaria) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/movsSucursales/' + idMaquinaria)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //retornamos un vehiculo a la sucursal central
        RetornarMaquinariaSucursalCentralByIdMaquinaria: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Maquinaria/movToOrigen', data)
                return response
            } catch (e) {
                return e.response
            }
        },
        //obtener todos los movimientos de las sucursales de una maquinaria
        TimeLineMovimientosPorMaquinarias: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/movsProyecto/' + data.idMaquinaria + '/fechaInicio/' + data.fechaInicio + '/fechaFin/' + data.fechaFin)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        //obtiene todas las maquinarias y la información se comportara dependiendo del estado del puedeSolicitar
        TodasMaquinariasSolicitud: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Maquinaria/forSolicitud/proyecto/' + data.proyecto + '/sucursal/' + data.sucursal)
                    // const response = await Vue.axios.get('/Maquinaria/forSolicitud/'+data.bodega+'/proyecto/' + data.proyecto + '/sucursal/' + data.sucursal)
                return response
            } catch (e) {
                return e.response
            }
        },
        // descargamos la solicitud en pdf para que las pueda firmar
        DescargarSolicitudPDF: async({}, idSolicitud) => {
            try {
                const response = await Vue.axios.get('/Reportes/pdf/solicitud/' + idSolicitud, {
                    responseType: 'arraybuffer'
                })
                return response
            } catch (e) {
                return e.response
            }
        },
    }
}

export default store