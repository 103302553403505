<template>
    <div>
        <!-- boton para actualizar tabla de herramientas -->
        <v-tooltip bottom color="#0019d8" >
            <template v-slot:activator="{ on }">
                    <v-btn color="#0019d8" rounded dark @click="Actualizartabla()"
                        v-on="on" class="TamañoLetraBoton mx-2"
                        :disabled="active"
                    > 
                        <v-icon >fas fa-sync-alt</v-icon> 
                    </v-btn>
            </template>
            <span>Actualizar Tabla</span>
        </v-tooltip>                                         
    </div>
</template>

<script>
export default {
    props:{
        active:Boolean
    },
    data() {
        return {
            
        }
    },

    methods: {
        Actualizartabla(){
            this.$emit('Actualizartabla')
        }
    },
}
</script>