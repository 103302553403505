<template>
  <v-card>
    <!-- mandamos parametros para abrir el dialogo para crear un nuevo menu -->
    <NuevoMenu 
        @CerrarDialogoCrearNuevoMenu="CerrarDialogoCrearNuevoMenu" 
        :EstadoPermisoEditar="EstadoPermisoEditar" :EstadoPermisoCrear="EstadoPermisoCrear" :EstadoPermisoEliminar="EstadoPermisoEliminar"
        :OpenDialogNewMenu = "OpenDialogNewMenu" :DataUser= "DataUser" :AllMenus="AllMenus"
        @GetAllMenus="GetAllMenus"
    />            
    <v-alert dense outlined type="error" v-if="alertError">
        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
    </v-alert>        
    <v-card-title>
      <v-toolbar-title>Colaboradores</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-radio-group v-model="colaboradorActivo" row @change="traerUsuarios()">
        <v-radio label="Activos" value="1" ></v-radio>
        <v-radio label="Inactivos" value="2" ></v-radio>
      </v-radio-group>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" text outlined @click="openDialogUsuario()" v-if="permisos.crear">Nuevo
          <v-icon right dark>fas fa-user-plus</v-icon>
      </v-btn>
     
      <nuevoUsuario @usuarioAgregado="traerUsuarios" :titulo="titulo" :MensajeTabla="MensajeTabla"  :idColaborador="idColaborador" :dialog="dialog" @cancelar="cancelar" :EstadoLicencia="EstadoLicencia" :EstadoEditar="EstadoEditar" 
        :EstadoPermisoEditar="EstadoPermisoEditar"      
      /> 
      <cambiarClave :id="id" :dialogClave="dialogClave" @cancelClave="cancelClave" @claveActualizada="claveActualizada" />
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar Colaborador" outlined></v-text-field>
    </v-card-title>
    <v-data-table
      v-on:inseteCliente="traerUsuarios()"
      :headers="headers"
      :items="desserts"
      :search="search"
      class="elevation-1"                
      :footer-props="{itemsPerPageText: 'Filas por página'}" 
      :loading="loading"
      loading-text="Cargando Datos"      
      >
        <template v-slot:item.estaActivo="{ item }">
            <v-chip color="#00b40f" text-color="white" v-if="item.estaActivo">Activo
            </v-chip>      
            <v-chip color="#d80000" text-color="white" v-if="!item.estaActivo">Inactivo
            </v-chip>                                          
        </template>                
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom color="orange" v-if="permisos.editar">
              <template v-slot:activator="{ on }">
                  <v-btn v-if="item.estaActivo" small @click="cambiarContraseña(item)" class="mx-3" fab dark color="orange" v-on="on">
                      <v-icon small>fas fa-key</v-icon>
                  </v-btn>
              </template>
              <span>Cambiar Contraseña</span>
          </v-tooltip>
          <v-tooltip bottom color="#00B40F">
              <template v-slot:activator="{ on }">
                  <v-btn small @click=" editarUsuario(item)" class="mx-3" fab dark color="#00B40F" v-on="on">
                      <v-icon >fas fa-user-circle</v-icon>
                  </v-btn>
              </template>
              <span>Ver colaborador</span>
          </v-tooltip>
          <v-tooltip bottom color="#0019D8">
              <template v-slot:activator="{ on }">
                  <v-btn v-if="item.estaActivo" small @click=" AbrirDialogoCrearNuevoMenu(item)" class="mx-3" fab dark color="#0019D8" v-on="on">
                      <v-icon small >fas fa-bars</v-icon>
                  </v-btn>
              </template>
              <span>Asignar Menu</span>
          </v-tooltip>
        </template>
        <template v-slot:no-results>
            <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                Colaborador no existe
            </v-alert>                        
        </template>
        <template v-slot:no-data v-if="desserts.length === 0">
            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
            >
                No existen colaboradores registrados
            </v-alert>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>                                                            
        </template>        
    </v-data-table>
    <UserConfig @updateConfig="updateConfig()" :IdUser="userId" :dessertsUserRoles="dessertsUserRoles" :userName="userName" 
        :dialogConfigureUser="dialogConfigureUser" :dessertsUserModulos="dessertsUserModulos"
        :dessertsUserAcciones="dessertsUserAcciones" @cerrar="cerrar()"/>
    <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
    <v-overlay :value="EstadoCircularProgress">
        <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular>
    </v-overlay>                              
  </v-card>
</template>

<script>
import { apiAuthority } from '../../services/index';
import nuevoUsuario from '../../components/usuarios/nuevoUsuario.vue';
import cambiarClave from '../../components/usuarios/cambiarContraseña.vue'
import UserConfig from '../../components/usuarios/configuracionUser.vue'
import {showMessageError,showMessageSuccess,showMessageWarning} from '../../services/sweetAlert'
import NuevoMenu from '../../components/Administracion/Menu/NuevoMenu'
import moment from 'moment'
import { mapGetters, mapActions  } from 'vuex'

export default {

    data () {
      return {
        colaboradorActivo: '1',
        // alerta cuando el api deja de funcionar
        alertError:false,
        // variable para mostrar un circular progress 
        EstadoCircularProgress: false,                    

        loading: true,
        // variables para validar los permisos del usuario de crear, editar, eliminar
        permisos:{
          crear:false,
          editar:false,
          eliminar:false
        },
        EstadoPermisoCrear: false,
        EstadoPermisoEditar: false,
        EstadoPermisoEliminar: false,
        UserName:'Identificate',
        namespace:'oidcStore/',   

        UsuarioEstaActivo: false,
        id:'',
        search: '',
        desserts:[],
        text:'hello',
        dialog:false,
        EstadoLicencia: false,
        EstadoEditar : false,
        dialogClave:false,
        email:'',
        idColaborador:'',
      
        titulo:'',
        MensajeTabla: '',
        headers: [
          { text: 'NOMBRE', align: 'left', value: 'nombre'},
          { text: 'Alias', align: 'left',  value: 'alias' },
          { text: 'Correo', align: 'left',  value: 'email' },
          { text: 'Sucursal', align: 'left',  value: 'sucursal' },
          { text: 'Fecha de Ingreso', align: 'left',  value: 'fechaIngreso' },
          { text: 'Estado', align: 'center',  value: 'estaActivo' },
          { text: 'Acciones', value: 'actions',align: 'center', sortable: false }
        ],
        //para dialog de configuracion de usuario
        dialogConfigureUser:false,
        userName:'',
        dessertsUserRoles:[],
        userId:'',
        //modulos
        dessertsUserModulos:[],
        dessertsUserAcciones:[],

      // variables para asignar un nuevo menu
        // abrimos el dialogo para crear un nuevo menu
        OpenDialogNewMenu: false,
        DataUser: {id:'', alias:''},

        AllMenus: [],                 
      }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },
  
    created () {
        this.traerUsuarios();
        this.ObtenerPermisosSubmenuUsuario()
    },
    components: {
      'cambiarClave':cambiarClave,
      'nuevoUsuario':nuevoUsuario,
      'UserConfig': UserConfig,
      NuevoMenu,
    }, 

    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        
        
        //obtenemos los permisos de usuario para este modulo
        ...mapActions('oidcStore', ['authenticateOidcSilent',
                'signOutOidc', 'authenticateOidc']),

        async ObtenerPermisosSubmenuUsuario(role){
           if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
               if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.permisos.crear = true
                    this.permisos.editar = true
                    this.EstadoPermisoEditar=true
                    this.EstadoPermisoEliminar = true           
                    return 0
               }else{
                   //preguntar al backend por los permisos del submenu del usuario
                    const response  = await this.$store.dispatch('PermisosSubMenu', 'colaboradores')
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        return 0
                    }                    
                    if(response.status === 200){
                      this.permisos = response.data
                      this.EstadoPermisoEliminar = response.data.eliminar
                    }else if(response.status === 400){
                        showMessageError('Error 400','Petición mala')
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                    }
                    return 0
               }
           }
        },
      // metodos para el modulo de menus      
        // metodos para abrir el dialogo para crear un nuevo menu
        async AbrirDialogoCrearNuevoMenu(user) {
          this.EstadoCircularProgress = true          
          if(this.permisos.editar) {
            this.EstadoPermisoEditar=true
          }else{
            this.EstadoPermisoEditar=false
          }

          if(this.permisos.crear){
            this.EstadoPermisoCrear = true            
          }else{
            this.EstadoPermisoCrear = false
          }
            this.DataUser.id= user.id
            this.DataUser.alias= user.alias
            this.GetAllMenus()
        },
      // obtenemos los menus asignados al usuario para mostrarlo 
        async GetAllMenus(){
          const response = await this.$store.dispatch('getAllMenus', this.DataUser.id);
          if(typeof response === 'undefined'){
              this.ControllerException()
              this.EstadoCircularProgress = false
              return 0
          }                                            
          if(response.status === 200){
            this.AllMenus= response.data
            // console.log(this.AllMenus)
              this.EstadoCircularProgress = false
              this.OpenDialogNewMenu= true
          }else if(response.status === 400){
            showMessageError('Error 400','Petición mala')
            this.EstadoCircularProgress = false
          }else if(response.status === 401){
            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            this.EstadoCircularProgress = false
          }else if(response.status === 404){
            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            this.EstadoCircularProgress = false
          }else if(response.status === 500){
            showMessageError('Error 500','Error en el servidor')            
            this.EstadoCircularProgress = false
          }
        },

        CerrarDialogoCrearNuevoMenu(){
            this.OpenDialogNewMenu=false
        },
            
      openDialogUsuario(){
        if(this.EstadoPermisoCrear) this.EstadoPermisoEditar=true
        this.idColaborador='0'
        this.dialog =true
        this.EstadoLicencia=false 
        this.EstadoEditar = true       
        this.titulo = 'Nuevo'
        this.MensajeTabla="NO EXISTEN LICENCIAS PARA ASIGNAR"
        if(this.permisos.crear) this.EstadoPermisoEditar=true
      },
      updateConfig(){
        this.dessertsUserAcciones=[]
        this.dessertsUserModulos=[]
        this.dessertsUserRoles = []
          this.loadConfigurationInTable()
      },
      claveActualizada(){
        this.dialogClave=false;
      },
      cancelClave(){
        this.dialogClave=false;
      },
      cancelar(){
        this.idColaborador='0'
        this.dialog=false;
        this.titulo='Editar'
        this.MensajeTabla="EL COLABORADOR NO TIENE LICENCIAS REGISTRADAS"
      },
      cambiarContraseña(user){
        this.id=user.id;
        this.dialogClave=true;
      },
      editarUsuario(user){
        console.log('user:', user)
        this.dialog=true;
        this.titulo='Editar'
        this.EstadoLicencia=true
        this.EstadoEditar = false
        this.idColaborador=user.id
        this.titulo='Editar'
        if(user.estaActivo== false){
          this.EstadoPermisoEditar=false
        } else if(this.permisos.editar) {
          this.EstadoPermisoEditar=true
        }else{
          this.EstadoPermisoEditar=false
        }
      },
      cargarTablaUsuarios(usuario){
          this.desserts=[]
          Object.keys(usuario).forEach((element,key,_array)=>{
              this.desserts.push({
                  id:usuario[element].id,
                  nombre:usuario[element].nombre,
                  alias: usuario[element].alias,
                  email: usuario[element].email,
                  sucursal:usuario[element].sucursal,
                  fechaIngreso: moment(usuario[element].fechaIngreso).format('L'),
                  estaActivo:usuario[element].estaActivo,
                  confirmado:usuario[element].emailConfirmed,
              });
          });
      },

      traerUsuarios(){
          this.loading = true
          let _this = this;
          this.dialog = false
          apiAuthority.get('/usuarios/readUsers/estado/'+this.colaboradorActivo)
          .then(response => {
            this.desserts=[]
            //this.desserts=response.data
            this.cargarTablaUsuarios(response.data)
            this.loading = false
            // console.log('desserts', response.data)
          }).catch(eror => {
            // showMessageError('Error', 'Ha ocurrido un error al obtener los colaboradores')
            this.alertError=true
            // console.log('se produjo un error al leer los usuaris');
            this.loading = false
          });
      },

      //para la configuracion de usuario
      async getAllConfigurationUser(){
          return await apiAuthority.get('/Usersroles/'+this.userId)
                .then(response => {
                    return response.data
                }).catch(error => {
                    showMessageError('Ocurrio un error al intentar listar la configuracion del usuario')
                });
      },
      async loadConfigurationInTable(){
          this.dessertsUserRoles=[]
          const data =  await this.getAllConfigurationUser();
          if(typeof data === 'undefined'){
              this.ControllerException()
              return 0
          }          
          Object.keys(data).forEach((element,key,_array)=> {
              const modulos = data[element].applicationRoles.applicationModuls
                  Object.keys(modulos).forEach((m,k,_arrays)=> {
                      //modulos array
                      this.dessertsUserModulos.push({
                        nombre: modulos[m].nombre,
                        icono: modulos[m].icono,
                        url: modulos[m].url,
                        rol: modulos[m].requiereRol
                      });
                      //roles array
                      this.dessertsUserRoles.push({
                          idApplicationUser: data [element].idApplicationUser,
                          idRolApplicationi: data[element].idRoleApplication,
                          idRolAspNet: data[element].id,
                          nombreRol: data[element].applicationRoles.aspRoles.name,
                          nombreApp: data[element].applicationRoles.applications.nombre,
                          nombreModulo: modulos[m].nombre,
                      });
                      //permisos
                      const acciones = modulos[m].accionesModuls
                      Object.keys(acciones).forEach((ac,ks,_aray) => {
                          this.dessertsUserAcciones.push({
                              moduloNombre:modulos[m].nombre,
                              moduloURL: modulos[m].url,
                              moduloRol:  modulos[m].requiereRol,
                              accionNombre: acciones[ac].nombre,
                              accionIcono: acciones[ac].icono,
                              accionUrl: acciones[ac].url,
                              accionPermiso: acciones[ac].requierePermiso
                          });
                      });
                  });
          });
      },
      cerrar(){
        this.dialogConfigureUser=false;
      },
      async configureUser(item){
          this.userId = item.id
          this.userName= item.nombre
          this.dialogConfigureUser=true
          await this.loadConfigurationInTable()
        },  
      }
}
</script>