<template>
    <v-dialog
        v-model="DialogoCrearMaterialConstruccion"    
        persistent :overlay="false"
        max-width="800px" scrollable
        transition="dialog-transition"
    >
        <v-card>
            <v-app-bar color="#3c8dbc" flat dark>
                <v-toolbar-title class="titulo">{{TituloDialogo}} Material o Insumo</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- boton registrar herramienta -->
                <v-tooltip bottom color="success" v-if="this.esNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn large v-on="on" color="success" rounded dark class="mr-4" @click="GuardarMaterialConstruccion()"
                            :loading="loading" :disabled="loading" 
                        >
                            <v-icon left>fas fa-save</v-icon> Guardar
                        </v-btn>                
                    </template>
                    <span>Registrar Material de Construcción</span>
                </v-tooltip>
                <!-- boton editar herramienta -->
                <v-tooltip bottom color="indigo" v-if="!this.esNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn large v-on="on" color="indigo" rounded dark class="mr-4" @click="GuardarCambios()"
                            :loading="loading" :disabled="loading" 
                        >
                            <v-icon left>fas fa-save</v-icon> Guardar
                        </v-btn>                
                    </template>
                    <span>Editar Materia Prima</span>
                </v-tooltip>
                <v-btn large color="error" rounded dark @click="CerrarDialogoCrearMaterialConstruccion()">
                    <v-icon left>fas fa-window-close</v-icon> Cancelar
                </v-btn>                
            </v-app-bar>
            
            <v-card-text>
                <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>      
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                               
                <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                    <v-row wrap>
                        <!-- nombre -->
                        <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >
                            <v-autocomplete :disabled="!EstadoPermisoEditar"
                                :items="ArrayCatalogoMateriaPrima"
                                item-text="nombre"
                                item-value="catalogoMaterialesId"
                                v-model="modeloCrearMaterialConstruccion.catalogoMaterialesId"
                                label="Nombre"
                                outlined
                            ></v-autocomplete>
                            <!-- <v-text-field 
                            ></v-text-field> -->
                        </v-col>
                        <!-- marcas  -->
                        <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 mb-0" >
                            <v-autocomplete :disabled="!EstadoPermisoEditar"
                                :items="ArrayMarcasMateriaPrima"
                                item-text="nombre"
                                item-value="idMarcaMateriaPrima"
                                v-model="modeloCrearMaterialConstruccion.idMarcaMateriaPrima"
                                label="Marca"
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <!-- unidades de medida -->
                        <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 mt-0 pb-0 mb-0" >
                            <v-autocomplete :disabled="!EstadoPermisoEditar"
                                :items="ArrayUnidadesDeMedida"
                                item-text="nombre"
                                item-value="idUnidadDeMedida"
                                v-model="modeloCrearMaterialConstruccion.idUnidadDeMedida"
                                label="Unidades de Medida"
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <!-- ubicacion 1 -->
                        <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 mt-0 pb-0 mb-0" >
                            <v-text-field :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearMaterialConstruccion.ubicacion1"
                                label="Ubicación 1"
                                outlined 
                            ></v-text-field>
                        </v-col>                            
                        <!-- ubicacion 2 -->
                        <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 mt-0 pb-0 mb-0" >
                            <v-text-field :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearMaterialConstruccion.ubicacion2"
                                label="Ubicación 2"
                                outlined
                            ></v-text-field>
                        </v-col>                            
                        <!-- Descripcion                          -->
                        <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 mt-0 pb-0 mb-0">
                            <v-textarea :disabled="!EstadoPermisoEditar"
                                outlined
                                label="Descripción"
                                v-model="modeloCrearMaterialConstruccion.descripcion"
                            >
                            </v-textarea>
                        </v-col>
                        <!-- observacion -->
                        <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 mt-0 pb-0 mb-0">
                            <v-textarea  :disabled="!EstadoPermisoEditar"
                                outlined
                                label="Observación"
                                v-model="modeloCrearMaterialConstruccion.observacion"
                            >
                            </v-textarea>
                        </v-col>                                                                                      
                    </v-row>                    
                </v-form>                
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'
export default {
    data: vm => ({
            // alerta cuando el api deja de funcionar
            alertError:false,                          

            loading: false,
            valid: false,
            lazy: false,
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],                     
            modeloCrearMaterialConstruccion: {
                idMateriaPrima: 0,
                idMarcaMateriaPrima: 0,
                idUnidadDeMedida: 0,
                catalogoMaterialesId: '',
                bodegaId:0,
                ubicacion1:'',
                ubicacion2:'',
                descripcion: '',
                observacion: '',
            },   
            ArrayMarcasMateriaPrima: [],
            ArrayUnidadesDeMedida: [],
            // variables para la fecha
            menufechaCompra: false,
            dateFechaCompra: new Date().toISOString().substr(0, 10),
            dateFormattedFechaCompra: vm.formatDateFechaCompra(new Date().toISOString().substr(0, 10)),
            validarfecha:  new Date().toISOString().substr(0, 10),  
            CambioFecha: false,

            menuFechaCompraEditar: false,
            fechaCompraEditar: new Date().toISOString().substr(0, 10),
            // errores registros duplicados 
            alertErrorPost:false,
            arrayErrorPost:[],            
    }),

    props: {
        ArrayCatalogoMateriaPrima: Array, bodega: Number,
        DialogoCrearMaterialConstruccion: Boolean,
        TituloDialogo: String,
        esNuevo: Boolean,
        ObjectEditarMaterialConstruccion: Object,
        validarfechaobtenida: String,
        EstadoPermisoEditar: Boolean,
    },

    created() {
        this.ObtenerMarca()
        this.ObtenerUnidadesMedida()
    },

    computed: {
        computedDateFormattedFechaCompra () {
            return this.formatDateFechaCompra(this.dateFechaCompra)
        },
    },

    watch: {
        TituloDialogo(val){
            if(!val){
                // console.log('es nuevo => ',val)
                this.catalogoMaterialesId=this.ArrayCatalogoMateriaPrima[0].nombre
            }
        },
        fechaCompraEditar (){
            this.CambioFecha = true                 
        },

        dateFechaCompra (val) {
            this.dateFormattedFechaCompra = this.formatDateFechaCompra(this.dateFechaCompra)
            this.CambioFecha = true     
        },
        DialogoCrearMaterialConstruccion () {
            if(this.esNuevo === true){
                this.modeloCrearMaterialConstruccion.fechaCompra = 'No asignado'
            }
            this.modeloCrearMaterialConstruccion = this.ObjectEditarMaterialConstruccion
        },
    },    
    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        // metodos para la fecha
            formatDateFechaCompra (dateFechaCompra) {
                if (!dateFechaCompra) return null

                const [year, month, day] = dateFechaCompra.split('-')
                return `${month}/${day}/${year}`
            },
            parseDateFechaCompra (dateFechaCompra) {
                if (!dateFechaCompra) return null

                const [month, day, year] = dateFechaCompra.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },                
        // metodos para registrar material de construccion
            CerrarDialogoCrearMaterialConstruccion(){
                this.alertErrorPost=false
                this.arrayErrorPost=[]                
                this.$refs.form.reset()
                this.$emit('CerrarDialogoCrearMaterialConstruccion')
            },
            // obtenemos las marcas de material de construccion
            async ObtenerMarca (){
                const response = await this.$store.dispatch('getAllRawMaterial')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                } 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayMarcasMateriaPrima = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },

            //obtenemos las unidades de medida de material de construccion
            async ObtenerUnidadesMedida(){
                const response = await this.$store.dispatch('getAllUnitsMeasure')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayUnidadesDeMedida = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },

            //registramos el material de construccion 
            async GuardarMaterialConstruccion(){
                this.loading = true
                this.alertErrorPost=false;this.arrayErrorPost=[];
                if(this.$refs.form.validate()){
                    this.modeloCrearMaterialConstruccion.fechaCompra = this.dateFechaCompra
                    this.modeloCrearMaterialConstruccion.bodegaId = this.bodega
                    const response = await this.$store.dispatch('GuardarMateriaPrima', this.modeloCrearMaterialConstruccion)
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        this.loading = false
                        return 0
                    } 
                    if(response.status === 200){
                        this.alertError= false
                        this.$emit('ActualizarTabla')
                        this.loading = false
                        this.modeloCrearMaterialConstruccion.fechaCompra = new Date().toISOString().substr(0, 10)
                        this.dateFechaCompra = new Date().toISOString().substr(0, 10)                                            
                        this.CambioFecha = false
                        showMessageSuccess('¡BIEN!', 'Material de construcción registrada correctamente')
                        this.CerrarDialogoCrearMaterialConstruccion()
                    }else if(response.status === 400){
                        this.alertErrorPost=true
                        var object = response.data.errors
                        for (var property in object){
                            this.arrayErrorPost.push({description: `${object[property]}`})
                        }
                        this.loading = false
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        this.loading = false                      
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        this.loading = false
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                        this.loading = false
                    }
                }else{
                    this.loading = false
                }
            },
        // metodos para actualizar una materia prima 
            async GuardarCambios () {
                this.loading = true
                this.alertErrorPost=false;this.arrayErrorPost=[];
                if(this.fechaCompraEditar === undefined){
                    this.modeloCrearMaterialConstruccion.fechaCompra = this.validarfechaobtenida
                }else if(this.CambioFecha === true){
                    this.modeloCrearMaterialConstruccion.fechaCompra = this.fechaCompraEditar      
                    // console.log(this.modeloCrearMaterialConstruccion.fechaCompra)              
                }        

                const response = await this.$store.dispatch('ActualizarMateriaPrima', this.modeloCrearMaterialConstruccion)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loading = false
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false
                    this.$emit('ActualizarTabla')
                    showMessageSuccess('¡BIEN!', 'Materia Prima actualizado correctamente')
                    this.modeloCrearMaterialConstruccion.fechaCompra = new Date().toISOString().substr(0, 10)
                    this.CambioFecha = false
                    this.CerrarDialogoCrearMaterialConstruccion()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }        
    },
}
</script>