<template>
    <v-dialog v-model="DialogEliminarIncidente" persistent max-width="500px">
        <v-card class="FuenteLetra" style="border: 2px solid black">
            <div class="text-center py-6">
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>                
                <v-icon size="80" color="red">fas fa-times</v-icon>
                <h3 class="pt-5" v-if="EliminarInsumoIncidente">¿{{TituloEliminar}} {{DatosInsumo.nombre}}?</h3>
                <h3 class="pt-5" v-if="!EliminarInsumoIncidente">¿{{TituloEliminar}} {{DatosIncidente.nombre}}?</h3>
                <v-subheader>
                    De Incidente de {{Titulo}}
                </v-subheader> <v-divider class="mb-4"></v-divider>
            </div>
            <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                    <v-btn large rounded color="#2ED71C" :loading="loading" :disabled="loading"
                        @click="EliminarInsumo()" class="mx-2" v-if="EliminarInsumoIncidente"
                    >
                        Si, Eliminar<v-icon right dark>fas fa-save</v-icon>
                    </v-btn>
                    <v-btn large rounded color="#2ED71C" :loading="loading" :disabled="loading"
                        @click="EliminarIncidente()" class="mx-2" v-if="!EliminarInsumoIncidente"
                    >
                        Si, Eliminar<v-icon right dark>fas fa-save</v-icon>
                    </v-btn>
                    <v-btn dark large rounded color="error" @click="CerrarDialogoEliminarIncidente()" class="mx-2">
                        No, Cancelar<v-icon right dark>far fa-window-close</v-icon>
                    </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../services/sweetAlert'
export default {
    props: {
        DialogEliminarIncidente: Boolean,
        TituloEliminar: String,
        Titulo: String,
        DatosInsumo: Object,
        EliminarInsumoIncidente: Boolean,
        DatosIncidente: Object,
    },

    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,                                      
            loading: false,            
        }
    },

    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        CerrarDialogoEliminarIncidente(){
            this.$emit('CerrarDialogoEliminarIncidente')
        },
        //eliminamos insumos
        async EliminarInsumo(){
            this.loading = true
            if(this.Titulo === 'Vehiculo'){
                const response = await this.$store.dispatch('EliminarInsumoDeIncidenteVehiculo', this.DatosInsumo.idInsumoIncidente)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    showMessageSuccess('¡BIEN!','Insumo eliminado correctamente')
                    this.loading = false
                    this.CerrarDialogoEliminarIncidente()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else if(this.Titulo === 'Maquinaria'){
                const response = await this.$store.dispatch('EliminarInsumoDeIncidenteMaquinaria', this.DatosInsumo.idInsumoIncidente)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    showMessageSuccess('¡BIEN!','Insumo eliminado correctamente')
                    this.loading = false
                    this.CerrarDialogoEliminarIncidente()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }
        },
        //eliminamos incidentes
        async EliminarIncidente(){
            this.loading = true
            if(this.Titulo === 'Vehiculo'){
                const response = await this.$store.dispatch('EliminarIncidenteVehiculo', this.DatosIncidente.idIncidente)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    showMessageSuccess('¡BIEN!','Incidente eliminado correctamente')
                    this.loading = false
                    this.CerrarDialogoEliminarIncidente()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else if(this.Titulo === 'Maquinaria'){
                const response = await this.$store.dispatch('EliminarIncidenteMaquinaria', this.DatosIncidente.idIncidente)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    showMessageSuccess('¡BIEN!','Incidente eliminado correctamente')
                    this.loading = false
                    this.CerrarDialogoEliminarIncidente()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }
        }
    },
}
</script>