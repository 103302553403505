<template>
    <div class="text-center">
        <!-- <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>             -->               
        <v-toolbar title elevation="1">
            <v-icon color="black " left>fas fa-snowplow</v-icon>
            <v-toolbar-title class="tituloMaquinaria">Inventario de Maquinarias disponibles</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
            <BotonActualizarTabla @Actualizartabla="ObtenerInventarioDeMaquinariasDisponibles"/>
        </v-toolbar>        
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>  
        <v-data-table
            :headers="EncabezadoTablaInventarioMaquinariasDisponibles"
            :items="ArrayTablaInventarioMaquinariasDisponibles"
            item-key="idHerramienta"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."    
        >
            <template v-slot:item.puedeSolicitar="{ item }">
                <v-row justify="space-around">
                    <v-tooltip left color="#00b40f" v-if="item.puedeSolicitar">
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" color="#00b40f" text-color="white" > 
                                <v-icon>fas fa-check</v-icon>
                            </v-chip>
                        </template>
                        <span class="fontButton">sí puede realizar solicitud</span>
                    </v-tooltip>                    
                    <v-tooltip left color="#d80000" v-if="!item.puedeSolicitar">
                        <template v-slot:activator="{ on }">
                            <v-chip v-on="on" color="#d80000" text-color="white" >
                                <v-icon >fas fa-times-circle</v-icon>
                            </v-chip>
                        </template>
                        <span class="fontButton">{{item.motivo}}</span>
                    </v-tooltip>                    
                </v-row>
            </template>       
            <template v-slot:no-data v-if="ArrayTablaInventarioMaquinariasDisponibles.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                >
                    No hay maquinarias disponibles en inventario
                </v-alert>
            </template>                    
            <template v-slot:item.action="{ item }">
                <v-tooltip left color="#000000" v-if="item.puedeSolicitar">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AgregarMaquinariaASolicitud(item)" >
                            <v-icon small>fas fa-plus-circle</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Agregar Solicitud</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <TablaDeSolicitudesDeMaquinariasAEnviar :ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar="ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar" 
            :sucursal="sucursal" :bodega="bodega" :proyecto="proyecto"
            @ObtenerInventarioDeMaquinariasDisponibles="ObtenerInventarioDeMaquinariasDisponibles"
        />
    </div>
</template>

<script>
import { showMessageError, showMessageWarning } from '../../../../services/sweetAlert'
import TablaDeSolicitudesDeMaquinariasAEnviar from '../../SolicitudesDeMaquinarias/EnviarSolicitudesMaquinarias/TablaDeSolicitudesDeMaquinariasAEnviar'
import BotonActualizarTabla from '../../../../views/BotonActualizarTabla'

export default {
    components:{
        TablaDeSolicitudesDeMaquinariasAEnviar,
        BotonActualizarTabla,
    },
    props:{
        proyecto:Number,
        sucursal:Number,
        bodega:Number,
        tab: String,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            ArrayTablaInventarioMaquinariasDisponibles: [],
            EncabezadoTablaInventarioMaquinariasDisponibles:[
                {text: 'Id', value:'idMaquinaria', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Código', value:'codigo', sortable: true, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Tipo Maquinaria', value: 'tipoMaquinaria.nombre', sortable: true, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Nombre', value:'nombre', sortable: true, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: true, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Bodega', value: 'bodega.nombre', sortable: true, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Proyecto actual', value: 'proyecto.nombre', sortable: true, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: '¿Puede Solicitar?', value: 'puedeSolicitar', sortable: true, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            ArraySolicitudesHerramientasAEnviar:[],
            PuedeSolicitar: false,

            ObjectDatosSolicitudDeMaquinariaInventario:{
                idMaquinaria:0,
                codigo:0,
                tipoMaquinaria:'',
                nombreMaquinaria:'',
                marcaMaquinaria:'',
            },
            ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar:[{}],

            PuedeSolicitar: false,
            bandera:false,
            alertErrorPost:false,
            arrayErrorPost:[],                        
        }
    },
    watch: {
        tab(){
            this.ObtenerInventarioDeMaquinariasDisponibles()
        },
        sucursal(){
            this.ArrayTablaInventarioMaquinariasDisponibles = []
            this.ObtenerInventarioDeMaquinariasDisponibles()
        },
        proyecto(){
            this.ObtenerInventarioDeMaquinariasDisponibles()
        }
    },
    created() {
        this.ObtenerInventarioDeMaquinariasDisponibles()
    },

    

    methods: {   
        ValidarMaquinariaTwo(datos){
            var existe = false
            this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.forEach(x =>{
                if(x.idMaquinaria === datos.idMaquinaria){
                    existe = true;                    
                }
            })
            if(existe){
                showMessageWarning('Oops!','Maquinaria ya está agregada a la solicitud')
            }else{
                this.PushMaquinaria(datos)
            }            
        },

        PushMaquinaria(datos){
            this.ObjectDatosSolicitudDeMaquinariaInventario = {}
            this.ObjectDatosSolicitudDeMaquinariaInventario.idMaquinaria = datos.idMaquinaria
            this.ObjectDatosSolicitudDeMaquinariaInventario.codigo = datos.codigo
            this.ObjectDatosSolicitudDeMaquinariaInventario.tipoMaquinaria = datos.tipoMaquinaria.nombre
            this.ObjectDatosSolicitudDeMaquinariaInventario.nombreMaquinaria = datos.nombre
            this.ObjectDatosSolicitudDeMaquinariaInventario.marcaMaquinaria = datos.vehiculoMarca.nombre            
            this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.push(this.ObjectDatosSolicitudDeMaquinariaInventario)            
        },

        AgregarMaquinariaASolicitud(datos){   
            if(this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.length === 0){
                this.PushMaquinaria(datos)
            }else{
                this.ValidarMaquinariaTwo(datos)
            }
        },
        async ObtenerInventarioDeMaquinariasDisponibles (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            var data = {proyecto: this.proyecto, sucursal: this.sucursal, bodega: this.bodega}
            const response = await this.$store.dispatch('TodasMaquinariasSolicitud', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar = []
                this.ArrayTablaInventarioMaquinariasDisponibles = response.data
                this.PuedeSolicitar = response.data.puedeSolicitar
                // alert(this.PuedeSolicitar)
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false  
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        }              
    },
}
</script>