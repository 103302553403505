<template>
    <v-dialog v-model="AbrirTablaDeHerramientasPorSolicitud" fullscreen scrollable>
        <v-card>
            
            <v-card-title class="AppBar" elevation="0" style="color: #ffffff">
                <v-icon color="white" left>fas fa-tools</v-icon> Herramientas de solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalHerramientasDeSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>                
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <!-- <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>             -->
                    <div class="my-3">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaterial">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaterial">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaterial">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>                        
                    <v-toolbar title elevation="1">
                        <v-chip color="blue" outlined>
                            <v-icon left>fas fa-tools</v-icon>
                            <v-toolbar-title class="tituloMaterial">Herramientas</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>                    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn v-if="filtrarEstadoSolicitudHerramienta==='1'" dark color="indigo" v-on="on" :disabled="loading" @click="AutorizarTodasLasSolicitudesSeleccionadas()"
                            :loading="loading" 
                        >Autorizar herramientas
                            <v-icon color="white" >far fa-check-circle</v-icon>
                        </v-btn>        
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table
                        :headers="EncabezadoTablaSolicitudes"
                        :items="HerramientasBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."             
                        item-key="sol_SucursalHerramientaId"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-tooltip left color="#000000" v-if="this.filtrarEstadoSolicitudHerramienta==='3'">
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirDialogoVerMotivoRechazoSolicitud(item)" >
                                        <v-icon small>fas fa-eye</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Ver Motivo de Rechazo</span>
                            </v-tooltip>
                        </template>        
                        <template v-slot:item.herramienta.cantidad="{ item }">
                            <v-row justify="space-around">
                                <v-chip color="#00b40f" text-color="white" v-if="item.herramienta.cantidad>item.cantidadSolicitada || item.herramienta.cantidad===item.cantidadSolicitada"> {{item.herramienta.cantidad}}
                                    <v-icon small right>fas fa-check</v-icon>
                                </v-chip>
                                <v-chip color="#d80000" text-color="white" v-if="item.herramienta.cantidad<item.cantidadSolicitada"> {{item.herramienta.cantidad}}
                                    <v-icon small right>fas fa-times-circle</v-icon>
                                </v-chip>
                            </v-row>
                        </template>       
                        <template v-slot:item.cantidadSolicitada="{ item }" v-if="this.filtrarEstadoSolicitudHerramienta==='1'">
                            <v-text-field type="number"
                                :label="'Cantidad Solicitada '" 
                                v-model="item.cantidadSolicitada"
                            ></v-text-field>
                        </template>       
                        <template v-slot:no-data v-if="HerramientasBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen solicitudes registradas
                            </v-alert>
                        </template>                                     
                    </v-data-table>                       
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../../views/BotonActualizarTabla'
import AutorizarSolicitudDeHerramienta from '../../../components/bodega/AutorizarSolicitudHerramienta/FormularioAutorizarSolicitud'
import VerMotivoRechazoSolicitud from '../../../views/Bodegas/VerMotivoRechazoSolicitud'

export default {
    props:{
        AbrirTablaDeHerramientasPorSolicitud: Boolean,
        HerramientasBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,        
        filtrarEstadoSolicitudHerramienta: String,
    },
    components:{
        BotonActualizarTabla,
        AutorizarSolicitudDeHerramienta,
        VerMotivoRechazoSolicitud,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            EncabezadoTablaListadoSolicitudHerramientasEnProceso:[
                {text: 'Nombre', value: 'herramienta.catalogoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'herramienta.catalogoHerramienta.marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'herramienta.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Bodega origen', value: 'herramienta.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Ubicación 1', value: 'herramienta.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Ubicación 2', value: 'herramienta.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Existencia Actual', value: 'herramienta.cantidad', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Fecha Creación', value: 'fecha_creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            EncabezadoTablaListadoSolicitudHerramientasAprobado:[
                {text: 'Nombre', value: 'herramienta.catalogoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'herramienta.catalogoHerramienta.marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'herramienta.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Bodega origen', value: 'herramienta.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Ubicación 1', value: 'herramienta.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Ubicación 2', value: 'herramienta.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu', width: '200px'},
                {text: 'Cantidad Autorizada', value: 'cantidadAprobada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu', width: '200px'},
            ],
            EncabezadoTablaListadoSolicitudHerramientasRechazados:[
                {text: 'Nombre', value: 'herramienta.catalogoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'herramienta.catalogoHerramienta.marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal origen', value: 'herramienta.bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Bodega origen', value: 'herramienta.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Ubicación 1', value: 'herramienta.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Ubicación 2', value: 'herramienta.ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu', width: '200px'},
                {text: 'Fecha Rechazo', value: 'fecha_modificacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Creación', value: 'fecha_creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
  
            EncabezadoTablaSolicitudes:[],
            // variables para autorizacion de solicitud de herramientas
            FormularioAutorizarSolicitudHerramienta:false,           

            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },

    watch: {
        AbrirTablaDeHerramientasPorSolicitud(){
            this.EstadoOpcionSolicitud()
            // console.log('HerramientasBySolicitud: ', this.HerramientasBySolicitud)
        },
    },

    created() {        
    },

    methods: {
        //-----------------------------------------------------------------------------
        EstadoOpcionSolicitud(){
            if(this.filtrarEstadoSolicitudHerramienta === '1'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudHerramientasEnProceso
            }else if(this.filtrarEstadoSolicitudHerramienta === '2'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudHerramientasAprobado
            }else if(this.filtrarEstadoSolicitudHerramienta === '3'){
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudHerramientasRechazados
            }        
        },        
        //-----------------------------------------------------------------------------
        AbrirDialogoVerMotivoRechazoSolicitud(datos){
            this.DatosSolictudRechazado = datos
            this.AbrirModalVerMotivoRechazo = true
        },

        CerrarDialogoVerMotivoRechazoSolicitud(){
            this.AbrirModalVerMotivoRechazo = false
        },

        MapearHerramientas(datos){
            this.ArrayTodasLasSolicitudesAutorizar=[]
            Object.keys(datos).forEach((element,key,_array)=>{
                this.ArrayTodasLasSolicitudesAutorizar.push({
                    sol_ProyectoHerramientaId:datos[element].sol_ProyectoHerramientaId,
                    cantidadAutorizada:datos[element].cantidadSolicitada,
                });
                // console.log('ArrayTodasLasSolicitudesAutorizar: ', this.ArrayTodasLasSolicitudesAutorizar)
            });
        },

        async AutorizarTodasLasSolicitudesSeleccionadas(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.MapearHerramientas(this.HerramientasBySolicitud)
            var data = {sol_Herramientas: this.ArrayTodasLasSolicitudesAutorizar, proyectoSolicitudId: this.DatosSolicitud.idProyecto}
            const response = await this.$store.dispatch('AutorizarSolicitudesDeHerramientasSeleccionadas', data)
            if(response.status === 200){
                this.alertErrorPost=false
                this.arrayErrorPost=[]                                                          
                this.loading = false
                this.$emit('ObtenerListadoDeSolicitudesDeHerramientas')
                showMessageSuccess('Bien!', response.data.message)
                this.CerrarModalHerramientasDeSolicitud()
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false     
                setTimeout(() => {
                    this.alertErrorPost=false
                }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },
        AbrirModalAutorizarSolicitudDeHerramienta(datos){
            this.FormularioAutorizarSolicitudHerramienta = true
        },
        CerrarModalAutorizarSolicitudDeHerramienta(){
            this.FormularioAutorizarSolicitudHerramienta = false
        },
                
        CerrarModalHerramientasDeSolicitud(){
            this.$emit('CerrarModalHerramientasDeSolicitud')
        }
    },
}
</script>
<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
    .tituloMaterial {
        font-family: 'PT Serif';
        font-size: 20px;        
    }      
</style>