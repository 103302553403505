<template>
    <div >
        <v-dialog
            v-model="ActualizarCantidadHerramientaSolicitud"
            scrollable 
            persistent :overlay="false"
            transition="dialog-transition"
            max-width="800px"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-file-import</v-icon>
                    <v-toolbar-title class="TituloDialogo">Actualizar Cantidad Herramienta</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="ActualizarCantidadSolicitada()"
                                :loading="loading" :disabled="loading || bloquearBoton"                        
                            >Guardar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Actualizar Cantidad Solicitada de Herramienta</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="cerrar()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar</span>
                    </v-tooltip>                    
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Tipo de herramienta:</strong> {{datosHerramienta.tipoHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Herramienta:</strong> {{datosHerramienta.nombreHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Marca de herramienta:</strong> {{datosHerramienta.marcaHerramienta}}</span> <br>
                    <span class="fuenteTitulo"><strong>Existencia:</strong> {{datosHerramienta.existencia}}</span>
                </div>
                <hr>
                <v-alert v-if="cantidadMayor" type="error" outlined transition="scale-transition" color="deep-orange" class="TituloDialogo">
                    {{errorAgregarSol}}
                </v-alert>                                                                                                
                <v-card-text class="TituloDialogo">
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                            <v-col cols="12" lg="6" md="6" sm="6" class="mt-0 pb-0">
                                <v-text-field outlined 
                                    label="Ingrese cantidad a solicitar" type="number"
                                    v-model="cantidadSolicitada"
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    props:{
        ActualizarCantidadHerramientaSolicitud: Boolean,
        datosHerramienta: Object,
        idHerramienta: Number,
        ProyectoSolicitudId: Number,
        sol_ProyectoHerramientaId: Number,
    },
    data() {
        return {
            alertError:false,
            loading:false,
            valid: true,
            lazy: false,
            cantidadMayor:false,            
            errorAgregarSol:'Existencia insuficiente',
            rules: [
                value => !!value || 'Campo requerido',
                value => (value && value > 0) || 'Debe ingresar un número mayor que 0',
            ],   
            cantidadSolicitada:0,    
            bloquearBoton:false,     
        }
    },

    watch:{
        ActualizarCantidadHerramientaSolicitud(){
            this.cantidadSolicitada = this.datosHerramienta.cantidadSolicitada
        },
        cantidadSolicitada(){
            if(this.cantidadSolicitada > this.datosHerramienta.existencia) {
                this.cantidadMayor = true
                this.bloquearBoton = true
            }
            else {
                this.cantidadMayor = false
                this.bloquearBoton = false
            }
        }
    },
    methods: {
        async ActualizarCantidadSolicitada(){
            if (this.$refs.form.validate()){
                this.loading = true
                var herramienta = {sol_ProyectoHerramientaId: this.sol_ProyectoHerramientaId, cantidadSolicitada: this.cantidadSolicitada}
                var data = {solicitudProyectoId: this.ProyectoSolicitudId, herramienta: herramienta}
                // console.log('dataherramienta: ',data)
                const response = await this.$store.dispatch('ActualizarCantidadSolicitadaItemSolicitud', data)
                if(response.status === 200){
                    this.$emit('ObtenerListadoDeSolicitudesDeHerramientas')
                    //this.$emit('CerrarModalHerramientasDeSolicitud')
                    showMessageSuccess('BIEN!','Cantidad Solicitada Actualizada Correctamente.')
                    this.loading = false
                    this.cerrar()
                }else{
                    this.ControlladorExcepciones(response)
                }                                                                        
            }
        },
        cerrar(){
            this.cantidadSolicitada=''
            this.$emit('cerrar')
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http        
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.loading = false                      
                showMessageError('Oops!', response.data.errors[0])
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },

    },
}
</script>