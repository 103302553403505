<template>
    <v-dialog
        v-model="DialogoCrearVehiculo"
        fullscreen
        persistent :overlay="false"
        transition="dialog-transition"
    >
        <v-card>
        <!-- barra principal del dialogo -->
            <v-app-bar flat dark color="#3c8dbc" class="mb-5">
                <v-toolbar-title class="TituloDialogo">{{Titulo}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- si es nueva vehiculo a registrar -->
                <v-tooltip bottom color="success" v-if="this.EsNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn  v-on="on" dark large rounded class="mx-2 TituloDialogo" color="success" @click="CrearVehiculo()"
                            :loading="loading" :disabled="loading"                        
                        >Guardar
                            <v-icon right dark>fas fa-save</v-icon>
                        </v-btn>                                    
                    </template>
                    <span>Registrar Vehículo</span>
                </v-tooltip>
                <v-tooltip bottom color="error" v-if="this.EsNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarDialogoCrearVehiculo()">Cancelar
                            <v-icon right dark>far fa-window-close</v-icon>
                        </v-btn>                        
                    </template>
                    <span>Cancelar Registro</span>
                </v-tooltip>
                <!-- si es editar vehiculo -->
                <v-btn v-if="!this.EsNuevo && EstadoPermisoEditar" dark large rounded class="mx-2" color="indigo TituloDialogo" @click="GuardarCambios()"
                    :loading="loading" :disabled="loading"
                >Guardar Cambios
                    <v-icon right dark>fas fa-save</v-icon>
                </v-btn>            
                <v-btn v-if="!this.EsNuevo" dark large rounded class="mx-2" color="error TituloDialogo" @click="CerrarDialogoCrearVehiculo()">Cancelar
                    <v-icon right dark>far fa-window-close</v-icon>
                </v-btn>                        
            </v-app-bar>       
        <!-- cuerpo del formulario      -->
            <v-card-text>
                <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                                     
                <v-form
                    v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                >
                    <v-row wrap>
                    <!-- primera seccion -->
                        <!-- codigo -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.codigo"
                                label="Código"
                            ></v-text-field>
                        </v-col>
                        <!-- nombre -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.nombre"
                                label="Nombre"
                            ></v-text-field>
                        </v-col>
                        <!-- placa -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.placa"
                                label="Placa"
                            ></v-text-field>
                        </v-col>        
                        <!-- propietario                 -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.propietario"
                                label="Propietario"
                            ></v-text-field>
                        </v-col>      
                    <!-- segunda seccion -->
                        <!-- modelo -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field type="number" outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.modelo"
                                label="Modelo"
                            ></v-text-field>
                        </v-col>     
                        <!-- tipo de vehiculo                    -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0"> 
                            <v-select outlined :disabled="!EstadoPermisoEditar"
                                :items="ArrayTiposVehiculos"
                                item-value="idTipoVehiculo"
                                item-text="nombre"
                                v-model="modeloCrearVehiculo.idTipoVehiculo"
                                label="Tipo Vehículo"
                            ></v-select>
                        </v-col>     
                        <!-- marca                    -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" > 
                            <v-select outlined :disabled="!EstadoPermisoEditar"
                                :items="ArrayMarcas"
                                item-value="idMarca"
                                item-text="nombre"
                                v-model="modeloCrearVehiculo.idMarca"
                                label="Marca"
                            ></v-select>
                        </v-col>  
                        <!-- linea -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.linea"
                                label="Línea"
                            ></v-text-field>
                        </v-col>     
                    <!-- tercera seccion -->
                        <!-- VIN -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.vin"
                                label="VIN"
                                hint="Número de Identificación del Vehículo"
                                persistent-hint
                            ></v-text-field>
                        </v-col>          
                        <!-- chasis               -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.chasis"
                                label="Chasis"
                            ></v-text-field>
                        </v-col>  
                        <!-- cc                                                -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.cc"
                                label="CC" type="number"
                                hint="CC = Cilindrada del motor"
                                persistent-hint
                            ></v-text-field>
                        </v-col>                        
                        <!-- rendimiento                                                -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.rendimiento"
                                label="Rendimiento" type="number"
                            ></v-text-field>
                        </v-col>                        
                    <!-- cuarta seccion                   -->
                        <!-- color -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.color"
                                label="Color"
                            ></v-text-field>
                        </v-col>   
                        <!-- motor                      -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.motor"
                                label="Motor"
                            ></v-text-field>
                        </v-col>    
                        <!-- año tarjeta de circulacion                     -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.tarjetaCirculacion"
                                label="Año Tarjeta Circulación"
                            ></v-text-field>
                        </v-col>      
                        <!-- combustible                   -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-select outlined :disabled="!EstadoPermisoEditar"
                                :items="ArrayCombustible"
                                item-text="nombre"
                                item-value="idTipoCombustible"
                                v-model="modeloCrearVehiculo.idTipoCombustible"
                                label="combustible"
                            ></v-select>                            
                        </v-col>                        
                    <!-- quinta seccion                    -->
                        <!-- procedencia -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" > 
                            <v-select outlined  :disabled="!EstadoPermisoEditar"
                                :items="ArrayEstadoVehiculo"
                                
                                item-text="procedencia"
                                v-model="modeloCrearVehiculo.procedencia"
                                label="Estado Vehículo"
                            ></v-select>
                        </v-col>     
                        <!-- proveedor                       -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" > 
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.proveedor"
                                label="Proveedor"
                            ></v-text-field>
                        </v-col>
                        <!-- fecha compra cuando es nuevo -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="this.EsNuevo"> 
                            <v-menu
                                v-model="menuFechaCompra"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined 
                                        v-model="modeloCrearVehiculo.fechaCompra"
                                        label="Fecha Compra"
                                        prepend-inner-icon="far fa-calendar-alt"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="es-GT" v-model="modeloCrearVehiculo.fechaCompra" @input="menuFechaCompra = false"></v-date-picker>
                            </v-menu>
                        </v-col>        
                        <!-- fecha compra cuando se edita -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" v-if="!this.EsNuevo"> 
                            <v-menu
                                v-model="menuFechaCompra2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                        v-model="fechaCompra"
                                        label="Fecha Compra"
                                        prepend-inner-icon="far fa-calendar-alt"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :hint="'Fecha Compra Actual = '+modeloCrearVehiculo.fechaCompra"
                                        persistent-hint
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="es-GT" v-model="fechaCompra" @input="menuFechaCompra2 = false"></v-date-picker>
                            </v-menu>               
                        </v-col>       
                        <!-- sucursales                   -->
                        <!-- <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-select outlined :disabled="!EstadoPermisoEditar"
                                :items="ArraySucursales"
                                item-text="nombre"
                                item-value="idProyecto"
                                v-model="modeloCrearVehiculo.idProyecto"
                                label="Sucursal"
                            ></v-select>                            
                        </v-col>                                                 -->
                        <!-- observacion  -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0" > 
                            <v-textarea outlined :disabled="!EstadoPermisoEditar"
                                v-model="modeloCrearVehiculo.observacion"
                                label="Observación"
                                color="info"
                                hide-details
                            ></v-textarea>         
                        </v-col>                
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
export default {
    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,                          

            loading: false,
            valid: false,
            lazy: false,
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],
            menuFechaCompra2: false,
            fechaCompra: new Date().toISOString().substr(0, 10),
            validarfecha:  new Date().toISOString().substr(0, 10),

            modeloCrearVehiculo: {
                idMarca: 0,
                idTipoVehiculo: 0,
                idEstado: 1,
                proveedor: '',
                codigo: '',
                nombre: '',
                placa: '',
                color: '',
                motor: '',
                cc: 0,
                idTipoCombustible:'',
                procedencia: '',
                modelo: 0,
                chasis: '',
                linea: '',
                vin: '',
                tarjetaCirculacion: '',
                fechaCompra:  new Date().toISOString().substr(0, 10),
                propietario: '',
                idProyecto:0,
                rendimiento: 0,
                observacion: '',
                CambioFecha: false,
                bodegaId:0,
            },
            ArrayTiposVehiculos: [],
            ArrayMarcas: [],
            ArrayCombustible: [],
            ArrayEstadoVehiculo: [
                {
                    procedencia: 'Nuevo'
                },
                {
                    procedencia: 'Usado'
                }
            ],
            ArraySucursales:[],

            ArrayProveedores:[],
            MensajeEstadoVehiculo: '',
            menuFechaCompra: false,
            // errores registros duplicados
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },

    props: {
        DialogoCrearVehiculo: Boolean,
        Titulo: String,
        EsNuevo: Boolean,
        ArrayObtenerVehiculoEditar: Object,
        validarfechaobtenida: String,
        ObtenerProcedencia: String,
        EstadoPermisoEditar: Boolean,
        bodega: Number,
    },

    watch: {
        fechaCompra(){
            this.CambioFecha = true
        },
        
        DialogoCrearVehiculo (){
            this.modeloCrearVehiculo = this.ArrayObtenerVehiculoEditar
        }
    },
    created() {
        this.ObtenerTipoVehiculo()
        this.ObtenerMarcas()
        this.TodasSucursales()
        this.ValidarEstadoVehiculo()
        this.TodosTiposCombustibles()
    },

    methods: {         
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        //obtenemos todos los tipos de combustibles
            async TodosTiposCombustibles(){
                this.loading = true
                const response = await this.$store.dispatch('getAllTypesFuel')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayCombustible = response.data
                    this.loading = false
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            },

        // validamos el estado del vehiculo a crear
            ValidarEstadoVehiculo(){
                if(this.modeloCrearVehiculo.idEstado === false){
                    this.MensajeEstadoVehiculo = 'Inactivo'
                }else{
                    this.MensajeEstadoVehiculo = 'Activo'
                }
            },

        //cerramos el dialogo
            CerrarDialogoCrearVehiculo(){
                this.alertErrorPost=false
                this.arrayErrorPost=[]                
                this.Reset()
                this.$emit('CerrarDialogoCrearVehiculo')
            },

        //obtenemos todas las sucursales
            async TodasSucursales(){
                const response = await this.$store.dispatch('getAllProyectos')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }else if(response.status === 200){
                    this.alertError= false
                    this.ArraySucursales = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }                
            },
        //actualizamos la tabla de vehiculos cuando guardamos un vehiculo y guardamos un vehiculo
            TodosVehiculos(){
                this.$emit('TodosVehiculos')
            },

            async CrearVehiculo(){
                this.loading = true
                this.modeloCrearVehiculo.bodegaId = this.bodega
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.modeloCrearVehiculo.idEstado = 1
                this.$refs.form.validate()
                if(this.$refs.form.validate() === true){     
                    const response =  await this.$store.dispatch('GuardarVehiculo', this.modeloCrearVehiculo)
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        return 0
                    }                     
                    if(response.status === 200){
                        this.alertError= false
                        this.TodosVehiculos()
                        this.CerrarDialogoCrearVehiculo()
                        this.CambioFecha =false
                        this.loading = false
                        showMessageSuccess('¡BIEN!', 'Vehículo registrado correctamente')
                    }else if(response.status === 400){
                        this.alertErrorPost=true
                        var object = response.data.errors
                        for (var property in object){
                            this.arrayErrorPost.push({description: `${object[property]}`})
                        }
                        this.loading = false   
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        this.loading = false                      
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        this.loading = false
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                        this.loading = false
                    }
                }else{
                    this.loading = false
                }
            },

            Reset (){
                this.$refs.form.reset()
                this.modeloCrearVehiculo.fechaCompra = new Date().toISOString().substr(0, 10)
            },

        //obtenemos tipos de vehiuclos, marca, proveedores
            async ObtenerTipoVehiculo(){
                const response = await this.$store.dispatch('AllTypesOfVehicules')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayTiposVehiculos = response.data            
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },

            async ObtenerMarcas (){
                const response = await this.$store.dispatch('getAllBrands')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayMarcas = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },

            async ObtenerProveedores (){
                const response = await this.$store.dispatch('ObtenerTodosProveedores')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayProveedores = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },
        //Actualizamos el vehiculo
        async GuardarCambios(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            if(this.fechaCompra === undefined){
                this.modeloCrearVehiculo.fechaCompra = this.validarfechaobtenida
            }else if(this.CambioFecha === true){
                this.modeloCrearVehiculo.fechaCompra = this.fechaCompra                    
            }         

            const response = await this.$store.dispatch('ActualizarVehiculo', this.modeloCrearVehiculo)
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }             
            if(response.status === 200){
                this.alertError= false
                this.TodosVehiculos()
                showMessageSuccess('¡BIEN!', 'Vehículo actualizado correctamente')
                this.CambioFecha = false
                this.loading = false        
                this.CerrarDialogoCrearVehiculo()
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false                        
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false        
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false        
            }
        },

    },
}
</script>