<template>
    <div >
        <v-dialog
            v-model="AgregarNuevoItemASolicitud"
            scrollable 
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-file-import</v-icon>
                    <v-toolbar-title class="TituloDialogo">Agregar Nueva Maquinaria</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="AgregarSolicitudHerramienta()"
                                :loading="loading" :disabled="loading"                        
                            >Guardar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Agregar Nueva Maquinaria a la Solicitud</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="cerrar()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar</span>
                    </v-tooltip>                    
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Tipo Maquinaria:</strong> {{datosMaquinaria.tipoMaquinaria}}</span> <br>
                    <span class="fuenteTitulo"><strong>Maquinaria:</strong> {{datosMaquinaria.nombreMaquinaria}}</span> <br>
                    <span class="fuenteTitulo"><strong>Marca Maquinaria:</strong> {{datosMaquinaria.marcaMaquinaria}}</span> <br>
                </div>
                <hr>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    props:{
        AgregarNuevoItemASolicitud: Boolean,
        datosMaquinaria: Object,
        idMaquinaria: Number,
        ProyectoSolicitudId: Number,
    },
    data() {
        return {
            alertError:false,
            loading:false,
        }
    },

    methods: {
        async AgregarSolicitudHerramienta(){
                this.loading = true
                var maquinaria = {idMaquinaria: this.idMaquinaria}
                var data = {solicitudProyectoId: this.ProyectoSolicitudId, maquinaria: maquinaria}
                const response = await this.$store.dispatch('AgregarNuevoItemASolicitud', data)
                if(response.status === 200){
                    this.$emit('ObtenerListadoDeSolicitudesDeMaquinarias')
                    //this.$emit('CerrarModalMaquinariaPorSolicitud')
                    showMessageSuccess('BIEN!','Nueva Maquinaria Agregada a la Solicitud Correctamente.')
                    this.loading = false
                    this.cerrar()
                }else{
                    this.ControlladorExcepciones(response)
                }                                                                        
        },
        cerrar(){
            this.$emit('cerrar')
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http        
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                showMessageError('Oops!', response.data.errors[0])
                this.loading = false                      
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },

    },
}
</script>