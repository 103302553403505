<template>
    <div>
        <v-dialog
            v-model="AbrirTimeLine"
            fullscreen scrollable
        >
            <v-card class="FuenteTimeLine">
                <v-card-title class="AppBar" dark elevation="0" style="color: #ffffff">
                    <v-toolbar-title >
                        <v-icon large left color="white">fas fa-exchange-alt</v-icon>Movimientos de Herramienta: {{NombreComponente}}
                    </v-toolbar-title>
                    <v-toolbar-title class="ml-5">
                        Total de Movimientos: {{ArrayMovimientosComponente.length}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarModalTimeLine()">Salir
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Salir</span>
                    </v-tooltip>

                </v-card-title>
                <v-card-text>
                    
                    <v-timeline dense-top class="mx-10">
                        <v-timeline-item fill-dot
                            v-for="(item, index) in ArrayMovimientosComponente" :key="index"
                        >
                            <template v-slot:icon>
                                <v-icon color="white">
                                    mdi-text-box-check
                                </v-icon>
                            </template>
                            <template v-slot:opposite>
                                <span>Fecha: {{item.fecha}}</span>
                            </template>                    
                            <v-card>
                                <v-card-title :class="item.tipoMovimientoInventario.color">
                                    <v-icon dark size="30" class="mr-4" >fas fa-fingerprint</v-icon>
                                    <h4 class="white--text" > ID del movimiento: {{item.mov_inventarioHerramientasId}}</h4>
                                </v-card-title>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="10" class="mt-0 pt-0 mb-0 pb-0"> 
                                            <v-list dense>
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-arrow-left-right-bold</v-icon>
                                                    <v-list-item-content>Tipo de Movimiento: </v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        <v-chip >{{item.tipoMovimientoInventario.nombre}}</v-chip>                                                    
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-account</v-icon>
                                                    <v-list-item-content>Usuario: </v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.usuario}}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="item.proveedorOTienda != null">
                                                    <v-icon left color="black">mdi-home</v-icon>
                                                    <v-list-item-content>Proveedor o Tienda: </v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.proveedorOTienda}}
                                                    </v-list-item-content>
                                                </v-list-item> <v-divider></v-divider>
                                                <v-list-item v-if="item.existenciaActual !=0">
                                                    <v-icon left color="black">mdi-clipboard-list-outline</v-icon>
                                                    <v-list-item-content>Existencia antes del movimiento:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.existenciaActual}}
                                                    </v-list-item-content>
                                                </v-list-item>                                            
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-numeric</v-icon>
                                                    <v-list-item-content>Cantidad:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.cantidad}}
                                                    </v-list-item-content>
                                                </v-list-item>                                            
                                                <v-list-item v-if="item.precioUnitario !=0">
                                                    <v-icon left color="black">mdi-numeric</v-icon>
                                                    <v-list-item-content>Precio Unitario:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.precioUnitario}}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="item.precioTotal !=0">
                                                    <v-icon left color="black">mdi-numeric</v-icon>
                                                    <v-list-item-content>Precio Total:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.precioTotal}}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-icon left color="black">mdi-card-text-outline</v-icon>
                                                    <v-list-item-content>Descripción:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.descripcion}}
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item >
                                                    <v-icon left color="black">mdi-chat</v-icon>
                                                    <v-list-item-content>Observación:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{item.observacion}}
                                                    </v-list-item-content>
                                                </v-list-item>                                                
                                            </v-list>   
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    props:{
        AbrirTimeLine: Boolean,
        ArrayMovimientosComponente: Array,
        NombreComponente: String,
    },

    data() {
        return {
            contador: 0,
        }
    },

    methods: {
        CerrarModalTimeLine(){
            this.$emit('CerrarModalTimeLine')
        },                
    },
}
</script>
<style>
    .FuenteTimeLine {
        font-family: 'PT Serif';
    }
    .TamañoLetraEncabezado {
        font-size: 10px;        
    }
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
</style>