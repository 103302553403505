import Vue from 'vue'

const store = {
    actions: {
        // // se realiza la busqueda por rubro y criterio
        // Reportes : async ({}, data ) => {
        //     try{
        //         const response = await Vue.Axios.get('/Reportes/buscador/rubro/'+data.rubro+'/criterio/'+data.criterio)
        //         return response
        //     }catch(e){
        //         return e.response
        //     }
        // }, 
    }
}

export default store