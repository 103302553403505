<template>
  <v-data-table :headers="headers" :items="Materiales" sort-by="nombre"
    class="elevation-1"
    :loading="loanding"
    :footer-props="{itemsPerPageText: 'Filas por página'}"
    loading-text="Cargando datos ... por favor espere"
  >
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>Catalogo de Materiales o insumos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="#0019d8" >
            <template v-slot:activator="{ on }">
                <v-btn color="#0019d8" rounded outlined dark @click="getAllMateriales()"
                    v-on="on" class="TamañoLetraBoton mx-2"
                > 
                    <v-icon >fas fa-sync-alt</v-icon> 
                </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip> 
        <v-btn outlined color="primary" dark class="mb-2" @click="nuevaMaterial" v-if="permisos.crear">
            <i class="fas fa-plus"></i>
            Nuevo
        </v-btn>
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <alertErrorGeneric :alertErrorPost="alertErrorPost" :arrayErrorPost="arrayErrorPost" />
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                      <v-text-field
                      v-model="modelMaterial.nombre"
                      label="Nombre"
                      ></v-text-field>
                  </v-col>
                  <!--
                  <v-col cols="12" sm="6" md="12" >
                    <v-select 
                        :items="marcasMateriales"
                        item-text="nombre"
                        item-value="idMarcaMateriaPrima"
                        v-model="modelMaterial.idMarcaMateriaPrima"
                        label="Seleccione marca"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="12" >
                    <v-select 
                        :items="UnidadesDeMedida"
                        item-text="nombre"
                        item-value="idUnidadDeMedida"
                        v-model="modelMaterial.idUnidadDeMedida"
                        label="Seleccione unidad de medida"
                    ></v-select>
                  </v-col>
                  -->
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" outlined text @click="close">
                <v-icon class="ma-2">far fa-window-close</v-icon>
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" outlined text @click="saveMaterial" :loading="loading3">
                    Guardar
                    <v-icon class="ma-2 ">far fa-save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
        <v-tooltip left color="#d69b01" v-if="permisos.editar" >
            <template v-slot:activator="{ on }">
                <v-btn small class="ml-2" icon color="#d69b01" v-on="on" @click="editItem(item)">
                    <v-icon small>fas fa-edit</v-icon>
                </v-btn>                        
            </template>
            <span class="fontButton">Editar</span>
        </v-tooltip> 
    </template>
  </v-data-table>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../services/sweetAlert'
import alertErrorGeneric from '../../components/alertError.vue'
  export default {
    components:{
      alertErrorGeneric
    },
    data: () => ({
        loading3:false,
        UnidadesDeMedida: [],
        marcasMateriales: [],
        loanding:false,
        dialog: false,
        alertErrorPost:false,
        arrayErrorPost:[],
        dialogDelete: false,
        headers: [
            { text: 'Id', align: 'start', sortable: false, value: 'catalogoMaterialesId'},
            { text: 'Nombre', value: 'nombre' },
            //{ text: 'Marca', value: 'marcaMateriaPrima.nombre' },
            //{ text: 'Unidad de medida', value: 'unidadDeMedida.nombre' },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        Materiales: [],
        editedIndex: -1,
        editedItem: {
        },
        defaultItem: {
        },
        modelMaterial:{
            catalogoMaterialesId:0,
            Nombre:'',
            idMarcaMateriaPrima:0,
            idUnidadDeMedida:0
        },
        permisos:{
          crear:false,
          editar:false,
          eliminar:false
        }
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Material o insumo' : 'Editar Material o insumo'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.getAllMateriales()
      var user = this.$store.getters['oidcStore/'+'oidcUser']
      this.validarPermisos(user)
    },

    methods: {
      async validarPermisos(usr){
        if(usr.role ==='Administrador'){
          this.permisos.crear=true
          this.permisos.editar=true
          this.permisos.eliminar=true
        }else{
          var url='catalogoMateriales'
          const response  = await this.$store.dispatch('PermisosSubMenu', url)
          if(response.status == 200){
            this.permisos=response.data
          }
        }
      },
        reset(){
            this.modelMaterial={
                CatalogoMaterialId:0,
                Nombre:'',
                idTipoMaterial:0,
                idMarcaMaterial:0
            }
            this.alertErrorPost=false
        },
        showSuccess(msg){
            this.dialog=false
            this.getAllMateriales()
            showMessageSuccess(msg)
        },
        save () {
        if (this.editedIndex > -1) {
          Object.assign(this.Materiales[this.editedIndex], this.editedItem)
        } else {
          this.Materiales.push(this.editedItem)
        }
        this.close()
      },
        async saveMaterial(){
          this.loading3=true
            if(this.editedIndex > -1){
                const response = await this.$store.dispatch('updateCatalogoMaterial', this.modelMaterial)
                if(response === undefined){
                  this.loading3=false
                  showMessageError('Ocurrio un error, intente de nuevo')
                  return
                }
                if(response.status === 200){
                    this.showSuccess('Registro actualizado corrrectamente')
                }else{
                    this.parseErrors(response.data.erros)
                }
            }else{
                const response = await this.$store.dispatch('newCatalogoMaterial', this.modelMaterial)
                if(response === undefined){
                  this.loading3=false
                  showMessageError('Ocurrio un error, intente de nuevo')
                  return
                }
                if(response.status === 200){
                    this.showSuccess('Registro exitoso')
                }else{
                    this.parseErrors(response.data.errors)
                }
            }
            this.loading3=false
        },
        parseErrors(erros){
            this.alertErrorPost=true
            this.arrayErrorPost=[]
            var object = erros
            for (var property in object){
                this.arrayErrorPost.push({description: `${object[property]}`})
            }
        },
        async ObtenerMarcasMateriales(){
            const response = await this.$store.dispatch('getAllRawMaterial')
            if(response.status === 200){
                this.marcasMateriales = response.data
            }
        },
        async getAllUnidadesDeMedida (){
            const response = await this.$store.dispatch('getAllUnitsMeasure')
            if(response.status === 200){
                this.UnidadesDeMedida=response.data
            }
        },
        async getAllMateriales(){
            this.loanding=true
            this.Materiales=[]
            var response = await this.$store.dispatch('getAllCatalogoMateriales');
            if(response.status === 200){
                this.Materiales = response.data
            }else{
                showMessageError("Ocurrio un error intentando recuperar el catálogo de herrameintas, intente de nuevo")
            }
            this.loanding=false
        },
        nuevaMaterial(){
            this.reset()
            this.dialog=true
            this.loading3=false
            this.ObtenerMarcasMateriales()
            this.getAllUnidadesDeMedida()
        },
      editItem (item) {
            this.ObtenerMarcasMateriales()
            this.getAllUnidadesDeMedida()
            this.editedIndex = this.Materiales.indexOf(item)
            this.modelMaterial = Object.assign({}, item)
            this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.Materiales.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.Materiales.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }
    },
  }
</script>