<template>
    <div class="text-center">
        <!-- <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>             -->
        <v-toolbar-title class="tituloMaquinaria">
            <v-icon>fas fa-snowplow</v-icon>
            Solicitudes de Maquinarias</v-toolbar-title>        
        <v-container fluid>
            <v-row>
                <v-col class="d-flex" cols="12" sm="6" md="4">
                    <v-select outlined class="ml-6" 
                        :items = "ArraySucursales"
                        item-text ="nombre"
                        item-value="sucursalId"
                        v-model = "sucursalId"
                        label="Seleccione sucursal"
                    ></v-select>   
                </v-col>
            </v-row>
        </v-container>
        <div style="paddingLeft: 20px">
            <hr class="mt-2">
                <v-radio-group class="text-center" v-model="filtrarEstadoSolicitudesMaquinarias" row @change="ObtenerListadoDeSolicitudesDeMaquinarias()" :disabled="desactivarOpciones">
                    <v-icon left color="blue">fas fa-sync-alt</v-icon>
                    <v-radio label="En Proceso" value="1"></v-radio>
                    <v-divider vertical class="mr-4"></v-divider>

                    <v-icon left color="green">fas fa-check</v-icon>
                    <v-radio label="Aprobado" value="2"></v-radio>
                    <v-divider vertical class="mx-4"></v-divider>

                    <v-icon left color="red">fas fa-times-circle</v-icon>
                    <v-radio label="Rechazado" value="3"></v-radio>
                </v-radio-group>                
            <hr>            
        </div>
        <v-toolbar title elevation="1">
            <v-chip color="blue" text-color="white" v-if="filtrarEstadoSolicitudesMaquinarias === '1'">
                <v-icon left>fas fa-sync-alt</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes {{TituloOpcion}}</v-toolbar-title>
            </v-chip>            
            <v-chip color="green" text-color="white" v-if="filtrarEstadoSolicitudesMaquinarias === '2'">
                <v-icon left>fas fa-check</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes {{TituloOpcion}}</v-toolbar-title>
            </v-chip>            
            <v-chip color="red" text-color="white" v-if="filtrarEstadoSolicitudesMaquinarias === '3'">
                <v-icon left>fas fa-times-circle</v-icon>
                <v-toolbar-title class="tituloMaquinaria">Solicitudes {{TituloOpcion}}</v-toolbar-title>
            </v-chip>            
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>      
            <v-text-field outlined class=" pr-4"
                label="Buscar Registro"
                v-model="buscarRegistro"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>     
            <BotonActualizarTabla @Actualizartabla="ObtenerListadoDeSolicitudesDeMaquinarias" :active="desactivarOpciones" />            
        </v-toolbar>        
        <v-alert :value="alertErrorPost"
                type="error"
                align="left"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>                                                                                        
        <v-data-table
            :headers="EncabezadoTablaSolicitudes"
            :items="ArrayListadoDeSolicitudDeMaquinarias"
            class="elevation-1"
            :search="buscarRegistro"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :loading="loading"
            loading-text="Cargando Datos..."             
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip left color="#4baef3">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#4baef3" v-on="on" @click="AbrirModalMaquinariasDeSolicitud(item)">
                            <v-icon small>fas fa-tools</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver maquinarias de la solicitud</span>
                </v-tooltip>   
                <GenerarPDFDeSolicitud  :filtrarEstadoSolicitudes="filtrarEstadoSolicitudesMaquinarias" :TituloComponente="TituloComponente"
                    :DatosSolicitud="item"
                 />
                <v-tooltip left color="#d80000" v-if="filtrarEstadoSolicitudesMaquinarias==='1'">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#d80000" v-on="on" @click="AbrirFormularioRechazarSolicitudHerramienta(item)" >
                            <v-icon small>fas fa-times-circle</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Rechazar Solicitud</span>
                </v-tooltip>     
                <v-tooltip left color="#000000" v-if="filtrarEstadoSolicitudesMaquinarias==='3'">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirDialogoVerMotivoRechazoSolicitud(item)" >
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver Motivo de Rechazo</span>
                </v-tooltip>                           
            </template>        
            <template v-slot:no-data v-if="ArrayListadoDeSolicitudDeMaquinarias.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    No existen solicitudes registradas
                </v-alert>
            </template>                                     
        </v-data-table>                
        <TablaVerTodasLasMaquinariasPorSolicitud 
            :AbrirTablaDeMaquinariasPorSolicitud="AbrirTablaDeMaquinariasPorSolicitud" @CerrarModalMaquinariasDeSolicitud="CerrarModalMaquinariasDeSolicitud"
            :MaquinariasBySolicitud="MaquinariasBySolicitud" :codigoSolicitud="codigoSolicitud" :DatosSolicitud="DatosSolicitud" :filtrarEstadoSolicitudesMaquinarias="filtrarEstadoSolicitudesMaquinarias"
            @ObtenerListadoDeSolicitudesDeMaquinarias="ObtenerListadoDeSolicitudesDeMaquinarias"
        />
        <RechazarSolicitudMaquinaria 
            :AbrirModalRechazarSolicitudMaquinaria="AbrirModalRechazarSolicitudMaquinaria" @CerrarFormularioRechazarSolicitudMaquinaria="CerrarFormularioRechazarSolicitudMaquinaria"
            :ObtenerSolicitudDeMaquinaria="ObtenerSolicitudDeMaquinaria" @ObtenerListadoDeSolicitudesDeMaquinarias="ObtenerListadoDeSolicitudesDeMaquinarias"
        />        
        <VerMotivoRechazoSolicitud 
            :DatosSolictudRechazado="DatosSolictudRechazado" :AbrirModalVerMotivoRechazo="AbrirModalVerMotivoRechazo" @CerrarDialogoVerMotivoRechazoSolicitud="CerrarDialogoVerMotivoRechazoSolicitud"
        />        
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../BotonActualizarTabla'
import TablaVerTodasLasMaquinariasPorSolicitud from '../../../components/bodega/SolicitudesDeMaquinaria/TablaVerTodasLasMaquinariasPorSolicitud'
import RechazarSolicitudMaquinaria from './RechazarSolicitudMaquinaria'
import VerMotivoRechazoSolicitud from '../../../views/Bodegas/VerMotivoRechazoSolicitud'
import GenerarPDFDeSolicitud from '../GenerarPDFDeSolicitud'

export default {
    components:{
        BotonActualizarTabla,
        TablaVerTodasLasMaquinariasPorSolicitud,
        RechazarSolicitudMaquinaria,
        VerMotivoRechazoSolicitud,
        GenerarPDFDeSolicitud,
    },

    data() {
        return {
            TituloComponente:'Maquinaria',

            loading: false,
            alertError:false,
            buscarRegistro:'',
            filtrarEstadoSolicitudesMaquinarias: '1',
            ArrayListadoDeSolicitudDeMaquinarias: [],
            EncabezadoTablaListadoSolicitudMaquinariasEnProceso:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Encargado', value: 'proyecto.nombreEncargado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'sucursal.bodegas[0].nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaListadoSolicitudMaquinariasAprobado:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Encargado', value: 'proyecto.nombreEncargado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'sucursal.bodegas[0].nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Aprobación', value: 'fechaAprobacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaListadoSolicitudMaquinariasRechazados:[
                {text: 'Código Solicitud', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Encargado', value: 'proyecto.nombreEncargado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Sucursal', value: 'sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'sucursal.bodegas[0].nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
                {text: 'Fecha de registro', value: 'fechA_CREACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Rechazo', value: 'fechA_MODIFICACION', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            EncabezadoTablaSolicitudes:[],
            // variables para autorizacion de solicitud de maquinarias
            ArrayTodasLasSolicitudesAutorizar: [],

            //rechazar solicitud de maquinaria
            AbrirModalRechazarSolicitudMaquinaria:false,
            ObtenerSolicitudDeMaquinaria:{
                codigo:'',
                proyecto:'',
                proyectoSolicitudId:'',
                sucursal:'',
                motivo:'',
            },                 
            TituloOpcion:'',
            // ver motivo de rechazo de la solicitud
            DatosSolictudRechazado:{},
            AbrirModalVerMotivoRechazo:false,

            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],          
            
            // abrimos el modal donde se mostraran todas las maquinarias por solicitud
            MaquinariasBySolicitud: [],
            AbrirTablaDeMaquinariasPorSolicitud: false,
            codigoSolicitud: "",
            DatosSolicitud: {proyecto:'', sucursal:'', idProyecto:''},
            //elegir sucursal
            ArraySucursales:[],
            sucursalId:"",
            desactivarOpciones:true,
        }
    },
    watch: {
        filtrarEstadoSolicitudesMaquinarias(){
            this.EstadoOpcionSolicitud()
        },
        sucursalId(){
            this.ObtenerListadoDeSolicitudesDeMaquinarias();
            this.desactivarOpciones= false
        }
    },
    created() {        
        this.ObtenerSucursales()
        this.EstadoOpcionSolicitud()
    },

    methods: {
        //-----------------------------------------------------------------------------
        EstadoOpcionSolicitud(){
            if(this.filtrarEstadoSolicitudesMaquinarias === '1'){
                this.TituloOpcion = 'en proceso'
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMaquinariasEnProceso
            }else if(this.filtrarEstadoSolicitudesMaquinarias === '2'){
                this.TituloOpcion = 'aprobadas'
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMaquinariasAprobado
            }else if(this.filtrarEstadoSolicitudesMaquinarias === '3'){
                this.TituloOpcion = 'rechazadas'
                this.EncabezadoTablaSolicitudes = this.EncabezadoTablaListadoSolicitudMaquinariasRechazados
            }        
        },

        async ObtenerListadoDeSolicitudesDeMaquinarias (){
            this.loading = true
            var data = {estado: this.filtrarEstadoSolicitudesMaquinarias, sucursal: this.sucursalId}
            const response = await this.$store.dispatch('ListadoSolicitudesMaquinarias', data)
            if(response.status === 200){
                this.ArrayListadoDeSolicitudDeMaquinarias = response.data
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false     
                this.ObtenerListadoDeSolicitudesDeMaquinarias()
                setTimeout(() => {
                    this.alertErrorPost=false
                }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },

        //mostramos el dialogo con todas las maquinarias de cada solicitud
        AbrirModalMaquinariasDeSolicitud(datos){
            this.codigoSolicitud = datos.codigo
            this.MaquinariasBySolicitud = datos.sol_proyectoMaquinarias
            this.DatosSolicitud.idProyecto = datos.solicitudProyectoId
            this.DatosSolicitud.proyecto = datos.proyecto.nombre
            this.DatosSolicitud.sucursal = datos.sucursal.nombre
            this.AbrirTablaDeMaquinariasPorSolicitud = true
        },

        CerrarModalMaquinariasDeSolicitud(){
            this.AbrirTablaDeMaquinariasPorSolicitud = false
        },

        AbrirFormularioRechazarSolicitudHerramienta(item){
            this.ObtenerSolicitudDeMaquinaria.codigo = item.codigo
            this.ObtenerSolicitudDeMaquinaria.proyectoSolicitudId = item.solicitudProyectoId
            this.ObtenerSolicitudDeMaquinaria.proyecto = item.proyecto.nombre
            this.ObtenerSolicitudDeMaquinaria.sucursal = item.sucursal.nombre
            this.AbrirModalRechazarSolicitudMaquinaria = true
        },
        CerrarFormularioRechazarSolicitudMaquinaria(){
            this.AbrirModalRechazarSolicitudMaquinaria = false
        },
        async ObtenerSucursales(){
            const response = await this.$store.dispatch('misSucursales')
            this.ArraySucursales = response.data
            // console.log('misSucursales: ', response.data)
        },        
        //-----------------------------------------------------------------------------
        AbrirDialogoVerMotivoRechazoSolicitud(datos){
            this.DatosSolictudRechazado = datos
            this.AbrirModalVerMotivoRechazo = true
        },

        CerrarDialogoVerMotivoRechazoSolicitud(){
            this.AbrirModalVerMotivoRechazo = false
        },        
    },
}
</script>