import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '../config/oidc'

Vue.use(Vuex)
    //store app
import {
    storeUsers,
    storeLocalBusinesses,
    storeProyectos,
    storeTipoLiecencas,
    storeMenu,
    storeCatalogoMenus,
    storeMaquinaria,
    storeVehiculos,
    storeHerramientas,
    storeMaterialConstruccion,
    storeMarcas,
    storeTipos,
    storeBodegas,
    storeReporte,
} from '../storeApp'

export default new Vuex.Store({
    state: {
        version: '1.0.0'
    },
    modules: {
        //add here all modules of store
        storeUsers,
        storeLocalBusinesses,
        storeProyectos,
        storeTipoLiecencas,
        storeMenu,
        storeCatalogoMenus,
        storeMaquinaria,
        storeVehiculos,
        storeHerramientas,
        storeMaterialConstruccion,
        storeMarcas,
        storeTipos,
        storeBodegas,
        storeReporte,

        //
        oidcStore: vuexOidcCreateStoreModule(
            oidcSettings,
            // Optional OIDC store settings
            { namespaced: true },
            // Optional OIDC event listeners
            {
                userLoaded: (user) => {
                    console.log('OIDC user is loaded:', user)
                    Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.id_token;
                    Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST
                },
                userUnloaded: () => console.log('OIDC user is unloaded'),
                accessTokenExpiring: () => {
                    console.log('Access token will expire')
                        //alert('Sesion vencida, que hago ?')
                },
                accessTokenExpired: () => {
                    console.log('Access token did expire')
                    this.$router.push('/autenticate')
                },
                silentRenewError: () => console.log('OIDC user is unloaded'),
                userSignedOut: () => console.log('OIDC user is signed out'),
                oidcError: (payload) => console.log(`An error occured at ${payload.context}:`, payload.error)
            }
        )
    }
})