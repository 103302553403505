<template>
    <v-dialog v-model="AbrirTablaDeMaterialesPorSolicitud" fullscreen scrollable persistent :overlay="false" transition="dialog-transition">
        <v-card>
            
            <v-card-title class="AppBar" dark elevation="0" style="color: #ffffff">
                Materiales o Insumos de la solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalMaterialesDeSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <!-- <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>             -->
                    <div class="my-5">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>
                    <v-toolbar title elevation="1">
                        <v-chip color="black" outlined>
                            <v-icon left>fas fa-dolly</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Materiales o Insumos</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>      
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table
                        :headers="EncabezadoTablaListadoSolicitudHerramientasEnProceso"
                        :items="MaterialesBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."             
                        item-key="sol_SucursalHerramientaId"
                    >
                        <template v-if="filtrarEstadoSolicitudMateriaPrima=='1'" v-slot:item.action="{ item }">
                            <v-tooltip left color="#d80000" >
                                <template v-slot:activator="{ on }">
                                    <v-btn outlined icon x-small class="ml-2" dark fab color="#d80000" v-on="on" @click="AbrirMensajeEliminarItem(item)" >
                                        <v-icon x-small>fas fa-times-circle</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Eliminar Material</span>
                            </v-tooltip>                                
                            <v-tooltip left color="#d69b01" >
                                <template v-slot:activator="{ on }">
                                    <v-btn outlined icon x-small class="ml-2" dark fab color="#d69b01" v-on="on" @click="AbrirActualizarCantidadMaterial(item)" >
                                        <v-icon x-small>fas fa-edit</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Editar Cantidad Material</span>
                            </v-tooltip>                                
                        </template>                                                
                        <template v-slot:no-data v-if="MaterialesBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen solicitudes registradas
                            </v-alert>
                        </template>                                     
                    </v-data-table>                       
                </div>
                <div v-if="filtrarEstadoSolicitudMateriaPrima=='1'" class="pt-10 tituloMaquinaria">
                    <v-toolbar title elevation="1">
                        <v-chip color="black" outlined>
                            <v-icon left>fas fa-dolly</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Materiales disponibles</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistroMaterialDisponible"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-toolbar>        
                    <v-data-table
                        :headers="EncabezadoTablaMateriales"
                        :items="ArrayTodasMateriales"
                        item-key="idMateriaPrima"
                        class="elevation-1"
                        :search="buscarRegistroMaterialDisponible"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                    >
                        <template v-slot:no-data v-if="ArrayTodasMateriales.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                            >
                                No existen solicitudes registradas
                            </v-alert>
                        </template>                    
                        <template v-slot:item.action="{ item }">
                            <v-tooltip left color="#000000">
                                <template v-slot:activator="{ on }">
                                    <v-btn outlined icon x-small class="ml-2" dark fab color="#000000" v-on="on" @click="AgregarNuevoMaterialASolicitud(item)" >
                                        <v-icon small>fas fa-plus-circle</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Agregar Nuevo Material a Solicitud</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>                                        
                </div>
            </v-card-text>
        </v-card>
        <ComponentNuevoItemSolicitud :AgregarNuevoItemASolicitud="AgregarNuevoItemASolicitud" :datosMaterial="datosMaterial"
            @cerrar="cerrarAgregarNuevoMaterialSolicitud" :idMateriaPrima="idMateriaPrima" :ProyectoSolicitudId="ProyectoSolicitudId"
            @CerrarModalMaterialesDeSolicitud="CerrarModalMaterialesDeSolicitud" @ObtenerListadoDeSolicitudesDeMateriasPrimas="ObtenerListadoDeSolicitudesDeMateriasPrimas"
        />        
        <ComponentEditarCantidadMaterialItemSolicitud :ActualizarCantidadMaterialSolicitud="ActualizarCantidadMaterialSolicitud" :datosMaterial="datosMaterial"
            @cerrar="cerrar" :ProyectoSolicitudId="ProyectoSolicitudId" :sol_ProyectoMaterialId="sol_ProyectoMaterialId" @ObtenerListadoDeSolicitudesDeMateriasPrimas="ObtenerListadoDeSolicitudesDeMateriasPrimas"
            @CerrarModalMaterialesDeSolicitud="CerrarModalMaterialesDeSolicitud"
        />        
        <ComponentEliminarItem 
            :DialogoEliminarItem="DialogoEliminarItem" :TituloEliminarItem="TituloEliminarItem" :LoadingEliminarItem="LoadingEliminarItem"
            @CerrarMensajeEliminarItem="CerrarMensajeEliminarItem" @EliminarItemDeLaSolicitud="EliminarItemDeLaSolicitud" 
        />        
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../../views/BotonActualizarTabla'
import ComponentNuevoItemSolicitud from '../../../components/bodega/AgregarNuevoItemASolicitudMateriales'
import ComponentEditarCantidadMaterialItemSolicitud from '../../../components/bodega/ActualizarItemDeLaSolicitudMaterial'
import ComponentEliminarItem from '../../../components/bodega/QuitarItemDeLaSolicitud'


export default {
    props:{
        AbrirTablaDeMaterialesPorSolicitud: Boolean,
        MaterialesBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,
        proyecto: Number,
        sucursal:Number,
        bodega:Number,
        ProyectoSolicitudId: Number,
        filtrarEstadoSolicitudMateriaPrima: String
    },
    components:{
        BotonActualizarTabla,
        ComponentNuevoItemSolicitud,
        ComponentEditarCantidadMaterialItemSolicitud,
        ComponentEliminarItem,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            ArrayHerramientasPorSolicitudEnviada: [],
            EncabezadoTablaListadoSolicitudHerramientasEnProceso:[
                {text: 'ID Material', value: 'materiaPrima.idMateriaPrima', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Material o Insumo', value: 'materiaPrima.catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca Material', value: 'materiaPrima.marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu', width: '200px'},
                {text: 'Cantidad Autorizada', value: 'cantidadAprobada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Fecha Creación', value: 'fecha_creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'materiaPrima.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
                {text: 'Acción', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
            ],
            TituloOpcion:'',
            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],            
            // materiales disponibles
            buscarRegistroMaterialDisponible:'',
            ArrayTodasMateriales: [],
            EncabezadoTablaMateriales:[
                {text: 'Id Material o Insumo', value:'catalogoMateriales.catalogoMaterialesId', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Material o Insumo', value:'catalogoMateriales.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value:'marcaMateriaPrima.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Unidad de Medida', value:'unidadDeMedida.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 1', value: 'ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 2', value: 'ubicacion2', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia', value: 'existencia', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],          
            cantidadSolicitada:0,
            idMateriaPrima:0,
            AgregarNuevoItemASolicitud: false,
            TituloDialogo: '',
            datosMaterial:{
                nombreMaterial:'',
                unidadMedida:'',
                marcaMaterial:'',
                existencia:0,
                cantidadSolicitada:0,
            },          
            sol_ProyectoMaterialId:0,    
            ActualizarCantidadMaterialSolicitud: false,
            // eliminar item de material
            DialogoEliminarItem: false,
            TituloEliminarItem: '',
            LoadingEliminarItem: false,                        
        }
    },

    watch:{
        AbrirTablaDeMaterialesPorSolicitud(){
            if(this.AbrirTablaDeMaterialesPorSolicitud) this.ObtenerTodasLasMateriales();
        }
    },

    methods: {
        //-----------------------------------------------------------------------------
        async ObtenerTodasLasMateriales (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            this.ArrayTodasMateriales=[]
            var data = {proyecto: this.proyecto, sucursal: this.sucursal, bodega: this.bodega}
            const response = await this.$store.dispatch('TodasMaterialesSolicitud', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayTodasMateriales = response.data
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },        
        //agregamos una nueva materia prima a la solicitud
        async AgregarNuevoMaterialASolicitud(item){
            this.idMateriaPrima = item.idMateriaPrima
            this.datosMaterial.nombreMaterial = item.catalogoMateriales.nombre
            this.datosMaterial.unidadMedida = item.unidadDeMedida.nombre
            this.datosMaterial.marcaMaterial = item.marcaMateriaPrima.nombre
            this.datosMaterial.existencia = item.existencia
            this.AgregarNuevoItemASolicitud = true
        },
        cerrarAgregarNuevoMaterialSolicitud(){
            this.AgregarNuevoItemASolicitud = false
        },        
        //actualizamos la cantidad solicitada de un material de una solicitud
        async AbrirActualizarCantidadMaterial(item){
            this.sol_ProyectoMaterialId = item.sol_ProyectoMaterialId
            this.datosMaterial.nombreMaterial = item.materiaPrima.catalogoMateriales.nombre
            this.datosMaterial.unidadMedida = item.materiaPrima.unidadDeMedida.nombre
            this.datosMaterial.marcaMaterial = item.materiaPrima.marcaMateriaPrima.nombre
            this.datosMaterial.existencia = item.materiaPrima.existencia
            this.datosMaterial.cantidadSolicitada = item.cantidadSolicitada
            console.log('material: ', item)
            this.ActualizarCantidadMaterialSolicitud = true
        },
        cerrar(){
            this.ActualizarCantidadMaterialSolicitud = false
        },        
        //eliminamos un item de la solicitud x
        AbrirMensajeEliminarItem(item){
            this.sol_ProyectoMaterialId = item.sol_ProyectoMaterialId
            this.DialogoEliminarItem = true
            this.TituloEliminarItem = "Material: " + item.materiaPrima.catalogoMateriales.nombre
        },
        CerrarMensajeEliminarItem(){
            this.DialogoEliminarItem = false
        },
        async EliminarItemDeLaSolicitud(){
            this.LoadingEliminarItem = true
            var data = {solicitudProyectoId: this.ProyectoSolicitudId, sol_ProyectoHerramientaId: 0,
                sol_ProyectoMaterialId:this.sol_ProyectoMaterialId, sol_ProyectoVehiculoId:0, sol_ProyectoMaquinariaId:0
            }
            const response = await this.$store.dispatch('DeleteItemRequest', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ObtenerListadoDeSolicitudesDeMateriasPrimas()
                //this.$emit('CerrarModalMaterialesDeSolicitud')
                this.CerrarMensajeEliminarItem()
                showMessageSuccess('BIEN!','Material o Insumo eliminado de la solicitud.')
                this.LoadingEliminarItem = false
            }else{
                this.ControlladorExcepciones(response)
            }                                                        
        },        
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
            this.LoadingEliminarItem = false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                showMessageError('Oops!', response.data.errors[0])            
                this.loading = false     
                this.LoadingEliminarItem = false 
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false           
                this.LoadingEliminarItem = false           
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.LoadingEliminarItem = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.LoadingEliminarItem = false
            }                
        },
                
        CerrarModalMaterialesDeSolicitud(){
            this.$emit('CerrarModalMaterialesDeSolicitud')
        },
        ObtenerListadoDeSolicitudesDeMateriasPrimas(){
            this.$emit('ObtenerListadoDeSolicitudesDeMateriasPrimas')
        }
    },
}
</script>

<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
</style>