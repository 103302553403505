<template>
    <v-dialog
        v-model="DialogoRegistroDeIncidentes"
        fullscreen 
        persistent :overlay="false"
    >
        <v-card>
        <!-- barra principal del dialogo -->
            <v-app-bar flat dark color="#3c8dbc" class="mb-5">
                <v-toolbar-title class="TituloDialogo">Registrar Incidente {{Titulo}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- si es nueva vehiculo a registrar -->
                <v-tooltip bottom color="success" v-if="!EsNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="GuardarRegistroDeIncidente()"
                            :loading="loading" :disabled="loading"                        
                        >Guardar
                            <v-icon right dark>fas fa-save</v-icon>
                        </v-btn>                                    
                    </template>
                    <span>Registrar Incidente</span>
                </v-tooltip>
                <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarDialogo()">Cancelar
                            <v-icon right dark>far fa-window-close</v-icon>
                        </v-btn>                        
                    </template>
                    <span>Cancelar Registro</span>
                </v-tooltip>
            </v-app-bar>
            <v-card-text>
                <v-form
                    v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                >
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>     
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                
                <v-subheader>
                    Incidente de {{Titulo}} {{DataVehiculo.marca + ' ' + DataVehiculo.placa }}
                </v-subheader> <v-divider class="mb-4"></v-divider>
                    <v-row wrap>
                    <!-- primera seccion -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-select outlined
                                :items="ArrayIncidentes"
                                item-value="idTipoIncidente"
                                item-text="nombre"
                                v-model="modeloCrearIncidenteVehiculo.idTIpoIncidente"
                                label="Tipo Incidente"
                            ></v-select>                            
                        </v-col>                    
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-menu
                                v-model="menuFecha"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined
                                        v-model="modeloCrearIncidenteVehiculo.fecha"
                                        label="Fecha"
                                        prepend-inner-icon="far fa-calendar-alt"
                                        readonly                                
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                            <v-date-picker locale="es-GT" v-model="fechaIncidente" @input="menuFecha = false"></v-date-picker>
                            </v-menu>
                        </v-col>          
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-combobox outlined
                                v-model="modeloCrearIncidenteVehiculo.ubicacion"
                                :items="itemsUbicacion"
                                label="Ubicación"
                            ></v-combobox>                            
                        </v-col>                                                      
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined type="number"
                                v-model="modeloCrearIncidenteVehiculo.kilometraje"
                                label="Kilometraje"
                            ></v-text-field>
                        </v-col> 
                    <!-- segunda seccion-->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="modeloCrearIncidenteVehiculo.tallerMecanicoOColaborador"
                                label="Taller/Mecánico/Colaborador"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                            <v-textarea
                                outlined
                                v-model="modeloCrearIncidenteVehiculo.descripcion"
                                label="Descripción"
                                auto-grow
                                rows="1"
                                row-height="15"                                
                            ></v-textarea>
                        </v-col> 
                        <v-col cols="12" lg="5" md="5" sm="6" class="pt-0 pb-0">
                            <v-textarea
                                outlined
                                v-model="modeloCrearIncidenteVehiculo.observacion"
                                label="Observaciones"
                                auto-grow
                                rows="1"
                                row-height="15"                                
                            ></v-textarea>
                        </v-col>                         
                    </v-row>
                </v-form>
                <v-subheader>Insumos</v-subheader> <v-divider class="mb-4"></v-divider>
                    <v-row wrap>
                        <!-- primera seccion -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="getinsumosIncidenteVehiculos.proveedor"
                                label="Proveedor"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="getinsumosIncidenteVehiculos.factura"
                                label="Factura"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-combobox outlined
                                v-model="getinsumosIncidenteVehiculos.bodegaSalio"
                                label="Bodega de donde salió repuesto"
                                :items="itemsBodega"
                            ></v-combobox>                                                        
                        </v-col> 
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="getinsumosIncidenteVehiculos.nombre"
                                label="Insumo" 
                            ></v-text-field>
                        </v-col> 
                        <!-- segunda seccion -->
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="getinsumosIncidenteVehiculos.unidadDeMedida"
                                label="Unidad de Medida" 
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="getinsumosIncidenteVehiculos.cantidad"
                                label="Cantidad" type="number"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">
                            <v-text-field outlined
                                v-model="getinsumosIncidenteVehiculos.precioUnitario"
                                label="Precio Unitario" type="number"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="3" md="3" sm="6" class="pt-0 pb-0">                
                            <v-text-field outlined :disabled="true"
                                v-model="Calcular"
                                label="Precio Total" type="number"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                          <v-toolbar color="primary" dense flat dark>
                            <v-toolbar-title class="tituloTabla">Tabla de Insumos</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn large class="mt-1" text color="white" dark @click="AgregarInsumos()">Agregar Insumo
                                <v-icon right dark small>fas fa-save</v-icon>
                            </v-btn>                                                  
                          </v-toolbar>                            
                            <v-data-table
                                :headers="EncabezadoTablaInsumos"
                                :items="ArrayinsumosIncidenteVehiculos"
                                item-key=""
                                class="elevation-1"
                                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                            >
                            <!-- //quitar insumos} -->
                                <template v-slot:item.action="{ item }">
                                <v-tooltip bottom color="#D80000">
                                    <template v-slot:activator="{ on }">
                                        <v-btn small class="ml-2" dark fab color="#D80000" v-on="on" @click="QuitarInsumo(item)">
                                            <v-icon small>fas fa-trash-alt</v-icon>
                                        </v-btn>                        
                                    </template>
                                    <span class="fontButton">Quitar Insumo</span>
                                </v-tooltip>                                              
                                </template>
                            <!-- mostramos un mensaje si no se encuentran datos en la BD        -->
                                <template v-slot:no-data v-if="ArrayinsumosIncidenteVehiculos.length === 0">
                                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                                    >
                                        No existen insumos registrados
                                    </v-alert>
                                </template>                                                
                            </v-data-table>
                        </v-col> 
                    </v-row>

            </v-card-text>
        </v-card>      
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    props:{
        DialogoRegistroDeIncidentes: Boolean,
        EsNuevo: Boolean,
        Titulo: String,
        DataVehiculo: Object,
    },

    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,

            // items de ubicacion 
            itemsUbicacion: [
                'Predio',
                'Oficinas Centrales',
                'Taller',
            ],            
            // items de campo bodega de insumos 
            itemsBodega: [
                'Predio',
                'Oficinas Centrales',
                'Compra Externa',
            ],            
            loading: false,
            valid: false,
            lazy: false,
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],    
            modeloCrearIncidenteVehiculo: {
                idVehiculo: 0,
                idTIpoIncidente: 0,
                fecha: '',
                ubicacion: '',
                kilometraje: '',
                tallerMecanicoOColaborador:'',
                descripcion:'',
                observacion:'',
                insumosIncidenteVehiculos:[]
            },
            ArrayUnidadesDeMedida: [],
            getinsumosIncidenteVehiculos: {
                factura: '',
                proveedor:'',
                bodegaSalio:'',
                nombre: '',
                unidadDeMedida:'',
                cantidad:'',
                precioUnitario:'',
                precioTotal:'',
            },

            ArrayinsumosIncidenteVehiculos:[],
            fechaIncidente: new Date().toISOString().substr(0, 10),  
            menuFecha: false,
            ArrayIncidentes:[],
            EncabezadoTablaInsumos:[
                {text: 'Proveedor', value: 'proveedor', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Factura', value: 'factura', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega donde Salio', value: 'bodegaSalio', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Insumo', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad Medida', value: 'unidadDeMedida', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Cantidad', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Precio Unitario', value: 'precioUnitario', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Precio Total', value: 'precioTotal', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                
            ],
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },

    created() {
        this.ObtenerTodosIncidentes()
        this.getinsumosIncidenteVehiculos.precioTotal = this.getinsumosIncidenteVehiculos.cantidad * this.getinsumosIncidenteVehiculos.precioUnitario
    },

    computed: {
        Calcular(){
            return this.getinsumosIncidenteVehiculos.precioTotal = this.getinsumosIncidenteVehiculos.cantidad * this.getinsumosIncidenteVehiculos.precioUnitario
        }
    },
    watch:{
        fechaIncidente(val){
            const [year, month, day] = val.split('-')
            this.modeloCrearIncidenteVehiculo.fecha = `${day}/${month}/${year}`
        }
    },
    methods: {
    // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
    ControllerException(){
        this.alertError=true
        this.loading=false
    },                
    //guardamos o cancelamos un incidente
        async GuardarRegistroDeIncidente(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.modeloCrearIncidenteVehiculo.idVehiculo = this.DataVehiculo.idVehiculo
            this.modeloCrearIncidenteVehiculo.insumosIncidenteVehiculos = this.ArrayinsumosIncidenteVehiculos
            if(this.$refs.form.validate() === true){
                const response = await this.$store.dispatch('RegistrarIncidente', this.modeloCrearIncidenteVehiculo)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    showMessageSuccess('¡BIEN!', 'Incidente registrado correctamente')
                    this.loading = false
                    this.CerrarDialogo()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else{
                this.loading = false
            }

        },

        CerrarDialogo(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
            this.ArrayinsumosIncidenteVehiculos = []
            this.Reset()
            this.$emit('CerrarDialogo')
        },

        Reset (){
            this.$refs.form.reset()            
        },

    //obtenemos todos los incidentes para mostrarlo en un select
        async ObtenerTodosIncidentes(){
            const response = await this.$store.dispatch('getAllTypeIncidents')
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }             
            if(response.status === 200){    
                this.alertError= false       
                this.ArrayIncidentes = response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },

    //agregamos insumos para luego guardarlos
        AgregarInsumos(){
            this.ArrayinsumosIncidenteVehiculos.push(this.getinsumosIncidenteVehiculos)            
            this.getinsumosIncidenteVehiculos = {}
        },
        QuitarInsumo(item){
            const index =this.ArrayinsumosIncidenteVehiculos.indexOf(item)
            this.ArrayinsumosIncidenteVehiculos.splice(index,1)
        },
    },
}
</script>