<template>
    <v-layout row justify-center >
        <v-dialog v-model="DialogNuevoInsumoIncidente" persistent max-width="650px" scrollable>
            <v-card class="FuenteLetra">
                <v-app-bar flat dark color="#3c8dbc">
                    <v-toolbar-title class="FuenteLetra">Agregar Insumo Incidente</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn large rounded color="success" :loading="loading" :disabled="loading" class="mx-2 TituloDialogo"
                        @click="GuardarNuevoInsumo()"
                    >
                        Guardar Insumo<v-icon right dark>fas fa-save</v-icon>
                    </v-btn>
                    <v-btn dark large rounded color="error" @click="CerrarDialogoAgregarInsumoIncidente()" class="mx-2 TituloDialogo">
                            Cerrar<v-icon right dark>far fa-window-close</v-icon>
                    </v-btn>                    
                </v-app-bar>

                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                         
                <v-subheader>
                    {{DatosIncidente.nombre}} de {{Titulo}}
                </v-subheader> <v-divider class="mb-4"></v-divider>                    
                <v-card-text>
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- proveedor -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined
                                    label="Proveedor"
                                    prepend-inner-icon="mdi-account"
                                    v-model="modeloAgregarInsumo.proveedor"
                                ></v-text-field>                            
                            </v-col>  
                            <!-- Insumo -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined
                                    label="Insumo"
                                    prepend-inner-icon="mdi-hammer-screwdriver"
                                    v-model="modeloAgregarInsumo.nombre"
                                ></v-text-field>                            
                            </v-col>  
                            <!-- Unidad de Medida -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined
                                    label="Unidad de Medida"
                                    prepend-inner-icon="mdi-hammer-screwdriver"
                                    v-model="modeloAgregarInsumo.unidadDeMedida"
                                ></v-text-field>                            
                            </v-col>  
                            <!-- factura -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined 
                                    label="Factura"
                                    prepend-inner-icon="mdi-receipt"
                                    v-model="modeloAgregarInsumo.factura"
                                ></v-text-field>                            
                            </v-col>  
                            <!-- Bodega donde salio -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-select outlined 
                                    label="Bodega donde salio"
                                    :items="itemsBodega"
                                    item-value="nombre"
                                    item-text="nombre"
                                    prepend-inner-icon="mdi-map-marker"
                                    v-model="modeloAgregarInsumo.bodegaSalio"
                                ></v-select>                            
                            </v-col>                                                                                  
                            <!-- Cantidad -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined type="number"
                                    label="Cantidad"
                                    prepend-inner-icon="mdi-cash"
                                    v-model="modeloAgregarInsumo.cantidad"
                                ></v-text-field>
                            </v-col> 
                            <!-- Precio unitario -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined type="number"
                                    label="Precio Unitario"
                                    prepend-inner-icon="mdi-cash"
                                    v-model="modeloAgregarInsumo.precioUnitario"
                                ></v-text-field>
                            </v-col> 
                        </v-row>
                    </v-form>            
                        <v-row wrap>
                            <!-- Precio Total -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined type="number" :disabled="true"
                                    label="Precio Total"
                                    prepend-inner-icon="mdi-cash"
                                    v-model="CalcularPrecioTotal"
                                ></v-text-field>
                            </v-col> 
                        </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../services/sweetAlert'

export default {
    props: {
        DialogNuevoInsumoIncidente: Boolean,
        Titulo: String,
        modeloAgregarInsumo: Object,
        DatosIncidente: Object,
    },

    data() {
        return {      
            // alerta cuando el api deja de funcionar
            alertError:false,                          

            loading: false,
            valid: false,
            lazy: false,
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],                
            // items de campo bodega de insumos 
            itemsBodega: [
                {nombre: 'Predio'},
                {nombre: 'Oficinas Centrales'},
                {nombre: 'Compra Externa'},
            ],    
            // errores de registros duplicados y vacios
            alertErrorPost:false,
            arrayErrorPost:[],                                
        }
    },

    computed: {
        CalcularPrecioTotal(){
            return this.modeloAgregarInsumo.precioTotal = this.modeloAgregarInsumo.cantidad * this.modeloAgregarInsumo.precioUnitario
        }
    },

    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        async GuardarNuevoInsumo(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.modeloAgregarInsumo.idIncidente = this.DatosIncidente.idIncidente
            if(this.Titulo === 'Vehiculo'){            
                const response = await this.$store.dispatch('RegistrarInsumoIncidenteVehiculo', this.modeloAgregarInsumo)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                } 
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    this.loading = false
                    showMessageSuccess('¡BIEN!','Insumo agregado correctamente')
                    this.CerrarDialogoAgregarInsumoIncidente()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else if(this.Titulo === 'Maquinaria'){
                const response = await this.$store.dispatch('RegistrarInsumoIncidenteMaquinaria', this.modeloAgregarInsumo)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                } 
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    this.loading = false
                    showMessageSuccess('¡BIEN!','Insumo agregado correctamente')
                    this.CerrarDialogoAgregarInsumoIncidente()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                                        
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }
        },
        CerrarDialogoAgregarInsumoIncidente(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
            this.$refs.form.reset()            
            this.$emit('CerrarDialogoAgregarInsumoIncidente')
        }
    },
}
</script>