<template>
    <v-card>
        <v-toolbar-title class="tituloMaquinaria text-center">
            <v-icon color="#1976d2">fas fa-dolly</v-icon>
            Inventario - Solicitudes de Materiales o Insumos</v-toolbar-title>     
        <v-container fluid>
            <v-row align="center">
                <v-col
                    class="d-flex" cols="12" sm="6" md="4"
                >
                    <v-select outlined class="ml-6"
                        :items = "ArrayProyectos"
                        item-text ="nombre"
                        item-value="idproyecto"
                        v-model = "proyecto"
                        label="Seleccionar proyecto o proyecto"
                    ></v-select>   
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="4">
                    <v-select outlined class="ml-6" :disabled="!proyecto"
                        :items = "ArraySucursales"
                        item-text ="nombre"
                        item-value="sucursalId"
                        v-model = "nombreSucursal"
                        label="¿A qué sucursal desea solicitar?"
                        @change="selecSucursal"
                    ></v-select>   
                </v-col>
            </v-row>
        </v-container>
        <v-tabs
            v-model="tab"
            centered
            icons-and-text
            :disabled="true"
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" :disabled="!ActivarMenuTabs">
                Enviar Solicitudes
                <v-icon>mdi-send</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="!ActivarMenuTabsMisSolicitudes">
                Mis Solicitudes
                <v-icon>mdi-format-list-numbered</v-icon>
            </v-tab>
        </v-tabs>
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>    
        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1"
            >
                <v-card flat>
                    <v-card-text>
                        <TablaDeSolicitudesAEnviar :tab="tab" :proyecto="ProyectoId" :sucursal="sucursal" :bodega="bodega" />
                    </v-card-text>        
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-2"
            >
                <v-card flat>
                    <v-card-text>
                        <MisSolicitudesMateriales :tab="tab" :proyecto="ProyectoId" :sucursal="sucursal" :bodega="bodega" />
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>    
    </v-card>
</template>

<script>
import TablaDeSolicitudesAEnviar from './TablaDeSolicitudesAEnviar'
import MisSolicitudesMateriales from './MisSolicitudesMateriales'

    export default {
        props:{

        },

        components: {
            TablaDeSolicitudesAEnviar,
            MisSolicitudesMateriales,
        },

        data () {
            return {
                tab: null,
                ArrayProyectos:[],
                ArraySucursales:[],
                ArrayBodegas:[],
                proyecto:0,
                sucursal:0,
                bodega:0,
                nombreSucursal:'',
                nombreBodega:'',
                ActivarMenuTabs: false,
                ProyectoId:0,
                DesactivarOpciones: false,
                ActivarMenuTabsMisSolicitudes:false,

                alertErrorPost:false,
                arrayErrorPost:[],                 
            }
        },

        watch: {
            tab(){
                if(this.tab==="tab-1") {
                    this.DesactivarOpciones = true
                }
                if(this.tab==="tab-2") {
                    this.DesactivarOpciones = false
                }
            },
            proyecto(){
                if(this.tab==="tab-1" || this.tab==="tab-2" || this.tab==="tab-3" || this.tab === null){
                    this.ArrayProyectos.forEach(x => {
                        if(x.nombre === this.proyecto){
                            this.ProyectoId = x.idProyecto
                            this.DesactivarOpciones=true
                        }
                    })
                }
                this.ActivarMenuTabsMisSolicitudes = true
            }
        },
        mounted() {
            this.ObtenerproyectoDelUsuario()
            this.ObtenerSucursales()
        }, 
        methods:{
            async ObtenerSucursales(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                const response = await this.$store.dispatch('AllSucursales')
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.ArraySucursales = response.data
                }else{
                    this.ControlladorExcepciones(response)
                }                                                
            },
            selecSucursal(){
                this.bodega=0
                this.nombreBodega=''
                this.ArraySucursales.forEach(x=> {
                    if(x.sucursalId == this.nombreSucursal){
                        this.sucursal = x.sucursalId
                        this.ArrayBodegas = x.bodegas
                    }
                })
                if(this.tab === null){
                    this.tab = 'tab-1'
                }
                this.ActivarMenuTabs = true
            },
            // devuelve proyecto del usuario
            async ObtenerproyectoDelUsuario(){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                const response = await this.$store.dispatch('DevuelveProyectoUSuario')
                if(response.status === 200){
                    this.alertErrorPost=false;this.arrayErrorPost=[];
                    this.ArrayProyectos = response.data
                }else{
                    this.ControlladorExcepciones(response)
                }                                
            },
            // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
            ControllerException(){
                this.alertError=true
                this.loading=false
            },                        
            // controlamos todas las excepciones http
            ControlladorExcepciones(response){
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false  
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }                
            }             
        }        
    }
</script>