<template>
    <div>
        <!-- mandamos parametros para abrir el dialogo para crear un nuevo menu -->
        <NuevoMenu 
            @CerrarDialogoCrearNuevoMenu="CerrarDialogoCrearNuevoMenu"
            :OpenDialogNewMenu = "OpenDialogNewMenu"
        />                
        <v-toolbar dense flat class="mt-5">
            <v-toolbar-title class="titulomenu"><strong>Gestión de Menu</strong></v-toolbar-title>
            <v-divider dark inset light vertical></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="indigo">
                <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" color="indigo" dark fab @click="AbrirDialogoCrearNuevoMenu()">
                        <v-icon small>fas fa-plus-circle</v-icon>
                    </v-btn>
                </template>
                <span class="fontTitle">Crear Menu</span>
            </v-tooltip>
        </v-toolbar>
        <v-data-table
            :headers="HeaderMenu"
            :items="AllMenus"
            class="elevation-1 tituloTablaMenu"
            show-expand
            item-key="name"
            hide-default-footer
        >
            <template v-slot:item.action="{  }">                
                <!-- <v-snackbar
                    v-model="snackbarDeleteCategory"
                    :timeout="3000"
                    color="#D80000"
                >
                    Category Deleted Successfully!
                    <v-icon right color="#FFFFFF">fas fa-check</v-icon>
                </v-snackbar>
                <v-tooltip bottom color="#F65A06">
                    <template v-slot:activator="{ on }">
                        <v-btn small dark v-on="on" fab color="#F65A06" @click="OpenDialogCreateProduct(item)">
                            <v-icon small>fas fa-plus-square</v-icon>
                        </v-btn>
                    </template>
                    <span class="fontButton">Create Product</span>
                </v-tooltip> -->
                <!-- boton para eliminar una categoria -->
                <!-- <v-snackbar
                    v-model="snackbarModifyCategory"
                    color="#0019D8"
                    :timeout="3000"
                >
                    Modified Category
                    <v-icon right color="#FFFFFF">fas fa-check</v-icon>
                </v-snackbar>                 -->
                <v-tooltip bottom color="#0019D8">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" fab dark color="#0019D8" v-on="on">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Editar Menu</span>
                </v-tooltip>
                <v-tooltip bottom color="#D80000">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" dark fab color="#D80000" v-on="on" >
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Eliminar Menu</span>
                </v-tooltip>
            </template>
            <!-- <template v-slot:expanded-item="{headers}">
                <td :colspan="headers.length">
                    <ShowAllProducts
                        @GetAllProducts="GetAllProducts"
                        :dessertsProducts="dessertsProducts"
                    />
                </td>
            </template> -->
            <template v-slot:no-data v-if="AllMenus.length === 0">
                <v-progress-circular indeterminate color="#01abe6" v-if="waiting"></v-progress-circular>
                <v-alert dark class="text-xs-center" :value="true" type="warning" v-if="!waiting">No hay datos</v-alert>
            </template>    
        </v-data-table>   
    </div>
</template>

<script>
import {showMessageError,showMessageSuccess} from '../../services/sweetAlert';
import { throws } from 'assert';
import Vue from 'vue'
import store from '@/store'
import NuevoMenu from '../../components/Administracion/Menu/NuevoMenu'

export default {
    components: {
        NuevoMenu
    }, 

    data() {
        return {
            // abrimos el dialogo para crear un nuevo menu
            OpenDialogNewMenu: false,
            // encabezado de la tabla menu
            HeaderMenu: [
                {text: 'Menu', value: 'menu', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaMenu'},                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaMenu'},                
            ],

            // obtenemos todos los menus
            AllMenus: [],
            //mostrar el progresscircular en la tabla cuando no existan registros para mostrar
            waiting:true,
        }
    },

    created() {
        this.GetAllMenu()
    },

    methods: {
        // metodos para abrir el dialogo para crear un nuevo menu
        AbrirDialogoCrearNuevoMenu() {
            this.OpenDialogNewMenu= true
        },
        CerrarDialogoCrearNuevoMenu(){
            this.OpenDialogNewMenu=false
        },

        // obtenemos todos los menus para mostrarlo en la tabla
        async GetAllMenu() {
            this.waiting=true
            const response = await this.$store.dispatch('getAllMenus');
            if(response.status && response.status === 200){
                this.waiting=true
                this.AllMenus= response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }                        
        }
    },
}
</script>

<style>
    .titulomenu{
        font-family: 'PT Serif';
        font-size: 20pt;        
    }
    .tituloTablaMenu {
        font-family: 'PT Serif';
        font-size: 50px;
    }
</style>