<template>
    <v-card>
        <v-card-title class="text-center justify-center py-6">
            <h1 class="font-weight-bold display-2 basil--text">
                {{proyecto.nombre}}
            </h1>
        </v-card-title>
        <v-tabs
            v-model="tab"
            centered
            icons-and-text
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" @change="ObtenerInventarioVehiculosPorProyecto()" :disabled="!ActivarMenuTabs">
                Vehículos
                <v-icon>mdi-car</v-icon>
            </v-tab>

            <v-tab href="#tab-2" @change="ObtenerInventarioMaquinariaPorProyecto()" :disabled="!ActivarMenuTabs">
                Maquinaria
                <v-icon>mdi-dump-truck</v-icon>
            </v-tab>

            <v-tab href="#tab-3" @change="ObtenerInventarioHerramientaPorProyecto()" :disabled="!ActivarMenuTabs">
                Herramientas
                <v-icon>mdi-tools</v-icon>
            </v-tab>

            <v-tab href="#tab-4" @change="ObtenerInventarioMaterialOInsumoPorProyecto()" :disabled="!ActivarMenuTabs">
                Materiales o Insumos
                <v-icon>fas fa-dolly</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1"
            >
                <v-card flat>
                    <v-card-text><TablaVehiculos :tab="tab" :TituloTabla="TituloTabla" :ArrayInventarioVehiculo="ArrayInventarioVehiculo" :loading="loading"
                        @ObtenerInventarioVehiculosPorProyecto="ObtenerInventarioVehiculosPorProyecto" :EstadoPermisoCrear="EstadoPermisoCrear"
                    />
                    </v-card-text>        
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-2"
            >
                <v-card flat>
                    <v-card-text><TablaMaquinarias :tab="tab" :TituloTabla="TituloTabla" :ArrayInventarioMaquinaria="ArrayInventarioMaquinaria" :loading="loading" 
                        @ObtenerInventarioMaquinariaPorProyecto="ObtenerInventarioMaquinariaPorProyecto" :EstadoPermisoCrear="EstadoPermisoCrear"
                    />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-3"
            >
                <v-card flat>
                    <v-card-text><TablaHerramientas :tab="tab" :TituloTabla="TituloTabla" :ArrayInventarioHerramienta="ArrayInventarioHerramienta" :loading="loading" 
                        @ObtenerInventarioHerramientaPorProyecto="ObtenerInventarioHerramientaPorProyecto"
                    />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-4"
            >
                <v-card flat>
                    <v-card-text><TablaMaterialInsumo :tab="tab" :TituloTabla="TituloTabla" :ArrayInventarioMaterialOInsumo="ArrayInventarioMaterialOInsumo" :loading="loading"
                        @ObtenerInventarioMaterialOInsumoPorProyecto="ObtenerInventarioMaterialOInsumoPorProyecto"            
                    />
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import TablaRegistros from './tabla'
import TablaVehiculos from './TablaVehiculos/tablaVehiculos'
import TablaMaquinarias from './TablaMaquinarias/TablaMaquinarias'
import TablaHerramientas from './TablaHerramientas/TablaHerramientas'
import TablaMaterialInsumo from './TablaMaterialOInsumos'

    export default {
        props:{
            sucursal:Number,
            proyecto:Object,
            ActivarMenuTabs:Boolean,
            ArrayInventarioVehiculo: Array,
            ArrayInventarioMaquinaria: Array,
            ArrayInventarioHerramienta: Array,
            ArrayInventarioMaterialOInsumo: Array,
            loading: Boolean,
            EstadoPermisoCrear: Boolean,
        },

        components: {
            TablaRegistros,
            TablaVehiculos,
            TablaMaquinarias,
            TablaHerramientas,
            TablaMaterialInsumo,
        },

        data () {
            return {
                tab: null,
                TituloTabla:'',
            }
        },

        watch: {
            tab(){
                if(this.tab==='tab-1') {
                    this.TituloTabla="Vehículos"  }
                if(this.tab==='tab-2') this.TituloTabla='Maquinaria'
                if(this.tab==='tab-3') this.TituloTabla='Herramientas'
                if(this.tab==='tab-4') this.TituloTabla='Materiales o Insumos'
            },
            sucursal(){
                if(this.tab === null){this.tab='tab-1'}
                if(this.tab === 'tab-1') this.ObtenerInventarioVehiculosPorProyecto()
                if(this.tab === 'tab-2') this.ObtenerInventarioMaquinariaPorProyecto()
                if(this.tab === 'tab-3') this.ObtenerInventarioHerramientaPorProyecto();
                if(this.tab === 'tab-4') this.ObtenerInventarioMaterialOInsumoPorProyecto()
            }
        },        
        created() {
        }, 
        methods: {
            ObtenerInventarioVehiculosPorProyecto(){
                // this.ArrayInventarioVehiculo=[]
                this.$emit('ObtenerInventarioVehiculosPorProyecto')
            },
            ObtenerInventarioMaquinariaPorProyecto(){
                // this.ArrayInventarioMaquinaria=[]
                this.$emit('ObtenerInventarioMaquinariaPorProyecto')
            },
            ObtenerInventarioHerramientaPorProyecto(){
                // this.ArrayInventarioHerramienta=[]
                this.$emit('ObtenerInventarioHerramientaPorProyecto')
            },
            ObtenerInventarioMaterialOInsumoPorProyecto(){
                // this.ArrayInventarioMaterialOInsumo=[]
                this.$emit('ObtenerInventarioMaterialOInsumoPorProyecto')
            },
        },
    }
</script>