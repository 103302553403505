<template>
    <div>
        <v-dialog
            v-model="AbrirModalRechazarSolicitudMateriaPrima"
            scrollable  
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-file-import</v-icon>
                    <v-toolbar-title class="TituloDialogo">Rechazar Solicitud</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="AprobarRechazoDeSolicitud()"
                                :loading="loading" :disabled="loading"                        
                            >Rechazar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Solicitud de Materia Prima</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarFormularioRechazarSolicitudMateriaPrima()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar Solicitud</span>
                    </v-tooltip>                    
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Código:</strong> {{ObtenerSolicitudMateriaPrima.codigo}}</span> <br>
                    <span class="fuenteTitulo"><strong>Proyecto:</strong> {{ObtenerSolicitudMateriaPrima.proyecto}}</span> <br>
                    <span class="fuenteTitulo"><strong>Sucursal:</strong> {{ObtenerSolicitudMateriaPrima.sucursal}}</span> <br>
                </div>
                <hr>
                <!-- <v-alert v-if="cantidadMayor"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Alerta:</h4>
                        <ul>
                            <li type="disc">
                                <h6>{{MensajeError}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                                 -->
                <v-card-text class="TituloDialogo">
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- cantidad -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="mt-0 pb-0">
                                <v-textarea outlined
                                    required :rules="nameRules"
                                    label="Motivo de rechazo"
                                    color="info"
                                    v-model="ObtenerSolicitudMateriaPrima.motivo"
                                ></v-textarea>                                         
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>                
            </v-card>                        
        </v-dialog>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    props:{
        AbrirModalRechazarSolicitudMateriaPrima: Boolean,
        ObtenerSolicitudMateriaPrima: Object,
    },
    data() {
        return {
            alertError:false,                          
            loading: false,
            valid: false,
            lazy: false,
            nameRules: [
                v => !!v || 'Campo es requerido',
            ],                      
        }
    },

    methods: {
        CerrarFormularioRechazarSolicitudMateriaPrima(){
            this.$refs.form.reset()
            this.$emit('CerrarFormularioRechazarSolicitudMateriaPrima')
        },
        async AprobarRechazoDeSolicitud(){
            this.loading = true
            if(this.$refs.form.validate()){
                var data = {proyectoSolicitudId: this.ObtenerSolicitudMateriaPrima.proyectoSolicitudId, motivo: this.ObtenerSolicitudMateriaPrima.motivo}
                const response = await this.$store.dispatch('RechazarSolicitudMateriaPrimaById', data)
                if(response.status === 200){
                    this.loading = false
                    this.$emit('ObtenerListadoDeSolicitudesDeMateriaPrima')
                    showMessageSuccess('Bien!','Solicitud de Materia Prima rechazada correctamente.')
                    this.CerrarFormularioRechazarSolicitudMateriaPrima()
                }else{
                    this.ControlladorExcepciones(response)
                }                                                            
            }else{
                this.loading = false
            }
        },
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },        
    },
}
</script>