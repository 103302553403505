<template>
    <div class="mb-2 pb-2">
        <EditarPermisos 
            @CerrarDialogoEditarPermiso="CerrarDialogoEditarPermiso" :DialogoEditarPermiso="DialogoEditarPermiso"
            :ObjetoEditarSubmenu="ObjetoEditarSubmenu" @GetAllSubMenus="GetAllSubMenus"
        />
        <!-- formulario para guardar submenus -->
        <div class="mt-3 mx-2" style="border: 2px solid black">
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>            
            <v-alert dense type="warning" outlined prominent border="left" class="text-center titulos" v-if="!EstadoBotonEliminarMenu">                
                <h3 >Seleccionar Menú</h3>
            </v-alert>
            <v-form
            >
                <v-row class="ml-2">
                    <v-col cols="12" lg="4" md="6" xs="12">
                        <v-select class="ml-2 mt-2" :disabled="!EstadoPermisoCrear"
                            :items="AllSubMenusParaAsignar"
                            item-value="idAccion"
                            item-text="titulo"
                            v-model="GetidSubmenu"
                            outlined
                            label="Seleccionar Submenú"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" lg="3" md="6" xs="12">
                        <h3 class="text-center titulos">Permisos</h3>
                        <v-row justify="space-around">
                            <v-switch label="Crear" v-model="PermisoSubMenuCrear" color="primary" :disabled="this.EstadoSwitchPermisosSubMenu"></v-switch>
                            <v-switch label="Editar" v-model="PermisoSubMenuEditar"  color="primary" :disabled="this.EstadoSwitchPermisosSubMenu"></v-switch>
                            <v-switch label="Eliminar" v-model="PermisoSubMenuEliminar" color="primary" :disabled="this.EstadoSwitchPermisosSubMenu"></v-switch>                
                        </v-row>                        
                    </v-col>
                    <v-col cols="12" lg="5" md="12" xs="12">
                    <v-tooltip bottom color="#01abe6" >
                        <template v-slot:activator="{ on }">
                            <v-btn large class="mt-1" rounded color="#01abe6" @click="GuardarSubMenus()" 
                                :disabled="EstadoBotonGuardarSubMenu" :loading="EstadoProgressCircularGuardarSubMenu" v-on="on"
                            >
                                <v-icon left> fas fa-save</v-icon> <strong>Guardar SubMenús</strong>
                            </v-btn>
                        </template>
                        <span class="fontButton">Asignar SubMenú al Menú del Usuario</span>
                    </v-tooltip>                                            
                    <v-tooltip bottom color="#D80000">
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="!EstadoPermisoEliminar || !EstadoBotonEliminarMenu" small class="mt-1 ml-3" rounded large color="error" @click="AbrirDialogoMensajeEliminarMenu()"
                                v-on="on"
                            >
                                <v-icon left>fas fa-trash-alt</v-icon> Eliminar Menú
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar Menú Asignado al Usuario</span>
                    </v-tooltip>                                            
                    </v-col>                
                </v-row>
            </v-form>
        </div>
        <!-- tabla de submenus ya asignados al usuario -->
        <div class="mt-1 mx-2" style="border: 2px solid black">
            <h3 class="text-center titulos"><strong>Gestión de SubMenús del Menú {{tituloMenu}}</strong></h3>            
            <v-data-table 
                :headers="HeadersSubMenus"
                :items="SubMenusAsignadoUsuario"
                class="elevation-0"
                item-key="orden"
                hide-default-footer
                :footer-props="{itemsPerPageText: 'Filas por página'}" 
                :loading="loading"
                loading-text="Cargando Datos"                
            >
                <template v-slot:item.action="{ item }">
                    <v-tooltip bottom color="#fb8c00">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="EstadoPermisoEditar" small class="ml-2" dark fab color="#fb8c00" v-on="on" @click="AbrirDialogoEditarPermiso(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar</span>
                    </v-tooltip>                    
                    <v-tooltip bottom color="#D80000">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="EstadoPermisoEliminar" small class="ml-2" dark fab color="#D80000" v-on="on" 
                                @click="AbrirDialogoMensajeEliminarSubMenu(item)"
                            >
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar SubMenú</span>
                    </v-tooltip>                    
                </template>                    
                <template v-slot:item.icono="{item}">
                    <v-icon>{{item.icono}}</v-icon>
                </template>        
                <template v-slot:item.permisos="{ item }">
                    <v-row justify="space-around">
                        <v-chip color="#00b40f" text-color="white" v-if="item.crear"> Crear
                            <v-icon small right>fas fa-check</v-icon>
                        </v-chip>
                        <v-chip color="#d80000" text-color="white" v-if="!item.crear"> Crear
                            <v-icon small right>fas fa-times-circle</v-icon>
                        </v-chip>
                        <v-chip color="#00b40f" text-color="white" v-if="item.editar"> Editar
                            <v-icon small right>fas fa-check</v-icon>
                        </v-chip>
                        <v-chip color="#d80000" text-color="white" v-if="!item.editar"> Editar
                            <v-icon small right>fas fa-times-circle</v-icon>
                        </v-chip>
                        <v-chip color="#00b40f" text-color="white" v-if="item.eliminar"> Eliminar
                            <v-icon small right>fas fa-check</v-icon>
                        </v-chip>
                        <v-chip color="#d80000" text-color="white" v-if="!item.eliminar"> Eliminar
                            <v-icon small right>fas fa-times-circle</v-icon>
                        </v-chip>
                    </v-row>
                </template>                        
                <template v-slot:no-data v-if="SubMenusAsignadoUsuario.length === 0">
                    <v-alert outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                    >
                        NO EXISTEN SUBMENUS ASOCIADOS
                    </v-alert>
                </template>
            </v-data-table>              
        </div>
        <!-- eliminamos un menu -->
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminarMenu" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminarMenu"
            :tituloMensajeEliminar="tituloMensajeEliminarMenu" @Eliminar="EliminarMenuAsignado"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarMenu" :alertError="alertError" 
        />
        <!-- eliminamos un submenu -->
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminarSubMenu" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminarSubMenu"
            :tituloMensajeEliminar="tituloMensajeEliminarSubMenu" @Eliminar="EliminarSubmenuAsignado"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarSubMenu" :alertError="alertError" 
        />
    </div>
</template>

<script>
import {showMessageError,showMessageSuccess} from '../../../services/sweetAlert';
import EditarPermisos from './EditarPermisos'
import MensajeEliminar from '../../MensajeEliminar'

export default {
    props: {
        SubMenusAsignadoUsuario: Array,
        idMenuSelect: Number,
        AllSubMenusParaAsignar: Array,
        OpenDialogSubMenu2: Boolean,
        idMenuSelecconado: Number,
        tituloMenu: String, 
        EstadoPermisoCrear: Boolean,
        EstadoPermisoEditar: Boolean,
        EstadoPermisoEliminar: Boolean,
        loading: Boolean,
        EstadoBotonEliminarMenu: Boolean,
    },

    components: {
        EditarPermisos,
        MensajeEliminar,
    },

    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,            
            // variables del componente eliminar submenu
            DialogoMensajeEliminarSubMenu: false,
            tituloMensajeEliminarSubMenu: '',
            loadingEliminarSubMenu: false,

            // variables del componente eliminar menu
            DialogoMensajeEliminarMenu: false,
            tituloMensajeEliminarMenu: '',
            loadingEliminarMenu: false,
            DesactivarBotonEliminar: false,
            IdSubMenu:'',
            EstadoProgressCircularGuardarSubMenu: false,
            // editar informacion             
            DialogoEditarPermiso: false,
            ObjetoEditarSubmenu: {
                'idSubMenu': 0,
                'crear': false,
                'editar': false,
                'eliminar': false,
                'orden': 0
            },
            GetidSubmenu: '',
            HeadersSubMenus:[
                {text: 'SubMenu', value: 'titulo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Icono', value: 'icono', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Orden', value: 'orden', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Permisos', value: 'permisos', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'}, 
            ],

            GuardarDatosSubMenu: {
                'idMenu': 0,
                'titulo': '',
                'icono': '',
                'url': '',
                'crear': false,
                'editar': false,
                'eliminar': false,
                'orden': 0,
            },  
            ArrayGuardarSubMenuSeleccionado: [],
            EstadoBotonGuardarSubMenu: true,
            EstadoSwitchPermisosSubMenu: true,
            PermisoSubMenuCrear: false,
            PermisoSubMenuEditar: false,
            PermisoSubMenuEliminar: false,
        }
    },
created() {
            // console.log('Crear: '+this.EstadoPermisoCrear)
            // console.log('Editar: '+this.EstadoPermisoEditar)
            // console.log('Eliminar: '+this.EstadoPermisoEliminar)
},
    watch:{
        GetidSubmenu : function (params) {
            if(params){
                this.ObtenerSubMenu()
                this.Seleccionar()
                this.EstadoBotonGuardarSubMenu = false
                this.EstadoSwitchPermisosSubMenu = false
            }
            else{

            }
        },
        PermisoSubMenuCrear : function (params) {
            if(params){
                this.ObtenerSubMenu()
                this.Seleccionar()
            }
            else{
                this.ObtenerSubMenu()
                this.Seleccionar()              
            }
        },
        PermisoSubMenuEditar : function (params) {
            if(params){
                this.ObtenerSubMenu()
                this.Seleccionar()
            }
            else{
                this.ObtenerSubMenu()
                this.Seleccionar()            
            }
        },
        PermisoSubMenuEliminar : function (params) {
            if(params){
                this.ObtenerSubMenu()
                this.Seleccionar()
            }
            else{
                this.ObtenerSubMenu()
                this.Seleccionar()
            }
        },
    },
    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
        },                
        ObtenerCatalogoDeMenus(){
            this.$emit('ObtenerCatalogoDeMenus')
        },
        GetAllMenu(){
            this.$emit('GetAllMenu')
        },
        // metodos para eliminar un submenu 
            AbrirDialogoMensajeEliminarSubMenu(item){
                this.tituloMensajeEliminarSubMenu = ' SubMenú '+ item.titulo
                this.IdSubMenu = item.idSubMenu
                this.DialogoMensajeEliminarSubMenu = true
            },

            CerrarDialogoMensajeEliminarSubMenu(){
                this.DialogoMensajeEliminarSubMenu = false
            },
        // metodos para eliminar un menu 
            AbrirDialogoMensajeEliminarMenu(){
                this.tituloMensajeEliminarMenu = ' Menú '+ this.tituloMenu
                this.DialogoMensajeEliminarMenu = true
            },

            CerrarDialogoMensajeEliminarMenu(){
                this.DialogoMensajeEliminarMenu = false
            },

        // eliminamos el menu asignado al usuario
        async EliminarMenuAsignado(){
            this.loadingEliminarMenu = true
            const response = await this.$store.dispatch('DeleteMenu',this.idMenuSelect)
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.loadingEliminarMenu = false
                return 0
            }                        
            if(response.status === 200){
                this.alertError= false
                this.$emit('DesactivarBotonEliminarMen')
                this.ObtenerCatalogoDeMenus()
                this.GetAllMenu()
                showMessageSuccess('Eliminado !','Menú eliminado con éxito')
                this.loadingEliminarMenu = false
                this.CerrarDialogoMensajeEliminarMenu()
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingEliminarMenu = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                this.loadingEliminarMenu = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingEliminarMenu = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingEliminarMenu = false
            }
        },

        AbrirDialogoEditarPermiso(item){
            this.ObjetoEditarSubmenu.idSubMenu = item.idSubMenu
            this.ObjetoEditarSubmenu.crear = item.crear
            this.ObjetoEditarSubmenu.editar = item.editar
            this.ObjetoEditarSubmenu.eliminar = item.eliminar
            this.ObjetoEditarSubmenu.orden = item.orden
            this.DialogoEditarPermiso = true
        },
        CerrarDialogoEditarPermiso(){
            this.DialogoEditarPermiso= false
        },

        CerrarDialog(){
            this.$emit('CerrarDialog')
        },        
        GetAllSubMenus(){
            this.$emit('GetAllSubMenus')
        },
        GetAllSubMenusParaAsignar(){
            this.$emit('GetAllSubMenusParaAsignar')
        },
    // obtenemos un unico submenu para asignarlo
        async ObtenerSubMenu(){
            this.ArrayGuardarSubMenuSeleccionado=[]
            const response = await this.$store.dispatch('getSelectedSubMenu', this.GetidSubmenu );
            if(typeof response === 'undefined'){
                // this.ControllerException()
                // return 0
            }                        
            if(response.status === 200){
                this.alertError= false
                // obtenemos los datos del submenu para guardarlos
                this.GuardarDatosSubMenu.idMenu = this.idMenuSelect
                this.GuardarDatosSubMenu.titulo = response.data.titulo
                this.GuardarDatosSubMenu.icono = response.data.icono
                this.GuardarDatosSubMenu.url = response.data.url
                this.GuardarDatosSubMenu.crear = this.PermisoSubMenuCrear,
                this.GuardarDatosSubMenu.editar = this.PermisoSubMenuEditar,
                this.GuardarDatosSubMenu.eliminar = this.PermisoSubMenuEliminar,
                this.GuardarDatosSubMenu.orden = response.data.orden
                this.ArrayGuardarSubMenuSeleccionado.push(this.GuardarDatosSubMenu)
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },
    // Eliminamos unicamente un submenu de la tabla de submenus asignados al menu del usuario 
        async EliminarSubmenuAsignado(){
            this.loadingEliminarSubMenu = true
            const response = await this.$store.dispatch('DeleteSubMenuAsignado', this.IdSubMenu)
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.loadingEliminarSubMenu = false
                return 0
            }                                    
            if(response.status === 200){
                this.alertError= false
                showMessageSuccess('SubMenú borrado con éxito')
                this.GetAllSubMenus()
                this.GetAllSubMenusParaAsignar()   
                this.loadingEliminarSubMenu = false
                this.CerrarDialogoMensajeEliminarSubMenu()
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingEliminarSubMenu = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                this.loadingEliminarSubMenu = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingEliminarSubMenu = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingEliminarSubMenu = false
            }
        },

    // seleccionamos los submenus que deseamos guardar, mas de un menu
        Seleccionar(){
            this.GuardarDatosSubMenu = {}
        },

        CambiarEstadoPermisos(item){
            this.GuardarDatosSubMenu.idMenu = item.idMenu
            this.GuardarDatosSubMenu.titulo = item.titulo
            this.GuardarDatosSubMenu.icono = item.icono
            this.GuardarDatosSubMenu.url = item.url
            this.GuardarDatosSubMenu.crear = item.crear,
            this.GuardarDatosSubMenu.editar = item.editar,
            this.GuardarDatosSubMenu.eliminar = item.eliminar,
            this.GuardarDatosSubMenu.orden = item.orden
        },

        // guardamos el conjunto de submenus seleccionados
        async GuardarSubMenus(){
            this.EstadoProgressCircularGuardarSubMenu = true
            // guardamos los menus seleccionados
            const response = await this.$store.dispatch('addSubMenuToMenu',this.ArrayGuardarSubMenuSeleccionado)
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.EstadoProgressCircularGuardarSubMenu = false
                return 0
            }            

            if(response.status === 200){
                this.alertError= false
                showMessageSuccess('Bien !','SubMenú(s) guardado(s) con éxito')
                this.EstadoBotonGuardarSubMenu = true
                this.EstadoSwitchPermisosSubMenu = true
                this.GetAllSubMenus()
                this.GetAllSubMenusParaAsignar()                
                this.ArrayGuardarSubMenuSeleccionado=[]
                //this.GetidSubmenu = ''
                this.PermisoSubMenuCrear = false
                this.PermisoSubMenuEditar = false
                this.PermisoSubMenuEliminar = false

                this.EstadoProgressCircularGuardarSubMenu = false
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.EstadoProgressCircularGuardarSubMenu = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                this.EstadoProgressCircularGuardarSubMenu = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.EstadoProgressCircularGuardarSubMenu = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.EstadoProgressCircularGuardarSubMenu = false
            }
        }
    },
}
</script>

<style>
    .tituloTablaSubMenu {
        font-family: 'PT Serif';
        font-size: 50%;
    }
    .textoalerta{
        font-family: 'PT Serif';
        font-size: 20px;
    }
    .titulos{
        font-family: 'PT Serif';
        font-size: 20px;
    }
</style>