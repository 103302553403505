<template>
    <div>
        <v-dialog
            v-model="AbrirDialogoRegistrarTipoIncidente"
            persistent :overlay="false"
            max-width="600px" scrollable
            transition="dialog-transition"
        >
            <v-card>
                <!-- barra principal del dialogo -->
                <v-app-bar color="#3c8dbc" flat dark>
                    <v-toolbar-title class="titulo">{{TituloDialogo}} Tipo Incidente</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- boton registrar Tipo Vehiculo -->
                    <v-tooltip bottom color="success" v-if="this.esNuevo ">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" color="success" rounded class="mr-4" @click="GuardarTipoIncidentes()"
                                :loading="loading" :disabled="loading" 
                            >
                                <v-icon left>fas fa-save</v-icon> Guardar
                            </v-btn>                
                        </template>
                        <span>Registrar Tipo Incidente</span>
                    </v-tooltip>
                    <!-- boton editar Tipo Vehiculo -->
                    <v-tooltip bottom color="success" v-if="!this.esNuevo">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" color="success" rounded class="mr-4" @click="GuardarCambios()"
                                :loading="loading" :disabled="loading  || !EstadoPermisoEditar" 
                            >
                                <v-icon left>fas fa-save</v-icon> Guardar
                            </v-btn>                
                        </template>
                        <span>Editar Tipo Incidente</span>
                    </v-tooltip>
                    <v-btn large color="error" rounded dark @click="CerrarModalRegistrarTipoIncidente">
                        <v-icon left>fas fa-window-close</v-icon> Cancelar
                    </v-btn>                                                                       
                </v-app-bar>             
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>               
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                                             
                <v-card-text class="TipoFuente">
                    <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                        <v-row wrap>
                            <v-col cols="12" lg="12" md="12" sm="12" class="pb-0 mb-0" >                    
                                <v-text-field :disabled="!EstadoPermisoEditar"
                                    outlined
                                    label="Nombre"
                                    v-model="modeloCrearTipoIncidente.nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 mt-0 pb-0 mb-0" >                    
                                <v-textarea :disabled="!EstadoPermisoEditar"
                                    outlined
                                    label="Descripción"
                                    v-model="modeloCrearTipoIncidente.descripcion"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>        
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../../services/sweetAlert'

export default {
    props: {
        esNuevo: Boolean,
        TituloDialogo: String,
        AbrirDialogoRegistrarTipoIncidente: Boolean,
        DatosEditarTipoIncidente: Object,
        EstadoPermisoEditar: Boolean,
    },
    data() {
        return {
            alertError:false,
            loading: false,
            valid: false,
            lazy: false,      
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],
            modeloCrearTipoIncidente:{
                idTipoIncidente:0,
                nombre: '',
                descripcion: '',
            },
            // errores registros duplicados 
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },
    watch: {
        AbrirDialogoRegistrarTipoIncidente(){
            if(this.TituloDialogo === "Editar") this.modeloCrearTipoIncidente = this.DatosEditarTipoIncidente
            else this.modeloCrearTipoIncidente.idTipoIncidente = 0
        }
    },
    methods: {
        CerrarModalRegistrarTipoIncidente(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
            this.$refs.form.reset()
            this.$emit('CerrarModalRegistrarTipoIncidente')
        },        
    // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            if(typeof response === 'undefined'){
                this.alertError=true
                this.loading = false
                return 0
            }if(response.status === 200){
                if(this.TituloDialogo === "Crear"){
                    showMessageSuccess('¡BIEN!', 'Tipo Incidente registrado correctamente')
                    this.alertError= false
                    this.loading = false
                    this.$emit("TodosTiposIncidentes")
                    this.CerrarModalRegistrarTipoIncidente()
                }else if(this.TituloDialogo === "Editar"){
                    showMessageSuccess('¡BIEN!', 'Tipo Incidente actualizado correctamente')
                    this.alertError= false
                    this.loading = false
                    this.$emit("TodosTiposIncidentes")
                    this.CerrarModalRegistrarTipoIncidente()
                }
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')            
                this.loading = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }
        },        

        async GuardarTipoIncidentes(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            if(this.$refs.form.validate()){
                if(this.TituloDialogo === "Crear"){
                    const response = await this.$store.dispatch('SaveTypeIncidents', this.modeloCrearTipoIncidente)
                    this.ControllerException(response)
                }
            }else{
                this.loading = false                
            }
        },

        async GuardarCambios(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            const response = await this.$store.dispatch('EditTypeIncidentsById', this.modeloCrearTipoIncidente)
            this.ControllerException(response)
        }
    },
}
</script>

<style>
    .TipoFuente{
        font-family: 'PT Serif';
    }    
</style>