<template>
<v-dialog
        v-model="dialogConfigureUser" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card tile>
          <v-toolbar card dark color="primary">
            <v-btn icon dark @click="close()">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Configuracion del usuario: {{userName}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark flat @click="dialog = false">Save</v-btn>
            </v-toolbar-items>
            
          </v-toolbar>
          <v-card-text>
            
            <v-tabs centered color="cyan" dark icons-and-text >
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab href="#tab-1">
                    Roles
                <v-icon>fas fa-dice-d6</v-icon>
                </v-tab>
                <v-tab @click="llenarArrayModulos()" href="#tab-2">
                    Modulos
                <v-icon>fas fa-american-sign-language-interpreting</v-icon>
                </v-tab>
                <v-tab @click="getAUserAcciones()" href="#tab-3">
                    Acciones
                <v-icon>fab fa-artstation</v-icon>
                </v-tab>

                <v-tab-item :value="'tab-' + 1" >
                    <v-card flat>
                        <v-card>
                            <v-card-title>
                                <v-toolbar-title>Roles Asignados</v-toolbar-title>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <v-tooltip bottom color="#00B40F">
                                    <template v-slot:activator="{ on }">
                                    <v-btn small @click="OpenDialoAsignarRol()" class="mx-3" fab dark color="#00B40F" v-on="on">
                                        <v-icon >far fa-plus-square </v-icon>
                                    </v-btn>
                                    </template>
                                    <span>ASIGNAR NUEVO ROL</span>
                                </v-tooltip>
                            </v-card-title>

                            <!--DATA TABLE -->
                            <v-data-table  :headers="headersUserRoles" :items="dessertsUserRoles">
                                <template v-slot:items="props">
                                    <td class="text-xs-center">{{ props.item.nombreRol}}</td>
                                    <td class="text-xs-center">{{ props.item.nombreApp }}</td>
                                    <td class="text-xs-center">{{ props.item.nombreModulo }}</td>
                                    <td class="text-xs-center">
                                        <v-tooltip bottom color="#00B40F">
                                            <template v-slot:activator="{ on }">
                                                <v-btn small @click="deleteRol(props.item)" class="mx-3" fab dark color="#00B40F" v-on="on">
                                                    <v-icon >fas fa-trash-alt </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Quitar Rol</span>
                                        </v-tooltip>
                                    </td>
                                </template>
                            </v-data-table>                           
                        </V-card>
                    </v-card>
                </v-tab-item>

                <!--tap 2 -->
                <v-tab-item :value="'tab-' + 2" >
                    <v-card flat>
                        <v-card>
                            <v-card-title>
                                <v-toolbar-title>Modulos Asignados</v-toolbar-title>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <!--
                                <v-tooltip bottom color="#00B40F">
                                    <template v-slot:activator="{ on }">
                                    <v-btn small @click="OpenDialoAsignarRol()" class="mx-3" fab dark color="#00B40F" v-on="on">
                                        <v-icon >far fa-plus-square </v-icon>
                                    </v-btn>
                                    </template>
                                    <span>ASIGNAR MODULO</span>
                                </v-tooltip>
                                -->
                            </v-card-title>

                            <!--DATA TABLE -->
                            <v-data-table  :headers="headersUserModulos" :items="dessertsUserModulos">
                                <template v-slot:items="props">
                                    <td class="text-xs-center">{{ props.item.nombre}}</td>
                                    <td class="text-xs-center">{{ props.item.icono }}</td>
                                    <td class="text-xs-center">{{ props.item.url }}</td>
                                    <td class="text-xs-center">{{ props.item.rol }}</td>
                                    <td class="text-xs-center">
                                        <v-tooltip bottom color="#00B40F">
                                            <template v-slot:activator="{ on }">
                                                <v-btn small @click="deleteRol(props.item)" class="mx-3" fab dark color="#00B40F" v-on="on">
                                                    <v-icon >fas fa-trash-alt </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Quitar Rol</span>
                                        </v-tooltip>
                                    </td>
                                </template>
                            </v-data-table>                           
                        </V-card>
                    </v-card>
                </v-tab-item>


                <!--TAP 3, ACCIONES DE MODULOS -->
                <v-tab-item :value="'tab-' + 3" >
                    <v-card flat>
                        <v-card>
                            <v-card-title>
                                <v-toolbar-title>Acciones de Modulos</v-toolbar-title>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-divider class="mx-2" inset vertical></v-divider>


                                <!--
                                <v-tooltip bottom color="#00B40F">
                                    <template v-slot:activator="{ on }">
                                    <v-btn small @click="openDialoNuevaAccion()" class="mx-3" fab dark color="#00B40F" v-on="on">
                                        <v-icon >far fa-plus-square </v-icon>
                                    </v-btn>
                                    </template>
                                    <span>ASIGNAR ACCION</span>
                                </v-tooltip>
                                -->
                            </v-card-title>

                            <!--DATA TABLE -->
                            <v-data-table  :headers="headersUserAcciones" :items="dessertsUserAcciones">
                                <template v-slot:items="props">
                                    <td class="text-xs-center">{{ props.item.moduloNombre}}</td>
                                    <td class="text-xs-center">{{ props.item.moduloURL }}</td>
                                    <td class="text-xs-center">{{ props.item.moduloRol }}</td>
                                    <td class="text-xs-center">{{ props.item.accionNombre }}</td>
                                    <td class="text-xs-center">{{ props.item.accionIcono }}</td>
                                    <td class="text-xs-center">{{ props.item.accionUrl }}</td>
                                    <td class="text-xs-center">{{ props.item.accionPermiso }}</td>
                                    <td class="text-xs-center">
                                        <v-tooltip bottom color="#00B40F">
                                            <template v-slot:activator="{ on }">
                                                <v-btn small @click="deleteRol(props.item)" class="mx-3" fab dark color="#00B40F" v-on="on">
                                                    <v-icon >fas fa-trash-alt </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Quitar Rol</span>
                                        </v-tooltip>
                                    </td>
                                </template>
                            </v-data-table>                           
                        </V-card>
                    </v-card>
                </v-tab-item>


            </v-tabs>
            <asignarRol @updateConfig="updateConfig()" :IdUser="IdUser" @closeDialoAsignarRol="closeDialoAsignarRol()" :dialogAsignarRol="dialogAsignarRol" :itemsApplications="itemsApplications" />
           
           
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
</template>


<script>


import {apiAuthority} from '../../services/index.js'
import {showMessageError,showMessageSuccess,showMessageWarning} from '../../services/sweetAlert'
import asignarRol from './asignarRole.vue'

  export default {
    data () {
      return {
          //roles
            itemsApplications:[],
            dialogAsignarRol:false,
            modeloNewRol:{
                Id:'',
                IdAplication:0
            },
          itemsRoles:[],
          dialogNuevoRol:false,
          headersUserRoles: [
          { text: 'NOMBRE ROL', align: 'center', value: 'nombreRol'},
          { text: 'NOMBRE APP', align: 'center',  value: 'nombreApp' },
          { text: 'NOMBRE MODULO', align: 'center',  value: 'nombreModulo' },
          { text: 'ACCIONES', value: 'acciones',align: 'center', sortable: false }
        ],
       //para los modulos
       headersUserModulos: [
          { text: 'NOMBRE MODULO', align: 'center', value: 'nombre'},
          { text: 'ICONO', align: 'center',  value: 'icono' },
          { text: 'URL', align: 'center',  value: 'url' },
          { text: 'REQUIERE ROL', align: 'center',  value: 'rol' },
          { text: 'ACCIONES', value: 'acciones',align: 'center', sortable: false }
        ],
        dialogNuevoModulo:false,

        //para acciones
        headersUserAcciones: [
            { text: 'NOMBRE MODULO', align: 'center', value: 'moduloNombre'},
            { text: 'URL MODULO', align: 'center', value: 'moduloURL'},
            { text: 'ROL MODULO', align: 'center', value: 'moduloRol'},
            { text: 'NOMBRE ACCION', align: 'center', value: 'accionNombre'},
            { text: 'ICONO ACCION', align: 'center', value: 'accionIcono'},
            { text: 'URL ACCION', align: 'center', value: 'accionUrl'},
            { text: 'PERMISO ACCION', align: 'center', value: 'accionPermiso'},
            { text: 'ACCIONES', align: 'center', value: 'acciones'},
        ],
        dialogNuevaAccion:false,
      }
    },
    components:{
        'asignarRol':asignarRol
    },
    props: {
        appId:Number,
        dialogConfigureUser:Boolean,
        userName:String,
        IdUser: String,
        dessertsUserRoles: {
            type:Array,
            default: () => ({})
        },
        dessertsUserModulos:Array,
        dessertsUserAcciones:Array
    },
    methods:{
        updateConfig(){
            this.dialogAsignarRol=false
            this.$emit('updateConfig')
        },
        RolAsociado(){
            this.$emit('RecargarTabla')
            //this.listarRoles(this.appId)
            this.dialogNuevoRol=false
        },
        close(){
            this.$emit('cerrar')
        },
        
        closeDialoAsignarRol(){
            this.dialogAsignarRol=false
        },

        getAllRoles(){
            apiAuthority.get('/roles/readRoles')
            .then(response => {
                this.llenarItemsRoles(response.data)
            }).catch(error => {
                showMessageError('Ocurrio un error al intentar listar la configuracion')
            });
        },

        llenarItemsRoles(data){
            this.itemsRoles=[]
            Object.keys(data).forEach((element,key,_array)=>{
                this.itemsRoles.push({
                    value: data[element].id,
                    label: data[element].name
                });
            });
        },

        deleteRol(item){
            var data = {IdApplicationUser : item.idApplicationUser}
            apiAuthority.post('Usersroles/UnasignateRole',data)
            .then(response => { 
                showMessageSuccess('Bien !', 'Rol desasignado correctamente');
                this.$emit('updateConfig');
            }).catch(error  => {
                showMessageError('Ocurrio un error al intentar quitar el rol al usuario')
            });
        },

        

        //metodos para modulos
        async OpenDialoAsignarRol(){
            this.dialogAsignarRol=true
            this.itemsApplications = await this.getAllApp()
            //this.itemsApplications = a
        },

        async getAllApp(){
            return await apiAuthority.get('/Applications')
                            .then(response => {
                                return response.data
                            }).catch(error => {
                                showMessageError('Ocurrio un error al intentar listar las aplicaciones')
                            });
        },


        

        openDialoNuevaAccion(){
            this.dialogNuevaAccion=true
        },
        

    }
  }
</script>