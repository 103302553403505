import axios from 'axios'
const api = axios

const store = {
    actions :{
        createLocalBusinesses : async ({},data)=> {
            return await api.post('/LocalBusinesses',data, {
                headers:
                {
                    'Content-Type': 'multipart/form-data',
                }
                }).then(response => {
                    return response
                }).catch(error => {
                    return error.response
                });
        },

        getLocalBusinessesById : async ({},idBussiness)=> {
            return await api.get('/LocalBusinesses/getOne/'+idBussiness)
                .then(response => {
                    return response
                }).catch(error => {
                    return error.response
                });
        },

        getAllLocalBusinessesByIdState : async ({}, idState ) => {
            return await api.get('/LocalBusinesses/request/'+idState)
                .then(response => {
                    return response
                }).catch(error => {
                    return error.response
                });
        },

        getAllLocalBusinessesByIdStateAndUser : async ({}, data) => {
            return await api.get('LocalBusinesses/'+data.idState+'/user/'+data.user)
                .then(response => {
                    return response
                }).catch(error => {
                    return error.response
                });
        }
    }
}

export default store