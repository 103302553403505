 <template>
    <v-dialog
        v-model="DialogoCrearMaquinaria" scrollable
        max-width="1100px" persistent
    >
        <v-card>
        <v-app-bar flat dark color="#3c8dbc" >
            <v-toolbar-title class="TituloDialogo">{{Titulo}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- si es nueva maquinaria a registrar -->
            <v-tooltip bottom color="success" v-if="this.EsNuevo">
                <template v-slot:activator="{ on }">
                    <v-btn  v-on="on" dark large rounded class="mx-2 TituloDialogo" color="success" @click="GuardarMaquinaria()"
                        :loading="loading" :disabled="loading"
                    >Guardar
                        <v-icon right dark>fas fa-save</v-icon>
                    </v-btn>                                    
                </template>
                <span>Registrar Maquinaria</span>
            </v-tooltip>
            <v-tooltip bottom color="error" v-if="this.EsNuevo">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarDialogoCrearMaquinaria()">Cancelar
                        <v-icon right dark>far fa-window-close</v-icon>
                    </v-btn>                        
                </template>
                <span>Cancelar Registro</span>
            </v-tooltip>
            <!-- si es editar maquinaria -->
            <v-btn v-if="!this.EsNuevo && EstadoPermisoEditar" dark large rounded class="mx-2" color="indigo TituloDialogo" @click="GuardarCambiosMaquinaria()"
                :loading="loading" :disabled="loading"
            >Guardar Cambios
                <v-icon right dark>fas fa-save</v-icon>
            </v-btn>            
            <v-btn v-if="!this.EsNuevo" dark large rounded class="mx-2" color="error TituloDialogo" @click="CerrarDialogoEditarMaquinaria()">Cancelar
                <v-icon right dark>far fa-window-close</v-icon>
            </v-btn>                        
        </v-app-bar> 
        <v-card-text>
            <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>  
            <v-alert :value="alertErrorPost"
                    type="error"
                    outlined
                    transition="scale-transition"
                    color="deep-orange">
                    <h4>Errores:</h4>
                    <ul>
                        <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                            <h6>{{i.description}}</h6>
                        </li>
                    </ul>
            </v-alert>                                                                                           
            <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                <v-row wrap>
                    <!-- codigo -->
                    <v-col cols="12" lg="4" md="4" sm="6" >
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.codigo"
                            label="Código"
                        ></v-text-field>
                    </v-col>
                    <!-- nombre -->
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.nombre"
                            label="Nombre"
                        ></v-text-field>
                    </v-col>
                    <!-- tipo de maquinaria -->
                    <v-col cols="12" lg="4" md="4" sm="6" v-if="this.EsNuevo"> 
                        <v-select outlined :disabled="!EstadoPermisoEditar"
                            :items="ArrayTiposMaquinarias"
                            item-value="idTipoMaquinaria"
                            item-text="nombre"
                            v-model="modeloCrearMaquinaria.idTipoMaquinaria"
                            label="Tipo Maquinaria"
                        ></v-select>
                    </v-col>
                    <!-- serie -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.serie"
                            label="Serie"
                        ></v-text-field>
                    </v-col>
                    <!-- modelo -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.modelo"
                            label="Modelo"
                        ></v-text-field>
                    </v-col>
                    <!-- chasis -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.chasis"
                            label="Chasis"
                        ></v-text-field>
                    </v-col>
                    <!-- motor -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.motor"
                            label="Motor"
                        ></v-text-field>
                    </v-col>
                    <!-- Tamaño del motor -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.tamanioMotor"
                            label="Tamaño del Motor CC" type="number"
                            hint="CC = Cilindrada del motor"
                            persistent-hint                            
                        ></v-text-field>
                    </v-col>
                    <!-- rendimiento -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.rendimiento"
                            label="Rendimiento" type="number"
                        ></v-text-field>
                    </v-col>
                    <!-- fecha compra cuando se edita -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-10" v-if="!this.EsNuevo">
                        <v-menu
                            v-model="menuFechaCompra2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                    v-model="fechaCompra"
                                    label="Fecha Compra"
                                    prepend-inner-icon="far fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :hint="'Fecha Compra Actual = '+modeloCrearMaquinaria.fechaCompra"
                                    persistent-hint
                                ></v-text-field>
                            </template>
                            <v-date-picker locale="es-GT" v-model="fechaCompra" @input="menuFechaCompra2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- fecha compra cuando es nuevo registro -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0"  v-if="this.EsNuevo">
                        <v-menu
                            v-model="menuFechaCompra"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                    v-model="modeloCrearMaquinaria.fechaCompra"
                                    label="Fecha Compra"
                                    prepend-inner-icon="far fa-calendar-alt"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker locale="es-GT" v-model="modeloCrearMaquinaria.fechaCompra" @input="menuFechaCompra = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- propietario -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-text-field outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.propietario"
                            label="Propietario"
                        ></v-text-field>
                    </v-col>
                    <!-- proveedor -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0" v-if="this.EsNuevo">
                        <v-text-field outlined
                            v-model="modeloCrearMaquinaria.proveedor"
                            label="Proveedor"
                        ></v-text-field>
                    </v-col>
                    <!-- marca -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0" v-if="this.EsNuevo">
                        <v-select outlined
                            :items="ArrayMarcas"
                            item-text="nombre"
                            item-value="idMarca"
                            v-model="modeloCrearMaquinaria.idMarca"
                            label="Marca"
                        ></v-select>
                    </v-col>
                    <!-- combustible                   -->
                    <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-select outlined :disabled="!EstadoPermisoEditar"
                            :items="ArrayCombustible"
                            item-text="nombre"
                            item-value="idTipoCombustible"
                            v-model="modeloCrearMaquinaria.idTipoCombustible"
                            label="combustible"
                        ></v-select>                            
                    </v-col>         
                    <!-- sucursales                   -->
                    <!-- <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
                        <v-select outlined :disabled="!EstadoPermisoEditar"
                            :items="ArraySucursales"
                            item-text="nombre"
                            item-value="idProyecto"
                            v-model="modeloCrearMaquinaria.idProyecto"
                            label="Sucursal"
                        ></v-select>                            
                    </v-col>                                                                                                        -->
                    <!-- observacion -->
                    <v-col cols="12" lg="4" md="4" sm="12" class="pt-0 pb-0">
                        <v-textarea outlined :disabled="!EstadoPermisoEditar"
                            v-model="modeloCrearMaquinaria.observacion"
                            label="Observación"
                        ></v-textarea>                        
                    </v-col>
                </v-row>
            </v-form>            
        </v-card-text>
        </v-card>
    </v-dialog>        
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import moment from 'moment'

export default {
    props: {
        DialogoCrearMaquinaria: Boolean,
        Titulo: String,
        ArrayObtenerMaquinariaById: Object,
        EsNuevo:Boolean,
        EstadoPermisoCrear: Boolean,
        EstadoPermisoEditar: Boolean,
        EstadoPermisoEliminar: Boolean,   
        validarfechaobtenida: String,
        bodega:Number,
    },

    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,                          

            loading: false,
            valid: false,
            lazy: false,
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],
            modeloCrearMaquinaria: {
                idTipoMaquinaria: 0,
                idMarca: 0,
                proveedor:'',
                idEstado: 1,
                idTipoCombustible:0,
                codigo: '',
                nombre: '',
                serie: '',
                modelo: 0,
                chasis: '',
                motor: '',
                tamanioMotor: 0,
                rendimiento: 0,
                fechaCompra: new Date().toISOString().substr(0, 10),
                propietario: '',
                observacion: '',
                idProyecto:0,
                bodegaId:0,
            },            
            ArrayTiposMaquinarias: [],
            ArrayCombustible: [],
            ArraySucursales:[],

            fechaCompra: new Date().toISOString().substr(0, 10),
            validarfecha:  new Date().toISOString().substr(0, 10),

            ArrayMarcas: [],
            menuFechaCompra: false,
            menuFechaCompra2: false,
            modeloGuardarCambios:{},
            CambioFecha: false,
            // errores registros duplicados y vacios
            alertErrorPost:false,
            arrayErrorPost:[],            
        }
    },

    created() {
        this.ObtenerMaquinarias()
        this.ObtenerMarcas()
        this.TodosTiposCombustibles()
        this.TodasSucursales()
    },

    watch: {
        fechaCompra (){
            this.CambioFecha = true                 
        },

        DialogoCrearMaquinaria(){
            this.DatosMaquinaria()
        }
    },

    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        //obtenemos todos los tipos de combustibles
            async TodosTiposCombustibles(){
                this.loading = true
                const response = await this.$store.dispatch('getAllTypesFuel')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }else if(response.status === 200){
                    this.alertError= false
                    this.ArrayCombustible = response.data
                    this.loading = false
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }                
            },

        //obtenemos todas las sucursales
            async TodasSucursales(){
                const response = await this.$store.dispatch('getAllProyectos')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }else if(response.status === 200){
                    this.alertError= false
                    this.ArraySucursales = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }                
            },
        // obtenemos la maquinaria para editarlo
        DatosMaquinaria(){
            this.modeloCrearMaquinaria = this.ArrayObtenerMaquinariaById
        },
        //obtenemos todos los tipos de maquinaria para mostrarlo en el select
        async ObtenerMaquinarias(){
            const response = await this.$store.dispatch('getAllTypeMachinery')
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }             
            if(response.status === 200){
                this.alertError= false
                this.ArrayTiposMaquinarias = response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },

        //obtenemos todas las marcas para mostrarlo en el select 
        async ObtenerMarcas(){
            const response = await this.$store.dispatch('getAllBrands')
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                         
            if(response.status === 200){
                this.alertError= false
                this.ArrayMarcas = response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },
        //cerramos el dialogo de crear maquinaria
        CerrarDialogoCrearMaquinaria(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
            this.CerrarDialogoEditarMaquinaria()
        },
        //cerramos el dialogo de editar maquinaria
        CerrarDialogoEditarMaquinaria(){
            this.modeloCrearMaquinaria.fechaCompra =  new Date().toISOString().substr(0, 10),
            this.modeloCrearMaquinaria.idTipoMaquinaria = ''            
            this.modeloCrearMaquinaria.proveedor =''            
            this.modeloCrearMaquinaria.idMarca = ''
            this.modeloCrearMaquinaria.idEstado=''
            this.modeloCrearMaquinaria.idTipoCombustible=''
            this.modeloCrearMaquinaria.codigo=''
            this.modeloCrearMaquinaria.nombre=''
            this.modeloCrearMaquinaria.serie=''
            this.modeloCrearMaquinaria.modelo=''
            this.modeloCrearMaquinaria.chasis=''
            this.modeloCrearMaquinaria.motor=''
            this.modeloCrearMaquinaria.tamanioMotor=''
            this.modeloCrearMaquinaria.rendimiento=''
            this.modeloCrearMaquinaria.propietario= '',
            this.modeloCrearMaquinaria.observacion= '',
            this.modeloCrearMaquinaria.idProyecto='',
            this.modeloCrearMaquinaria.bodegaId='',            
            this.Reset()
            this.$emit('CerrarDialogoCrearMaquinaria')
        },
        //reseteamos los valores de los campos
        Reset(){
            this.$refs.form.reset()
        },
        //guardamos la maquinaria
        async GuardarMaquinaria(){
            this.loading = true
            this.modeloCrearMaquinaria.bodegaId = this.bodega
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.$refs.form.validate()
            if(this.$refs.form.validate() === true){
                this.modeloCrearMaquinaria.idEstado = 1
                const response = await this.$store.dispatch('GuardarNuevaMaquinaria', this.modeloCrearMaquinaria)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loading = false
                    return 0
                }                             
                if(response.status === 201){
                    this.alertError= false
                    this.$emit('TodasMaquinarias')
                    this.CambioFecha = false
                    this.loading = false
                    showMessageSuccess('¡BIEN!', 'Maquinaria registrada correctamente')
                    this.CerrarDialogoCrearMaquinaria()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else{
                this.loading = false
            }
        },

        //actualizamos la maquinaria por su id
        async GuardarCambiosMaquinaria(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];            
            this.$refs.form.validate()
            if(this.$refs.form.validate() === true){
                if(this.fechaCompra === undefined){
                    this.modeloCrearMaquinaria.fechaCompra = this.validarfechaobtenida
                }else if(this.CambioFecha === true){
                    this.modeloCrearMaquinaria.fechaCompra = this.fechaCompra                    
                }                         
                const response = await this.$store.dispatch('ActualizarMaquinaria', this.modeloCrearMaquinaria)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loading = false
                    return 0
                }                             
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('TodasMaquinarias')
                    showMessageSuccess('¡BIEN!', 'Maquinaria actualizada correctamente')                    
                    this.CambioFecha = false
                    this.loading = false
                    this.CerrarDialogoCrearMaquinaria()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else{
                this.loading = false
            }
        },

    },
}
</script>

<style>
    .TituloDialogo{
        font-family: 'PT Serif';
        font-size: 20px;        
    }
</style>