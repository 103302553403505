<template>
    <v-card>
        <!-- <div class="text-center pt-2 pb-2">
            <v-toolbar-title class="tituloMaquinaria">Tipos</v-toolbar-title>
        </div> -->

        <v-tabs class="mt-4"
            v-model="tab"
            background-color="#01abe6"
            centered
            dark
            icons-and-text
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
                Vehículo
                <v-icon>mdi-car</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
                Maquinaria
                <v-icon>mdi-dump-truck</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
                Herramientas
                <v-icon>mdi-tools</v-icon>
            </v-tab>

            <v-tab href="#tab-4">
                Incidentes
                <v-icon>mdi-car-cog</v-icon>
            </v-tab>

            <v-tab href="#tab-5">
                Combustible
                <v-icon>mdi-gas-station-outline</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1"
            >
                <v-card flat>
                    <v-card-text><TipoVehiculo :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-2"
            >
                <v-card flat>
                    <v-card-text><TipoMaquinaria :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-3"
            >
                <v-card flat>
                    <v-card-text><TipoHerramienta :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-4"
            >
                <v-card flat>
                    <v-card-text><TipoIncidentes :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-5"
            >
                <v-card flat>
                    <v-card-text><TipoCombustible :tab="tab"/></v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import TipoVehiculo from './TipoVehiculo'
import TipoMaquinaria from './TipoMaquinaria'
import TipoHerramienta from './TipoHerramienta'
import TipoIncidentes from './TipoIncidentes'
import TipoCombustible from './TipoCombustible'

    export default {
        components: {
            TipoVehiculo,
            TipoMaquinaria,
            TipoHerramienta,
            TipoIncidentes,
            TipoCombustible,
        },

        data () {
            return {
                tab: null,
            }
        },
    }
</script>