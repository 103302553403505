import Vue from 'vue'

const store = {
    actions: {
        // obtenemos todas las Proyectos
        getAllProyectos: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Proyecto')
                return response
            } catch (e) {
                return e.response
            }
        },

        // guardarmos una Proyecto
        SaveProyecto: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Proyecto', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos una Proyecto por su id
        GetProyectoById: async({}, id) => {
            try {
                const response = await Vue.axios.get('/Proyecto/byid/' + id)
                return response
            } catch (error) {
                return error.response
            }
        },
        // guardamos los cambios de una Proyecto
        SaveChangesProyecto: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Proyecto', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de vehiculos de una Proyecto del modulo admin
        InventarioVehiculosPorProyectoAdmin: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/vehiculos/' + data.idProyecto + "/sucursal/" + data.idSucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de maquinaria de una Proyecto del modulo admin
        InventarioMaquinariaPorProyectoAdmin: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/maquinarias/' + data.idProyecto + "/sucursal/" + data.idSucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de herramienta de una Proyecto del modulo admin
        InventarioHerramientaPorProyectoAdmin: async({}, datos) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/herramientas/' + datos.idProyecto + '/sucursal/' + datos.idSucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de materia o inusmos de una Proyecto del modulo admin
        InventarioMaterialInsumosPorProyectoAdmin: async({}, datos) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/materiales/' + datos.idProyecto + '/sucursal/' + datos.idSucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de vehiculos de una Proyecto
        InventarioVehiculosMiProyecto: async({}) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/vehiculos/miproyecto')
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de maquinaria de una Proyecto
        InventarioMaquinariaMiProyecto: async({}) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/maquinarias/miproyecto')
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de herramientas de una Proyecto
        InventarioHerramientaMiProyecto: async({}) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/herramientas/miproyecto')
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos el inventario de material o insumos de una Proyecto
        InventarioMaterialInsumoMiProyecto: async({}) => {
            try {
                const response = await Vue.axios.get('/Proyecto/inventario/materiales/miproyecto')
                return response
            } catch (error) {
                return error.response
            }
        },
        // devuelve la Proyecto del usuario
        DevuelveProyectoUSuario: async({}) => {
            try {
                const response = await Vue.axios.get('/Proyecto/miProyecto')
                return response
            } catch (error) {
                return error.response
            }
        },
        // mostramos pdf de inventarios
        InventarioGeneralPDF: async({}, datos) => {
            try {
                const response = await Vue.axios.get('/Proyecto/pdf/' + datos.idProyecto + '/rubro/' + datos.rubro + '/idSucursal/' + datos.idSucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // mostramos corte de una Proyecto
        CorteProyectoPDF: async({}, datos) => {
            try {
                const response = await Vue.axios.get('/Proyecto/corte/' + datos.idProyecto + '/sucursal/' + datos.idSucursal + '/usuario/' + datos.usuario, {
                    responseType: 'arraybuffer'
                })
                return response
            } catch (error) {
                return error.response
            }
        },
        // descargar bitacora de cortes pdf
        DescargarBitacoraDeCorte: async({}, idCorte) => {
            try {
                const response = await Vue.axios.get('/Proyecto/corte/pdf/' + idCorte, {
                    responseType: 'arraybuffer'
                })
                return response
            } catch (error) {
                return error.response
            }
        },
        // obtenemos bitacoras de corte por Proyecto
        ObtenerBitacoraDeCortes: async({}, idProyecto) => {
            try {
                const response = await Vue.axios.get('/Proyecto/corte/bitacora/' + idProyecto)
                return response
            } catch (error) {
                return error.response
            }
        },
        misSucursales: async({}) => {
            try {
                return await Vue.axios.get('/sucursal/misSucursales')
            } catch (error) {
                return error.response
            }
        },
        //obtiene todas las sucursales
        AllSucursales: async({}) => {
            try {
                return await Vue.axios.get('/Sucursal')
            } catch (error) {
                return error.response
            }
        },
        newSucursal: async({}, data) => {
            try {
                return await Vue.axios.post('/sucursal', data)
            } catch (error) {
                return error.response
            }
        },
        updateSucursal: async({}, data) => {
            try {
                return await Vue.axios.put('/sucursal', data)
            } catch (error) {
                return error.response
            }
        },
        addBodega: async({}, data) => {
            try {
                return await Vue.axios.post('/sucursal/bodegas', data)
            } catch (error) {
                return error.response
            }
        },
        updateBodega: async({}, data) => {
            try {
                return await Vue.axios.put('/sucursal/bodegas', data)
            } catch (error) {
                return error.response
            }
        },
        EliminarSucursal: async({}, id) => {
            try {
                return await Vue.axios.delete('/sucursal/ById/'+ id)
            } catch (error) {
                return error.response
            }
        },
        EliminarBodegaSucursal: async({}, id) => {
            try {
                return await Vue.axios.delete('/sucursal/bodega/ById/'+ id)
            } catch (error) {
                return error.response
            }
        }
    }
}

export default store