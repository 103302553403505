<template>
    <v-layout row justify-center >
        <v-dialog v-model="DialogoEditarIncidente" persistent max-width="650px" scrollable>
            <v-card class="FuenteLetra">
                <v-app-bar flat dark color="#3c8dbc">
                    <v-toolbar-title class="FuenteLetra">Editar Incidente {{Titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn large rounded color="success" :loading="loading" :disabled="loading"
                        @click.native="GuardarCambiosIncidente()" class="mx-2 TituloDialogo"
                    >
                        Guardar Cambios<v-icon right dark>fas fa-save</v-icon>
                    </v-btn>
                    <v-btn dark large rounded color="error" @click.native="CerrarDialogoEditarIncidente()" class="mx-2 TituloDialogo">
                            Cerrar<v-icon right dark>far fa-window-close</v-icon>
                    </v-btn>                    
                </v-app-bar>
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>   
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                          
                <v-card-text>
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- tipo incidente -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-2 pb-0">
                                <v-select outlined 
                                    label="Tipo Incidente"
                                    :items="ArrayTiposIncidentes"
                                    item-value="idTipoIncidente"
                                    item-text="nombre"
                                    prepend-inner-icon="mdi-hammer-screwdriver"
                                    v-model="modeloEditarIncidente.idTIpoIncidente"
                                ></v-select>                            
                            </v-col>  
                            <!-- fecha -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-2 pb-0">
                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"                                
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined
                                            v-model="modeloEditarIncidente.fecha"
                                            label="Fecha"
                                            readonly
                                            prepend-inner-icon="far fa-calendar-alt"
                                            v-bind="attrs"
                                            v-on="on"                                        
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker locale="es-GT" v-model="FechaEditarIncidente" @input="menu2 = false"></v-date-picker>
                                </v-menu>                                
                            </v-col>  
                            <!-- ubicacion -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined                                    label="Ubicación"
                                    prepend-inner-icon="mdi-map-marker"
                                    laber="Ubicación"
                                    v-model="modeloEditarIncidente.ubicacion"
                                ></v-text-field>                          
                            </v-col>                                                                                  
                            <!-- kilometraje -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined type="number"
                                    label="Kilometraje"
                                    prepend-inner-icon="mdi-speedometer-medium"
                                    v-model="modeloEditarIncidente.kilometraje"
                                ></v-text-field>
                            </v-col> 
                            <!-- taller/mecanico/colaborador -->
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                <v-text-field outlined
                                    label="Taller/Mecánico/Colaborador"
                                    prepend-inner-icon="mdi-account"
                                    v-model="modeloEditarIncidente.tallerMecanicoOColaborador"
                                ></v-text-field>
                            </v-col> 
                            <!-- descripcion -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <v-textarea
                                    outlined
                                    label="Descripción"
                                    auto-grow
                                    rows="1"
                                    row-height="15"                                
                                    prepend-inner-icon="mdi-receipt"
                                    v-model="modeloEditarIncidente.descripcion"                                    
                                ></v-textarea>
                            </v-col> 
                            <!-- observacion -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <v-textarea
                                    outlined
                                    label="Observaciones"
                                    auto-grow
                                    rows="1"
                                    row-height="15"                                
                                    prepend-inner-icon="mdi-receipt"
                                    v-model="modeloEditarIncidente.observacion"                                    
                                ></v-textarea>
                            </v-col>                                                     
                        </v-row>
                    </v-form>                                      
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../services/sweetAlert'
export default {
    props:{
        DialogoEditarIncidente: Boolean,
        validarfecha: String,
        modeloEditarIncidente: Object,
        Titulo: String,
    },

    data: vm => ({
        // alerta cuando el api deja de funcionar
        alertError:false,        

        loading: false,
        valid: false,
        lazy: false,        
        // items de ubicacion 
        itemsUbicacion: [
            {nombre: 'Predio'},
            {nombre: 'Oficinas Centrales'},
            {nombre: 'Taller'},
        ],        
        ArrayTiposIncidentes:[],
        menu2: false,
        // fecha
        FechaEditarIncidente: new Date().toISOString().substr(0, 10), 
        // errores de registros duplicados y vacios
        alertErrorPost:false,
        arrayErrorPost:[],        
    }),

    watch: {
        FechaEditarIncidente (val) {
            const [year, month, day] = val.split('-')
            this.modeloEditarIncidente.fecha = `${day}/${month}/${year}`
        }
    },

    created() {
        this.ObtenerTodosIncidentes()
    },
    methods: { 

        CerrarDialogoEditarIncidente(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
            this.$emit('CerrarDialogoEditarIncidente')
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        
    //obtenemos todos los incidentes para mostrarlo en un select
        async ObtenerTodosIncidentes(){
            const response = await this.$store.dispatch('getAllTypeIncidents')
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                                
            if(response.status === 200){
                this.alertError= false
                this.ArrayTiposIncidentes = response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },   
    //guardamos los cambios realizados
        async GuardarCambiosIncidente(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            
            if(this.Titulo === 'Vehiculo'){            
                const response = await this.$store.dispatch('ActualizarIncidenteVehiculo', this.modeloEditarIncidente)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                                  
                if(response.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    this.loading = false
                    showMessageSuccess('¡BIEN!','Incidente actualizado correctamente')
                    this.CerrarDialogoEditarIncidente()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }else if(this.Titulo === 'Maquinaria'){               
                const response2 = await this.$store.dispatch('ActualizarIncidenteMaquinaria', this.modeloEditarIncidente)
                if(typeof response2 === 'undefined'){
                    this.ControllerException()
                    return 0
                }                                    
                if(response2.status === 200){
                    this.alertError= false
                    this.$emit('actualizarTabla')
                    this.loading = false
                    showMessageSuccess('¡BIEN!','Incidente actualizado correctamente')
                    this.CerrarDialogoEditarIncidente()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }
        }     
    },
}
</script>