<template> 
    <v-dialog 
        v-model="dialogoMensajeRetornarCentral"
        persistent 
        max-width="500px"
    >
        <v-card  style="border: 2px solid black">
            <div class="text-center py-6">
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>                
                <v-icon size="50" color="success">fas fa-exchange-alt</v-icon>
                <h2 class="titulomensaje">¿Esta seguro que desea Retornar {{datosVehiculoMaquinaria.VehiculoMaquinaria}} a Sucursal origen ?</h2>
                <!-- <h2 class="titulomensaje">{{tituloMensajeEliminar}}</h2> -->
            </div>
            <hr class="mt-2">
            <div class="text-center">
                <span class="fuenteTitulo"><strong>Código:</strong> {{datosVehiculoMaquinaria.codigo}}</span> <br>
                <span class="fuenteTitulo"><strong>Placa:</strong> {{datosVehiculoMaquinaria.Placa}} </span> <br>
                <span class="fuenteTitulo"><strong>Tipo:</strong> {{datosVehiculoMaquinaria.Tipo}} </span> <br>
                <span class="fuenteTitulo"><strong>Marca:</strong> {{datosVehiculoMaquinaria.Marca}} </span> <br>
                <v-form ref="form" v-model="valid" lazy-validation>                
                    <v-textarea label="Observación" outlined v-model="Observacion" class="mx-5 mt-5" required :rules="nameRules"></v-textarea>                
                </v-form>
            </div>
            <hr>            
            <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                <v-btn large rounded class="mx-2" color="success" @click="RetornarVehiculoCentral()"
                    :loading="loadingMensajeRetornarCentral" :disabled="loadingMensajeRetornarCentral"
                >Si, Retornar
                    <v-icon right dark>fas fa-save</v-icon>
                </v-btn>            
                <v-btn dark large rounded class="mx-2" color="error" @click="CerrarModalRetornarVehiculoACentral()">Cancelar
                    <v-icon right dark>far fa-window-close</v-icon>
                </v-btn>                        </v-card-actions>
        </v-card>        
        <ControlladorExcepciones :response="responseStatus"/>  
    </v-dialog>      
</template>

<script>
import ControlladorExcepciones from '../../../components/ControlandoExcepcionesHTTPS'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
export default {
    props:{
        dialogoMensajeRetornarCentral: Boolean,
        datosVehiculoMaquinaria: Object,
        alertError: Boolean,       
        idVehiculo: Number,
    },

    components:{
        ControlladorExcepciones,
    },

    data() {
        return {
            valid: true,
            nameRules: [
                v => !!v || 'Campo requerido',
            ] ,           
            Observacion: '',   
            loadingMensajeRetornarCentral: false,
            responseStatus:[],
        }
    },

    methods: {
        CerrarModalRetornarVehiculoACentral(){
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.$emit('CerrarModalRetornarVehiculoACentral')
            this.Observacion = ""
        },
        async RetornarVehiculoCentral(){
            if(this.$refs.form.validate()){
                this.loadingMensajeRetornarCentral = true
                var data = {idVehiculo: this.idVehiculo, observacion: this.Observacion}
                const response = await this.$store.dispatch('RetornarVehiculoSucursalCentralByVehiculo', data)
                if(response.status === 200){                    
                    this.$emit('ObtenerInventarioVehiculosPorSucursal')
                    this.alertError= false
                    this.loadingMensajeRetornarCentral = false
                    this.CerrarModalRetornarVehiculoACentral()
                    showMessageSuccess('BIEN!', 'Vehículo retornado exitosamente a Sucursal de origen.')
                }else{
                    this.responseStatus = response
                }            
            }
        },        
    },
}
</script>