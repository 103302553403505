<template>
    <v-dialog v-model="AbrirTablaDeHerramientasPorSolicitud" fullscreen scrollable persistent :overlay="false" transition="dialog-transition">
        <v-card>
            
            <v-card-title class="AppBar" dark elevation="0" style="color: #ffffff">
                Herramientas de solicitud
                <v-spacer></v-spacer>
                <v-btn color="#d80000" rounded dark @click="CerrarModalHerramientasDeSolicitud()"> 
                    <v-icon left>fas fa-times-circle</v-icon> Cerrar
                </v-btn>
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <!-- <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>             -->
                    <div class="my-5">
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-file-alt</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Solicitud: {{codigoSolicitud}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-building</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Proyecto: {{DatosSolicitud.proyecto}}</v-toolbar-title>
                        </v-chip>                      
                        <v-chip color="black" outlined class="mx-2">
                            <v-icon left>fas fa-home</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Sucursal: {{DatosSolicitud.sucursal}}</v-toolbar-title>
                        </v-chip>                      
                    </div>
                    <v-toolbar title elevation="1">
                        <v-chip color="black" outlined>
                            <v-icon left>fas fa-tools</v-icon>
                            <v-toolbar-title class="tituloMaquinaria">Herramientas</v-toolbar-title>
                        </v-chip>                      
                        <v-divider vertical class="ml-3"></v-divider>    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistro"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>      
                    </v-toolbar>   
                    <v-alert :value="alertErrorPost"
                            type="error"
                            align="left"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>
                    <v-data-table
                        :headers="EncabezadoTablaListadoSolicitudHerramientasEnProceso"
                        :items="HerramientasBySolicitud"
                        class="elevation-1"
                        :search="buscarRegistro"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."             
                        item-key="sol_SucursalHerramientaId"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-tooltip v-if="filtrarEstadoSolicitudHerramienta==1" left color="#d80000" >
                                <template v-slot:activator="{ on }">
                                    <v-btn x-small dark fab color="#d80000" v-on="on" @click="AbrirMensajeEliminarItem(item)" >
                                        <v-icon small>fas fa-times-circle</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Eliminar Herramienta</span>
                            </v-tooltip>                                
                            <v-tooltip v-if="filtrarEstadoSolicitudHerramienta==1" left color="#d69b01" >
                                <template v-slot:activator="{ on }">
                                    <v-btn x-small dark fab color="#d69b01" v-on="on" @click="AbrirActualizarCantidadHerramienta(item)" >
                                        <v-icon small>fas fa-edit</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Editar Cantidad Herramienta</span>
                            </v-tooltip>                                
                        </template>                            
                        <template v-slot:no-data v-if="HerramientasBySolicitud.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                            >
                                No existen solicitudes registradas
                            </v-alert>
                        </template>                                                            -->
                    </v-data-table>   
                </div>
                <div v-if="filtrarEstadoSolicitudHerramienta==1" class="pt-10">
                    <v-toolbar title elevation="1">
                        <v-chip color="black" outlined>
                            <v-icon left>fas fa-tools</v-icon>
                            <v-toolbar-title class="">Herramientas disponibles</v-toolbar-title>
                        </v-chip>                                              
                        <v-divider vertical class="ml-3"></v-divider>    

                        <v-spacer></v-spacer>      
                        <v-text-field outlined class=" pr-4"
                            label="Buscar Registro"
                            v-model="buscarRegistroHerramientasDisponibles"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-toolbar>         
                    <v-data-table
                        :headers="EncabezadoTablaHerramientas"
                        :items="ArrayTodasHerramientas"
                        item-key="idHerramienta"
                        class="elevation-1"
                        :search="buscarRegistroHerramientasDisponibles"
                        :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                        :loading="loading"
                        loading-text="Cargando Datos..."    
                    >
                        <template v-slot:no-data v-if="ArrayTodasHerramientas.length === 0">
                            <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                            >
                                No existen solicitudes registradas
                            </v-alert>
                        </template>                    
                        <template v-slot:item.action="{ item }">
                            <v-tooltip left color="#000000">
                                <template v-slot:activator="{ on }">
                                    <v-btn x-small class="ml-2" dark fab color="#000000" v-on="on" @click="AgregarNuevaHerramientaASolicitud(item)" >
                                        <v-icon small>fas fa-plus-circle</v-icon>
                                    </v-btn>                        
                                </template>
                                <span class="fontButton">Agregar Nueva Herramienta a Solicitud</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>                                                           
                </div>
            </v-card-text>
        </v-card>
        <ComponentNuevoItemSolicitud :AgregarNuevoItemASolicitud="AgregarNuevoItemASolicitud" :datosHerramienta="datosHerramienta"
            @RefreshHerramientasBySolicitud="RefreshHerramientasBySolicitud"
            @cerrar="cerrarNuevaITem" :idHerramienta="idHerramienta" :ProyectoSolicitudId="ProyectoSolicitudId"
            @CerrarModalHerramientasDeSolicitud="CerrarModalHerramientasDeSolicitud" @ObtenerListadoDeSolicitudesDeHerramientas="ObtenerListadoDeSolicitudesDeHerramientas"
        />
        <ComponentEditarCantidadHerramientaItemSolicitud :ActualizarCantidadHerramientaSolicitud="ActualizarCantidadHerramientaSolicitud" :datosHerramienta="datosHerramienta"
            @cerrar="cerrar" :idHerramienta="idHerramienta" :ProyectoSolicitudId="ProyectoSolicitudId" :sol_ProyectoHerramientaId="sol_ProyectoHerramientaId"
            @CerrarModalHerramientasDeSolicitud="CerrarModalHerramientasDeSolicitud" @ObtenerListadoDeSolicitudesDeHerramientas="ObtenerListadoDeSolicitudesDeHerramientas"
        />
        <ComponentEliminarItem 
            :DialogoEliminarItem="DialogoEliminarItem" :TituloEliminarItem="TituloEliminarItem" :LoadingEliminarItem="LoadingEliminarItem"
            @CerrarMensajeEliminarItem="CerrarMensajeEliminarItem" @EliminarItemDeLaSolicitud="EliminarItemDeLaSolicitud" :sol_ProyectoHerramientaId="sol_ProyectoHerramientaId"
        />
    </v-dialog>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import BotonActualizarTabla from '../../../views/BotonActualizarTabla'
import ComponentNuevoItemSolicitud from '../../../components/bodega/AgregarNuevoItemASolicitudHerramientas'
import ComponentEditarCantidadHerramientaItemSolicitud from '../../../components/bodega/ActualizarItemDeLaSolicitudHerramientas'
import ComponentEliminarItem from '../../../components/bodega/QuitarItemDeLaSolicitud'
export default {
    props:{
        AbrirTablaDeHerramientasPorSolicitud: Boolean,
        HerramientasBySolicitud: Array,
        codigoSolicitud: String,
        DatosSolicitud: Object,
        proyecto: Number,
        sucursal:Number,
        bodega:Number,
        ProyectoSolicitudId: Number,
        filtrarEstadoSolicitudHerramienta:Number
    },
    components:{
        BotonActualizarTabla,
        ComponentNuevoItemSolicitud,
        ComponentEditarCantidadHerramientaItemSolicitud,
        ComponentEliminarItem,
    },

    data() {
        return {
            loading: false,
            alertError:false,
            buscarRegistro:'',
            ArrayHerramientasPorSolicitudEnviada: [],
            EncabezadoTablaListadoSolicitudHerramientasEnProceso:[
                {text: 'ID Herramienta', value: 'herramienta.idHerramienta', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Herramienta', value: 'herramienta.catalogoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca Herramienta', value: 'herramienta.catalogoHerramienta.marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Cantidad Solicitada', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Cantidad Autorizada', value: 'cantidadAprobada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Fecha Creación', value: 'fecha_creacion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'herramienta.bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
                {text: 'Acción', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                                
            ],
            TituloOpcion:'',
            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],      

            buscarRegistroHerramientasDisponibles:'',      
            ArrayTodasHerramientas: [],
            EncabezadoTablaHerramientas:[
                {text: 'ID Herramienta', value: 'idHerramienta', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Herramienta', value: 'catalogoHerramienta.tipoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Nombre', value:'catalogoHerramienta.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'catalogoHerramienta.marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],            
            cantidadSolicitada:0,
            idHerramienta:0,
            AgregarNuevoItemASolicitud: false,
            TituloDialogo: '',
            datosHerramienta:{
                nombreHerramienta:'',
                tipoHerramienta:'',
                marcaHerramienta:'',
                existencia:0,
                cantidadSolicitada:0,
            },
            sol_ProyectoHerramientaId:0,
            // editar cantidad solicitada de una herramienta
            ActualizarCantidadHerramientaSolicitud: false,

            // eliminar item de herramienta
            DialogoEliminarItem: false,
            TituloEliminarItem: '',
            LoadingEliminarItem: false,            
        }
    },

    watch:{
        AbrirTablaDeHerramientasPorSolicitud(){
            if(this.AbrirTablaDeHerramientasPorSolicitud) this.ObtenerTodasLasHerramientas();
        }
    },


    methods: {
        //-----------------------------------------------------------------------------
        async ObtenerTodasLasHerramientas (){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = true
            var data = {proyecto: this.proyecto, sucursal: this.sucursal, bodega: this.bodega}
            const response = await this.$store.dispatch('TodasHerramientasSolicitud', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.ArrayTodasHerramientas = response.data
                this.loading = false
            }else{
                this.ControlladorExcepciones(response)
            }                                            
        },        
        //agregamos una nueva herramienta a la solicitud
        async AgregarNuevaHerramientaASolicitud(item){
            this.idHerramienta = item.idHerramienta
            this.datosHerramienta.nombreHerramienta = item.catalogoHerramienta.nombre
            this.datosHerramienta.tipoHerramienta = item.catalogoHerramienta.tipoHerramienta.nombre
            this.datosHerramienta.marcaHerramienta = item.catalogoHerramienta.marcaHerramienta.nombre
            this.datosHerramienta.existencia = item.cantidad

            // console.log('herramienta: ', item)
            this.AgregarNuevoItemASolicitud = true
        },
        cerrarNuevaITem(){
            this.AgregarNuevoItemASolicitud = false
        },
        //actualizamos la cantidad solicitada de una herramienta de una solicitud
        async AbrirActualizarCantidadHerramienta(item){
            this.sol_ProyectoHerramientaId = item.sol_ProyectoHerramientaId
            this.idHerramienta = item.idHerramienta
            this.datosHerramienta.nombreHerramienta = item.herramienta.catalogoHerramienta.nombre
            this.datosHerramienta.tipoHerramienta = item.herramienta.catalogoHerramienta.tipoHerramienta.nombre
            this.datosHerramienta.marcaHerramienta = item.herramienta.catalogoHerramienta.marcaHerramienta.nombre
            this.datosHerramienta.cantidadSolicitada = item.cantidadSolicitada
            this.datosHerramienta.existencia = item.herramienta.cantidad

            // console.log('herramienta: ', item)
            this.ActualizarCantidadHerramientaSolicitud = true
        },
        cerrar(){
            this.ActualizarCantidadHerramientaSolicitud = false
        },
        //eliminamos un item de la solicitud x
        AbrirMensajeEliminarItem(item){
            console.log('itemMaterial: ', item)
            this.sol_ProyectoHerramientaId = item.sol_ProyectoHerramientaId
            this.idHerramienta = item.idHerramienta
            this.DialogoEliminarItem = true
            this.TituloEliminarItem = "Herramienta: " + item.herramienta.catalogoHerramienta.nombre
        },
        CerrarMensajeEliminarItem(item){
            this.DialogoEliminarItem = false
        },
        async EliminarItemDeLaSolicitud(){
            this.LoadingEliminarItem = true
            var data = {solicitudProyectoId: this.ProyectoSolicitudId, sol_ProyectoHerramientaId: this.sol_ProyectoHerramientaId,
                sol_ProyectoMaterialId:0, sol_ProyectoVehiculoId:0, sol_ProyectoMaquinariaId:0
            }
            // console.log('eliminarItem: ', data)
            const response = await this.$store.dispatch('DeleteItemRequest', data)
            if(response.status === 200){
                this.ObtenerListadoDeSolicitudesDeHerramientas()
                //this.CerrarModalHerramientasDeSolicitud()
                this.alertErrorPost=false;this.arrayErrorPost=[];
                showMessageSuccess('BIEN!','Herramienta eliminada correctamente de la solicitud.')
                this.CerrarMensajeEliminarItem()
                this.LoadingEliminarItem = false
            }else{
                this.ControlladorExcepciones(response)
            }                                                        
        },
        // ediitar la cantidad solicitada de herramienta de una solicitud
        // eliminamos un item de la solicitud x
        // AbrirMensajeEliminarItem(item){
        //     this.sol_ProyectoHerramientaId = item.sol_ProyectoHerramientaId
        //     console.log('itemMaterial2222: ', item)
        //     this.idHerramienta = item.idHerramienta
        //     this.DialogoEliminarItem = true
        //     this.TituloEliminarItem = "Herramienta: " + item.herramienta.catalogoHerramienta.nombre
        // },
        // CerrarMensajeEliminarItem(item){
        //     this.DialogoEliminarItem = false
        // },        
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
            this.LoadingEliminarItem = false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                // this.alertErrorPost=true
                // var object = response.data.errors
                // for (var property in object){
                //     this.arrayErrorPost.push({description: `${object[property]}`})
                // }
                showMessageError('Oops!', response.data.errors[0])            
                this.loading = false     
                this.LoadingEliminarItem = false
                // this.ObtenerListadoDeSolicitudesDeHerramientas()
                // setTimeout(() => {
                //     this.alertErrorPost=false
                // }, 12000);         
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
                this.LoadingEliminarItem = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.LoadingEliminarItem = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.LoadingEliminarItem = false
            }                
        },
        async RefreshHerramientasBySolicitud(){
            await this.$emit('RefreshHerramientasBySolicitud')
        },
                
        CerrarModalHerramientasDeSolicitud(){
            this.$emit('CerrarModalHerramientasDeSolicitud')
        },
        async ObtenerListadoDeSolicitudesDeHerramientas(){
            await this.$emit('ObtenerListadoDeSolicitudesDeHerramientas')
        }
    },
}
</script>

<style>
    .AppBar {
        border: 2px black outset;
        background-color: #3c8dbc;
    }       
</style>