var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.dialogConfigureUser),callback:function ($$v) {_vm.dialogConfigureUser=$$v},expression:"dialogConfigureUser"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"card":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v("Configuracion del usuario: "+_vm._s(_vm.userName))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","flat":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Save")])],1)],1),_c('v-card-text',[_c('v-tabs',{attrs:{"centered":"","color":"cyan","dark":"","icons-and-text":""}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" Roles "),_c('v-icon',[_vm._v("fas fa-dice-d6")])],1),_c('v-tab',{attrs:{"href":"#tab-2"},on:{"click":function($event){return _vm.llenarArrayModulos()}}},[_vm._v(" Modulos "),_c('v-icon',[_vm._v("fas fa-american-sign-language-interpreting")])],1),_c('v-tab',{attrs:{"href":"#tab-3"},on:{"click":function($event){return _vm.getAUserAcciones()}}},[_vm._v(" Acciones "),_c('v-icon',[_vm._v("fab fa-artstation")])],1),_c('v-tab-item',{attrs:{"value":'tab-' + 1}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v("Roles Asignados")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":"","color":"#00B40F"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"small":"","fab":"","dark":"","color":"#00B40F"},on:{"click":function($event){return _vm.OpenDialoAsignarRol()}}},on),[_c('v-icon',[_vm._v("far fa-plus-square ")])],1)]}}])},[_c('span',[_vm._v("ASIGNAR NUEVO ROL")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headersUserRoles,"items":_vm.dessertsUserRoles},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.nombreRol))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.nombreApp))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.nombreModulo))]),_c('td',{staticClass:"text-xs-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#00B40F"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"small":"","fab":"","dark":"","color":"#00B40F"},on:{"click":function($event){return _vm.deleteRol(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar Rol")])])],1)]}}])})],1)],1)],1),_c('v-tab-item',{attrs:{"value":'tab-' + 2}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v("Modulos Asignados")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersUserModulos,"items":_vm.dessertsUserModulos},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.nombre))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.icono))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.url))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.rol))]),_c('td',{staticClass:"text-xs-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#00B40F"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"small":"","fab":"","dark":"","color":"#00B40F"},on:{"click":function($event){return _vm.deleteRol(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar Rol")])])],1)]}}])})],1)],1)],1),_c('v-tab-item',{attrs:{"value":'tab-' + 3}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v("Acciones de Modulos")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersUserAcciones,"items":_vm.dessertsUserAcciones},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.moduloNombre))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.moduloURL))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.moduloRol))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.accionNombre))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.accionIcono))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.accionUrl))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.accionPermiso))]),_c('td',{staticClass:"text-xs-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#00B40F"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-3",attrs:{"small":"","fab":"","dark":"","color":"#00B40F"},on:{"click":function($event){return _vm.deleteRol(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar Rol")])])],1)]}}])})],1)],1)],1)],1),_c('asignarRol',{attrs:{"IdUser":_vm.IdUser,"dialogAsignarRol":_vm.dialogAsignarRol,"itemsApplications":_vm.itemsApplications},on:{"updateConfig":function($event){return _vm.updateConfig()},"closeDialoAsignarRol":function($event){return _vm.closeDialoAsignarRol()}}})],1),_c('div',{staticStyle:{"flex":"1 1 auto"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }