import Vue from 'vue'

const store = {
    actions: {
    //obtenemos todas los tipos de vehiculos
            AllTypesOfVehicules : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Tipos/vehiculos')
                    return response
                }catch(e){
                    return e.response
                }
            },
        // guardamos un tipo de vehiculo
            SaveTypeVehicle: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/Tipos/vehiculos', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // eliminar un tipo de vehiculo
            DeleteTypeVehicle: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.delete('/Tipos/vahiculos/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // obtener un tipo de vehiculo por su id
            GetTypeVehicleById: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.get('/Tipos/vehiculos/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // editar un tipo de vehiculo
            EditTypeVehicleById: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/Tipos/vehiculos', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
    //peticiones de tipos de maquinarias
        // obtenemos todas los tipos de maquinarias
            getAllTypeMachinery : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Tipos/maquinarias')
                    return response
                }catch(e){
                    return e.response
                }
            },    
        // guardamos un tipo de maquinaria
            SaveTypeMachinery: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/Tipos/maquinaria', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // eliminar un tipo de maquinaria
            DeleteTypeMachinery: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.delete('/Tipos/maquinarias/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },            
        // obtener un tipo de maquinaria por su id
            GetTypeMachineryById: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.get('/Tipos/maquinarias/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },        
        // editar un tipo de maquinaria por su id
            EditTypeMachineryById: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/Tipos/maquinarias', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },                
    //peticiones de tipos de Herramientas
        // obtenemos todas los tipos de Herramientas
            getAllTypeTools : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Tipos/Herramientas')
                    return response
                }catch(e){
                    return e.response
                }
            },    
        // guardamos un tipo de Herramienta
            SaveTypeTools: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/Tipos/Herramientas', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // eliminar un tipo de Herramienta
            DeleteTypeTools: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.delete('/Tipos/Herramientas/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },            
        // obtener un tipo de Herramienta por su id
            GetTypeToolsById: async ({}, idTipo) => {
                try {
                    const response = await Vue.axios.get('/Tipos/Herramientas/'+ idTipo)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },        
        // editar un tipo de Herramienta por su id
            EditTypeToolsById: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/Tipos/Herramientas', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },     
    //peticiones de tipos de Incidentes
        // obtenemos todas los tipos de incidentes
        getAllTypeIncidents : async ({}) => {
            try{
                const response = await Vue.axios.get('/Tipos/incidentes')
                return response
            }catch(e){
                return e.response
            }
        },    
    // guardamos un tipo de incidente
        SaveTypeIncidents: async ({}, data) => {
            try {
                const response = await Vue.axios.post('/Tipos/incidentes', data)
                return response                    
            } catch (error) {
                return error.response
            }
        },
    // eliminar un tipo de Incidente
        DeleteTypeIncidents: async ({}, idTipo) => {
            try {
                const response = await Vue.axios.delete('/Tipos/incidentes/'+ idTipo)
                return response                    
            } catch (error) {
                return error.response
            }
        },            
    // obtener un tipo de incidente por su id
        GetTypeIncidentsById: async ({}, idTipo) => {
            try {
                const response = await Vue.axios.get('/Tipos/incidentes/'+ idTipo)
                return response                    
            } catch (error) {
                return error.response
            }
        },        
    // editar un tipo de incidente por su id
        EditTypeIncidentsById: async ({}, data) => {
            try {
                const response = await Vue.axios.put('/Tipos/incidentes', data)
                return response                    
            } catch (error) {
                return error.response
            }
        },                            
    //peticiones de tipos de combustible
        // obtenemos todas los tipos de combustible
        getAllTypesFuel : async ({}) => {
            try{
                const response = await Vue.axios.get('/Tipos/combustible')
                return response
            }catch(e){
                return e.response
            }
        },    
    // guardamos un tipo de combustible
        SaveTypeFuel: async ({}, data) => {
            try {
                const response = await Vue.axios.post('/Tipos/combustible', data)
                return response                    
            } catch (error) {
                return error.response
            }
        },
    // eliminar un tipo de combustible
        DeleteTypeFuel: async ({}, idTipo) => {
            try {
                const response = await Vue.axios.delete('/Tipos/combustible/'+ idTipo)
                return response                    
            } catch (error) {
                return error.response
            }
        },            
    // obtener un tipo de combustible por su id
        GetTypeFuelById: async ({}, idTipo) => {
            try {
                const response = await Vue.axios.get('/Tipos/combustible/'+ idTipo)
                return response                    
            } catch (error) {
                return error.response
            }
        },        
    // editar un tipo de combustible por su id
        EditTypeFuelById: async ({}, data) => {
            try {
                const response = await Vue.axios.put('/Tipos/combustible', data)
                return response                    
            } catch (error) {
                return error.response
            }
        },                            
    //peticiones de unidades de medida
        // obtenemos todas las unidades de medida
        getAllUnitsMeasure : async ({}) => {
            try{
                const response = await Vue.axios.get('/UnidadesDeMedida')
                return response
            }catch(e){
                return e.response
            }
        },    
    // guardamos una unidad de medida
        SaveUnitMeasure: async ({}, data) => {
            try {
                const response = await Vue.axios.post('/UnidadesDeMedida', data)
                return response                    
            } catch (error) {
                return error.response
            }
        },
    // eliminar una unidad de medida
        DeleteUnitMeasure: async ({}, idUnidad) => {
            try {
                const response = await Vue.axios.delete('/UnidadesDeMedida/'+ idUnidad)
                return response                    
            } catch (error) {
                return error.response
            }
        },            
    // obtener una unidad de medida por su id
        GetUnitsMeasureById: async ({}, idUnidad) => {
            try {
                const response = await Vue.axios.get('/UnidadesDeMedida/'+ idUnidad)
                return response                    
            } catch (error) {
                return error.response
            }
        },        
    // editar una unidad de medida por su id
        EditUnitMeasureById: async ({}, data) => {
            try {
                const response = await Vue.axios.put('/UnidadesDeMedida', data)
                return response                    
            } catch (error) {
                return error.response
            }
        },   
    // obtiene listado de tipos de movimientos de invenario
        tipoMovimientoInventario: async ({}) => {
            try {
                const response = await Vue.axios.get('/Tipos/movInventario')
                return response                    
            } catch (error) {
                return error.response
            }
        },   
        // se realiza la busqueda por rubro y criterio
        SearchReports : async ({}, data ) => {
            try{
                const response = await Vue.axios.get('/Reportes/buscador/rubro/'+data.rubro+'/criterio/'+data.criterio)
                return response
            }catch(e){
                return e.response
            }
        },         
                       
    }
}

export default store