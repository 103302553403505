import Vue from 'vue'

const store = {
    actions: {
    //obtenemos todas las marcas de vehiculos / maquinaria
            getAllBrands : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Marcas/vehiculoOrMaquinaria')
                    return response
                }catch(e){
                    return e.response
                }
            },
        // guardamos una marca
            SaveBrand: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/Marcas/vehiculoOrMaquinaria', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // eliminar una marca
            DeleteBrand: async ({}, idMarca) => {
                try {
                    const response = await Vue.axios.delete('/Marcas/vehiculoOrMaquinaria/'+ idMarca)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // obtener una marca por su id
            GetBrandById: async ({}, idMarca) => {
                try {
                    const response = await Vue.axios.get('/Marcas/vehiculoOrMaquinaria/'+ idMarca)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // editar una marca
            EditBrandById: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/Marcas/vehiculoOrMaquinaria', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
    //peticiones a marcars de herramientas
        // obtenemos todas las marcas de herramientas
            getAllBrandsTools : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Marcas/Herramientas')
                    return response
                }catch(e){
                    return e.response
                }
            },    
        // guardamos una marca
            SaveBrandTools: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/Marcas/Herramientas', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // eliminar una marca
            DeleteBrandTools: async ({}, idMarcaHerramienta) => {
                try {
                    const response = await Vue.axios.delete('/Marcas/Herramientas/'+ idMarcaHerramienta)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },            
        // obtener una marca por su id
            GetBrandToolsById: async ({}, idMarcaHerramienta) => {
                try {
                    const response = await Vue.axios.get('/Marcas/Herramientas/'+ idMarcaHerramienta)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },        
        // editar una marca
            EditBrandToolsById: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/Marcas/Herramientas', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },                
    //peticiones a marcars de materia prima
        // obtenemos todas las marcas de materia prima
            getAllRawMaterial : async ({}) => {
                try{
                    const response = await Vue.axios.get('/Marcas/MateriaPrima')
                    return response
                }catch(e){
                    return e.response
                }
            },
        // guardamos una marca
            SaveRawMaterial: async ({}, data) => {
                try {
                    const response = await Vue.axios.post('/Marcas/MateriaPrima', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },        
        // eliminar una marca
            DeleteRawMaterial: async ({}, idMarca) => {
                try {
                    const response = await Vue.axios.delete('/Marcas/MateriaPrima/'+ idMarca)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // obtener una marca por su id
            GetRawMaterialById: async ({}, idMarca) => {
                try {
                    const response = await Vue.axios.get('/Marcas/MateriaPrima/'+ idMarca)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
        // editar una marca
            EditRawMaterialById: async ({}, data) => {
                try {
                    const response = await Vue.axios.put('/Marcas/MateriaPrima', data)
                    return response                    
                } catch (error) {
                    return error.response
                }
            },
    }
}

export default store