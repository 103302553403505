<template>
<div id="app">
    <!-- funcionalidad del menu----------------------------------------------------------------- -->
    <v-navigation-drawer app v-model="drawer" permanent expand-on-hover hide-overlay :mini-variant.sync="mini" width="auto"  v-if="oidcIsAuthenticated">
        <v-toolbar dense color="#3c8dbc" dark>
            <span>Menú</span>
            <v-spacer></v-spacer>
            <v-btn class="mb-0 pb-0" icon @click="mini =!mini">
                <v-icon right>mdi-chevron-left</v-icon>
            </v-btn>                                
        </v-toolbar>
        <v-card elevation="0">
            <v-list>
                <v-list-item>
                    <v-list-item-avatar color="white" class="fa-2x" v-if="oidcIsAuthenticated">
                        <v-icon color="black">fas fa-user</v-icon>
                    </v-list-item-avatar>                
                    <v-list-item-content>
                        <span class="ml-2" @click="showUserProfile()">{{userNameComputedMenu}} </span>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <v-divider></v-divider>
        <v-list nav dense>
            <v-list-group 
                v-for="item in userMenu" 
                :key="item.titulo"
                :prepend-icon="item.icono"
                no-action
            >                    
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.titulo"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item    
                    v-for="subitem in item.subMenus" 
                    :key="subitem.titulo"
                    :to="{path: subitem.url }"
                >
                    <v-list-item-icon>
                        <v-icon v-text="subitem.icono" left></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="subitem.titulo"></v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
    <!-- fin del menu del sistema----------------------------------------------------------------- -->

    <v-app-bar app color="#3c8dbc" dense elevation="0" dark flat>
        <!-- titulo de la barra -->
        <v-toolbar-title>CORPORACIÓN REMON DE GUATEMALA</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- boton de inicio -->
        <v-tooltip bottom color="#3c8dbc" v-if="oidcIsAuthenticated">
            <template v-slot:activator="{on}">
                <v-btn v-on="on" text fab small @click="gohome()"><v-icon>fas fa-home</v-icon></v-btn>
            </template>
            <span>Inicio</span>
        </v-tooltip>
        <v-divider class="mx-4" vertical></v-divider>
        <!-- boton del menu del usuario -->
        <v-menu open-on-hover offset-y bottom>
            <template v-slot:activator="{on, attrs}">
                <!-- botton del menu -->
                <v-btn text v-on="on" v-bind="attrs">
                    {{userNameComputed}}
                </v-btn>
            </template>
            <!-- cuerpo del menu desplegable -->
            <v-list>                        
                <v-list-item @click="openLogin()" v-if="!oidcIsAuthenticated">
                    <v-list-item-title>Iniciar Sesión</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showUserProfile" v-if="oidcIsAuthenticated">
                    <v-list-item-title><v-icon class="pr-4">fas fa-user-edit</v-icon> Modificar Perfil</v-list-item-title>
                </v-list-item>
                <v-list-item @click="signOutOidc" v-if="oidcIsAuthenticated">
                    <v-list-item-title><v-icon class="pr-4">fas fa-sign-out-alt</v-icon> Cerrar Sesión</v-list-item-title>
                </v-list-item>
            </v-list>     
        </v-menu>
    </v-app-bar>
</div>
</template>


<script>
import { mapGetters, mapActions  } from 'vuex'
import {showMessageError,showMessageSuccess} from '../../services/sweetAlert';

export default {
    name: 'toolbar',
    data() {
        return {
            expandOnHover: false,
            UserName:'Identificate',
            title_user: 'Pedro',
            drawer: true,
            mini: true,
            router:false,
            userMenu:[],
            itemsmenu:[
                {
                    titulo: "Administración", icono: 'mdi-book',
                    roles: ['Administrador'],
                    subMenus: [
                        { titulo:' Colaboradores', icono:'mdi-account-group', url:'/colaboradores'},
                        { titulo:' Sucursales', icono:'mdi-city', url:'/sucursales'},
                        { titulo:' Proyectos', icono:'mdi-domain', url:'/proyectos'}
                    ]
                },
                {
                    titulo: "Almacen", icono: 'mdi-warehouse',
                    roles: ['Administrador','Operador'],
                    subMenus: [
                        {titulo: 'Herramientas', icono:'mdi-hammer-screwdriver', url:'/herramientas'},
                        {titulo: 'Maquinaria', icono:'mdi-truck', url:'/maquinaria'},
                        {titulo: 'Materiales o Insumos', icono:'fas fa-dolly', url:'/materialdeconstruccion'},
                        {titulo: 'Vehículos', icono:'mdi-car', url:'/vehiculos'},
                    ]
                },
                {
                    titulo: "Bodegas", icono: 'mdi-home',
                    roles: ['Administrador','Operador'],
                    subMenus: [
                        {titulo: 'Solicitud de Maquinaria', icono:'mdi-truck', url:'/BodegaSolMaquinarias'},
                        {titulo: 'Solicitud de Vehículos', icono:'mdi-car', url:'/BodegaSolVehiculos'},
                        {titulo: 'Solicitud de Herramientas', icono:'mdi-hammer-screwdriver', url:'/bodegaSolHerramienta'},
                        {titulo: 'Solicitud de Materiales o Insumos', icono:'fas fa-dolly', url:'/bodegaSolMateriales'},
                    ]
                },
                {
                    titulo: "Ingreso Nueva Información", icono: 'mdi-plus-circle-outline',
                    roles: ['Administrador'],
                    subMenus: [
                        {titulo: 'Marca', icono:'mdi-car', url:'/marca'},
                        {titulo: 'Tipo', icono:'mdi-car', url:'/tipo'},
                        {titulo: 'Tipo de Licencia', icono:'mdi-card-account-details-outline', url:'/tipodelicencia'},
                        {titulo: 'Unidades de Medida', icono:'mdi-arrow-expand-vertical', url:'/unidadesdemedida'},
                        {titulo: 'Herramientas', icono:'mdi-hammer-screwdriver', url:'/catalogoHerramientas'},
                        {titulo: 'Materiales o Insumos', icono:'fas fa-dolly', url:'/catalogoMateriales'},
                    ]
                },
                {
                    titulo: "Inventarios", icono: 'mdi-clipboard-list-outline',
                    roles: ['Administrador'],
                    subMenus: [
                        { titulo:' Buscador', icono:'mdi-magnify', url:'/buscador'},
                        { titulo:' Por Proyecto', icono:'mdi-home', url:'/inventarioProyecto'},
                        // { titulo:' Reversar Mov. Herramienta', icono:'mdi-undo-variant', url:'/reversarMovimientoHerramienta'},
                    ]
                },
                {
                    titulo: "Mantenimiento", icono: 'mdi-truck',
                    roles: ['Administrador'],
                    subMenus: [
                        {titulo: 'Maquinaria', icono:'mdi-truck', url:'/maquinariamantenimiento'},
                        {titulo: 'Vehículos', icono:'mdi-car', url:'/vehiculosmantenimiento'},
                    ]
                },
            ],  
            namespace:'oidcStore/',   
            
            // variables para obtener los menus y submenus asignados al usuario
            AllMenus: [],
            idUser:'',
        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),

        hasAccess: function() {
            return this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']
        },
        userNameComputedMenu: function () {
            if(this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']){
                var user = this.$store.getters['oidcStore/'+'oidcUser']
                this.obtenerOpcionesDeMenu()
                return user.name
            }else{
                return 'Iniciar Sesión'
            }
        },

        userNameComputed: function () {
            if(this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']){
                var user = this.$store.getters['oidcStore/'+'oidcUser']
                this.idUser = user.sub
                return user.name
            }else{
                return 'Iniciar Sesión'
            }
        }
    },
    
    methods: {
        ...mapActions('oidcStore', ['authenticateOidcSilent',
                'signOutOidc', 'authenticateOidc']),
        
        openLogin(){
            this.$router.push('autenticate')
        },
        showUserProfile(){
            this.$router.push('userProfile')
        },
        gohome(){
            this.$router.push('/autenticate')
        },
        async obtenerOpcionesDeMenu(role){
           if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
               if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    var a = this.itemsmenu.filter(x => x.roles && x.roles.some(y => y === this.$store.getters['oidcStore/oidcUser'].role))
                    this.userMenu = a
                    return 0
               }else{
                   //preguntar al backend por el menu del usuario
                    const response = await this.$store.dispatch('GetMyMenu')
                    if(response.status){
                        if(response.status === 200){
                            this.userMenu = response.data
                            return 0
                        }else if(response.status === 400){
                            showMessageError('Error 400','Petición mala')
                        }else if(response.status === 401){
                            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        }else if(response.status === 404){
                            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        }else if(response.status === 500){
                            showMessageError('Error 500','Error en el servidor')            
                        }                                    
                    }
                    this.userMenu = []
                    return 0
               }
           }
        },

        // obtenemos todos los menus asignados al usuario y lo mostramos en el  menu desplegable
        async GetAllMenu() {
            this.AllMenus=[]
            const response = await this.$store.dispatch('getAllMenus', this.idUser);
            if(response.status === 200){
                this.AllMenus= response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }            
        },        
    },


}
</script>
<style>
  .border-logo{
    border-radius:2px;
  }

</style>