<template>
    <div>
        <v-card flat>
            <v-toolbar-title class="titulovehiculos text-center">Registro de Incidentes de Vehículos</v-toolbar-title>
            <v-toolbar flat title>
                <v-divider vertical class="ml-3"></v-divider>    

                <v-spacer></v-spacer>      
                <v-text-field outlined class=" pr-4"
                    label="Buscar Vehículo"
                    v-model="buscarVehiculo"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
            </v-toolbar>
            <v-data-table
                dense
                :headers="EncabezadoTablaVehiculo"
                :items="ArrayToddosVehiculos"
                :search="buscarVehiculo"
                item-key=""
                class="elevation-1"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading="loading"
                loading-text="Cargando Datos..."                
            >
                <template v-slot:item.idEstado="{ item }">
                    <v-chip color="#00b40f" text-color="white" v-if="item.idEstado">Activo
                    </v-chip>      
                    <v-chip color="#d80000" text-color="white" v-if="!item.idEstado">Inactivo
                    </v-chip>                                          
                </template>            
            <!-- mostramos los botones que apareceran en cada registro de la tabla -->
                <template v-slot:item.action="{ item }">
                    <v-tooltip bottom color="#0019d8" v-if="permisos.crear">
                        <template v-slot:activator="{ on }">
                            <v-btn small color="#0019d8" dark outlined icon v-on="on" @click="AbrirDialogoRegistroDeIncidentes(item)">
                                <v-icon>fas fa-car-crash</v-icon>
                            </v-btn>
                        </template>
                        <span>Registrar Incidente</span>
                    </v-tooltip>                                          
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark outlined icon color="#d69b01" v-on="on" @click="MostrarIncidenteVehiculo(item)">
                                <v-icon small>fas fa-eye</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Ver Incidente del Vehículo</span>
                    </v-tooltip>                                              
                </template>
            <!-- mostramos un mensaje si no se encuentran datos en la BD        -->
                <template v-slot:no-data v-if="ArrayToddosVehiculos.length === 0">
                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                    >
                        NO EXISTEN VEHICULOS REGISTRADOS
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>                    
                </template>                
            </v-data-table>
        </v-card>
        <RegistrarIncidenteDeVehiculo 
            :DialogoRegistroDeIncidentes="DialogoRegistroDeIncidentes" @CerrarDialogo="CerrarDialogoRegistroDeIncidentes"
            :Titulo="Titulo" :DataVehiculo="DataVehiculo"
        />
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import RegistrarIncidenteDeVehiculo from '../../../components/Mantenimiento/Vehiculos/RegistrarIncidenteDeVehiculo'
import { mapGetters, mapActions  } from 'vuex'

export default {
    components: {
        RegistrarIncidenteDeVehiculo,
    },

    data() {
        return {
            sucursalesAsignadas:[],
            sucursalBodegas:[],
            bodega:0,
            bodegaId:0,
            sucursal:0, 

            EsNuevo: false,
            Titulo: '',
            loading: false,
            buscarVehiculo: '',
            EncabezadoTablaVehiculo:[
                {text: 'Id', value: 'idVehiculo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Código', value: 'codigo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Nombre', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Tipo Vehículo', value: 'tipoVehiculo.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                //{text: 'Modelo', value: 'modelo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto Actual', value: 'proyecto.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Estado', value: 'idEstado', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],    
            ArrayToddosVehiculos:[],
            DialogoRegistroDeIncidentes: false,
            DataVehiculo:{
                idVehiculo:'',
                tipo: '',
                marca:'',
                placa:'',
                propietario:''
            },

            //variables para los permisos del colaborador
            namespace:'oidcStore/',    
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },         
            EstadoPermisoEditar: true,     
            // alerta cuando el api deja de funcionar
            alertError:false,                                              
        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    

    created() {
        //this.obtenerSucursalesAsignadas()
        this.TodosVehiculos()
        this.ObtenerPermisosSubmenuUsuario()
        // console.log(this.permisos)

    },

    methods: {                      
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        
        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),        

            async ObtenerPermisosSubmenuUsuario(role){
            if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.permisos.crear = true
                        this.permisos.editar = true
                        this.permisos.eliminar = true
                        return 0            
                }else{
                    //preguntar al backend por los permisos del submenu del usuario
                        var url = 'vehiculosmantenimiento'
                        const response  = await this.$store.dispatch('PermisosSubMenu', url)
                        if(typeof response === 'undefined'){
                            this.ControllerException()
                            return 0
                        }                         
                        if(response.status === 200){
                            this.alertError= false
                            this.permisos = response.data      
                            // console.log(this.permisos)
                        }else if(response.status === 400){
                            showMessageError('Error 400','Petición mala')
                        }else if(response.status === 401){
                            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')            
                        }else if(response.status === 404){
                            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        }else if(response.status === 500){
                            showMessageError('Error 500','Error en el servidor')            
                        }
                        return 0
                }
            }
            },

        //obtenemos todos los vehiculos para mostrarlo en la tabla
            async TodosVehiculos(){
                this.loading = true
                var data = {idEstado : 1, bodega:0, sucursal:0} 
                const response = await this.$store.dispatch('ObtenerTodosLosVehiculos', data)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayToddosVehiculos = response.data   
                    this.loading = false  
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false  
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false  
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false  
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false  
                }
            },     
        //abrimos el dialogo con los incidente del vehiculo seleccionado
            AbrirDialogoRegistroDeIncidentes(item){
                if(this.permisos.crear) this.EstadoPermisoEditar = true
                this.Titulo = 'Vehículo'
                this.EsNuevo = true
                this.DataVehiculo.idVehiculo = item.idVehiculo
                this.DataVehiculo.tipo = item.tipoVehiculo.nombre
                this.DataVehiculo.marca = item.vehiculoMarca.nombre
                this.DataVehiculo.placa = item.placa
                this.DataVehiculo.propietario = item.propietario
                this.DialogoRegistroDeIncidentes = true
            },

            CerrarDialogoRegistroDeIncidentes(){
                this.DialogoRegistroDeIncidentes = false
            },
        //abrimos el modal de incidentes de un vehiculo
            MostrarIncidenteVehiculo(item){
                this.Titulo = 'Vehiculo'
                this.$router.push('/Mantenimiento/VerIncidente/'+this.Titulo+'/'+item.idVehiculo)
            },
    },
}
</script>

<style>
    .titulovehiculos {
        font-family: 'PT Serif';
        font-size: 25px;        
    }
</style>