<template>
    <v-alert :value="alertErrorPost"
            type="error"
            outlined
            border="left"
            transition="scale-transition"
            color="deep-orange">
            <h4>Errores:</h4>
            <ul>
                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                    <h6>{{i.description}}</h6>
                </li>
            </ul>
    </v-alert>  
</template>
<script>
export default {
    data: () => ({

    }),
    props:{
        alertErrorPost:Boolean,
        arrayErrorPost:Array
    }
}
</script>