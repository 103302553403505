<template>
    <div>
        <v-dialog
            v-model="AbrirModalVerMotivoRechazo"
            scrollable  
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-file-import</v-icon>
                    <v-toolbar-title class="TituloDialogo">Motivo Rechazo de Solicitud</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarDialogoVerMotivoRechazoSolicitud()">Cerrar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Mensaje</span>
                    </v-tooltip>                    
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Código:</strong> {{DatosSolictudRechazado.codigo}}</span> <br>
                    <span class="fuenteTitulo"><strong>Fecha Rechazo:</strong> {{DatosSolictudRechazado.fechA_MODIFICACION}}</span> <br>
                </div>
                <hr>
                <v-card-text class="TituloDialogo">
                    <h4><strong>Motivo</strong></h4>
                    <v-subheader>{{DatosSolictudRechazado.informacion}}</v-subheader>
                </v-card-text>                
            </v-card>                        
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        DatosSolictudRechazado:Object,
        AbrirModalVerMotivoRechazo: Boolean,
    },

    data() {
        return {
            
        }
    },

    methods: {
        CerrarDialogoVerMotivoRechazoSolicitud(){
            this.$emit('CerrarDialogoVerMotivoRechazoSolicitud')
        }
    },
}
</script>