<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialogClave" persistent max-width="600px">
      <v-card>
        <v-alert dense outlined type="error" v-if="alertErrorAPI">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>        
        <v-card-title>
          <span class="headline">Cambiar contraseña</span>
        </v-card-title>
        <v-alert :value="alertError"
                    :type=typeAlert
                    transition="scale-transition"
                    :color=typeAlert
                    outlined>
                    <h4>{{titleAlert}}</h4>
                <ul>
                  <li type="circle" v-for="(i) in arrayErrorPost" :key="i.id">
                      <h5>{{i.value}}</h5>
                  </li>
              </ul>
            </v-alert>
        <v-card-text>
          <v-form v-model="valid" ref="form" :lazy-validation="lazy">
                <v-container>
                      <v-row>
                        <v-col xs12 md12>
                          <v-text-field  @keypress="activeButton = true" :type="show1 ? 'text' : 'password'" v-model="model.password" 
                          @click:append="show1 = !show1" 
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="ContraseñaRules" label="Nueva contraseña" required ></v-text-field>
                        </v-col>
                        <v-col xs12 md12>
                          <v-text-field @keypress="activeButton = true" :type="show1 ? 'text' : 'password'" v-model="model.confirmPassword" 
                          @click:append="show1 = !show1" 
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="ContraseñaRules" label="Escriba de nuevo la contraseña" required ></v-text-field>
                        </v-col>
                      </v-row>                        
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
        <!-- mostramos el circular progress cuando haga la transaccion de cambio de contraseña -->
        <v-progress-circular v-if="EstadoCircularProgress" indeterminate :size="60" :width="6" color="black"></v-progress-circular>
          <v-spacer></v-spacer>
          <v-btn color="#016fbf" dark @click="guardar" rounded v-if="activeButton">
            <v-icon left> fas fa-save</v-icon> <strong>Cambiar Contraseña</strong>  
          </v-btn>
          <v-btn color="#d80000" dark @click="cancelar" rounded>
              <v-icon left> fas fa-times</v-icon> <strong>{{textButton}}</strong>            
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import {apiAuthority} from '../../services/index.js'
import {showMessageError,showMessageSuccess,showMessageWarning} from '../../services/sweetAlert'
import { StringDecoder } from 'string_decoder';
import store from '@/store'

  export default {
    data: () => ({
        // alerta cuando el api deja de funcionar
        alertErrorAPI:false,      
        EstadoCircularProgress: false,

        show1: false,
        titleAlert:'',
        typeAlert:'error',
        textButton: 'Cancelar',
        arrayErrorPost:[],
        activeButton:true,
        alertError:false,
        model:{
            password:'',
            confirmPassword:'',
            userID:'',
            // contraseñaActual:'',
        },
        valid: false,
        lazy: false,
        //dialogClave: false,
        ContraseñaRules: [
            v => !!v || 'contraseña es requerida',
            v => (v && v.length >= 8) || 'La contraseña debe cotener al menos 8 caracteres',
        ],
    }),
    props:{
        dialogClave:Boolean,
        id:String
    },
    methods:{
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertErrorAPI=true
            this.loading=false
        },        

        lanzar_alerta(Type,Title,Text,Footer){
        this.$swal.fire({
          type: Type,
          title: Title,
          text: Text,
          footer: Footer,
           allowOutsideClick: false
        });
      },
        claveActualizada(){
            this.model.password=''; this.model.confirmPassword='';
            this.lanzar_alerta('success', '¡ BIEN !', 'Contraseña Actualizada');
            this.$emit('claveActualizada');
        },
        pushError(error){
          // console.log('error => ',error)
          Object.keys(error).forEach((element,key,_array)=>{
            //  console.log('element => ', error[element])   
             this.parseErrorsGeneric(error[element])      
          });
        },

        parseErrorsGeneric(error){
          // console.log('error ==> ',error)
          Object.keys(error).forEach((element,key,_array)=> {
            this.arrayErrorPost.push({ value: error[element] });
          });
        },
      reset (){
          this.$refs.form.reset()
      },

      validate(){
        this.$refs.form.validate()
        if(this.$refs.form.validate() === true){
          this.guardar()
        }
      }, 

        async guardar(){
          this.EstadoCircularProgress = true
          this.arrayErrorPost = []
          this.activeButton = false
          this.model.userID = this.id
          if(this.model.password === this.model.confirmPassword){          
            const response = await this.$store.dispatch('updatePassword',this.model)
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.EstadoCircularProgress = false
                this.activeButton = true
                return 0
            }            
            if(response.status == 200){
              this.reset()
              this.alertError =true
              this.typeAlert='success'
              this.titleAlert='Contraseña Actualizada con Éxito'
              this.textButton='Cerrar'
              this.EstadoCircularProgress = false
              // this.$emit('cancelClave')
            }
            if(response.status == 400){
              this.alertError = true
              this.activeButton = true
              this.EstadoCircularProgress = false
              this.pushError(response.data.errors)
              this.reset()
            }
          }else{
            this.reset()
            this.alertError =true
            this.activeButton = true
            this.EstadoCircularProgress = false
            this.typeAlert='error'
            this.titleAlert='Las Contraseñas no coinciden, vuelva a intentarlo'          
          }
        },
        cancelar(){
            this.reset()
            this.alertError=false,
            this.$emit('cancelClave')
        }
    }
  }
</script>