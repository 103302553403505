<template>
    <div>
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarMensajeEliminarMaquinaria"
            :tituloMensajeEliminar="tituloMaquinaria" @Eliminar="EliminarMaquinaria"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarMaquinaria" :alertError="alertError"            
        />
        <CrearMaquinaria 
            @CerrarDialogoCrearMaquinaria="CerrarDialogoCrearMaquinaria" :Titulo="Titulo" :ArrayObtenerMaquinariaById="ArrayObtenerMaquinariaById"
            :DialogoCrearMaquinaria="DialogoCrearMaquinaria" @TodasMaquinarias="TodasMaquinarias" :EsNuevo="EsNuevo"
            :EstadoPermisoEditar="EstadoPermisoEditar" :EstadoPermisoCrear="EstadoPermisoCrear" :EstadoPermisoEliminar="EstadoPermisoEliminar"
            :validarfechaobtenida="validarfecha"     :bodega="bodegaId"           
        />
        <CambiarSucursal :NombreSucursalActual="NombreSucursalActual"
            :DialogoCambiarSucursal="DialogoCambiarSucursal" @CerrarModalCambiarSucursal="CerrarModalCambiarSucursal"
            :DatosRegistro="DatosRegistro" :NombreModulo="NombreModulo" :ArrayProyectos="ArrayProyectos"
            :EstadoPermisoEditar="EstadoPermisoEditar" @TodasMaquinarias="TodasMaquinarias"
        />
        <CambiarSucursalYBodegaMaquinaria :NombreSucursalActual="NombreSucursalActual"
            :DialogoCambiarSucursalYBodega="DialogoCambiarSucursalYBodega" @CerrarModalCambiarSucursalyBodegaMaquinaria="CerrarModalCambiarSucursalyBodegaMaquinaria"
            :DatosRegistro="DatosRegistro" :NombreModulo="NombreModulo" :sucursalesAsignadas="sucursalesAsignadas" :sucursalBodegas="sucursalBodegas"
            :EstadoPermisoEditar="EstadoPermisoEditar" @TodasMaquinarias="TodasMaquinarias"
        />
        <v-card flat>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>                                                                        
            <v-toolbar-title class="titulomaquinarias text-center">Maquinarias</v-toolbar-title>
            <div class="marginSelect">
                <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <v-select v-model="sucursal" :items="sucursalesAsignadas" menu-props="auto"
                            label="Sucursal"
                            prepend-icon="mdi-city"
                            item-text="nombre"
                            item-value="sucursalId"
                            @change="seleccionarSucursal()"
                        ></v-select>
                    </v-col>    
                    <v-col cols="12" lg="4" md="4">
                        <v-select :disabled="!sucursal"
                            v-model="bodega"
                            :items="sucursalBodegas"
                            menu-props="auto"
                            label="Bodega"
                            prepend-icon="mdi-warehouse"
                            item-text="nombre"
                            item-value="bodegaId"
                            @change="seleccionarBodega()"
                        ></v-select>
                    </v-col> 
                    <v-col>
                        <v-btn v-if="bodega > 0" icon fab dark small color="primary" @click="ResetBodega()">
                            <v-icon dark>fas fa-backspace</v-icon>
                        </v-btn>
                    </v-col>
                </v-row> 
            </div>

            <v-toolbar flat title>

                <v-text-field flat class=" pr-4"
                    label="Buscar Maquinaria"
                    v-model="buscarMaquinaria"
                    prepend-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>      

                <v-tooltip bottom color="#0019d8" >
                    <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" :disabled="activeActions" dark outlined v-on="on" @click="AbrirDialogoCrearMaquinaria()" v-if="EstadoPermisoCrear">
                            <v-icon left>fas fa-save</v-icon> Crear
                        </v-btn>
                    </template>
                    <span>Nueva Maquinaria</span>
                </v-tooltip>
                <!-- boton para actualizar tabla de herramientas -->
                <v-tooltip bottom color="#0019d8"  >
                    <template v-slot:activator="{ on }">
                            <v-btn color="#0019d8" outlined dark @click="TodasMaquinarias()"
                                v-on="on" class="TamañoLetraBoton mx-2"  :disabled="activeActions"
                            > 
                                <v-icon >fas fa-sync-alt</v-icon> 
                            </v-btn>
                    </template>
                    <span>Actualizar</span>
                </v-tooltip>                                 
            </v-toolbar>
            <v-divider dense></v-divider>      
            <v-data-table dense
                :headers="EncabezadoTablaMaquinarias"
                :items="ArrayTodasMaquinarias"
                item-key=""
                :search="buscarMaquinaria"
                class="elevation-1"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading ="loading"
                loading-text="Cargando Datos..."
            >
                <template v-slot:item.estadoMaquinaria.nombre="{ item }">
                    <v-chip color="#00b40f" text-color="white" v-if="item.idEstado">Activo
                    </v-chip>      
                    <v-chip color="#d80000" text-color="white" v-if="!item.idEstado">Inactivo
                    </v-chip>                                          
                </template>            
                <template v-slot:item.action="{ item }">
                    <FiltrosBusquedaTimeLine :DatosRegistro="item" :TituloBotonVerMovimientoTimeLine="TituloBotonVerMovimientoTimeLine"
                        :TituloComponente="TituloComponente"
                    />
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#d69b01" v-on="on" @click="AbrirDialogoEditarMaquinaria(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar</span>
                    </v-tooltip>                                              
                    <v-tooltip left color="#3c8dbc">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#3c8dbc" v-on="on" @click="AbrirModalCambiarDeSucursalYBodega(item)" v-if="EstadoPermisoCrear">
                                <v-icon small>fas fa-home</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Cambiar de Sucursal y Bodega</span>
                    </v-tooltip>                                              
                    <v-tooltip left color="#3c8dbc">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#3c8dbc" v-on="on" @click="AbrirModalCambiarSucursal(item)" v-if="EstadoPermisoCrear">
                                <v-icon small>fas fa-exchange-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Cambiar Proyecto</span>
                    </v-tooltip>                                              
                    <v-tooltip left color="#D80000">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="EstadoPermisoEliminar" outlined icon small class="ml-2" color="#D80000" v-on="on" @click="AbrirMensajeEliminarMaquinaria(item)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar</span>
                    </v-tooltip>                                              
                </template>
                <template v-slot:no-data v-if="ArrayTodasMaquinarias.length === 0">
                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                    >
                        No existen maquinarias registradas
                    </v-alert>
                </template>
            </v-data-table>            
        </v-card>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Cargando datos...
        </v-overlay>                      
    </div>
</template>

<script>
import CrearMaquinaria from '../../../components/Almacen/Maquinaria/CrearMaquinaria'
import CambiarSucursal from '../../../components/Almacen/CambiarSucursalMaquinariaVehiculo'
import CambiarSucursalYBodegaMaquinaria from '../../../components/Almacen/CambiarSucursalYBodegaMaquinaria'
import MensajeEliminar from '../../../components/MensajeEliminar'
import BotonActualizarTabla from '../../BotonActualizarTabla'
import FiltrosBusquedaTimeLine from '../../../components/Almacen/FiltroBusquedaTimeLine'

import { mapGetters, mapActions  } from 'vuex'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
export default {
    components: {
        CrearMaquinaria,
        MensajeEliminar,
        CambiarSucursal,
        BotonActualizarTabla,
        FiltrosBusquedaTimeLine,
        CambiarSucursalYBodegaMaquinaria,
    },

    data() {
        return {
            sucursalesAsignadas:[],
            sucursalBodegas:[],
            bodega:0,
            bodegaId:0,
            sucursal:-1,            
            // alerta cuando el api deja de funcionar
            alertError:false,
            // variable para mostrar un circular progress 
            EstadoCircularProgress: false,            
            loadingEliminarMaquinaria: false,
            loading: false,
            buscarMaquinaria: '',
            validarfecha: '',

            idMaquinaria: '',
            tituloMaquinaria: '',
            DialogoMensajeEliminar: false,
            DialogoCrearMaquinaria: false,
            DialogoEditarMaquinaria: false,
            Titulo: '',
            EsNuevo: true,
            ArrayTodasMaquinarias: [],
            ArrayObtenerMaquinariaById: {},
            EncabezadoTablaMaquinarias:[
                {text: 'Id', value: 'idMaquinaria', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},   
                {text: 'Maquinaria', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Código', value: 'codigo', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Tipo Maquinaria', value: 'tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Tipo Combustible', value: 'tipoCombustible.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Serie', value: 'serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                //{text: 'Propietario', value: 'propietario', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto Actual', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                //{text: 'Estado', value: 'estadoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],       
            
            // variables para cambiar sucursal
            DialogoCambiarSucursal:false,
            DatosRegistro:{},
            NombreModulo:'',
            ArrayProyectos:[],
            NombreSucursalActual: '',
            DialogoCambiarSucursalYBodega:false,

            namespace:'oidcStore/',    
            EstadoPermisoCrear: true,
            EstadoPermisoEditar: true,
            EstadoPermisoEliminar: true, 
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },         
            // variables para filtro de busqueda de timeline
            TituloBotonVerMovimientoTimeLine:"Ver movimientos de Maquinarias",
            TituloComponente: 'Maquinarias',      
            activeActions:true

        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    

    created() {
        this.obtenerSucursalesAsignadas()
        this.ObtenerPermisosSubmenuUsuario()
    },

    methods: {
        ResetBodega(){
            this.bodega = 0
            this.bodegaId = 0
            this.TodasMaquinarias()
        },
        seleccionarBodega(){
            //si ha seleccionado una sucursal y proyecto
            if(this.sucursal != '' && this.bodega != ''){
                this.activeActions=false
                this.bodegaId=this.bodega
                this.TodasMaquinarias()
            }
        },
        seleccionarSucursal(){
            this.bodega = 0
            this.sucursalesAsignadas.forEach(suc => {
                if(suc.sucursalId == this.sucursal){
                    this.sucursalBodegas = suc.bodegas
                }
            })
            this.activeActions=false
            this.TodasMaquinarias()
        },        
        async obtenerSucursalesAsignadas(){
            const response  = await this.$store.dispatch('misSucursales')
            if(response === undefined){
                showMessageError('No fue posible obtener las sucursales asignadas')
                return
            }else if(response.status === 200){
                this.sucursalesAsignadas = response.data
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.sucursalesAsignadas.push({nombre: 'Todas', sucursalId:0})
                }
            }
        },        
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        
        //obtenemos los permisos de usuario        
        ...mapActions('oidcStore', ['authenticateOidcSilent',
                'signOutOidc', 'authenticateOidc']),

        async ObtenerPermisosSubmenuUsuario(role){
           if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
               if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.permisos.crear = true
                    this.permisos.editar = true
                    this.permisos.eliminar = true
                    return 0
               }else{
                   //preguntar al backend por los permisos del submenu del usuario
                    var url = 'maquinaria'
                    const response  = await this.$store.dispatch('PermisosSubMenu', url)
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        return 0
                    }
                    if(response.status === 200){
                        this.alertError= false
                        this.permisos = response.data
                      this.EstadoPermisoCrear = response.data.crear
                      this.EstadoPermisoEditar = response.data.editar
                      this.EstadoPermisoEliminar = response.data.eliminar
                    }else if(response.status === 400){
                        showMessageError('Error 400','Petición mala')
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        this.loading = false                      
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                    }
                    return 0
               }
           }
        },
        //metodos para el mensaje de eliminar una maquinaria
            AbrirMensajeEliminarMaquinaria(item){
                // console.log('item => ', item)
                this.tituloMaquinaria = item.nombre
                this.idMaquinaria = item.idMaquinaria
                this.DialogoMensajeEliminar = true
            },
            CerrarMensajeEliminarMaquinaria(){
                this.DialogoMensajeEliminar = false
            },
        // metodos de maquinaria para crear una maquinaria
            AbrirDialogoCrearMaquinaria(){
                if(this.permisos.crear) this.EstadoPermisoEditar = true
                this.DialogoCrearMaquinaria = true
                this.Titulo = 'Crear Maquinaria'
                this.EsNuevo = true
            },
            CerrarDialogoCrearMaquinaria(){
                this.DialogoCrearMaquinaria = false
            },
        //metodos para ver y editar una maquinaria
            async AbrirDialogoEditarMaquinaria (item){
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('ObtenerMaquinariaById', item.idMaquinaria)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }                                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayObtenerMaquinariaById = response.data
                    this.validarfecha = response.data.fechaCompra
                    this.Titulo = 'Editar Maquinaria'
                    this.EsNuevo = false
                    this.DialogoCrearMaquinaria = true
                    this.EstadoCircularProgress = false
                    if(this.permisos.editar){
                        this.EstadoPermisoEditar = true
                    }else{
                        this.EstadoPermisoEditar = false
                    }
                    
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            },
            CerrarDialogoEditarMaquinaria(){
                this.DialogoEditarMaquinaria = false
            },

        //obtenemos todos las maquinarias creadas y lo mostramos en la tabla
        async TodasMaquinarias(){
            this.loading = true
            var data = {idEstado : 1, bodega:this.bodegaId, sucursal : this.sucursal} 
            const response = await this.$store.dispatch('ObtenerTodasMaquinarias', data)
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }                                             
            if(response.status === 200){
                this.alertError= false
                this.ArrayTodasMaquinarias = response.data
                this.loading = false
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }
        },
        //borramos una maquinaria
        async EliminarMaquinaria(){
            this.loadingEliminarMaquinaria = true
            const response = await this.$store.dispatch('BorrarMaquinariaById', this.idMaquinaria)
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.loadingEliminarMaquinaria = false
                return 0
            }                             
            if(response.status === 200){
                this.alertError= false
                this.TodasMaquinarias()
                this.loadingEliminarMaquinaria = false  
                this.CerrarMensajeEliminarMaquinaria()              
                showMessageSuccess('Eliminado!', 'Maquinaria eliminada')
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingEliminarMaquinaria = false  
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingEliminarMaquinaria = false  
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingEliminarMaquinaria = false  
            }
        },

        async AbrirModalCambiarSucursal(data){
            // console.log('data => ', data)
            this.EstadoCircularProgress = true
            this.NombreModulo = "Maquinaria"
            this.DatosRegistro = data
            if(data.proyecto != null){
                this.NombreSucursalActual = data.proyecto.nombre
            }else{this.NombreSucursalActual=''}
            
            const response = await this.$store.dispatch('getAllProyectos')
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.EstadoCircularProgress = false
                return 0
            }else if(response.status === 200){
                this.alertError= false
                this.ArrayProyectos = response.data
                this.EstadoCircularProgress = false
                this.DialogoCambiarSucursal = true
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.EstadoCircularProgress = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.EstadoCircularProgress = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.EstadoCircularProgress = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')    
                this.EstadoCircularProgress = false        
            }                            
        },

        async AbrirModalCambiarDeSucursalYBodega(data){
            this.NombreModulo = "Maquinaria"
            this.DatosRegistro = data
            this.DialogoCambiarSucursalYBodega = true
        },

        CerrarModalCambiarSucursalyBodegaMaquinaria(){
            this.DialogoCambiarSucursalYBodega = false
        },

        CerrarModalCambiarSucursal(){
            this.DialogoCambiarSucursal = false
        },
    },
}
</script>
<style>
    .titulomaquinarias {
        font-family: 'PT Serif';
        font-size: 25px;        
    }
</style>