<template>
    <div>
        <v-toolbar title elevation="1" color="blue" dark>
            <v-icon left>fas fa-car-side</v-icon>
            <v-toolbar-title class="tituloMaquinaria">Vehículos a solicitar a inventarios</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>    
            <v-btn large color="primary" dark rounded class="mx-2 TituloDialogo"  @click="EnviarTodasLasSolicitudes()"
                :loading="loading" :disabled="loading || !ActivarBotonEnviarSolicitud"
            >Enviar Solicitudes
                <v-icon right dark>fas fa-save</v-icon>
            </v-btn>                                       
        </v-toolbar>
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>        
        <v-data-table
            :headers="EncabezadoTablaVehiculosASolicitarAInventarios"
            :items="ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar"
            item-key="idHerramienta"
            class="elevation-1"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom color="#D80000">
                    <template v-slot:activator="{ on }">
                        <v-btn  small class="ml-2" dark fab color="#D80000" v-on="on" @click="QuitarVehiculoDeLaSolicitudAEnviar(item)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Quitar vehículo de la solicitud</span>
                </v-tooltip>                                              
            </template>        
            <template v-slot:no-data v-if="ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" >
                    Agregar vehículos a esta nueva solicitud
                </v-alert>
            </template>                    
        </v-data-table>        
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../../services/sweetAlert'

export default {
    props:{
        ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar: Array,
        proyecto: Number,
        sucursal:Number,
        bodega:Number,
    },

    components:{

    },

    data() {
        return {
            loading: false,
            EncabezadoTablaVehiculosASolicitarAInventarios:[
                {text: 'Id', value:'idVehiculo', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Código', value: 'codigo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Nombre', value: 'nombreVehiculo', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Tipo Vehículo', value: 'tipoVehiculo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Modelo', value: 'modelo', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            ActivarBotonEnviarSolicitud:false,
            // errores registros duplicados y vacíos
            alertErrorPost:false,
            arrayErrorPost:[],                        
        }
    },
    watch:{
        ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar(){
            if(this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.length >0){
                this.ActivarBotonEnviarSolicitud = true
            }else{
                this.ActivarBotonEnviarSolicitud = false
            }
        },        
        sucursal(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
        },
    },

    methods: {
        async EnviarTodasLasSolicitudes(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[]; 
            var data = {vehiculos: this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar, proyecto: this.proyecto, sucursal: this.sucursal, bodega: this.bodega} 
            const response = await this.$store.dispatch('EnviarSolicitudesDeVehiculosABodega', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.loading = false
                this.alertError= false
                showMessageSuccess('¡BIEN!', response.data.message)
                this.$emit('ObtenerInventarioDeVehiculosDisponibles')
            }else{
                this.ControlladorExcepciones(response)
            }            
        },
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.alertErrorPost = false
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false       
                this.alertErrorPost = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.alertErrorPost = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.alertErrorPost = false
            }                
        },
        //quitamos el vehiculo dde la solicitud que no quiere agregar el usuario
        QuitarVehiculoDeLaSolicitudAEnviar(item){
            const index =this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.indexOf(item)
            this.ArrayObtenerTodasLasSolicitudesDeVehiculosDeInventarioASolicitar.splice(index,1)
        },                        
    },
}
</script>