<template> 
    <v-dialog 
        v-model="DialogoCancelarSolicitud"
        persistent 
        max-width="700px"
    >
        <v-card  style="border: 2px solid black">
            <div class="text-center py-6">
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>                
                <v-icon size="50" color="red">fas fa-times</v-icon>
                <h2 class="titulomensaje">¿Esta seguro de que desea cancelar la Solicitud?</h2>
                <h2 class="titulomensaje">{{tituloCancelarSolicitud}}</h2>
            </div>
            <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                <v-btn large rounded class="mx-2" color="primary" @click="EliminarSoliciutd()"
                    :loading="loadingCancelarSolicitud" :disabled="loadingCancelarSolicitud"
                >Si, Cancelar
                    <v-icon right dark>fas fa-save</v-icon>
                </v-btn>            
                <v-btn dark large rounded class="mx-2" color="error" @click="CerrarFormularioCancelarSolicitud()">Salir
                    <v-icon right dark>far fa-window-close</v-icon>
                </v-btn>                        </v-card-actions>
        </v-card>        
    </v-dialog>        
</template>

<script>
import { showMessageSuccess } from '../../services/sweetAlert'
export default {
    props: {
        DialogoCancelarSolicitud: Boolean,
        tituloCancelarSolicitud: String,
        loadingCancelarSolicitud: Boolean,
        alertError: Boolean,
    },
    data() {
        return {
            
        }
    },
    methods: {
        CerrarFormularioCancelarSolicitud(){
            this.$emit('CerrarFormularioCancelarSolicitud')
        },
        EliminarSoliciutd(){
            this.$emit('EliminarSoliciutd')
        }
    },
}
</script>

<style >
    .borders{
        border-style: 6px solid red;
    }
    .titulomensaje{
        font-family: 'PT Serif';
        font-size: 25px;  
    }
</style>