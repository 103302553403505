<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialogAsignarRol" persistent max-width="600px">
      
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">Asignar Nuevo Rol</span>
        </v-card-title>
        <v-card-text>

            </v-card-title>
            <v-card-text>
                <v-flex xs12 md10>
                        <v-select :return-object="true"  @change="changeValueApp()"  v-model="modeloApplication" hint="Seleccione Applicacion" persistent-hint="" item-text="nombre"  
                            :items="itemsApplications" menu-props="auto" label="Seleccionar app..." prepend-icon="fas fa-dice-d6" single-line 
                            :rules="[v => !!v || 'Seleccione una opcion']">
                        </v-select>
                    </v-flex>
            </v-card-text>
        <v-divider></v-divider>
            <v-card-text>
                <v-flex xs12 md10>
                        <v-select :return-object="true"  @change ="changeValueRol()" v-model="modeloRol" hint="Seleccione Rol" persistent-hint="" item-text="aspRoles.name"  
                            :items="itemsRolesByApp" menu-props="auto" label="Seleccionar rol..." prepend-icon="fas fa-dice-d6" single-line 
                            :rules="[v => !!v || 'Seleccione una opcion']">
                        </v-select>
                    </v-flex>
            </v-card-text>
        <v-divider></v-divider>
           
            
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="close()">Cancelar</v-btn>
          <v-btn color="blue darken-1" flat @click="AsignarRol()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { apiAuthority } from '../../services/index';
import {showMessageError,showMessageSuccess,showMessageWarning} from '../../services/sweetAlert'

  export default {
    data: () => ({
        modeloApplication:{},
        modeloRol:{},
        itemsRoles:[],
        dialog: false,
        modeloAsignarRol:{
            idUser: '',
            idRoleApplication:0,
            idAplication: 0,
        },
        itemsRolesByApp:[],
    }),
    props: {
        IdUser:String,
        dialogAsignarRol: Boolean,
        itemsApplications:Array,
    },
    methods:{
        async changeValueApp(){
            //alert('vamos a traer los roles de la app')
            this.itemsRolesByApp=[]
            this.modeloAsignarRol.idAplication= this.modeloApplication.idAplication
            this.itemsRolesByApp= await this.getRolesByApp();
        },
        changeValueRol(){
          this.modeloAsignarRol.idRoleApplication = this.modeloRol.idRoleApplication
          this.modeloAsignarRol.idUser = this.IdUser
        },
        close(){
            this.itemsRolesByApp=[]
            this.$emit('closeDialoAsignarRol')
        },
        AsignarRol(){
            apiAuthority.post('/Usersroles/asignateRole', this.modeloAsignarRol)
            .then(response => {
              showMessageSuccess('Bien !', 'Rol asignado correctamente')
              this.$emit('updateConfig')
            }).catch(error => {
              showMessageError('Ocurrio un error al intentar asignar el rol')
            });
        },
        //funciones sincronas JavaScript
        async getRolesByApp(){
            return await  apiAuthority.get('/RoleAplication/minified/'+this.modeloApplication.idAplication)
                            .then(response => {
                                return response.data
                            }).catch(error => {
                                showMessageError('Ocurrio un error al intentar registrar el modulo')
                            });
        }
    }
  }
</script>