<template>
  <v-app>
    <v-content>
      <toolbar />
        <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import toolbar from '@/components/homepage/toolbar.vue'

export default {
  name: 'App',

  components: {
    toolbar,
  },

  data: () => ({
    //
  }),
};
</script>
