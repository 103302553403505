<template>
   <v-card outlined >
  <v-card-title>
    
  </v-card-title>
    <v-form >
      <v-container>
      <v-row wrap >
        <v-col cols="6" sm="6" md="16" >
          <v-text-field outlined v-model="modeloActualizarInfo.email" prepend-inner-icon="fas fa-envelope" label="Correo electrónico" disabled/>
        </v-col>
        <v-col cols="6" sm="6" md="6" >
          <v-text-field outlined v-model="modeloActualizarInfo.alias" prepend-inner-icon="fas fa-user-friends" label="Alias" disabled/>
        </v-col>
      </v-row>
      <updatePassword :id="id" :dialogClave="dialogClave" @cancelClave="cancelClave" @claveActualizada="claveActualizada" />
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn class="mr-4" @click="changePassword" color="#d69b01" dark rounded>Cambiar contraseña 
          <v-icon right>fas fa-key</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'
  import updatePassword from './UpdatePassword.vue'
  import { mapGetters, mapActions  } from 'vuex'
  import {showMessageError,showMessageSuccess} from '../../services/sweetAlert';

  import store from '@/store'

  export default {
    data: () => ({
      BloquearBoton: false,
      aliasusuario: '',
      emailusuario: '',
      modeloActualizarInfo:{
        idUser:'',
        alias: '',
        email: '',
      },
      id:'',
      dialogClave:false,
    }),

    components:{
      updatePassword
    },

    computed: {
      
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),

      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('You must agree to continue!')
        return errors
      },
      selectErrors () {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('Item is required')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
      // emailErrors () {
      //   const errors = []
      //   if (!this.$v.email.$dirty) return errors
      //   !this.$v.email.email && errors.push('Must be valid e-mail')
      //   !this.$v.email.required && errors.push('E-mail is required')
      //   return errors
      // },
    },

    mounted() {
      var user = this.$store.getters['oidcStore/'+'oidcUser']
      // this.email = user.name
      // console.log('user', user)
      this.ObtenerUsuario(user)
    },
updated() {
  this.EstadoBoton()
  
},

    methods: {
      EstadoBoton(){
        if(this.modeloActualizarInfo.email === this.emailusuario && this.modeloActualizarInfo.alias === this.aliasusuario){
          this.BloquearBoton = true
        }
        else{
          this.BloquearBoton = false
        }
      },

      claveActualizada(){

      },
      cancelClave(){
        this.dialogClave=false
      },
      changePassword () {
        this.dialogClave = true
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.email = ''
        this.alias = ''
        this.select = null
        this.checkbox = false
      },

      async ObtenerUsuario (user){
        this.modeloActualizarInfo.idUser = user.sub
          // console.log('obtenerusuario', this.modeloActualizarInfo)
        const response = await this.$store.dispatch('getUserById', user.sub)
        if(response.status === 200){
          this.modeloActualizarInfo.email = response.data.email
          this.modeloActualizarInfo.alias = response.data.alias
          this.aliasusuario = response.data.alias
          this.emailusuario = response.data.email
        }else if(response.status === 400){
            showMessageError('Error 400','Petición mala')
        }else if(response.status === 401){
            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
        }else if(response.status === 404){
            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
        }else if(response.status === 500){
            showMessageError('Error 500','Error en el servidor')            
        }
      },

      async GuardarCambios(){
        const response = await this.$store.dispatch('ActualizarInfoUsuario', this.modeloActualizarInfo)
        if(response.status === 200){
          showMessageSuccess('Bien!', 'Información del Usuario Actualizada con Éxito')
        }else if(response.status === 400){
            showMessageError('Error 400','Petición mala')
        }else if(response.status === 401){
            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
        }else if(response.status === 404){
            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
        }else if(response.status === 500){
            showMessageError('Error 500','Error en el servidor')            
        }
      }
    },
  }
</script>