<template>
    <div>
        <!-- <h1 class="text-center">Generar PDF</h1> -->
        <iframe class="mt-1" style="border: inset 3px black" width="100%" height="650" :src="URLPDF" ></iframe>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
    },

    data() {
        return {
            URLPDF: '',
        }
    },

    created() {
        this.GenerarPDFVehiculoMaquinaria()
    },

    methods: {
        GenerarPDFVehiculoMaquinaria(){
            this.URLPDF= process.env.VUE_APP_BACKEND_HOST + '/Vehiculos/pdf/incidentes/'+this.id
        }
    },
}
</script>