<template>
    <div>
        <v-card flat>
            <!-- <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>             -->
            <v-toolbar flat title>
                <v-toolbar-title class="tituloMaquinaria">Proyectos</v-toolbar-title>
                <v-divider vertical class="ml-3"></v-divider>    

                <v-spacer></v-spacer>      
                <v-text-field outlined class=" pr-4"
                    label="Buscar Sucursal"
                    v-model="buscarSucursal"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
                <v-tooltip bottom color="#0019d8" v-if="permisos.crear">
                    <template v-slot:activator="{ on }">
                        <v-btn color="#0019d8" @click="AbrirModalNuevaSucursal()" dark rounded v-on="on" >
                            <v-icon left>fas fa-save</v-icon> Crear
                        </v-btn>
                    </template>
                    <span>Nuevo Proyecto</span>
                </v-tooltip>                                      
            </v-toolbar>
            <v-data-table
                :headers="EncabezadoSucursales"
                :items="ArraySucursales"
                class="elevation-1"
                :search="buscarSucursal"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading="loading"
                loading-text="Cargando Datos..."                                
            >
                <template v-slot:item.action="{ item }">
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#d69b01" v-on="on" @click="AbrirModalEditarSucursal(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar Sucursal</span>
                    </v-tooltip>                                              
                    <!-- <v-tooltip left color="#D80000">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#D80000" v-on="on" >
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Cambiar Estado Proyecto</span>
                    </v-tooltip>                                               -->
                </template>         
                <template v-slot:no-results>
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                        Sucursal no existe
                    </v-alert>                        
                </template>
                <template v-slot:item.estadoProyecto="{ item }">
                    <v-chip color="#00b40f" text-color="white" v-if="item.estadoProyecto==='Activo'">{{item.estadoProyecto}}
                    <v-icon right small>fas fa-check</v-icon></v-chip>      
                    <v-chip color="#d69b01" text-color="white" v-if="item.estadoProyecto==='Suspendido'">{{item.estadoProyecto}}
                    <v-icon right small>fas fa-ban</v-icon></v-chip>      
                    <v-chip color="#0019d8" text-color="white" v-if="item.estadoProyecto==='Finalizado'">{{item.estadoProyecto}}
                    <v-icon right small>fas fa-check-double</v-icon></v-chip>                                          
                </template>                                
                <template v-slot:item.recomendacion="{ item }">
                    <v-chip color="#D80000" text-color="white" v-if="item.recomendacion!== ''">{{item.recomendacion}}
                    <v-icon right small>fas fa-user-plus</v-icon></v-chip>      
                </template>                                
                <template v-slot:no-data v-if="ArraySucursales.length === 0">
                    <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError">
                        No existen Sucursales registradas
                    </v-alert>
                </template>                                
            </v-data-table>
        </v-card>
        <!-- mostramos el circular progress cuando carguen los datos para editar una sucursal -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular>Cargando datos...
        </v-overlay>                              
        <CrearSucursal 
            :AbrirDialogoNuevaSucursal="AbrirDialogoNuevaSucursal" @CerrarModalNuevaSucursal="CerrarModalNuevaSucursal"
            :esNuevo="esNuevo" :TituloFormulario="TituloFormulario" @ObtenerTodasSucursales="ObtenerTodasSucursales"
            :DatosEditarSucursal="DatosEditarSucursal" :EstadoPermisoEditar="EstadoPermisoEditar" 
            :UsuariosPorSucursal="UsuariosPorSucursal"       
        />
    </div>
</template>

<script>
import CrearSucursal from '../../../components/Administracion/Sucursal/CrearSucursal'
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'
import { mapGetters, mapActions  } from 'vuex'

export default {
    components:{
        CrearSucursal,
    },

    data() {
        return {
            //variables para los permisos del colaborador
            namespace:'oidcStore/',
            EstadoPermisoEditar: false,
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },
            EstadoCircularProgress:false,

            buscarSucursal:'',
            EncabezadoSucursales:[
                {text: 'Sucursal', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Dirección', value: 'direccion', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Teléfono', value: 'telefono', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Inicio', value: 'fechaInicio', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Fecha Fin', value: 'fechaFin', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Encargado', value: 'nombreEncargado', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Gestor', value: 'nombreGestor', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Estado', value: 'estadoProyecto', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                                
            ],
            ArraySucursales:[],
            alertError:false,
            loading: false,
            // variables para nueva sucursal
            esNuevo: false,
            AbrirDialogoNuevaSucursal: false,
            TituloFormulario:'',
            // variables para editar una sucursal
            DatosEditarSucursal:{},
            EstadoEditar: false,
            UsuariosPorSucursal:[],
        }   
    },
    
    created() {
        this.ObtenerTodasSucursales()
        this.ObtenerPermisosSubmenuUsuario()
        this.OtenerTodosUsuariosActivos()
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },

    methods: {
        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),
            async ObtenerPermisosSubmenuUsuario(role){
                if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                    if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                            this.permisos.crear = true
                            this.permisos.editar = true
                            this.permisos.eliminar = true
                            return 0            
                    }else{
                        //preguntar al backend por los permisos del submenu del usuario
                            var url = 'sucursales'
                            const response  = await this.$store.dispatch('PermisosSubMenu', url)
                            if(typeof response === 'undefined'){
                                this.alertError=true
                                this.loading=false
                                return 0
                            }if(response.status === 200){
                                this.alertError= false
                                this.permisos = response.data         
                            }else if(response.status === 400){
                                showMessageError('Error 400','Petición mala')
                            }else if(response.status === 401){
                                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                            }else if(response.status === 404){
                                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                            }else if(response.status === 500){
                                showMessageError('Error 500','Error en el servidor')            
                            }
                            return 0
                    }
                }
            },                            
        // controlamos las excepciones de las peticiones al api
        ControllerException(response){
            if(typeof response === 'undefined'){
                this.alertError=true
                this.loading = false
                this.EstadoCircularProgress = false
                return 0
            }if(response.status === 200){
                this.alertError= false
                this.loading = false
                if(this.TituloFormulario === "eliminar"){
                    showMessageSuccess('¡BIEN!', 'Licencia eliminado correctamente')
                    this.TituloDialogo = ''
                    this.GetAllLicenseType()
                    this.loadingEliminarLicencia = false
                    this.CerrarDialogoMensajeEliminar()
                }else if(this.TituloFormulario === "Editar"){
                    if(this.permisos.editar) this.EstadoPermisoEditar = this.permisos.editar
                    else this.EstadoPermisoEditar = this.permisos.editar

                    if(this.EstadoEditar){
                        this.DatosEditarSucursal = response.data
                        //console.log("editar sucursal: ", response.data)
                        //console.log("esatadoeditar: ", this.EstadoEditar)
                        this.EstadoEditar = false
                        this.EstadoCircularProgress = false
                        this.AbrirDialogoNuevaSucursal = true
                    }else{
                        // this.UsuariosPorSucursal = response.data
                        //console.log("usuario por sucursal: ", response.data)
                        this.EstadoCircularProgress = false
                        this.AbrirDialogoNuevaSucursal = true
                        //console.log("esatadoeditar: ", this.EstadoEditar)
                        return 0
                    }
                }else{                    
                    this.ArraySucursales = response.data
                }                
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.EstadoCircularProgress = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.EstadoCircularProgress = false
            }
        },                
        // metodos para obtener todas las sucursales 
            async ObtenerTodasSucursales(){
                this.loading = true
                const response = await this.$store.dispatch("getAllProyectos")
                // controlamos las excepciones
                this.ControllerException(response)
            },
        
        // metodos para crear una nueva sucursal
            AbrirModalNuevaSucursal(){
                if(this.permisos.crear) this.EstadoPermisoEditar = this.permisos.crear
                this.esNuevo = true
                this.TituloFormulario = "Crear"
                this.AbrirDialogoNuevaSucursal = true
            },

            CerrarModalNuevaSucursal(){
                this.AbrirDialogoNuevaSucursal = false
                this.TituloFormulario = ""
            },

        // metodos para editar una sucursal
            async AbrirModalEditarSucursal(sucursal){
                //console.log('sucursal => ',sucursal)
                this.EstadoCircularProgress = true
                this.esNuevo = false,
                this.TituloFormulario = "Editar"
                this.EstadoEditar = true
                const response = await this.$store.dispatch('GetProyectoById', sucursal.idProyecto)
                // controlamos las excepciones
                this.ControllerException(response)                

                // const response2 = await this.$store.dispatch('GetUserBySucursal', sucursal.nombre)
                // controlamos las excepciones
                // this.ControllerException(response2)                
            },

            async OtenerTodosUsuariosActivos(){
                const response  = await this.$store.dispatch('getAllUsers', 1)
                if(typeof response === 'undefined'){
                    this.alertError=true
                    this.loading=false
                    return 0
                }if(response.status === 200){
                    this.alertError= false
                    this.UsuariosPorSucursal = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }                
            }
    },
}
</script>