import Vue from 'vue'

const store = {
    actions: {
        //add here all acctions api
        // getMenusCatalogs : async ({}, data ) => {
        //     try{
        //         const response = await Vue.Axios.get('/Menu/catalogos/menus/porAsignar'+data)
        //         return response
        //     }catch(e){
        //         return e.response
        //     }
        // }, 
        // getSubMenusCatalogsByidModule : async ({}, data ) => {
        //     try{
        //         const response = await Vue.Axios.get('api/AccionesModulo/'+data)
        //         return response
        //     }catch(e){
        //         return e.response
        //     }
        // }, 
        // getMenusCatalogsByidModule : async ({}, data ) => {
        //     try{
        //         const response = await Vue.Axios.get('api/ModuloAplication/'+data)
        //         return response
        //     }catch(e){
        //         return console.log('error =>', e.response)
        //     }
        // } 
    }
}

export default store