<template>
    <v-card>
        
        <v-card-title class="text-center justify-center py-6">
            <h1 class="font-weight-bold display-1 basil--text">
                {{proyecto.nombre}}
            </h1>
        </v-card-title>
        <v-tabs
            v-model="tab"
            flat
            centered
            icons-and-text
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" @change="ObtenerInventarioVehiculosPorSucursal()" :disabled="!ActivarMenuTabs">
                Vehículos
                <v-icon>mdi-car</v-icon>
            </v-tab>

            <v-tab href="#tab-2" @change="ObtenerInventarioMaquinariaPorSucursal()" :disabled="!ActivarMenuTabs">
                Maquinaria
                <v-icon>mdi-dump-truck</v-icon>
            </v-tab>

            <v-tab href="#tab-3" @change="ObtenerInventarioHerramientaPorSucursal()" :disabled="!ActivarMenuTabs">
                Herramientas
                <v-icon>mdi-tools</v-icon>
            </v-tab>

            <v-tab href="#tab-4" @change="ObtenerInventarioMaterialInsumosPorSucursal()" :disabled="!ActivarMenuTabs">
                Materiales o Insumos
                <v-icon>fas fa-dolly</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1"
            >
                <v-card flat>
                    <v-card-text><TablaRegistrosVehiculos :tab="tab" :TituloTabla="TituloTabla" :idProyecto="idProyecto" :idSucursal="idSucursal"
                        :ArrayInventarioVehiculoPorSucursal="ArrayInventarioVehiculoPorSucursal" @ObtenerInventarioVehiculosPorSucursal="ObtenerInventarioVehiculosPorSucursal"
                        :loading="loading" :alertError="alertError" :permisoCrear="permisoCrear" :ActivarMenuTabs="ActivarMenuTabs"
                    /></v-card-text>        
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-2"
            >
                <v-card flat>
                    <v-card-text><TablaRegistrosMaquinaria :tab="tab" :TituloTabla="TituloTabla" :idProyecto="idProyecto" :idSucursal="idSucursal"
                        :ArrayInventarioMaquinariaPorSucursal="ArrayInventarioMaquinariaPorSucursal" @ObtenerInventarioMaquinariaPorSucursal="ObtenerInventarioMaquinariaPorSucursal"
                        :loading="loading" :alertError="alertError" :permisoCrear="permisoCrear" :ActivarMenuTabs="ActivarMenuTabs"
                    /></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-3"
            >
                <v-card flat>
                    <v-card-text><TablaRegistrosHerramientas :tab="tab" :TituloTabla="TituloTabla" :idProyecto="idProyecto"
                        :ArrayInventarioHerramientaPorSucursal="ArrayInventarioHerramientaPorSucursal" @ObtenerInventarioHerramientaPorSucursal="ObtenerInventarioHerramientaPorSucursal"
                        :loading="loading" :alertError="alertError" :permisoCrear="permisoCrear" :idSucursal="idSucursal" :ActivarMenuTabs="ActivarMenuTabs"
                    /></v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                value="tab-4"
            >
                <v-card flat>
                    <v-card-text><TablaRegistrosMaterialOInsumos :tab="tab" :TituloTabla="TituloTabla" :idProyecto="idProyecto" :idSucursal="idSucursal"
                        :ArrayInventarioMaterialInsumosPorSucursal="ArrayInventarioMaterialInsumosPorSucursal" @ObtenerInventarioMaterialInsumosPorSucursal="ObtenerInventarioMaterialInsumosPorSucursal"
                        :loading="loading" :alertError="alertError" :permisoCrear="permisoCrear" :ActivarMenuTabs="ActivarMenuTabs"
                    />
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import TablaRegistrosVehiculos from './TablaVehiculos/tablaVehiculos'
import TablaRegistrosMaquinaria from './TablaMaquinaria/tablaMaquinaria'
import TablaRegistrosHerramientas from './TablaHerramientas/TablaHerramientas'
import TablaRegistrosMaterialOInsumos from './TablaMaterialOInsumos'
import { showMessageError, showMessageWarning, showMessageSuccess } from '../../../services/sweetAlert'

    export default {
        props:{
            alertError: Boolean, permisoCrear: Boolean,
            idProyecto: Number, ActivarMenuTabs: Boolean,
            proyecto:Object, idSucursal: Number, proyectoSeleccionado: Number,
            loading: Boolean, tabSeleccionado: String,
            ArrayInventarioVehiculoPorSucursal: Array,
            ArrayInventarioMaquinariaPorSucursal: Array,
            ArrayInventarioHerramientaPorSucursal: Array,
            ArrayInventarioMaterialInsumosPorSucursal: Array,
        },

        components: {
            TablaRegistrosVehiculos,
            TablaRegistrosMaquinaria,
            TablaRegistrosHerramientas,
            TablaRegistrosMaterialOInsumos,
        },

        data () {
            return {
                tab: null,
                TituloTabla:'',
            }
        },

        watch: {
            tab(){
                this.AsignarTitulos()
                if(this.tab === "tab-3"){
                    this.ObtenerInventarioHerramientaPorSucursal()
                }                
            },
            idSucursal(){
                this.reloadData()
            },
            idProyecto(){
                this.reloadData()
            },
            ArrayInventarioMaterialInsumosPorSucursal(){
                // console.log('ArrayInventarioMaterialInsumosPorSucursal: ', this.ArrayInventarioMaterialInsumosPorSucursal)
            }
        },        
        created(){
            
        },
        methods: {
            AsignarTitulos(){
                if(this.tab==="tab-1") this.TituloTabla="Vehículos"
                if(this.tab==="tab-2") this.TituloTabla="Maquinaria"
                if(this.tab==="tab-3") this.TituloTabla="Herramientas"
                if(this.tab==="tab-4") this.TituloTabla="Material o Insumos"                
            },            
            reloadData(){
                if(this.tab === '') this.tab = 'tab-1'
                if(this.tab === 'tab-1') this.ObtenerInventarioVehiculosPorSucursal()
                if(this.tab === 'tab-2') this.ObtenerInventarioMaquinariaPorSucursal()
                if(this.tab === 'tab-3') this.ObtenerInventarioHerramientaPorSucursal()
                if(this.tab === 'tab-4') this.ObtenerInventarioMaterialInsumosPorSucursal()

            },
            ObtenerInventarioVehiculosPorSucursal(){
                this.$emit('ObtenerInventarioVehiculosPorSucursal')
            },
            ObtenerInventarioMaquinariaPorSucursal(){
                this.$emit('ObtenerInventarioMaquinariaPorSucursal')
            },
            ObtenerInventarioHerramientaPorSucursal(){
                if(this.proyectoSeleccionado && this.idSucursal!=null){
                    this.$emit('ObtenerInventarioHerramientaPorSucursal')                
                }else if(this.ArrayInventarioHerramientaPorSucursal.length === 0){
                    this.TituloTabla="Herramientas"
                    showMessageWarning('Oops!',' Seleccione una sucursal para reflejar los cambios en la tabla '+ this.TituloTabla)
                }
            },
            ObtenerInventarioMaterialInsumosPorSucursal(){
                this.$emit('ObtenerInventarioMaterialInsumosPorSucursal')
            },
        },
    }
</script>