<template>
    <div class="home">
        <section>
            <v-container fluid style="background-color: #ffffff">
                <v-row justify="space-around">
                    <v-col cols="12" md="5" xs="4">        
                        <v-img src="img/logocompany.jpeg" aspect-ratio="1.8" contain></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section>
            <v-parallax style="backgroundColor: red;"
                src="https://co.habcdn.com/photos/project/original/r-r-o-s-a-s-construcciones-253704.jpg"
                height="445"
            >
                <v-row align="center" justify="center">
                    <v-col class="text-center" cols="12" md="4" xs="4">
                        <v-card color="#d69b0e" dark>
                            <h1 class="mb-12">CONSTRUIMOS SUEÑOS</h1>                        
                        </v-card>
                    </v-col>
                </v-row>
            </v-parallax>
        </section>
        <section class="sectionthree py-12">
            <div class="text-center">
                <h3 class="secciontrestitle">Corporación Remon es una empresa dedicada a la Construcción</h3>
            </div>
        </section>
        <section class="sectioncuatro py-12">
            <div class="text-center">
                <h3 class="seccioncuatrotitle">Visión</h3>
            </div>
            <div class="text-center px-12">
                <p>
                    Ser una corporación reconocida en la industria de la construcción, expandiendo nuestra cobertura de trabajo fuera de las fronteras de nuestro país, proporcionando proyectos con tecnología vanguardista.                    
                </p>
            </div>
            <div class="text-center">
                <h3 class="seccioncuatrotitle">Misión</h3>
            </div>
            <div class="text-center px-12">
                <p>
                    Somos una Corporación dedicada a diseñar, planificar, construir y supervisar proyectos innovadores que hagan realidad el sueño de nuestros clientes, creando ambientes de comodidad y armonía.
                </p>
            </div>
        </section>
        <!-- seccion de contactos -->
        <section class="seccioncontacto py-12">
            <div class="text-center">
                <h3 class="seccioncontactotitle">Contáctenos</h3>
                <p>Dirección de contacto</p>
            </div>
            <div class="text-center px-12">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-icon size="100" dark>fas fa-envelope</v-icon>
                        <h2 class="seccioncontactosubtitle">Correo</h2>
                        <p>
                            corporacionremon@gmail.com
                        </p>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
</template>

<script>
export default {   
    data() {
        return {

        }
    },
}
</script>

<style>
    .sectionthree {
        background-color: #ffffff;
    }
    .secciontrestitle{
        color: #0171be;
        font-size: 45px;
        font-weight: bold;
        line-height: 55px;
    }
    .sectioncuatro {
        background-color: #d69b0e;
        color: white;
    }
    .seccioncuatrotitle{
        font-size: 45px;
        font-weight: bold;
        line-height: 55px;
    }
    .seccioncontacto {
        background-color: #01abe6;
        color: white;
    }
    .seccioncontactotitle{
        font-size: 45px;
        font-weight: bold;
        line-height: 55px;
    }
    .seccioncontactosubtitle{
        font-size: 22px;
        font-weight: bold;
    }
</style>