<template>
    <div>
        <v-dialog
            v-model="ModalMensajeEliminarDetallado"
            scrollable  
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
            <v-card>
                <v-toolbar color="primary" dark elevation="0">
                    <v-icon left>fas fa-tools</v-icon>
                    <v-toolbar-title class="TituloDialogo">Reversar Movimiento de la herramienta</v-toolbar-title>
                </v-toolbar>
                <hr class="mt-2">
                <div class="text-center">
                    <span class="fuenteTitulo"><strong>Id Mov. Herramienta: </strong> {{DatosMovimiento.tipoMovimientoInventarioId}}</span> <br>
                    <span class="fuenteTitulo"><strong>Tipo Movimiento: </strong>{{DatosMovimiento.nombre}} </span> <br>
                    <span class="fuenteTitulo"><strong>Usuario: </strong> {{DatosMovimiento.usuario}} </span> <br>
                    <span class="fuenteTitulo" v-if="DatosMovimiento.proveedorOTienda!=null"><strong >Proveedor o Tienda: </strong> {{DatosMovimiento.proveedorOTienda}} </span> <br  v-if="DatosMovimiento.proveedorOTienda!=null">
                    <span class="fuenteTitulo" v-if="DatosMovimiento.existenciaActual!=0"><strong >Existencia antes del Mov.: </strong> {{DatosMovimiento.existenciaActual}} </span> <br v-if="DatosMovimiento.existenciaActual!=0">
                    <span class="fuenteTitulo" v-if="DatosMovimiento.cantidad!=0"><strong >Cantidad: </strong> {{DatosMovimiento.cantidad}} </span> <br v-if="DatosMovimiento.cantidad!=0">
                    <span class="fuenteTitulo" v-if="DatosMovimiento.precioUnitario!=0"><strong >Precio Unitario: </strong> {{DatosMovimiento.precioUnitario}} </span> <br v-if="DatosMovimiento.precioUnitario!=0">
                    <span class="fuenteTitulo" v-if="DatosMovimiento.precioTotal!=0"><strong >Precio Total: </strong> {{DatosMovimiento.precioTotal}} </span> <br v-if="DatosMovimiento.precioTotal!=0">
                    <span class="fuenteTitulo"><strong>Descripción: </strong> {{DatosMovimiento.descripcion}} </span> <br>                    
                </div>
                <hr>
                <v-card-actions class="TituloDialogo">
                    <v-spacer></v-spacer>
                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" large rounded class="mx-2 mt-2 TituloDialogo" color="primary" @click="RevertirMovimientoDeHerramienta()" 
                                :loading="loading" :disabled="loading"
                            >Reversar
                                <v-icon right>fas fa-undo-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Movimiento</span>
                    </v-tooltip>                                        
                    <v-tooltip top color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 mt-2 TituloDialogo" color="error" @click="CerrarDialogoMensajeEliminar()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Transacción</span>
                    </v-tooltip>                    
                </v-card-actions>                
            </v-card>                        
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        DatosMovimiento:Object,
        ModalMensajeEliminarDetallado: Boolean,
        loading: Boolean,
    },

    data() {
        return {
            
        }
    },

    methods: {
        CerrarDialogoMensajeEliminar(){
            this.$emit('CerrarDialogoMensajeEliminar')
        },
        RevertirMovimientoDeHerramienta(){
            this.$emit('RevertirMovimientoDeHerramienta')
        }
    },
}
</script>