import Vue from 'vue'
import VueRouter from 'vue-router'
import Callback from '../views/oidc/OidcCallback.vue'
import callbackError from '../views/oidc/OidcCallbackError.vue'
import { mapGetters, mapActions } from 'vuex'

import Home from '../views/Home.vue'
import userProfile from '../views/UserProfile/userProfile.vue'
import about from '../views/About.vue'
// menu de administracion
import usuarios from '../views/usuarios/usuarios.vue'
import Menu from '../views/Menu/index.vue'
import NuevoMenu from '../components/Administracion/Menu/NuevoMenu.vue'
import proyectos from '../views/Administracion/Sucursales'
import sucursales from '../views/Administracion/Sucursales/sucursales.vue'
import InventarioProyecto from '../views/Administracion/InventarioSucursal'
// menu de almacen 
import MaquinariaMantenimiento from '../views/Almacen/Maquinaria/index.vue'
import VehiculosAlmacen from '../views/Almacen/vehiculos/index.vue'
import Herramientas from '../views/Almacen/Herramientas/index.vue'
import MaterialConstruccion from '../views/Almacen/MaterialConstruccion/index.vue'
import InventarioSucursalPorEncargado from '../views/Almacen/InventarioSucursalPorEncargado'

//mantenimiento
import MantenimientoVehiculo from '../views/Mantenimiento/vehiculos/index.vue'
import GenerarPDFVehiculos from '../components/Mantenimiento/Vehiculos/GenerarPDF.vue'
import MantenimientoMaquinaria from '../views/Mantenimiento/maquinaria/index.vue'
import GenerarPDFMaquinaria from '../components/Mantenimiento/Maquinarias/GenerarPDF.vue'
import MostrarIncidenteMaquinariaVehiculo from '../components/Mantenimiento/Vehiculos/MostrarIncidente.vue'
import GenerarPDFIncidentesVehiculosGeneral from '../components/Mantenimiento/Vehiculos/GenerarPDFVehiculosGeneral.vue'
import GenerarPDFIncidentesMaquinariasGeneral from '../components/Mantenimiento/Maquinarias/GenerarPDFIncidentesMaquinariasGeneral.vue'
//tipo Licencias
import TipoLicencias from '../views/IngresoNuevaInformacion/TipoLicencia'
//marcas
import Marcas from '../views/IngresoNuevaInformacion/MenuMarcas'
// tipos de vehiculos y maquinarias
import Tipos from '../views/IngresoNuevaInformacion/Tipos'
// unidades de medida
import UnidadesDeMedida from '../views/IngresoNuevaInformacion/UnidadesDeMedida'
//inventarios
import ReversarMovmientoHerramienta from '../views/Inventarios/ReversarMovimientoHerramienta'
import SolicitudVehiculos from '../views/Inventarios/SolicitudesDeVehiculos'
import SolicitudDeMaquinarias from '../views/Inventarios/SolicitudesDeMaquinaria'
import Buscador from '../views/Inventarios/Buscador'

// bodegas
import BodegaSolicitudHerramienta from '../views/Bodegas/SolicitudDeHerramientas'
import MenuDeSolicitudesDeHerramientas from '../views/Bodegas/EnviarSolicitudDeHerramientas/MenuDeSolicitudes.vue'
import MenuDeSolicitudesDeMateriales from '../views/Bodegas/EnviarSolicitudDeMateriales/MenuDeSolicitudesDeMateriales.vue'
import BodegaSolicitudMateriaPrima from '../views/Bodegas/SolicitudesDeMateriaPrima'
import SolicitudesDeMaquinarias from '../views/Bodegas/SolicitudesDeMaquinaria'
import SolicitudesDeVehiculos from '../views/Bodegas/SolicitudesDeVehiculos'

import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store'
import AccesoDenegado from '../views/AccesoDenegado'
import catalogoHerramientas from '../views/IngresoNuevaInformacion/herramientas.vue'
import catalogoMateriales from '../views/IngresoNuevaInformacion/Materiales.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/catalogoMateriales', // Needs to match redirect_uri in you oidcSettings
        name: 'catalogoMateriales',
        component: catalogoMateriales,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/catalogoHerramientas', // Needs to match redirect_uri in you oidcSettings
        name: 'catalogoHerramientas',
        component: catalogoHerramientas,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/userProfile',
        name: 'userprofile',
        component: userProfile
    },
    {
        path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
        name: 'oidcCallback',
        component: Callback,
        meta: {
            //isOidcCallback: true,
            isPublic: true
        }
    },
    {
        path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
        name: 'oidcCallbackError',
        component: callbackError,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/autenticate',
        name: 'home',
        component: about,
        meta: {
            isPublic: false
        }
    },
    // menu de administracion ----------------------------------------------------------
    {
        path: '/colaboradores',
        name: 'usuarios',
        component: usuarios,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/gestionmenu',
        name: 'Menu',
        component: Menu,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/NuevoMenu',
        name: 'NuevoMenu',
        component: NuevoMenu,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/sucursales',
        name: 'Sucursales',
        component: sucursales,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/proyectos',
        name: 'Proyectos',
        component: proyectos,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/inventarioProyecto',
        name: 'InventarioProyecto',
        component: InventarioProyecto,
        meta: {
            isPublic: false
        }
    },
    // menu de herramientas -----------------------------------------------------------------------
    {
        path: '/maquinaria',
        name: 'MaquinariaMantenimiento',
        component: MaquinariaMantenimiento,
        beforeEnter: (to, from, next) => {
            if (verifyRol('maquinaria', next)) {
                next()
            } else {
                next('/AccesoDenegado')
            }
        },
        meta: {
            isPublic: false
        }
    },
    {
        path: '/vehiculos',
        name: 'VehiculosAlmacen',
        component: VehiculosAlmacen,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/herramientas',
        name: 'Herramientas',
        component: Herramientas,
        beforeEnter: (to, from, next) => {
            if (verifyRol('herramientas', next)) {
                next()
            } else {
                next('/AccesoDenegado')
            }
        },
        meta: {
            isPublic: false
        }
    },
    {
        path: '/materialdeconstruccion',
        name: 'MaterialConstruccion',
        component: MaterialConstruccion,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/InventarioSucursalPorEncargado',
        name: 'InventarioSucursalPorEncargado',
        component: InventarioSucursalPorEncargado,
        meta: {
            isPublic: false
        }
    },
    //menu de mantenimientos ------------------------------------------------------------------
    {
        path: '/vehiculosmantenimiento',
        name: 'MantenimientoVehiculo',
        component: MantenimientoVehiculo,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/GenerarPDFIncidenteVehiculo/:id',
        name: 'GenerarPDFVehiculos',
        component: GenerarPDFVehiculos,
        meta: {
            isPublic: false
        },
        props: true
    },
    {
        path: '/maquinariamantenimiento',
        name: 'MantenimientoMaquinaria',
        component: MantenimientoMaquinaria,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/GenerarPDFIncidenteMaquinaria/:id',
        name: 'GenerarPDFMaquinaria',
        component: GenerarPDFMaquinaria,
        meta: {
            isPublic: false
        },
        props: true
    },

    {
        path: '/Mantenimiento/VerIncidente/:Titulo/:id',
        name: 'MostrarIncidenteMaquinariaVehiculo',
        component: MostrarIncidenteMaquinariaVehiculo,
        meta: {
            isPublic: false
        },
        props: true
    },
    {
        path: '/GenerarPDFIncidentesVehiculosGeneral/:id',
        name: 'GenerarPDFIncidentesVehiculosGeneral',
        component: GenerarPDFIncidentesVehiculosGeneral,
        meta: {
            isPublic: false
        },
        props: true
    },
    {
        path: '/GenerarPDFIncidentesMaquinariasGeneral/:id',
        name: 'GenerarPDFIncidentesMaquinariasGeneral',
        component: GenerarPDFIncidentesMaquinariasGeneral,
        meta: {
            isPublic: false
        },
        props: true
    },
    //tipo de licencias
    {
        path: '/tipodelicencia',
        name: 'TipoLicencias',
        component: TipoLicencias,
        meta: {
            isPublic: false
        },
    },
    //Marcas
    {
        path: '/marca',
        name: 'Marcas',
        component: Marcas,
        meta: {
            isPublic: false
        },
    },
    //tipos de vehiculos y maquinarias
    {
        path: '/tipo',
        name: 'Tipos',
        component: Tipos,
        meta: {
            isPublic: false
        },
    },
    //unidades de medida
    {
        path: '/unidadesdemedida',
        name: 'UnidadesDeMedida',
        component: UnidadesDeMedida,
        meta: {
            isPublic: false
        },
    },
    //bodegas
    {
        path: '/bodegaSolHerramienta',
        name: 'BodegaSolicitudHerramienta',
        component: BodegaSolicitudHerramienta,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/bodegaSolMateriales',
        name: 'BodegaSolicitudMateriaPrima',
        component: BodegaSolicitudMateriaPrima,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/BodegaSolMaquinarias',
        name: 'SolicitudesDeMaquinarias',
        component: SolicitudesDeMaquinarias,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/BodegaSolVehiculos',
        name: 'SolicitudesDeVehiculos',
        component: SolicitudesDeVehiculos,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/solicitudHerramientas',
        name: 'MenuDeSolicitudesDeHerramientas',
        component: MenuDeSolicitudesDeHerramientas,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/solicitudMateriales',
        name: 'MenuDeSolicitudesDeMateriales',
        component: MenuDeSolicitudesDeMateriales,
        meta: {
            isPublic: false
        },
    },
    //inventarios
    {
        path: '/buscador',
        name: 'Buscador',
        component: Buscador,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/reversarMovimientoHerramienta',
        name: 'ReversarMovmientoHerramienta',
        component: ReversarMovmientoHerramienta,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/SolicitudVehiculos',
        name: 'SolicitudVehiculos',
        component: SolicitudVehiculos,
        meta: {
            isPublic: false
        },
    },
    {
        path: '/SolicitudMaquinarias',
        name: 'SolicitudDeMaquinarias',
        component: SolicitudDeMaquinarias,
        meta: {
            isPublic: false
        },
    },
    //acceso denegado
    {
        path: '/AccesoDenegado',
        name: 'AccesoDenegado',
        component: AccesoDenegado,
        meta: {
            isPublic: true
        },
    },
]

async function verifyRol(url, next) {
    let userRoles = store.getters['oidcStore/oidcUser'].role
    if (userRoles === "Administrador") {
        console.log('es administrador: ', userRoles, ' - ' + url)
    } else {
        console.log('es empleado: ', userRoles, ' - ' + url)
        try {
            const response = await Vue.axios.get('/Menu/permisos/' + url)
            if (response.status === 200) {
                console.log('si tiene permisos el empleado de: ' + url + ' - permisos: ', response.data)
            } else if (response.status === 204) {
                console.log('no tiene permisos el empleado, acceso denegado de: ' + url + ' - permisos: ', response.data)
                return next('/AccesoDenegado')
            }
        } catch (error) {
            console.log('permisos desde rutas de InventarioSucursalPorEncargado: ', error)
        }
    }
    return
}
async function verifyRoles(rol) {
    var value = false
    let userRoles = store.getters['oidcStore/oidcUser'].role
    console.log('ver si es arrary ==> ', userRoles instanceof Array)
    console.log('roles => ', userRoles)
    if (userRoles === "Administrador") {
        console.log('administrador: ', userRoles)
    } else {
        console.log('es empleado: ', userRoles)
    }
    if (userRoles instanceof Array && rol instanceof Array) {
        userRoles.forEach(element => {
            if (rol.indexOf(element) > -1)
                value = true
            return
        });
    } else {
        if (userRoles instanceof Array === false && rol instanceof Array) {
            console.log('userroles es arrary pero rol no')
            rol.forEach(element => {
                if (element === userRoles)
                    value = true
                return
            });
        } else if (userRoles instanceof Array && rol instanceof Array === false) {
            console.log('userroles es arrary pero rol no')
            userRoles.forEach(element => {
                if (element === rol)
                    value = true
                return
            });
        } else {
            console.log('entro al else', rol instanceof Array)
            if (userRoles == rol)
                value = true
            return
        }
    }
    return value
}

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: routes
    })
    //router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))
router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

export default router