<template>
    <v-dialog
        v-model="OpenDialogNewMenu" 
        persistent :overlay="false"
        fullscreen
        transition="dialog-transition"
    >
        <v-toolbar color="#3c8dbc" dark dense flat>
            <v-card-title primary-title class="titulos">Configuración de Menú para el usuario: {{DataUser.alias}}</v-card-title>        
            <v-spacer></v-spacer>
            <v-btn @click="CerrarDialogoCrearNuevoMenu()" color="#D80000" dark>
                <v-icon left> fas fa-times</v-icon> <strong>Cerrar</strong>
            </v-btn>        
        </v-toolbar>
        <v-card >
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>            
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-row>
                    <v-col cols="12" sm="4" md="4" xs="12">
                    <v-select class="pl-2" :disabled="!EstadoPermisoCrear"
                        :items="AllMenusCatalogs"
                        item-value="idModulo"
                        item-text="titulo"     
                        v-model="idMenuSelecconado"                      
                        outlined :rules="Rules" required
                        label="Seleccionar Menú"
                    ></v-select>                            
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="12">
                    <v-tooltip bottom color="primary" >
                        <template v-slot:activator="{ on }">
                            <v-btn large class="mt-1" color="primary" @click="GuardarMenu()" 
                               :loading="loadingButton" :disabled="loadingButton" v-on="on"
                            >
                                <v-icon left> fas fa-save</v-icon> <strong>Guardar</strong>
                            </v-btn>
                        </template>
                        <span class="fontButton">Asignar Menú al Usuario</span>
                    </v-tooltip>                    
                    </v-col>
                </v-row>
            </v-form>
                          
            <v-tabs grow
                v-model="tab"
                background-color="primary"
                dark
                centered
                icons-and-text
            >
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                    v-for="item in AllMenus"
                    :key="item.idMenu"
                    @change="MostrarDatosMenuSeleccionado(item)"

                >
                    {{ item.titulo }}
                    <v-icon>{{item.icono}}</v-icon>
                </v-tab>
            </v-tabs>
            <v-container grid-list-xs class="text-center"  v-if="AllMenus.length === 0">
                <v-alert outlined class="text-xs-center textoalerta" type="warning" prominent border="left"
                >
                    NO EXISTEN MENUS ASOCIADOS
                </v-alert>                
            </v-container>
            <v-container grid-list-xs class="text-center"  v-if="loadingProgressCircular">
                <v-progress-circular indeterminate :size="40" :width="5" color="#000000" ></v-progress-circular>
                <h4>Cargando datos...</h4>
            </v-container>
        

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="item in AllMenus"
                    :key="item.idMenu"
                >
                    <v-card flat>
                    <MostrarSubMenus
                        @GetAllSubMenusParaAsignar="GetAllSubMenusParaAsignar" :AllSubMenusParaAsignar="AllSubMenusParaAsignar"
                        @GetAllSubMenus="GetAllSubMenus" :SubMenusAsignadoUsuario="SubMenusAsignadoUsuario" :TituloSubMenu="TituloSubMenu" :idMenuSelect="idMenuSelect"
                        @ObtenerCatalogoDeMenus="ObtenerCatalogoDeMenus" @GetAllMenu="ActualizarTabsDeMenus" :idMenuSelecconado="idMenuSelecconado" :tituloMenu="DatosMenuUsuario.titulo"
                        :EstadoPermisoEditar="EstadoPermisoEditar" :EstadoPermisoCrear="EstadoPermisoCrear" :EstadoPermisoEliminar="EstadoPermisoEliminar"
                        :loading="loadingDatosTabla" :EstadoBotonEliminarMenu="EstadoBotonEliminarMenu" 
                        @DesactivarBotonEliminarMen="DesactivarBotonEliminarMen"
                    />            
                </v-card>
            </v-tab-item>
        </v-tabs-items>        
        </v-card>
    </v-dialog>
</template>

<script>
import {showMessageError,showMessageSuccess} from '../../../services/sweetAlert';
import MostrarSubMenus from './MostrarSubmenus'

export default {
    data() {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,
            loadingProgressCircular: false,
            loadingButton: false,
            loadingDatosTabla: false,
            tab: 0,
            valid: true,
            Rules: [
                v => !!v || 'Campo Requerido',
            ],       
            idMenuSelecconado:0,
            ArrayGuardarDatosMenu:[],
            GuardarDatosMenu: {
                'idUser': '',
                'titulo': '',
                'icono': '',
                'orden': 0,
            },
            TituloSubMenu: '',
            idMenuSelect: 0,
            DatosMenuUsuario: {
                'idMenu': '',
                'titulo': '',
            },
            AllSubMenusParaAsignar: [],
            EstadoBotonEliminarMenu: false,
            AllMenusCatalogs: [],
            SubMenusAsignadoUsuario: [],
            EstadoBotonGuardarMenu: true,
        }
    },
    props: {
        OpenDialogNewMenu: Boolean,
        DataUser: Object,
        EstadoPermisoCrear: Boolean,
        EstadoPermisoEditar: Boolean,
        EstadoPermisoEliminar: Boolean,   
        AllMenus: Array,
    },

    components:{
        MostrarSubMenus,
    },
    watch:{
        OpenDialogNewMenu : function (params) {
            if(params){
                this.ObtenerCatalogoDeMenus()
            }
            else{
            }
        },
        idMenuSelecconado : function (params) {
            if(params){
                this.ObtenerDatosMenu()
                this.EstadoBotonGuardarMenu = false                
            }
        },
    },

    methods: {
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
        },        
    // desactivamos el boton eliminar menu cuando se elimina un menu
        DesactivarBotonEliminarMen(){
            this.DatosMenuUsuario = {}
            this.SubMenusAsignadoUsuario = []                                            
            this.EstadoBotonEliminarMenu = false
            this.DatosMenuUsuario.titulo = ''
        },
    // cerramos el modal
        CerrarDialogoCrearNuevoMenu(){
            this.EstadoBotonEliminarMenu = false
            this.DatosMenuUsuario = {}
            this.AllSubMenusParaAsignar = []
            this.SubMenusAsignadoUsuario = []
            this.$emit('CerrarDialogoCrearNuevoMenu')
        },    
    // obtenemos el catalogo de menus para mostrarlo en el select
        async ObtenerCatalogoDeMenus(){
            const response = await this.$store.dispatch('getMenusCatalogs', this.DataUser.id);
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }            
            if(response.status === 200){
                this.alertError= false
                this.loadingProgressCircular = false
                this.AllMenusCatalogs = response.data
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },   
    //mostramos los datos del menu seleccionado en el tab
        MostrarDatosMenuSeleccionado(item){  
            this.DatosMenuUsuario = {}
            this.SubMenusAsignadoUsuario = []

            this.EstadoBotonEliminarMenu = true
            this.DatosMenuUsuario.idMenu = item.idMenu
            this.DatosMenuUsuario.titulo = item.titulo
            this.GetAllSubMenusParaAsignar()
            this.GetAllSubMenus()
            this.ObtenerCatalogoDeMenus()
        },                
    // obtenemos todos los Submenus para mostrarlo en el select
        async GetAllSubMenusParaAsignar() {
            this.AllSubMenusParaAsignar=[]
            this.idMenuSelect = this.DatosMenuUsuario.idMenu
            this.TituloSubMenu = this.DatosMenuUsuario.titulo

            var datos2 = {idMenu: this.DatosMenuUsuario.idMenu, idUser: this.DataUser.id}
            const response = await this.$store.dispatch('getAllSubMenusParaAsignar', datos2);
            if(response.status === 200){
                this.alertError= false
                this.loadingProgressCircular = false
                this.AllSubMenusParaAsignar= response.data
            }else if(response.status === 400){
                // showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },             
    // obtenemos los datos del menu seleccionado para guardarlos
        async ObtenerDatosMenu(){
            this.ArrayGuardarDatosMenu = []
            const response = await this.$store.dispatch('getSubMenusCatalogsByidModule', this.idMenuSelecconado );
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }            
            if(response.status === 200){
                this.alertError= false
                this.loadingProgressCircular = false
                this.GuardarDatosMenu.idUser = this.DataUser.id
                this.GuardarDatosMenu.titulo = response.data.titulo
                this.GuardarDatosMenu.icono = response.data.icono
                this.GuardarDatosMenu.orden = response.data.orden  
                this.ArrayGuardarDatosMenu.push(this.GuardarDatosMenu)
                this.DatosMenuUsuario = {}
                this.AllSubMenusParaAsignar=[]
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
            }
        },            
    // mandarmos a guardar el menu asignado al usuario
        async GuardarMenu(){
            this.loadingButton = true
            if(this.$refs.form.validate() === true){
                const response = await this.$store.dispatch('NewMenu',this.ArrayGuardarDatosMenu)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loadingButton = false
                    return 0
                }                            
                if(response.status === 200){
                    this.DatosMenuUsuario = {}
                    this.SubMenusAsignadoUsuario = []                    
                    this.alertError= false
                    this.loadingProgressCircular = false
                    this.idMenuSelecconado = 0
                    this.EstadoBotonGuardarMenu = true
                    this.ObtenerCatalogoDeMenus()
                    this.$emit('GetAllMenus')
                    showMessageSuccess('Bien !','Menú guardado con éxito')
                    this.loadingButton = false
                    this.EstadoBotonEliminarMenu = false
                    this.$refs.form.reset()                    
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loadingButton = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                    this.loadingButton = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loadingButton = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loadingButton = false
                }
            }else{
                this.loadingButton = false
            }
        },      
    // actualizamos el tabs de menus asignados al usuario cuando se elimina un menu
        ActualizarTabsDeMenus() {
            this.$emit('GetAllMenus')
            // this.GetAllSubMenus()
            // this.AllSubMenusParaAsignar = []
            this.GetAllSubMenusParaAsignar()
        },
    
    // obtenemos todos los Submenus para mostrarlo en la tabla del menu del usuario
        async GetAllSubMenus() {
            this.loadingProgressCircular = true
            this.SubMenusAsignadoUsuario=[]
            var datos = {idMenu: this.DatosMenuUsuario.idMenu, idUser: this.DataUser.id}
            const response = await this.$store.dispatch('getAllSubMenus', datos);
            // console.log(response)
            if(typeof response === 'undefined'){
                // this.ControllerException()
                this.loadingDatosTabla = false
                this.loadingProgressCircular = false
                return 0
            }                        
            if(response.status === 200){
                this.alertError= false
                this.SubMenusAsignadoUsuario= response.data
                this.loadingDatosTabla = false
                this.loadingProgressCircular = false
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loadingDatosTabla = false
                this.loadingProgressCircular = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
                this.loadingDatosTabla = false
                this.loadingProgressCircular = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingDatosTabla = false
                this.loadingProgressCircular = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingDatosTabla = false
                this.loadingProgressCircular = false
            }
        },
    },
}
</script>

<style>
    .titulomenu{
        font-size: 5pt;        
        /* font-family: 'PT Serif'; */
    }
    .tituloTablaMenu {
        font-family: 'PT Serif';
        font-size: 10px;
        /* background-color: black; */
        color: blue;
    }
</style>