var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","title":""}},[_c('v-toolbar-title',{staticClass:"tituloMaquinaria"},[_vm._v("Proyectos")]),_c('v-divider',{staticClass:"ml-3",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:" pr-4",attrs:{"outlined":"","label":"Buscar Sucursal","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.buscarSucursal),callback:function ($$v) {_vm.buscarSucursal=$$v},expression:"buscarSucursal"}}),(_vm.permisos.crear)?_c('v-tooltip',{attrs:{"bottom":"","color":"#0019d8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#0019d8","dark":"","rounded":""},on:{"click":function($event){return _vm.AbrirModalNuevaSucursal()}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-save")]),_vm._v(" Crear ")],1)]}}],null,false,2895499496)},[_c('span',[_vm._v("Nuevo Proyecto")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.EncabezadoSucursales,"items":_vm.ArraySucursales,"search":_vm.buscarSucursal,"footer-props":{itemsPerPageText: 'Filas por página'},"loading":_vm.loading,"loading-text":"Cargando Datos..."},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"#d69b01"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","dark":"","fab":"","color":"#d69b01"},on:{"click":function($event){return _vm.AbrirModalEditarSucursal(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',{staticClass:"fontButton"},[_vm._v("Editar Sucursal")])])]}},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"text-xs-center mx-10 mt-2 titulo",attrs:{"type":"warning","prominent":"","dense":"","outlined":"","border":"left"}},[_vm._v(" Sucursal no existe ")])]},proxy:true},{key:"item.estadoProyecto",fn:function(ref){
var item = ref.item;
return [(item.estadoProyecto==='Activo')?_c('v-chip',{attrs:{"color":"#00b40f","text-color":"white"}},[_vm._v(_vm._s(item.estadoProyecto)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-check")])],1):_vm._e(),(item.estadoProyecto==='Suspendido')?_c('v-chip',{attrs:{"color":"#d69b01","text-color":"white"}},[_vm._v(_vm._s(item.estadoProyecto)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-ban")])],1):_vm._e(),(item.estadoProyecto==='Finalizado')?_c('v-chip',{attrs:{"color":"#0019d8","text-color":"white"}},[_vm._v(_vm._s(item.estadoProyecto)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-check-double")])],1):_vm._e()]}},{key:"item.recomendacion",fn:function(ref){
var item = ref.item;
return [(item.recomendacion!== '')?_c('v-chip',{attrs:{"color":"#D80000","text-color":"white"}},[_vm._v(_vm._s(item.recomendacion)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-user-plus")])],1):_vm._e()]}},(_vm.ArraySucursales.length === 0)?{key:"no-data",fn:function(){return [(!_vm.alertError)?_c('v-alert',{staticClass:"text-xs-center textoalerta",attrs:{"dense":"","outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" No existen Sucursales registradas ")]):_vm._e()]},proxy:true}:null],null,true)})],1),_c('v-overlay',{attrs:{"value":_vm.EstadoCircularProgress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":80,"width":10,"color":"#ffffff"}}),_vm._v("Cargando datos... ")],1),_c('CrearSucursal',{attrs:{"AbrirDialogoNuevaSucursal":_vm.AbrirDialogoNuevaSucursal,"esNuevo":_vm.esNuevo,"TituloFormulario":_vm.TituloFormulario,"DatosEditarSucursal":_vm.DatosEditarSucursal,"EstadoPermisoEditar":_vm.EstadoPermisoEditar,"UsuariosPorSucursal":_vm.UsuariosPorSucursal},on:{"CerrarModalNuevaSucursal":_vm.CerrarModalNuevaSucursal,"ObtenerTodasSucursales":_vm.ObtenerTodasSucursales}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }