<template>
    <v-dialog
        v-model="DialogoNuevaHerramienta"
        persistent :overlay="false"
        max-width="900px" scrollable
        transition="dialog-transition"
    >
        <v-card>
            <!-- barra principal del dialogo -->
            <v-app-bar color="#3c8dbc" flat dark>
                <v-toolbar-title class="titulo">{{TituloDialogo}} Herramienta</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- boton registrar herramienta -->
                <v-tooltip bottom color="success" v-if="this.esNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn large v-on="on" color="success" rounded dark class="mr-4" @click="GuardarHerramienta()"
                            :loading="loading" :disabled="loading" 
                        >
                            <v-icon left>fas fa-save</v-icon> Guardar
                        </v-btn>                
                    </template>
                    <span>Registrar Herramienta</span>
                </v-tooltip>
                <!-- boton editar herramienta -->
                <v-tooltip bottom color="indigo" v-if="!this.esNuevo">
                    <template v-slot:activator="{ on }">
                        <v-btn large v-on="on" color="indigo" rounded dark class="mr-4" @click="GuardarCambios()"
                            :loading="loading" :disabled="loading" 
                        >
                            <v-icon left>fas fa-save</v-icon> Guardar
                        </v-btn>                
                    </template>
                    <span>Editar Herramienta</span>
                </v-tooltip>
                <v-btn large color="error" rounded dark @click="CerrarDialogoNuevaHerramienta">
                    <v-icon left>fas fa-window-close</v-icon> Cancelar
                </v-btn>                
            </v-app-bar>
            <v-card-text>
                <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>     
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                                                                                
                <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                    <v-row wrap>
                        <!-- tipo de herramienta -->
                        <v-col cols="12" lg="3" md="6" sm="3">
                            <v-autocomplete outlined :disabled="editar"
                                label="Tipo"
                                :items="arrayTiposDeHerramientas"
                                item-text="nombre"
                                item-value="idTipoHerramienta"
                                v-model="tipoHerramienta"
                                @change="changeTipoHerramienta"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="6" >
                            <v-autocomplete outlined :disabled="editar"
                                :items="CatalogoHerramientas"
                                clearable
                                item-text="nombre"
                                item-value="CatalogoHerramientaId"
                                v-model="nombreHerramienta"
                                label="Seleccione herramienta"
                                @change="seleccionarHerramienta"
                            ></v-autocomplete>
                        </v-col>
                        <!-- marca herramienta -->
                        <v-col cols="12" lg="3" md="6" sm="3">
                            <v-text-field outlined disabled
                                v-model="marcaHerramienta"
                                label="Marca"
                            ></v-text-field>
                        </v-col>
                        <!-- ubicacion 1                          -->
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                label="Ubicación 1"
                                v-model="modeloCrearHerramienta.ubicacion1"
                            ></v-text-field>
                        </v-col>
                        <!-- ubicacion 2          -->
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-text-field outlined :disabled="!EstadoPermisoEditar"
                                label="Ubiación 2"
                                v-model="modeloCrearHerramienta.ubicacion2"
                            
                            ></v-text-field>
                        </v-col>
                        <!-- Descripcion de la herramienta                         -->
                        <v-col cols="12" lg="6" md="12" sm="6">
                            <v-textarea  :disabled="!EstadoPermisoEditar"
                                outlined
                                label="Descripción"
                                v-model="modeloCrearHerramienta.descripcion"
                            >
                            </v-textarea>
                        </v-col>
                        <!-- observacion de la herramienta -->
                        <v-col cols="12" lg="6" md="12" sm="6">
                            <v-textarea :disabled="!EstadoPermisoEditar"
                                outlined
                                label="Observación"
                                v-model="modeloCrearHerramienta.observacion"
                            >
                            </v-textarea>
                        </v-col>                        
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>      
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'

export default {
    props: {
        DialogoNuevaHerramienta: Boolean,
        TituloDialogo: String,
        ObjectHerramienta: Object,
        esNuevo: Boolean,
        EstadoPermisoEditar: Boolean,
        bodega:Number
    },

    data () {
        return {
            // alerta cuando el api deja de funcionar
            alertError:false,                          
            
            valid: false,
            lazy: false,      
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],            
            loading: false,
            modeloCrearHerramienta: {
                idHerramienta: 0,
                BodegaId:0,
                CatalogoHerramientaId:0,
                ubicacion1: '',
                ubicacion2: '',
                descripcion: '',
                observacion: '',
            },
            nombreHerramienta:'',
            marcaHerramienta:'',
            tipoHerramienta:'',
            marcasHerramientas: [],
            CatalogoHerramientas: [],
            tipoHerramientaId:0,

            // errores registros duplicados y vacíos
            alertErrorPost:false,
            arrayErrorPost:[],   
            arrayTiposDeHerramientas:[],
            editar:false         
        }
    },

    created() {
        this.ObtenerMarcasHerramientas()
        this.tiposDeHerramientas()
        //this.ObtenerCatalogoHerramientas()
    },

    watch: {
        DialogoNuevaHerramienta (val) {
            if(val == true){
                if(this.TituloDialogo === 'Editar'){
                    this.editar=true
                    this.modeloCrearHerramienta = this.ObjectHerramienta
                    this.marcaHerramienta = this.ObjectHerramienta.catalogoHerramienta.marcaHerramienta.nombre
                    this.tipoHerramienta = this.ObjectHerramienta.catalogoHerramienta.tipoHerramienta.idTipoHerramienta
                    this.arrayTiposDeHerramientas.forEach(x => {
                        if(x.idTipoHerramienta == this.tipoHerramienta){
                            this.CatalogoHerramientas = x.catalogoHerramientas
                        }
                    })
                    this.nombreHerramienta = this.ObjectHerramienta.catalogoHerramienta.nombre
                }else{
                    this.editar=false
                    this.marcaHerramienta=''
                    this.nombreHerramienta=''
                    this.modeloCrearHerramienta =  {
                        idHerramienta: 0,
                        BodegaId:0,
                        CatalogoHerramientaId:0,
                        ubicacion1: '',
                        ubicacion2: '',
                        descripcion: '',
                        observacion: '',
                    }
                }
            }
        },
    },

    methods: {
        changeTipoHerramienta(){
            this.arrayTiposDeHerramientas.forEach(item => {
                if(item.idTipoHerramienta === this.tipoHerramienta){
                    this.tipoHerramientaId = item.idTipoHerramienta
                    this.CatalogoHerramientas = item.catalogoHerramientas
                }
            });
        },
        async tiposDeHerramientas(){
            const response = await this.$store.dispatch('getAllTypeTools')
            this.arrayTiposDeHerramientas = response.data
        },
        seleccionarHerramienta(){
            //cuando seleccione una herramienta, mostrar la marca y nombre
            this.CatalogoHerramientas.forEach(hr => {
                if(hr.nombre === this.nombreHerramienta){
                    this.modeloCrearHerramienta.CatalogoHerramientaId = hr.catalogoHerramientaId
                    //this.tipoHerramienta = hr.tipoHerramienta.nombre
                    this.marcaHerramienta = hr.marcaHerramienta.nombre
                }
            })
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        // metodos para guardar una herramienta
            // cerramos el dialogo
            CerrarDialogoNuevaHerramienta(){
                this.alertErrorPost=false
                this.arrayErrorPost=[]                
                this.$refs.form.reset()
                this.$emit('CerrarDialogoNuevaHerramienta')
            },

            //obtenemos tipos de marcas de herramientas
            async ObtenerMarcasHerramientas() {
                const response = await this.$store.dispatch('getAllBrandsTools')
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                } 
                if(response.status === 200){
                    this.alertError= false
                    this.marcasHerramientas = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },

            //obtenemos tipos de maquinarias
            async ObtenerCatalogoHerramientas (){
                const response = await this.$store.dispatch('getAllCatalogoHerramientas', this.tipoHerramientaId)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                } 
                if(response.status === 200){
                    this.alertError= false
                    this.CatalogoHerramientas = response.data
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                }
            },
            //registramos la herramienta nueva
            async GuardarHerramienta (){
                this.loading = true
                this.modeloCrearHerramienta.BodegaId = this.bodega
                this.alertErrorPost=false;this.arrayErrorPost=[];
                if(this.$refs.form.validate()){
                    this.modeloCrearHerramienta.idEstadoHerramienta = 1
                    const response =  await this.$store.dispatch('RegistrarHerramienta', this.modeloCrearHerramienta)
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        this.loading = false
                        return 0
                    } 
                    if(response.status === 200){
                        this.alertError= false
                        this.$emit('ActualizarTabla')
                        this.loading = false
                        showMessageSuccess('¡BIEN!', 'Herramienta registrada correctamente')
                        this.CerrarDialogoNuevaHerramienta()
                    }else if(response.status === 400){
                        this.alertErrorPost=true
                        var object = response.data.errors
                        for (var property in object){
                            this.arrayErrorPost.push({description: `${object[property]}`})
                        }
                        this.loading = false                        
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        this.loading = false                      
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        this.loading = false
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                        this.loading = false
                    }
                }else{
                    this.loading = false
                }
            },    
        //metodos para editar una herramienta   
            async GuardarCambios () {
                this.loading = true
                this.alertErrorPost=false;this.arrayErrorPost=[];

                const response = await this.$store.dispatch('ActualizarHerramienta', this.modeloCrearHerramienta)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loading = false
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false
                    this.$emit('ActualizarTabla')
                    showMessageSuccess('¡BIEN!', 'Herramienta actualizada correctamente')
                    this.CerrarDialogoNuevaHerramienta()
                }else if(response.status === 400){
                    this.alertErrorPost=true
                    var object = response.data.errors
                    for (var property in object){
                        this.arrayErrorPost.push({description: `${object[property]}`})
                    }
                    this.loading = false                                            
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            }
    },
}
</script>