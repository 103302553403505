<template>
   <v-card outlined >
    <v-alert dense outlined type="error" v-if="alertError">
        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
    </v-alert> 
    <v-alert :value="alertErrorPost"
            type="error"
            align="left"
            outlined
            transition="scale-transition"
            color="deep-orange">
            <h4>Errores:</h4>
            <ul>
                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                    <h6>{{i.description}}</h6>
                </li>
            </ul>
    </v-alert>                   
    <v-form>
        <v-row wrap class="mx-1 mt-5" >
          <!-- primera seccion -->
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field outlined v-model="modeloActualizarInformacionPersonal.nombre" prepend-inner-icon="fas fa-user-tie" label="Nombre" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
              <v-menu
                  v-model="menuFechaNacimientoEditar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined
                          v-model="fechaNacimientoPersonal"
                          label="Fecha Nacimiento"
                          prepend-inner-icon="far fa-calendar-alt"
                          readonly                                
                          v-bind="attrs"
                          v-on="on"
                          :hint="'Fecha Nacimiento: '+modeloActualizarInformacionPersonal.fechaNacimiento"
                          persistent-hint                                      
                      ></v-text-field>
                  </template>
              <v-date-picker locale="es-GT" v-model="fechaNacimientoPersonal" @input="menuFechaNacimientoEditar = false"></v-date-picker>
              </v-menu>
          </v-col>    
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <div class="text-center mb-2 mt-2">
              <v-chip> 
                <v-avatar left>
                  <v-icon>fas fa-user-tie</v-icon>
                </v-avatar>
                Edad: {{eddadUsuario}} años</v-chip>                                        
            </div>                          
          </v-col>                                
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field outlined v-model="modeloActualizarInformacionPersonal.direccion" prepend-inner-icon="fas fa-street-view" label="Dirección" />
          </v-col>
          <!-- segunda seccion -->
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
              <v-select outlined
                  :items="EstadoCivil"
                  item-value="nombre"
                  item-text="nombre"
                  v-model="modeloActualizarInformacionPersonal.estadoCivil"
                  label="Estado Civil"
                  prepend-inner-icon="fas fa-user-tie"                              
              ></v-select>                            
          </v-col>                                                    
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field type="number" outlined v-model="modeloActualizarInformacionPersonal.noDependientes" prepend-inner-icon="fas fa-users" label="No. Dependientes" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field type="number" outlined v-model="modeloActualizarInformacionPersonal.dpi" prepend-inner-icon="far fa-id-card" label="DPI" />
          </v-col>
          <!-- segunda seccion -->
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field type="number" outlined v-model="modeloActualizarInformacionPersonal.afilicacionIggs" prepend-inner-icon="fas fa-file-medical" label="No. Afiliación IGSS" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field type="number" outlined v-model="modeloActualizarInformacionPersonal.phoneNumber" prepend-inner-icon="fas fa-mobile-alt" label="Telefono" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field type="number" outlined v-model="modeloActualizarInformacionPersonal.contactoDeEmergencia" prepend-inner-icon="fas fa-hospital" label="Contacto de emergencia" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" class="pt-0 pb-0">
            <v-text-field outlined v-model="modeloActualizarInformacionPersonal.nombreContactoEmergencia" prepend-inner-icon="fas fa-user-tag" label="Nombre de contacto de emergencia" />
          </v-col>
        </v-row>    
    </v-form>
    <v-card-actions>
      <v-spacer/>
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on }">
          <v-btn class="ma-2 pt-0 pb-0" rounded color="success"  v-on="on" @click="ActualizarInformacionPersonal()"
            :loading="loadingbutton" :disabled="loadingbutton"
          >
            Guardar cambios <v-icon right>fas fa-save</v-icon>
          </v-btn>
        </template>        
        <span>Actualizar Información Personal</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions  } from 'vuex'
  import {showMessageError,showMessageSuccess} from '../../services/sweetAlert';

  export default {
    data: () => ({
        // errores cuando la existencia no es suficinete
        alertErrorPost:false,
        arrayErrorPost:[],         
      // alerta cuando el api deja de funcionar
      alertError:false,      

      EstadoCivil: [
        {nombre: 'Soltero(a)'},
        {nombre: 'Comprometido(a)'},
        {nombre: 'Casado(a)'},
        {nombre: 'Separado(a)'},
        {nombre: 'Divorciado(a)'},
        {nombre: 'Viudo(a)'},
      ],      
      menuFechaNacimientoEditar: false,
      fechaNacimientoPersonal: new Date().toISOString().substr(0, 10),      
      eddadUsuario:0,
      modeloActualizarInformacionPersonal:{
        idUser: '',
        nombre: '',
        fechaNacimiento:'',
        estadoCivil:'',
        noDependientes:0,
        direccion: '',
        dpi:'',
        afilicacionIggs: '',
        phoneNumber: '',
        contactoDeEmergencia: '',
        nombreContactoEmergencia: '',
      },
      validarfechaActual:  new Date().toISOString().substr(0, 10),
      loadingbutton: false,
    }),

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),
      
    },

    mounted() {
      var user = this.$store.getters['oidcStore/'+'oidcUser']
      this.ObtenerInformacionPersonal(user)
    },

    methods: {
      async CalcularEdadUsuario(){
          const response = await this.$store.dispatch('CalcularEdad', this.modeloActualizarInformacionPersonal.fechaNacimiento)
          if(typeof response === 'undefined'){
              this.ControllerException()
              return 0
          }                           
          if(response.status === 200){
            this.alertError= false
            this.eddadUsuario = response.data.edad
          }else if(response.status === 400){
              showMessageError('Error 400','Petición mala')
          }else if(response.status === 401){
              showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
          }else if(response.status === 404){
              showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
          }else if(response.status === 500){
              showMessageError('Error 500','Error en el servidor')            
          }
      },      
    // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
    ControllerException(){
        this.alertError=true
        this.loadingbutton=false
    },                
      async ObtenerInformacionPersonal(user){
        const response = await this.$store.dispatch('getUserById', user.sub)
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                         
        if(response.status === 200){
          this.alertError= false
          this.modeloActualizarInformacionPersonal = response.data
          this.modeloActualizarInformacionPersonal.idUser = user.sub
          this.modeloActualizarInformacionPersonal.afilicacionIggs = response.data.certificadoMedico
          // console.log('iduser', this.modeloActualizarInformacionPersonal)
          this.CalcularEdadUsuario()
          }else if(response.status === 400){
              showMessageError('Error 400','Petición mala')
          }else if(response.status === 401){
              showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
          }else if(response.status === 404){
              showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
          }else if(response.status === 500){
              showMessageError('Error 500','Error en el servidor')            
          }
      },

      async ActualizarInformacionPersonal(){
        this.alertErrorPost=false;this.arrayErrorPost=[];
        this.loadingbutton = true
        if(this.validarfechaActual === this.fechaNacimientoPersonal){
        }else{
          this.modeloActualizarInformacionPersonal.fechaNacimiento = this.fechaNacimientoPersonal
        }                 
        
        const response = await this.$store.dispatch('ActualizarInfoPersonal', this.modeloActualizarInformacionPersonal)
        if(typeof response === 'undefined'){
            this.ControllerException()
            return 0
        }                         
        if(response.status === 200){
          this.alertErrorPost=false;this.arrayErrorPost=[];
          this.loadingbutton = false
          this.alertError= false
          showMessageSuccess('Bien!', 'Información Personal Actualizado con Éxito')
          this.fechaNacimientoPersonal = new Date().toISOString().substr(0, 10)
          this.CalcularEdadUsuario()
        }else if(response.status === 400){
          this.alertErrorPost=true
          var object = response.data.errors
          for (var property in object){
              this.arrayErrorPost.push({description: `${object[property]}`})
          }
          setTimeout(() => {
              this.alertErrorPost=false
          }, 12000); 
            this.loadingbutton = false
        }else if(response.status === 401){
          showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')                
            this.loadingbutton = false
        }else if(response.status === 404){
          showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
            this.loadingbutton = false
        }else if(response.status === 500){
          showMessageError('Error 500','Error en el servidor')            
            this.loadingbutton = false
        }        
      }
    },    

  }
</script>