<template>
    <div v-if="MostrarTabla">
        <v-toolbar title elevation="1">
            <v-chip color="grey" outlined>
                <v-toolbar-title class="tituloMaquinaria">{{TituloTablaSeleccionada}}</v-toolbar-title>
            </v-chip>                      
        </v-toolbar>          
        <v-data-table
            :headers="ArrayEncabezadoTablaPrincipal"
            :items="ArrayCuerpoDeLaTablaPrincipal"
            :sort-by="itemKeyAndSortBy"
            class="elevation-1"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :item-key="itemKeyAndSortBy"
            show-expand
            :loading="loading"
            loading-text="Cargando datos ... por favor espere"
        >
            <template v-slot:expanded-item="{ headers, item }" v-if="TablaPrincipalMostrar">
                <td :colspan="headers.length" >
                    <v-card >
                        <v-toolbar class="text-center" outlined dark dense color="blue">
                            <v-toolbar-title > <v-icon color="white" left>fas fa-list-alt</v-icon> Detalles de {{TituloTablaSeleccionada}}</v-toolbar-title>            
                        </v-toolbar>
                        <template>
                            <v-data-table dense :headers="EncabezadoDetalle" :items="item.detalle" item-key="nombre" class="elevation-1">
                            </v-data-table>
                        </template>
                    </v-card>
                </td>
            </template>        
            <template v-slot:no-data v-if="ArrayCuerpoDeLaTablaPrincipal.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" v-if="!alertError"
                >
                    No se encontraron registros.
                </v-alert>
            </template>                    
        </v-data-table>        
    </div>
</template>

<script>
export default {
    props:{
        rubroSeleccionado: Number,
        TituloTablaSeleccionada: String,
        ArrayEncabezadoTablaPrincipal: Array,
        ArrayCuerpoDeLaTablaPrincipal: Array,
        loading: Boolean,
        MostrarTabla: Boolean,
        itemKeyAndSortBy: String,
        TablaPrincipalMostrar: Boolean,
    },

    data() {
        return {
            
            expanded: [], 
            singleExpand: true,
            EncabezadoDetalle:[
                {text: 'Tipo', value: 'tipo', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Nombre', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Cantidad', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
            ],
            detalle:[
                {nombre: 'Predio General', cantidad: 90, tipo:'Sucursal'},
                {nombre: 'Oficinas Centrales', cantidad: 144, tipo:'Sucursal'},
                {nombre: 'Herramientas Segundo Nivel', cantidad: 70, tipo:'Bodega'},
                {nombre: 'Bodega de Materiales', cantidad: 144, tipo:'Bodega'},
            ],
        }
    },

    methods: {
        
    },
}
</script>