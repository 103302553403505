<template>
    <div>
        <v-dialog
            v-model="DialogoCambiarSucursalYBodega"
            persistent :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-card>
            <!-- barra principal del dialogo -->
                <v-app-bar flat dark color="#3c8dbc" class="mb-5">
                    <v-toolbar-title class="TituloDialogo">Cambiar de Sucursal y Bodega</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-app-bar>       
            <!-- cuerpo del formulario      -->
                <v-card-text>
                    <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>
                    <v-alert :value="alertErrorPost"
                            type="error"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>                                                                                                     
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- sucursales                   -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <v-select v-model="sucursal" :items="sucursalesAsignadas" menu-props="auto"
                                    label="Sucursal"
                                    prepend-icon="mdi-city"
                                    item-text="nombre"
                                    item-value="sucursalId"
                                    @change="seleccionarSucursal()"
                                ></v-select>                          
                            </v-col>                                                                        
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <v-select :disabled="!sucursal"
                                    v-model="bodega"
                                    :items="sucursalBodegas"
                                    menu-props="auto"
                                    label="Bodega"
                                    prepend-icon="mdi-warehouse"
                                    item-text="nombre"
                                    item-value="bodegaId"
                                    @change="seleccionarBodega()"
                                ></v-select>                         
                            </v-col>                                                                        
                            <!-- descripcion  -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0" > 
                                <v-textarea outlined
                                    v-model="modeloCambiarSucursal.descripcion"
                                    label="Descripción"
                                    color="info"
                                    hide-details
                                ></v-textarea>         
                            </v-col>                
                        </v-row>
                    </v-form>
                    <v-subheader>*Recuerde que este movimiento afecta el inventario de las sucursales involucradas.</v-subheader>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="GuardarCambios()"
                                :loading="loading" :disabled="loading"                        
                            >Guardar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Guardar Cambios</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarModalCambiarSucursalyBodegaMaquinaria()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar</span>
                    </v-tooltip>                    
                </v-card-actions>
            </v-card>                        
        </v-dialog>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../services/sweetAlert'

export default {
    props:{
        DialogoCambiarSucursalYBodega: Boolean,
        DatosRegistro: Object,
        NombreModulo: String,    
        EstadoPermisoEditar: Boolean,
        NombreSucursalActual: String,
    },

    data() {
        return {
            sucursalesAsignadas:[],
            sucursalBodegas:[],
            bodega:0,
            bodegaId:0,
            sucursal:0,        
            // alerta cuando el api deja de funcionar
            nombreSucursalCambiar:'',
            nombreBodegaCambiar:'',
            alertError:false,            
            loading: false,
            valid: false,
            lazy: false,

            modeloCambiarSucursal:{
                idVehiculo: 0,
                idMaquinaria:0,
                bodega: 0,
                descripcion:'',
            },
            // errores registros duplicados
            alertErrorPost:false,
            arrayErrorPost:[],                        
        }
    },

    watch: {
        DialogoCambiarSucursalYBodega(){
            if(this.NombreModulo === "Maquinaria"){
                this.modeloCambiarSucursal.idMaquinaria = this.DatosRegistro.idMaquinaria
            }else{
                this.modeloCambiarSucursal.idVehiculo = this.DatosRegistro.idVehiculo
            }
        }
    },

    created() {
        this.obtenerSucursalesAsignadas()
    },
    methods: {
        seleccionarBodega(){
            //si ha seleccionado una sucursal y proyecto
            if(this.sucursal != '' && this.bodega != ''){
                this.bodegaId=this.bodega
                this.modeloCambiarSucursal.bodega = this.bodega
            }
        },        
        seleccionarSucursal(){
            this.bodega = 0
            this.sucursalesAsignadas.forEach(suc => {
                if(suc.sucursalId == this.sucursal){
                    this.sucursalBodegas = suc.bodegas
                }
            })
        },         
        async obtenerSucursalesAsignadas(){
            const response  = await this.$store.dispatch('misSucursales')
            if(response === undefined){
                showMessageError('No fue posible obtener las sucursales asignadas')
                return
            }else if(response.status === 200){
                this.sucursalesAsignadas = response.data
            }else{
                this.ControlarEstadosExcepciones(response)
            }
        },                
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        CerrarModalCambiarSucursalyBodegaMaquinaria(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]          
            this.$refs.form.reset()
            this.$emit('TodasMaquinarias')
            this.$emit('CerrarModalCambiarSucursalyBodegaMaquinaria')
        },

        ControlarEstadosExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 200){
                this.alertError= false
                this.loading = false
                showMessageSuccess('¡BIEN!', 'Cambio realizado correctamente')
                this.CerrarModalCambiarSucursalyBodegaMaquinaria()
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false                   
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                                                    
        },

        async GuardarCambios(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];
            if(this.NombreModulo === "Maquinaria"){
                const response = await this.$store.dispatch('CambiarSucursalyBodegaDeMaquinarias', this.modeloCambiarSucursal)
                this.ControlarEstadosExcepciones(response)
            }else{
                const response = await this.$store.dispatch('CambiarSucursalyBodegaDeVehiculos', this.modeloCambiarSucursal)                
                this.ControlarEstadosExcepciones(response)
            }
        }
    },
}
</script>