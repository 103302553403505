<template>
    <!-- <div > -->
        <v-menu v-model="menu" :close-on-content-click="false"  left>
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left color="#000000">
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn small class="ml-2" icon outlined color="#000000" v-on="{...tooltip, ...menu}" v-bind="attrs" @click="AbrirFiltros()">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Ver Movimientos</span>
                </v-tooltip>                                                    
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-icon left>fas fa-search</v-icon>
                        <v-list-item-content>
                            <v-list-item-title>Filtros</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item >
                        <v-list-item-action class="mt-0 mb-0 pt-0 pb-0">
                            <v-menu v-model="menuFechaInicio" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined v-model="dateFechaInicio" label="Fecha Inicio" 
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="Es-es" v-model="dateFechaInicio" @input="menuFechaInicio = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-action class="mt-0 mb-0 pt-0 pb-0">
                            <v-menu v-model="menuFechaFinal" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined v-model="dateFechaFinal" label="Fecha Final" 
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker locale="Es-es"  v-model="dateFechaFinal" @input="menuFechaFinal = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded @click="AbrirModalTimeLine()">
                        Buscar
                    </v-btn>
                    <v-btn color="error" rounded @click="CerrarFiltros()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
            <v-overlay :value="EstadoCircularProgressMovimientos">
                <v-progress-circular indeterminate :size="50" :width="5" color="#ffffff"></v-progress-circular>Buscando movimientos...
            </v-overlay>        
            <TimeLineMovimientosMaterialInsumo 
                :AbrirTimeLine="AbrirTimeLine" @CerrarModalTimeLine="CerrarModalTimeLine"
                :ArrayMovimientosMaterialOInsumos="ArrayMovimientosMaterialOInsumos" :NombreMaterialInsumo="NombreMaterialInsumo"               
            /> 
            <ControlladorExcepciones :response="responseStatus"/>
        </v-menu>
    <!-- </div> -->
</template>

<script>
import TimeLineMovimientosMaterialInsumo from '../../../components/Almacen/MaterialConstruccion/TimeLineMovimientosMaterialesInsumos'
import ControlladorExcepciones from '../../ControlandoExcepcionesHTTPS'
import { showMessageError,showMessageSuccess } from '../../../services/sweetAlert'

export default {
    components:{
        TimeLineMovimientosMaterialInsumo,
        ControlladorExcepciones,
    },

    props:{
        DatosRegistro: Object,
    },

    data() {
        return {
            menu: false,
            //timeline
            NombreMaterialInsumo:'',
            ArrayMovimientosMaterialOInsumos: [],
            AbrirTimeLine: false,
            dateFechaInicio: new Date().toISOString().substr(0, 10),
            menuFechaInicio:false,
            dateFechaFinal: new Date().toISOString().substr(0, 10),
            menuFechaFinal:false,
            EstadoCircularProgressMovimientos:false,
            responseStatus:[],
        }
    },
    methods: {
        AbrirFiltros(){
            this.menu = true
            this.CerrarFiltros()
        },
        CerrarFiltros(){
            this.menu = false
            this.dateFechaInicio = new Date().toISOString().substr(0, 10), this.dateFechaFinal = new Date().toISOString().substr(0, 10)
        },
        //abrimos el modal para ver el timeline
            async AbrirModalTimeLine(){
                this.NombreMaterialInsumo = this.DatosRegistro.nombre
                let datos = {idMaterial: this.DatosRegistro.idMateriaPrima, fechaInicio: this.dateFechaInicio, fechaFin: this.dateFechaFinal}
                this.EstadoCircularProgressMovimientos= true
                const response = await this.$store.dispatch('TimeLineMovimientosPorMaterialInsumos', datos)
                if(response.status === 200){    
                    if(response.data.length === 0){
                        this.EstadoCircularProgressMovimientos= false
                        showMessageError('Lo siento!','Material o Insumo no cuenta con movimientos')
                    }else{
                        this.alertError= false
                        this.ArrayMovimientosMaterialOInsumos = response.data
                        this.EstadoCircularProgressMovimientos= false
                        this.AbrirTimeLine = true 
                        this.menu = false
                    }
                }else{
                    this.responseStatus = response
                }                                            
            },
            CerrarModalTimeLine(){
                this.AbrirTimeLine = false
                this.dateFechaInicio = new Date().toISOString().substr(0, 10), this.dateFechaFinal = new Date().toISOString().substr(0, 10)
            },        
    },
}
</script>