<template>
    <v-card>
        <v-toolbar-title class="tituloMaquinaria text-center">
            <v-icon color="black" left>fas fa-search</v-icon>
            Buscador</v-toolbar-title>  
        <v-alert dense outlined type="error" v-if="alertError">
            No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
        </v-alert>
        <v-container fluid>
            <v-form ref="form" v-model="valid" lazy-validation>                
                <v-row>
                    <v-col class="d-flex" cols="12" sm="6" md="4">
                        <v-select outlined class="ml-6" 
                            :items = "ArrayRubro"
                            item-text ="rubro"
                            item-value="id"
                            v-model="rubroSeleccionado"
                            label="Seleccione rubro"
                            :rules="nameRules" required
                        ></v-select>   
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" md="4">
                        <v-text-field outlined
                            label="Ingrese criterio"
                            v-model="criterioIngresado"
                            :rules="nameRules" required
                        ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" md="4">
                        <v-btn color="info" class="mb-2" @click="BuscarReporte()" :loading="loading" :disabled="loading">
                        <v-icon left>fas fa-search</v-icon>Buscar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <TablaPrincipalBusqueda :rubroSeleccionado="rubroSeleccionado" :TituloTablaSeleccionada="TituloTablaSeleccionada" :ArrayEncabezadoTablaPrincipal="ArrayEncabezadoTablaPrincipal"
        :ArrayCuerpoDeLaTablaPrincipal="ArrayCuerpoDeLaTablaPrincipal" :loading="loading" :MostrarTabla="MostrarTabla" :itemKeyAndSortBy="itemKeyAndSortBy"
        :TablaPrincipalMostrar="TablaPrincipalMostrar"
        />
    </v-card>
</template>

<script>
import TablaPrincipalBusqueda from './TablaPrincipal'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    components:{
        TablaPrincipalBusqueda,
    },

    data() {
        return {
            // validamos los campos
            valid: true,
            lazy: false,
            nameRules: [
                v => !!v || 'Campo requerido',
            ],

            rubroSeleccionado:0,
            criterioIngresado:'',
            itemKeyAndSortBy:'',
            TablaPrincipalMostrar:true,
            ArrayRubro:[
                {id:1, rubro:'Herramientas'},
                {id:2, rubro:'Materiales o Insumos'},
                {id:3, rubro:'Vehículos'},
                {id:4, rubro:'Maquinarias'},
            ],
            TituloTablaSeleccionada:'',
            ArrayEncabezadoTablaPrincipal: [],
            EncabezadoTablaHerramientas:[
                {text: 'Id', value: 'catalogoHerramientaId', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Tipo Herramienta', value: 'tipoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Herramienta', value:'nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia en Bodegas', value: 'existenciaBodegas', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia en Proyectos', value: 'existenciaProyectos', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia Total', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
            ],   
            EncabezadoTablaMaterialesInsumos:[
                // {text: 'Id', value: 'llave', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Material o Insumo', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Unidad de Medida', value:'unidadDeMedida', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'marca', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia en Bodegas', value: 'existenciaBodegas', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia en Proyectos', value: 'existenciaProyectos', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia Total', value: 'existencia', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
            ],   
            EncabezadoTablaMaquinarias:[
                {text: 'Id', value: 'idMaquinaria', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},   
                {text: 'Nombre', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Código', value: 'codigo', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Maquinaria', value: 'tipoMaquinaria.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Serie', value: 'serie', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Propietario', value: 'propietario', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Proyecto Actual', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
            ],               
            EncabezadoTablaVehiculos:[
                {text: 'Id', value: 'idVehiculo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Código', value: 'codigo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Nombre', value: 'nombre', align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Marca', value: 'vehiculoMarca.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Tipo Vehículo', value: 'tipoVehiculo.nombre', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Placa', value: 'placa', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Color', value: 'color', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Modelo', value: 'modelo', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Propietario', value: 'propietario', sortable: true, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Proyecto Actual', value: 'proyecto.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Sucursal', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
                {text: 'Bodega', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},
            ],
            ArrayCuerpoDeLaTablaPrincipal:[],
            loading: false,
            // errores cuando la existencia no es suficinete
            alertError:false,
            MostrarTabla:false,
        }
    },

    watch:{
        rubroSeleccionado(){
            this.criterioIngresado = ''
        }
    },
    methods: {
        // asignamos encabezado a la tabla segun rubro seleccionado
        AsignarEncabezadoTabla(){
            if(this.rubroSeleccionado === 4){
                this.TituloTablaSeleccionada = 'Maquinarias'
                this.TablaPrincipalMostrar = false
                this.ArrayEncabezadoTablaPrincipal = this.EncabezadoTablaMaquinarias
                this.itemKeyAndSortBy = "idMaquinaria"
            }else if(this.rubroSeleccionado === 3){
                this.TituloTablaSeleccionada = 'Vehículos'
                this.TablaPrincipalMostrar = false
                this.ArrayEncabezadoTablaPrincipal = this.EncabezadoTablaVehiculos
                this.itemKeyAndSortBy = "idVehiculo"
            }else if(this.rubroSeleccionado === 1){
                this.TituloTablaSeleccionada = 'Herramientas'
                this.TablaPrincipalMostrar = true
                this.itemKeyAndSortBy = "catalogoHerramientaId"
                this.ArrayEncabezadoTablaPrincipal = this.EncabezadoTablaHerramientas
            }else if(this.rubroSeleccionado === 2){
                this.TituloTablaSeleccionada = 'Materiales o Insumos'
                this.TablaPrincipalMostrar = true
                this.itemKeyAndSortBy = "llave"
                this.ArrayEncabezadoTablaPrincipal = this.EncabezadoTablaMaterialesInsumos
            }
        },
        asignarResultado(data){
            this.AsignarEncabezadoTabla()
            this.ArrayCuerpoDeLaTablaPrincipal = data.resultado
        },
        // realizamos la busqueda de reporte por rubro y criterio
        async BuscarReporte(){
            if(this.$refs.form.validate()){
                this.loading = true
                var datos = {rubro: this.rubroSeleccionado, criterio: this.criterioIngresado}
                const response = await this.$store.dispatch('SearchReports', datos)
                if(response.status === 200){
                    this.loading = false
                    this.asignarResultado(response.data)
                    this.AsignarEncabezadoTabla()
                    this.MostrarTabla = true
                }else{
                    this.ControlladorExcepciones(response)
                }
            }
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },                        
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                // console.log('response: ', response)
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loading = false                      
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },        
    },
}
</script>