<template> 
    <v-dialog 
        v-model="dialogoMensajeRetornarCentral"
        persistent 
        max-width="850px"
    >
        <v-card  style="border: 2px solid black">
            <div class="text-center py-3">
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>                
                <v-icon size="50" color="success">fas fa-exchange-alt</v-icon>
                <h2 class="titulomensaje">¿Esta seguro que desea retornar {{datosHerramientas.TituloHerramienta}} a bodega?</h2>
                <!-- <h2 class="titulomensaje">{{tituloMensajeEliminar}}</h2> -->
            </div>
            <hr class="mt-2">
            <div class="text-center pt-2 pb-2" style="background-color: #CCFFB4">
                <span class="fuenteTitulo mr-5"><strong>Herramienta:</strong> {{datosHerramientas.Nombre}} </span> 
                <span class="fuenteTitulo mr-5"><strong>Marca:</strong> {{datosHerramientas.Marca}} </span> 
                <span class="fuenteTitulo"><strong>Cantidad transferida:</strong> {{datosHerramientas.Cantidad}} </span> 
            </div>
            <hr> <br>
            <v-card-text>
                <v-alert v-if="ActivarAlertaCantidadExtraviadaPorElUsuario" dense
                    outlined 
                    type="warning"
                    prominent
                    border="left"
                >
                    Está acción generará un descuento a planilla del usuario a cargo
                </v-alert>                
                <v-form v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off">
                    <v-row wrap>
                        <v-col cols="12" lg="4" md="4" sm="12" class="mt-0 pt-0 mb-0 pb-0">
                            <v-text-field required :rules="ReglasFormulario"
                                outlined type="number" 
                                label="Cantidad Recibida"
                                v-model="CantidadRecibida"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="12" class="mt-0 pt-0 mb-0 pb-0">
                            <v-text-field required :rules="ReglasFormulario" 
                                outlined type="number"
                                label="Cantidad Extraviada"
                                v-model="CantidadExtraviadaPorElUsuario"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="12" class="mt-0 pt-0 mb-0 pb-0">
                            <v-text-field required :rules="ReglasFormulario" 
                                outlined type="number"
                                label="Cantidad Arruinada"
                                v-model="CantidadArruinada"
                            ></v-text-field>                
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="12" class="mt-0 pt-0 mb-0 pb-0">
                            <v-textarea outlined label="Observación de Cantidad Recibida" v-model="modeloRetornarHerramienta.observacionCantidadRecibida"
                            :disabled="!ActivarObservacionCantidadRecibida"></v-textarea>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="12" class="mt-0 pt-0 mb-0 pb-0">
                            <v-textarea outlined label="Observación de Cantidad Extraviada" v-model="modeloRetornarHerramienta.observacionCantidadExtraviada"
                            :disabled="!ActivarObservacionCantidadExtraviada"></v-textarea>
                        </v-col>
                        <v-col cols="12" lg="4" md="4" sm="12" class="mt-0 pt-0 mb-0 pb-0">
                            <v-textarea outlined label="Observación de Cantidad Arruinada" v-model="modeloRetornarHerramienta.observacionCantidadArruinada"
                            :disabled="!ActivarObservacionCantidadArruinada"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
                <v-alert v-if="ActivarAlertaCantidadesMayorCantidadTransferida" dense
                    outlined 
                    type="error"
                    prominent
                    border="left"
                >
                    Lo siento, la sumatoria de cantidad recibida, extraviada y arruinada no es igual a la cantidad transferida.
                </v-alert>                                
                <v-alert v-if="ActivarAlertaCantidadesSonIguales" dense
                    outlined 
                    type="success"
                    prominent
                    border="left"
                >
                    Bien! La sumatoria de las cantidades es igual a la cantidad transferida.
                </v-alert>                                
            </v-card-text>
            <hr>
            <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                <v-btn large rounded class="mx-2" color="success" @click="RetornarHerramientaABodega()"
                    :loading="loadingMensajeRetornarCentral" :disabled="loadingMensajeRetornarCentral || !DesactivarBotonRealizarTransaccion"
                >Si, retornar a bodega
                    <v-icon right dark>fas fa-save</v-icon>
                </v-btn>            
                <v-btn dark large rounded class="mx-2" color="error" @click="CerrarModalRetornarVehiculoACentral()">Cancelar
                    <v-icon right dark>far fa-window-close</v-icon>
                </v-btn>                        </v-card-actions>
        </v-card>        
    </v-dialog>        
</template>

<script>
import { showMessageSuccess } from '../../../services/sweetAlert'
export default {
    props:{
        dialogoMensajeRetornarCentral: Boolean,
        datosHerramientas: Object,
        alertError: Boolean,
    },

    data() {
        return {
            // variables para retornar herramientas a sucursal central
            valid: false,
            lazy: false,      
            ReglasFormulario: [
                v => !!v || 'Campo es requerido',
            ],
            modeloRetornarHerramienta:{
                idProyecto: 0,
                idHerramienta:0,
                cantidadRecibida:'',
                observacionCantidadRecibida:"",
                cantidadExtraviada:'',
                observacionCantidadExtraviada:"",
                cantidadArruinada:'',
                observacionCantidadArruinada:"",
            },
            loadingMensajeRetornarCentral: false,
            CantidadRecibida:0,
            CantidadExtraviadaPorElUsuario:0,
            CantidadArruinada:0,

            ActivarAlertaCantidadExtraviadaPorElUsuario: false,
            TotalCantidades:0,
            ActivarObservacionCantidadRecibida:false,
            ActivarObservacionCantidadExtraviada:false,
            ActivarObservacionCantidadArruinada:false,

            ActivarAlertaCantidadesMayorCantidadTransferida: false,
            ActivarAlertaCantidadesSonIguales: false,
            DesactivarBotonRealizarTransaccion:false,
        }
    },

    watch: {
        CantidadExtraviadaPorElUsuario(){
            if(this.CantidadExtraviadaPorElUsuario > 0){
                 this.ActivarAlertaCantidadExtraviadaPorElUsuario = true
                 this.ActivarObservacionCantidadExtraviada = true
            }else {
                this.ActivarAlertaCantidadExtraviadaPorElUsuario = false
                this.ActivarObservacionCantidadExtraviada = false
            }
            this.CalcularTotal()
        },

        CantidadRecibida (){
            if(this.CantidadRecibida>0) this.ActivarObservacionCantidadRecibida = true;
            else this.ActivarObservacionCantidadRecibida = false;
            this.CalcularTotal()
        },

        CantidadArruinada (){
            if(this.CantidadArruinada > 0) this.ActivarObservacionCantidadArruinada = true;
            else this.ActivarObservacionCantidadArruinada = false;
            this.CalcularTotal()
        },
    },

    created() {
        this.CantidadRecibida = ""
        this.CantidadExtraviadaPorElUsuario = ""
        this.CantidadArruinada = ""
    },
    methods: {
        CalcularTotal(){
            this.TotalCantidades = parseFloat(this.CantidadRecibida) + parseFloat(this.CantidadExtraviadaPorElUsuario) + parseFloat(this.CantidadArruinada)
            if(this.TotalCantidades > this.datosHerramientas.Cantidad || this.TotalCantidades < this.datosHerramientas.Cantidad){
                this.ActivarAlertaCantidadesMayorCantidadTransferida = true
                this.ActivarAlertaCantidadesSonIguales = false
            }else if(this.TotalCantidades === this.datosHerramientas.Cantidad){
                this.ActivarAlertaCantidadesMayorCantidadTransferida = false
                this.ActivarAlertaCantidadesSonIguales = true
                this.DesactivarBotonRealizarTransaccion = true
            }else if(this.TotalCantidades != this.datosHerramientas.Cantidad){
                this.DesactivarBotonRealizarTransaccion = false
            }            
        },

        CerrarModalRetornarVehiculoACentral(){
            this.$emit('CerrarModalRetornarVehiculoACentral')
            this.$refs.form.reset()
            this.ActivarAlertaCantidadesSonIguales = false
        },

        async RetornarHerramientaABodega(){            
            this.loadingMensajeRetornarCentral = true
            this.modeloRetornarHerramienta.idProyecto = this.datosHerramientas.idProyecto
            this.modeloRetornarHerramienta.idHerramienta = this.datosHerramientas.idHerramienta
            this.modeloRetornarHerramienta.cantidadRecibida = this.CantidadRecibida
            this.modeloRetornarHerramienta.cantidadExtraviada = this.CantidadExtraviadaPorElUsuario
            this.modeloRetornarHerramienta.cantidadArruinada = this.CantidadArruinada
            // console.log('modeloRetornarHerramienta: ', this.modeloRetornarHerramienta)

            if(this.$refs.form.validate()){
                if(this.ActivarAlertaCantidadesSonIguales){
                    // console.log('herramienta => ', this.datosHerramientas)
                    const response = await this.$store.dispatch('RetornarHerramientaBodega', this.modeloRetornarHerramienta)
                    if(response.status === 200){
                        this.loadingMensajeRetornarCentral = false
                        this.$emit('ObtenerInventarioHerramientaPorSucursal')
                        this.alertError= false
                        this.CerrarModalRetornarVehiculoACentral()
                        showMessageSuccess('BIEN!', 'Herramienta retornado exitosamente a bodega.')
                    }else{
                        this.responseStatus = response
                        this.loadingMensajeRetornarCentral = false
                    }
                }else{
                    this.ActivarAlertaCantidadesSonIguales = false
                }
            }else{
                this.loadingMensajeRetornarCentral = false
            }
        }        
    },
}
</script>