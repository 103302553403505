import Vue from 'vue'

const store = {
    actions: {
        //add here all acctions api        
        //obtenemos todas los materiales de construccion
        ObtenerListadoMateriaPrimaCatalogo: async({}) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/catalogo')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener Materiales de Construcción', e)
            }
        },
        //obtenemos todas los materiales de construccion
        ObtenerMaterialConstruccion: async({}, data) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/bodega/' + data.bodega + '/sucursal/' + data.sucursal)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error Obtener Materiales de Construcción', e)
            }
        },

        //obtenemos todas las marcas para materia prima
        ObtenerMarcasMateriaPrima: async({}) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/MarcaMateriaPrima')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error obtener marcas materia prima', e)
            }
        },

        //obtenemos todas las unidades de medida  para materia prima
        ObtenerUnidadesDeMedida: async({}) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/UnidadesDeMedida')
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error obtener unidades de medida materia prima', e)
            }
        },
        //obtenemos materia prima por su id
        ObtenerMateriaPrimaById: async({}, idMateriaPrima) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/byId/' + idMateriaPrima)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error obtener materia prima por su id', e)
            }
        },

        //guardamos materia prima
        GuardarMateriaPrima: async({}, data) => {
            try {
                const response = await Vue.axios.post('/MateriaPrima', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al guardar materia prima', error)
            }
        },
        //actualizamos materia prima
        ActualizarMateriaPrima: async({}, data) => {
            try {
                const response = await Vue.axios.put('/MateriaPrima', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al actualizar materia prima', error)
            }
        },
        //eliminar materia prima
        EliminarMateriaPrimaById: async({}, idMateriaPrima) => {
            try {
                const response = await Vue.axios.delete('/MateriaPrima/' + idMateriaPrima)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar materia prima', error)
            }
        },
        //obtener todos los movimientos de las sucursales de un material o insumos
        TimeLineMovimientosPorMaterialInsumos: async({}, data) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/movimientos/byMaterial/' + data.idMaterial + '/fechaInicio/' + data.fechaInicio + '/fechaFin/' + data.fechaFin)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        // se registra el movimiento de compra de un material o insumo
        GuardarMovimientoCompraMateriaInsumo: async({}, data) => {
            try {
                const response = await Vue.axios.post('/MateriaPrima/movimiento/compra', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar materia prima', error)
            }
        },
        // se registra el movimiento de salida de un material o insumo
        GuardarMovimientoSalidaMateriaInsumo: async({}, data) => {
            try {
                const response = await Vue.axios.post('/MateriaPrima/movimiento/salida', data)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al eliminar materia prima', error)
            }
        },
        // transferimos un material o insumo a una sucursal
        TransferirMaterialInsumoSucursal: async({}, data) => {
            try {
                const response = await Vue.axios.post('/MateriaPrima/transferir', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        //retornamos un material a bodega
        RetornarMaterialABodegaByIdMaterial: async({}, data) => {
            try {
                const response = await Vue.axios.post('/MateriaPrima/movToCentral', data)
                return response
            } catch (e) {
                return e.response
            }
        },
        //catalogos
        getAllCatalogoMateriales: async({}) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/catalogo')
                return response
            } catch (e) {
                return e.response
            }
        },
        newCatalogoMaterial: async({}, data) => {
            try {
                const response = await Vue.axios.post('/MateriaPrima/catalogo', data)
                return response
            } catch (e) {
                return e.response
            }
        },
        updateCatalogoMaterial: async({}, data) => {
            try {
                const response = await Vue.axios.put('/MateriaPrima/catalogo', data)
                return response
            } catch (e) {
                return e.response
            }
        }
    }
}

export default store