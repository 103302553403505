<template> 
    <v-dialog 
        v-model="dialogoMensajeRetornarCentral"
        persistent 
        max-width="500px"
    >
        <v-card  style="border: 2px solid black">
            <div class="text-center py-6">
                <v-alert dense outlined type="error" v-if="alertError">
                    No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                </v-alert>                
                <v-icon size="50" color="success">fas fa-exchange-alt</v-icon>
                <h2 class="titulomensaje">¿Esta seguro que desea Retornar {{datosVehiculoMaquinaria.VehiculoMaquinaria}} a Sucursal origen ?</h2>
                <!-- <h2 class="titulomensaje">{{tituloMensajeEliminar}}</h2> -->
            </div>
            <hr class="mt-2">
            <div class="text-center">
                <span class="fuenteTitulo"><strong>Código:</strong> {{datosVehiculoMaquinaria.codigo}}</span> <br>
                <span class="fuenteTitulo"><strong>Nombre:</strong> {{datosVehiculoMaquinaria.nombre}}</span> <br>                
                <span class="fuenteTitulo"><strong>Tipo:</strong> {{datosVehiculoMaquinaria.Tipo}} </span> <br>
                <span class="fuenteTitulo"><strong>Marca:</strong> {{datosVehiculoMaquinaria.Marca}} </span> <br>
                <v-textarea label="Observación" outlined v-model="Observacion" class="mx-5 mt-5"></v-textarea>   
                <v-alert :value="alertErrorPost"
                        type="error"
                        outlined
                        transition="scale-transition"
                        color="deep-orange">
                        <h4>Errores:</h4>
                        <ul>
                            <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                <h6>{{i.description}}</h6>
                            </li>
                        </ul>
                </v-alert>                              
            </div>
            <hr>            
            <v-card-actions class="pb-6">
                <v-spacer></v-spacer>
                <v-btn large rounded class="mx-2" color="success" @click="RetornarMaquinariaCentral()"
                    :loading="loadingMensajeRetornarCentral" :disabled="loadingMensajeRetornarCentral"
                >Si, Retornar
                    <v-icon right dark>fas fa-save</v-icon>
                </v-btn>            
                <v-btn dark large rounded class="mx-2" color="error" @click="CerrarModalRetornarMaquinariaACentral()">Cancelar
                    <v-icon right dark>far fa-window-close</v-icon>
                </v-btn>                        </v-card-actions>
        </v-card>        
        <ControlladorExcepciones :response="responseStatus"/>  
    </v-dialog>      
</template>

<script>
import ControlladorExcepciones from '../../../components/ControlandoExcepcionesHTTPS'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
export default {
    props:{
        dialogoMensajeRetornarCentral: Boolean,
        datosVehiculoMaquinaria: Object,
        alertError: Boolean,       
        idMaquinaria: Number,
    },

    components:{
        ControlladorExcepciones,
    },

    data() {
        return {
            // errores cuando la existencia no es suficinete
            alertErrorPost:false,
            arrayErrorPost:[],            
            Observacion: '',   
            loadingMensajeRetornarCentral: false,
            responseStatus:[],
        }
    },

    methods: {
        CerrarModalRetornarMaquinariaACentral(){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.$emit('CerrarModalRetornarMaquinariaACentral')
            this.Observacion = ""
        },
        async RetornarMaquinariaCentral(){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loadingMensajeRetornarCentral = true
            var data = {idMaquinaria: this.idMaquinaria, observacion: this.Observacion}
            // console.log('retornarMaquinaria: ', data)
            const response = await this.$store.dispatch('RetornarMaquinariaSucursalCentralByIdMaquinaria', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[];
                this.$emit("ObtenerInventarioMaquinariaPorSucursal")
                this.alertError= false
                this.loadingMensajeRetornarCentral = false
                this.CerrarModalRetornarMaquinariaACentral()
                showMessageSuccess('BIEN!', 'Maquinaria retornado exitosamente a Sucursal de origen.')
            }else{
                this.ControlladorExcepciones(response)
            }            
        },
        // controlamos todas las excepciones http
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loadingMensajeRetornarCentral = false 
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loadingMensajeRetornarCentral = false                      
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loadingMensajeRetornarCentral = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loadingMensajeRetornarCentral = false
            }                
        },         
    },
}
</script>