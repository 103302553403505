<template>
    <div>
        <v-toolbar title elevation="1" color="blue" dark>
            <v-icon left>fas fa-snowplow</v-icon>
            <v-toolbar-title class="tituloMaquinaria">Maquinarias a solicitar a inventarios</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>    
            <v-btn large color="primary" dark rounded class="mx-2 TituloDialogo"  @click="EnviarTodasLasSolicitudes()"
                :loading="loading" :disabled="loading || !ActivarBotonEnviarSolicitud"
            >Enviar Solicitudes
                <v-icon right dark>fas fa-save</v-icon>
            </v-btn>                                       
        </v-toolbar>
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>        
        <v-data-table
            :headers="EncabezadoTablaMaquinariaASolicitarAInventarios"
            :items="ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar"
            item-key="idHerramienta"
            class="elevation-1"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom color="#D80000">
                    <template v-slot:activator="{ on }">
                        <v-btn  small class="ml-2" dark fab color="#D80000" v-on="on" @click="QuitarMaquinariaDeLaSolicitudAEnviar(item)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Quitar maquinaria de la solicitud</span>
                </v-tooltip>                                              
            </template>        
            <template v-slot:no-data v-if="ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" >
                    Agregar maquinarias a esta nueva solicitud
                </v-alert>
            </template>                    
        </v-data-table>        
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../../services/sweetAlert'

export default {
    props:{
        ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar: Array,
        proyecto: Number,
        sucursal:Number,
        bodega:Number,
    },

    components:{

    },

    data() {
        return {
            loading: false,
            EncabezadoTablaMaquinariaASolicitarAInventarios:[
                {text: 'Código', value:'codigo', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Tipo Maquinaria', value: 'tipoMaquinaria', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Nombre', value:'nombreMaquinaria', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'marcaMaquinaria', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            ActivarBotonEnviarSolicitud:false,
            // errores registros duplicados y vacíos
            alertErrorPost:false,
            arrayErrorPost:[],                        
        }
    },
    watch:{
        ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar(){
            if(this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.length >0){
                this.ActivarBotonEnviarSolicitud = true
            }else{
                this.ActivarBotonEnviarSolicitud = false
            }
        },        
        sucursal(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]            
        },
    },

    methods: {
        async EnviarTodasLasSolicitudes(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[]; 
            var data = {maquinas: this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar, Proyecto: this.proyecto, Sucursal: this.sucursal, Bodega: this.bodega} 
            const response = await this.$store.dispatch('EnviarSolicitudesDeMaquinariaABodega', data)
            if(response.status === 200){
                this.alertErrorPost=false;this.arrayErrorPost=[]; 
                this.loading = false
                this.alertError= false
                showMessageSuccess('¡BIEN!', response.data.message)
                this.$emit('ObtenerInventarioDeMaquinariasDisponibles')
            }else{
                this.ControlladorExcepciones(response)
            }            
        },
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.alertErrorPost = false
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false       
                this.alertErrorPost = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.alertErrorPost = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.alertErrorPost = false
            }                
        },
        //quitamos la maquinaria dde la solicitud que no quiere agregar el usuario
        QuitarMaquinariaDeLaSolicitudAEnviar(item){
            const index =this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.indexOf(item)
            this.ArrayObtenerTodasLasSolicitudesDeMaquinariasDeInventarioASolicitar.splice(index,1)
        },                        
    },
}
</script>