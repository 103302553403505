<template>
  <div class="home">
   <HomePage/>
  </div>
</template>



<script>
import HomePage from '../components/homepage/HomePage.vue'

export default {
  name: 'home',

  components: {
    HomePage,
  },
  data() {
    return{
    }
  },


  methods: {

  },  
}
</script>

<style>
  .border-card{
    border-top: 8px solid #0C0A17 !important;
    border-radius:10px;
  }
  .border-bar{
    border-bottom: 8px solid green !important;
  }

</style>