<template>
    <div>
        <v-dialog
            v-model="dialogoVisualizarPDFInventario"
            scrollable fullscreen 
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title class="AppBar" dark elevation="0" style="color: #ffffff">
                    <v-icon left color="white"> mdi-file-pdf</v-icon> {{Titulo}}
                    <v-spacer></v-spacer>

                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="TituloDialogo" color="error" @click="CerrarModalVisualizarPDFInventario()">Salir
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Salir</span>
                    </v-tooltip>                    
                </v-card-title>
                <v-card-text>
                    <div>
                        <!-- <h1 class="text-center">Generar PDF</h1> -->
                        <iframe class="mt-1" style="border: inset 3px black" width="100%" height="620" :src="URLPDF" ></iframe>
                    </div>                    
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        dialogoVisualizarPDFInventario: Boolean,
        Titulo: String,
        URLPDF: String,
    },

    methods: {
        CerrarModalVisualizarPDFInventario(){
            this.$emit('CerrarModalVisualizarPDFInventario')
        }
    },
}
</script>