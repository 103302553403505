<template style="bac">
    <div>
        <v-card flat>
            <v-toolbar-title class="text-center titulo">
                <v-icon left>fas fa-tools</v-icon>
                Herramientas
            </v-toolbar-title>
            <div class="marginSelect">
                <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <v-select v-model="sucursal" :items="sucursalesAsignadas" menu-props="auto"
                            label="Sucursal"
                            prepend-icon="mdi-city"
                            item-text="nombre"
                            item-value="sucursalId"
                            @change="seleccionarSucursal()"
                        ></v-select>
                    </v-col>    
                    <v-col cols="12" lg="4" md="4">
                        <v-select :disabled="!sucursal"
                            v-model="bodega"
                            :items="sucursalBodegas"
                            menu-props="auto"
                            label="Bodega"
                            prepend-icon="mdi-warehouse"
                            item-text="nombre"
                            item-value="bodegaId"
                            @change="seleccionarBodega()"
                        ></v-select>
                    </v-col> 
                    <v-col>
                        <v-btn v-if="bodega > 0" icon fab dark small color="primary" @click="ResetBodega()">
                            <v-icon dark>fas fa-backspace</v-icon>
                        </v-btn>
                    </v-col>
                </v-row> 
            </div>
                                                    
            <v-toolbar color="#ffffff" flat title>
                <v-text-field class="pr-2"
                    label="Buscar Herramienta"
                    v-model="buscarherramienta"
                    prepend-icon="mdi-magnify"
                    single-line
                    hide-details                    
                ></v-text-field>
                <!-- <v-spacer></v-spacer> -->
                <!-- boton para crear una nueva herramienta -->
                <v-tooltip bottom color="#0019d8" v-if="permisos.crear" >
                    <template v-slot:activator="{ on }">
                            <v-btn color="#0019d8" :disabled="activeActions" outlined dark @click="AbrirDialogoNuevaHerramienta()" v-on="on" class="TamañoLetraBoton"> 
                                <i class="fas fa-plus"></i>
                                Nueva
                            </v-btn>
                    </template>
                    <span>Asociar herramienta</span>
                </v-tooltip>        
                <!-- boton para actualizar tabla de herramientas -->
                <v-tooltip bottom color="#0019d8"  >
                    <template v-slot:activator="{ on }">
                            <v-btn color="#0019d8" outlined dark @click="TodasHerramientas()"
                                v-on="on" class="TamañoLetraBoton mx-2"  
                            > 
                                <v-icon >fas fa-sync-alt</v-icon> 
                            </v-btn>
                    </template>
                    <span>Actualizar</span>
                </v-tooltip>        
            </v-toolbar>
            <v-divider dense></v-divider>      
            <v-data-table
                dense
                :headers="EncabezadoTabla"
                :items="ArrayHerramientas"
                item-key=""
                :search="buscarherramienta"
                class="elevation-1"
                :footer-props="{itemsPerPageText: 'Filas por página'}"                 
                :loading="loading"
                loading-text="Cargando Datos..."
            >
                <template v-slot:item.action="{ item }">
                    <FiltrosBusquedaTimeLine :DatosRegistro="item" :TituloBotonVerMovimientoTimeLine="TituloBotonVerMovimientoTimeLine"
                        :TituloComponente="TituloComponente"
                    />
                    <v-tooltip left color="#01abe6">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#01abe6" v-on="on" @click="AbrirDialogoMovimientoCompra(item)" v-if="permisos.crear">
                                <v-icon small>fas fa-plus-square</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Movimiento</span>
                    </v-tooltip>  
                    <!--                                            
                    <v-tooltip left color="#0019d8">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#0019d8" v-on="on" @click="AbrirDialogoTransferirHerramientaASucursal(item)" v-if="permisos.crear">
                                <v-icon small>fas fa-exchange-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Transferir Herramientas a Sucursales</span>
                    </v-tooltip>                                              
                    -->
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#d69b01" v-on="on" @click="AbrirDialogEditarHerramienta(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar Herramienta</span>
                    </v-tooltip> 
                    <!--                                             
                    <v-tooltip left color="#D80000" v-if="permisos.eliminar">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" outlined icon color="#D80000" v-on="on" @click="AbrirDialogoMensajeEliminar(item)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar Herramienta</span>
                    </v-tooltip>                                              
                    -->
                </template>
                <template v-slot:no-results>
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                        Herramienta  no existe
                    </v-alert>                        
                </template>
                <template v-slot:no-data>
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo" v-if="!alertError">
                        No existen herramientas registradas
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>                                        
                </template>
            </v-data-table>              
        </v-card>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Cargando datos...
        </v-overlay>  
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminar"
            :tituloMensajeEliminar="tituloVehiculoMensajeEliminar" @Eliminar="EliminarHerramienta"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarHerramienta" :alertError="alertError"
        />
        <NuevaHerramienta 
            @CerrarDialogoNuevaHerramienta="CerrarDialogoNuevaHerramienta" :DialogoNuevaHerramienta="DialogoNuevaHerramienta"
            :TituloDialogo="TituloDialogo" :ObjectHerramienta="ObjectHerramienta" :bodega="bodegaId"
            @ActualizarTabla="TodasHerramientas" :esNuevo="esNuevo" :validarfechaobtenida="validarfecha"
            :EstadoPermisoEditar="EstadoPermisoEditar"
        />
        <RegistrarMovimientoCompra
            :ModalMovimientoDeCompra="ModalMovimientoDeCompra" @CerrarDialogoMovimientoCompra="CerrarDialogoMovimientoCompra"
            :TituloDialogo="TituloDialogo" :esNuevo="esNuevo" :DatosHerramienta="DatosHerramienta"
            :EstadoPermisoEditar="EstadoPermisoEditar" @ActualizarTabla="TodasHerramientas"
        />
        <!--
        <TransferirHerramientasASucursales
            :ModalTransferirHerramientaSucursal="ModalTransferirHerramientaSucursal" @CerrarDialogoTransferirHerramientaASucursal="CerrarDialogoTransferirHerramientaASucursal"
            :DatosHerramienta="DatosHerramienta" :EstadoPermisoEditar="EstadoPermisoEditar" @ActualizarTabla="TodasHerramientas"
            :ArraySucursales="ArraySucursales"
        />
        -->
    </div>
</template>

<script>
import NuevaHerramienta from '../../../components/Almacen/Herramientas/NuevaHerramienta'
import RegistrarMovimientoCompra from '../../../components/Almacen/Herramientas/MovimientoCompra'
import TransferirHerramientasASucursales from '../../../components/Almacen/Herramientas/TransferirHerramientasSucursales'
import MensajeEliminar from '../../../components/MensajeEliminar'
import FiltrosBusquedaTimeLine from '../../../components/Almacen/FiltroBusquedaTimeLine'

import { mapGetters, mapActions  } from 'vuex'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'

export default {
    components: {
        NuevaHerramienta,
        MensajeEliminar,
        RegistrarMovimientoCompra,
        TransferirHerramientasASucursales,
        FiltrosBusquedaTimeLine,
    },
    
    data() {
        return {
            sucursalesAsignadas:[],
            sucursalBodegas:[],
            bodega:0,
            bodegaId:0,
            sucursal:-1,
            // alerta cuando el api deja de funcionar
            alertError:false,
            // variable para mostrar un circular progress 
            EstadoCircularProgress: false,            
            loadingEliminarHerramienta: false,
            loading: false,
            buscarherramienta: '',
            EncabezadoTabla: [
                {text: 'Tipo Herramienta', value: 'catalogoHerramienta.tipoHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Nombre', value:'catalogoHerramienta.nombre', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'catalogoHerramienta.marcaHerramienta.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Sucursal', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Bodega', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 1', value: 'ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 2', value: 'ubicacion2', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
            ],
            ArrayHerramientas: [],
            ObjectHerramienta: {},
            DialogoNuevaHerramienta: false,
            TituloDialogo: '',
            esNuevo: true,
            DialogoMensajeEliminar: false,
            tituloVehiculoMensajeEliminar: '',
            IdHerramienta: '',
            validarfecha: '',
            //variables para movimiento de compra
            ModalMovimientoDeCompra: false,
            DatosHerramienta:{},
            // variables para transferir herramientas a sucursales
            ModalTransferirHerramientaSucursal: false,
            ArraySucursales:[],
            // TimeLine Cambios Sucursales
            AbrirTimeLine: false,
            ArrayMovimientosHerramientas:[],
            NombreHerramienta:'',

            //variables para los permisos del colaborador
            namespace:'oidcStore/',    
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },         
            EstadoPermisoEditar: true,
            // variables para filtro de busqueda de timeline
            TituloBotonVerMovimientoTimeLine:"Ver movimientos",
            TituloComponente: 'Herramientas',
            activeActions:true
        }
    },

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    

    created() {
        this.obtenerSucursalesAsignadas()
        this.ObtenerPermisosSubmenuUsuario()
        this.TodasHerramientas()
    },

    methods: {
        //limpia el filtro de bodega
        ResetBodega(){
            this.bodega = 0
            this.TodasHerramientas()
        },
        seleccionarBodega(){
            //si ha seleccionado una sucursal y proyecto
            if(this.sucursal != '' && this.bodega != ''){
                this.activeActions=false
                this.bodegaId=this.bodega
                this.TodasHerramientas()
            }
        },
        seleccionarSucursal(){
            this.bodega = 0
            this.ArrayHerramientas=[]
            this.sucursalesAsignadas.forEach(suc => {
                if(suc.sucursalId == this.sucursal){
                    this.sucursalBodegas = suc.bodegas
                }
            })
            this.TodasHerramientas()
        },
        async obtenerSucursalesAsignadas(){
            const response  = await this.$store.dispatch('misSucursales')
            if(response === undefined){
                showMessageError('No fue posible obtener las sucursales asignadas')
                return
            }else if(response.status === 200){
                this.sucursalesAsignadas = response.data
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.sucursalesAsignadas.push({nombre: 'Todas', sucursalId:0})
                }
            }
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),        

            async ObtenerPermisosSubmenuUsuario(role){
            if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.permisos.crear = true
                        this.permisos.editar = true
                        this.permisos.eliminar = true
                        return 0            
                }else{
                    //preguntar al backend por los permisos del submenu del usuario
                        var url = 'herramientas'
                        const response  = await this.$store.dispatch('PermisosSubMenu', url)
                        if(typeof response === 'undefined'){
                            this.ControllerException()
                            return 0
                        }                                                 
                        if(response.status === 200){
                            this.alertError= false
                            this.permisos = response.data         
                        }else if(response.status === 400){
                            showMessageError('Error 400','Petición mala')
                        }else if(response.status === 401){
                            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        }else if(response.status === 404){
                            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        }else if(response.status === 500){
                            showMessageError('Error 500','Error en el servidor')            
                        }
                        return 0
                }
            }
            },
        //metodos para nueva herramienta
            AbrirDialogoNuevaHerramienta (){
                if(this.permisos.crear) this.EstadoPermisoEditar = true
                this.esNuevo = true
                this.TituloDialogo = 'Crear'
                this.DialogoNuevaHerramienta = true
            },

            CerrarDialogoNuevaHerramienta (){
                this.DialogoNuevaHerramienta = false
            },
        //metodos para editar una herramienta
            async AbrirDialogEditarHerramienta(herramienta){
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('ObtenerHerramientaById',herramienta.idHerramienta)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ObjectHerramienta = response.data    
                    this.validarfecha = response.data.fechaCompra
                    this.DialogoNuevaHerramienta = true
                    this.EstadoCircularProgress = false                                  
                    this.TituloDialogo = 'Editar'       
                    this.esNuevo = false
                    if(this.permisos.editar){
                        this.EstadoPermisoEditar = true
                    }else{
                        this.EstadoPermisoEditar = false
                    }
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false                    
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false                    
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false                    
                }
            },
        //obtenemos todas las herramientas registradas
            async TodasHerramientas () {
                this.loading = true
                var data = {bodega : this.bodega, sucursal: this.sucursal}
                const response = await this.$store.dispatch('ObtenerTodasHerramientas', data)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                } 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayHerramientas =  response.data
                    this.loading = false
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            },  
        //metodos para eliminar una herramienta por su id
            AbrirDialogoMensajeEliminar(herramienta){
                this.tituloVehiculoMensajeEliminar = herramienta.nombre
                this.IdHerramienta = herramienta.idHerramienta
                this.DialogoMensajeEliminar = true
            },

            CerrarDialogoMensajeEliminar(){
                this.DialogoMensajeEliminar = false                
            },

            async EliminarHerramienta() {
                this.loadingEliminarHerramienta = true
                const response = await this.$store.dispatch('EliminarHerramientaById', this.IdHerramienta)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loadingEliminarHerramienta = false                    
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.TodasHerramientas()
                    showMessageSuccess('¡BIEN!', 'Herramienta eliminado correctamente')
                    this.loadingEliminarHerramienta = false
                    this.CerrarDialogoMensajeEliminar()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loadingEliminarHerramienta = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loadingEliminarHerramienta = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loadingEliminarHerramienta = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loadingEliminarHerramienta = false
                }
            },
            
        // metodos para movimiento de compra de herramientas
            AbrirDialogoMovimientoCompra(datos){
                this.TituloDialogo = "Compra"
                this.esNuevo = true
                this.ModalMovimientoDeCompra = true
                this.DatosHerramienta = datos
            },

            CerrarDialogoMovimientoCompra(){
                this.ModalMovimientoDeCompra = false
            },

        // metodos para transferir herramientas a sucursales
        //obtenemos todas las sucursales
            async AbrirDialogoTransferirHerramientaASucursal(datos){
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('getAllProyectos')
                if(response.status === 200){
                    this.DatosHerramienta = datos
                    this.ArraySucursales = response.data
                    this.EstadoCircularProgress = false
                    this.ModalTransferirHerramientaSucursal = true
                }else{
                    this.ControlladorExcepciones(response)
                }
            },

            CerrarDialogoTransferirHerramientaASucursal(){
                this.ModalTransferirHerramientaSucursal = false
            },

        //abrimos el modal para ver el timeline
            async AbrirModalTimeLine(data){
                this.NombreHerramienta = data.nombre
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('MovimientosPorHerramienta', data.idHerramienta)
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayMovimientosHerramientas = response.data
                    this.EstadoCircularProgress = false
                    this.AbrirTimeLine = true                    
                }else{
                    this.ControlladorExcepciones(response)
                }                                            
            },

            CerrarModalTimeLine(){
                this.AbrirTimeLine = false
            },
        
            // controlamos todas las excepciones http
            ControlladorExcepciones(){
                this.loading = true
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')    
                    this.EstadoCircularProgress = false        
                    this.loading = false
                }                
            }
    },
}
</script>
<style>
    .titulo {
        font-family: 'PT Serif';
        font-size: 25px;        
    }
    .TamañoLetraBoton {
        font-family: 'PT Serif';
    }
    .marginSelect{
        margin-left: 20px;
    }
</style>