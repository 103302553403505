<template>
    <div>
        <v-dialog
            v-model="DialogoCambiarSucursal"
            persistent :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-card>
            <!-- barra principal del dialogo -->
                <v-app-bar flat dark color="#3c8dbc" class="mb-5">
                    <v-toolbar-title class="TituloDialogo">Proyecto Actual: {{NombreSucursalActual}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-app-bar>       
            <!-- cuerpo del formulario      -->
                <v-card-text>
                    <v-alert dense outlined type="error" class="mt-2"  v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>
                    <v-alert :value="alertErrorPost"
                            type="error"
                            outlined
                            transition="scale-transition"
                            color="deep-orange">
                            <h4>Errores:</h4>
                            <ul>
                                <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                                    <h6>{{i.description}}</h6>
                                </li>
                            </ul>
                    </v-alert>                                                                                                     
                    <v-form
                        v-model="valid" ref="form" :lazy-validation="lazy" autocomplete="off"
                    >
                        <v-row wrap>
                        <!-- primera seccion -->
                            <!-- sucursales                   -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <v-select outlined required :rules="nameRules"
                                    :items="ArrayProyectos"
                                    item-text="nombre"
                                    item-value="IdProyecto"
                                    v-model="nombreProyecto"
                                    label="Proyecto destino"
                                    @change="seleccionarProyecto()"
                                ></v-select>                            
                            </v-col>                                                                        
                            <!-- descripcion  -->
                            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-0" > 
                                <v-textarea outlined required :rules="nameRules"
                                    v-model="modeloCambiarSucursal.descripcion"
                                    label="Descripción"
                                    color="info"
                                    hide-details
                                ></v-textarea>         
                            </v-col>   
                        </v-row>
                    </v-form>
                    <v-subheader>*Recuerde que este movimiento afecta el inventario de su sucursal y del proyecto destino</v-subheader>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn  v-on="on" large rounded class="mx-2 TituloDialogo" color="success" @click="GuardarCambios()"
                                :loading="loading" :disabled="loading"                        
                            >Guardar
                                <v-icon right dark>fas fa-save</v-icon>
                            </v-btn>                                    
                        </template>
                        <span>Cambio de proyecto</span>
                    </v-tooltip>
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark large rounded class="mx-2 TituloDialogo" color="error" @click="CerrarModalCambiarSucursal()">Cancelar
                                <v-icon right dark>far fa-window-close</v-icon>
                            </v-btn>                        
                        </template>
                        <span>Cancelar</span>
                    </v-tooltip>                    
                </v-card-actions>
            </v-card>                        
        </v-dialog>
    </div>
</template>

<script>
import { showMessageError, showMessageSuccess } from '../../services/sweetAlert'

export default {
    props:{
        DialogoCambiarSucursal: Boolean,
        DatosRegistro: Object,
        ArrayProyectos: Array,
        NombreModulo: String,    
        EstadoPermisoEditar: Boolean,
        NombreSucursalActual: String,
    },

    data() {
        return {
            valid: true,
            nameRules: [
                v => !!v || 'Campo es requerido',
            ],            
            // alerta cuando el api deja de funcionar
            nombreProyecto:'',
            alertError:false,            
            loading: false,
            valid: false,
            lazy: false,

            modeloCambiarSucursal:{
                idVehiculo:0,
                idMaquinaria:0,
                IdProyecto: 0,
                descripcion:'',
            },
            // errores registros duplicados
            alertErrorPost:false,
            arrayErrorPost:[],                        
        }
    },

    watch: {
        DialogoCambiarSucursal(){
            if(this.NombreModulo === "Maquinaria"){
                this.modeloCambiarSucursal.idMaquinaria = this.DatosRegistro.idMaquinaria
            }else{
                this.modeloCambiarSucursal.idVehiculo = this.DatosRegistro.idVehiculo
            }
        }
    },

    methods: {
        seleccionarProyecto(){
            this.ArrayProyectos.forEach(x => {
                if(x.nombre === this.nombreProyecto){
                    this.modeloCambiarSucursal.IdProyecto = x.idProyecto
                }
            })
        },
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        CerrarModalCambiarSucursal(){
            this.alertErrorPost=false
            this.arrayErrorPost=[]       
            this.$refs.form.resetValidation()   
            this.$refs.form.reset()
            this.$emit('TodasMaquinarias')
            this.$emit('CerrarModalCambiarSucursal')
        },

        ControlarEstadosExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                return 0
            }else if(response.status === 200){
                this.alertError= false
                this.loading = false
                this.$emit('TodasMaquinarias')
                showMessageSuccess('¡BIEN!', 'Cambio realizado correctamente')
                this.CerrarModalCambiarSucursal()
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false                   
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                                                    
        },

        async GuardarCambios(){
            if(this.$refs.form.validate()){
                this.loading = true
                this.alertErrorPost=false;this.arrayErrorPost=[];
                if(this.NombreModulo === "Maquinaria"){
                    const response = await this.$store.dispatch('CambiarSucursalDeMaquinaria', this.modeloCambiarSucursal)
                    this.ControlarEstadosExcepciones(response)
                }else{
                    const response = await this.$store.dispatch('CambiarSucursalDelVehiculo', this.modeloCambiarSucursal)                
                    this.ControlarEstadosExcepciones(response)
                }
            }
        }
    },
}
</script>