<template>
<div>
    <div class="text-center pt-2 pb-2">
        <v-toolbar-title class="tituloMaquinaria">
            <v-icon>fas fa-scroll</v-icon>
            Inventario por proyecto
        </v-toolbar-title>
         <v-divider></v-divider>
        <v-row wrap>
            <v-col cols="12" lg="3" md="3" sm="6" >
                <v-select outlined class="ml-6"
                    :items = "ArrayProyectos"
                    item-text ="nombre"
                    item-value="idProyecto"
                    v-model = "proyectoSeleccionado"
                    label="Seleccionar proyecto"
                ></v-select>        
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6">
                <v-select outlined class="ml-6"
                    :items = "sucursalesAsignadas"
                    item-text ="nombre"
                    item-value="sucursalId"
                    v-model = "seleccionarSucursal"
                    label="Seleccionar Sucursal"
                    @change="SeleccionandoSucursal()"
                ></v-select>    
            </v-col>                                        
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" >
                <v-chip class="ma-2" color="" outlined v-if="proyecto.direccion"> 
                    <v-icon left>
                        fas fa-map-marker-alt
                    </v-icon>
                    {{proyecto.direccion}}
                </v-chip>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" >
                <v-chip class="ma-2" color="" outlined v-if="proyecto.telefono">
                    <v-icon left>
                        fas fa-phone
                    </v-icon>
                    {{proyecto.telefono}}
                </v-chip>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" >
                <v-chip class="ma-2" color="" outlined v-if="proyecto.usuarioEncargado">
                    <v-icon left>
                        fas fa-user-tie
                    </v-icon>
                    {{proyecto.nombreEncargado}}
                </v-chip>
            </v-col>
        </v-row>
        <v-divider></v-divider>
            <v-row v-if="bandera" class="mx-5 my-5">
                <v-btn class="mr-2" tile color="error" @click="AbrirModalVisualizarPDFInventario()">
                    <v-icon left> fas fa-file-pdf</v-icon> Inventario general
                </v-btn>
                <v-btn class="mr-2" tile color="error" @click="AbrirModalVisualizarPDFCorteProyecto()">
                    <v-icon left> fas fa-file-pdf</v-icon> Corte Proyecto
                </v-btn>
                <v-btn tile color="info" @click="AbrirModalVisualizarTablaCortes()">
                    <v-icon left> fas fa-list-alt</v-icon> Ver cortes
                </v-btn>                
            </v-row>        
        <v-divider></v-divider>
        <Menu 
            :alertError="alertError" :permisoCrear="permisos.crear" :proyectoSeleccionado="proyectoSeleccionado"
            :idProyecto="idProyecto" :loading="loading" :idSucursal="idSucursal"
            :proyecto="proyecto" :ActivarMenuTabs="ActivarMenuTabs" :tabSeleccionado="tabSeleccionado"
            :ArrayInventarioVehiculoPorSucursal="ArrayInventarioVehiculoPorSucursal"
            :ArrayInventarioMaquinariaPorSucursal="ArrayInventarioMaquinariaPorSucursal"
            :ArrayInventarioHerramientaPorSucursal="ArrayInventarioHerramientaPorSucursal"
            :ArrayInventarioMaterialInsumosPorSucursal="ArrayInventarioMaterialInsumosPorSucursal"

            @ObtenerInventarioVehiculosPorSucursal="ObtenerInventarioVehiculosPorSucursal"
            @ObtenerInventarioMaquinariaPorSucursal="ObtenerInventarioMaquinariaPorSucursal"
            @ObtenerInventarioHerramientaPorSucursal="ObtenerInventarioHerramientaPorSucursal"
            @ObtenerInventarioMaterialInsumosPorSucursal="ObtenerInventarioMaterialInsumosPorSucursal"
        />
        <VisualizarPDFInventarioGeneral 
            :dialogoVisualizarPDFInventario="dialogoVisualizarPDFInventario" :Titulo="Titulo" :URLPDF="URLPDF"
            @CerrarModalVisualizarPDFInventario="CerrarModalVisualizarPDFInventario"
        />  
        <VisualizarPDFCorteSucursal 
            :dialogoVisualizarPDFInventario="dialogoVisualizarPDFCorteSucursal" :Titulo="Titulo" :URLPDF="URLPDF"
            @CerrarModalVisualizarPDFInventario="CerrarModalVisualizarPDFCorteSucursal"
        />  
        <VerTablaCortes 
            :dialogoVisualizarTablaCortes="dialogoVisualizarTablaCortes" :Titulo="Titulo" @CerrarModalVisualizarTablaCortes="CerrarModalVisualizarTablaCortes"
            :ArrayBitacoraDeCortes="ArrayBitacoraDeCortes"
        />        
    </div>
        <!-- mostramos el circular progress cuando carguen los datos al mostrar -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Cargando datos...
        </v-overlay>      
</div>    
</template>

<script>
import VisualizarPDFInventarioGeneral from './VisualizarPDFInventario'
import VisualizarPDFCorteSucursal from './VisualizarPDFInventario'
import VerTablaCortes from './VerCortes'
import Menu from './menu'
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import { mapGetters, mapActions  } from 'vuex'

    export default {
        components: {
            Menu,
            VisualizarPDFInventarioGeneral,
            VisualizarPDFCorteSucursal,
            VerTablaCortes,
        },

        data () {
            return {
                loading: false,
                proyectoSeleccionado:0,
                idProyecto:0,
                proyecto:{},
                nombreSucursal:'',
                ArrayProyectos:[],
                ActivarMenuTabs: false,
                ArrayInventarioVehiculoPorSucursal:[],
                ArrayInventarioMaquinariaPorSucursal:[],
                ArrayInventarioHerramientaPorSucursal:[],
                ArrayInventarioMaterialInsumosPorSucursal:[],
                // permisos del usuario
                namespace:'oidcStore/',    
                permisos:{
                    crear:false,
                    editar:false,
                    eliminar:false
                },
                alertError: false,
                //variables para visualizar pdf de inventario general
                dialogoVisualizarPDFInventario: false,
                Titulo: '',
                URLPDF: '',
                EstadoCircularProgress:false,
                //variables para visualizar pdf corte sucursal
                dialogoVisualizarPDFCorteSucursal: false,
                // correo usuario
                correoUsuario: '',
                // ver tabla de bitacoras de cortes
                dialogoVisualizarTablaCortes: false,
                ArrayBitacoraDeCortes: [],
                sucursalesAsignadas:[],
                seleccionarSucursal:-1,           
                seleccionarTodos: false,     
                idSucursal:-1,
                bandera:false,
                isAdmin: false,
                tabSeleccionado:"",
            }
        },
        computed: {
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcUser'
            ]),
            hasAccess: function() {
                return this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']
            },
        },    

        created() {
            this.ObtenerPermisosSubmenuUsuario()
            this.ObtenerTodasSucursales()
            this.obtenerSucursalesAsignadas()
        },

        watch: {
            proyectoSeleccionado(){
                this.userNameComputed()
                this.ArrayProyectos.forEach(x => {
                    if(x.idProyecto === this.proyectoSeleccionado){
                        this.idProyecto = x.idProyecto
                        this.bandera = true
                    }
                });
                this.ObtenerSucursalPorId();
            },
        },
        methods: {
            SeleccionandoSucursal(){
                this.idSucursal = this.seleccionarSucursal
                this.ActivarMenuTabs=true
            },
            async obtenerSucursalesAsignadas(){
                const response  = await this.$store.dispatch('misSucursales')
                if(response === undefined){
                    showMessageError('No fue posible obtener las sucursales asignadas')
                    return
                }else if(response.status === 200){
                    //aca agregar la opcion todos si es admin
                    this.sucursalesAsignadas = response.data
                    if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.sucursalesAsignadas.push({nombre: 'Todas', sucursalId:0})
                    }
                }else{
                    this.ControlladorExcepciones(response)
                }
            },             
            userNameComputed () {
                if(this.$store.getters['oidcStore/' + 'oidcIsAuthenticated']){
                    var user = this.$store.getters['oidcStore/'+'oidcUser']
                    this.correoUsuario = user.name
                    return this.correoUsuario
                }
            },

            // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
            ControllerExceptionError(){
                this.alertError=true
            },        

            // obtenemos los permisos del usuario a este modulo        
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),

            async ObtenerPermisosSubmenuUsuario(role){
            if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.permisos.crear = true
                        this.permisos.editar = true
                        this.permisos.eliminar = true
                        this.isAdmin = true
                        return 0
                }else{
                    //preguntar al backend por los permisos del submenu del usuario
                        var url = 'inventarioProyecto'
                        const response  = await this.$store.dispatch('PermisosSubMenu', url)
                        if(typeof response === 'undefined'){
                            this.ControllerExceptionError()
                            return 0
                        }
                        if(response.status === 200){
                            this.alertError= false
                            this.permisos = response.data
                            this.isAdmin = false
                        }else if(response.status === 400){
                            showMessageError('Error 400','Petición mala')
                        }else if(response.status === 401){
                            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                            this.loading = false                      
                        }else if(response.status === 404){
                            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        }else if(response.status === 500){
                            showMessageError('Error 500','Error en el servidor')            
                        }
                        return 0
                }
            }
            },            
            // controlamos las excepciones de las peticiones al api
            ControllerException(response){
                if(typeof response === 'undefined'){
                    this.alertError=true
                    this.loading = false
                    this.EstadoCircularProgress = false
                    this.loadingEliminarTipoVehiculo = false
                    return 0
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loadingEliminarTipoVehiculo = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loadingEliminarTipoVehiculo = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loadingEliminarTipoVehiculo = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loadingEliminarTipoVehiculo = false
                    this.loading = false
                    this.EstadoCircularProgress = false
                }
            },        
            async ObtenerTodasSucursales(){
                this.loading = true
                const response = await this.$store.dispatch('getAllProyectos')
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false        
                    this.ArrayProyectos = response.data
                }else{
                    this.ControllerException(response)
                }
            },
            async ObtenerSucursalPorId(){
                this.loading = true
                const response = await this.$store.dispatch('GetProyectoById', this.idProyecto)
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false        
                    this.nombreSucursal = response.data.nombre
                    this.proyecto= response.data
                }else{
                    this.ControllerException(response)
                }
            },
            
            async ObtenerInventarioVehiculosPorSucursal(){
                this.loading = true
                this.ArrayInventarioVehiculoPorSucursal=[]
                var data = {idProyecto: this.idProyecto, idSucursal: this.idSucursal}
                const response = await this.$store.dispatch('InventarioVehiculosPorProyectoAdmin', data)
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false        
                    this.ArrayInventarioVehiculoPorSucursal = response.data
                }else{
                    this.ControllerException(response)
                }
            },

            async ObtenerInventarioMaquinariaPorSucursal(){
                this.loading = true
                this.ArrayInventarioMaquinariaPorSucursal = []
                var data = {idProyecto: this.idProyecto, idSucursal: this.idSucursal}
                const response = await this.$store.dispatch('InventarioMaquinariaPorProyectoAdmin', data)
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false        
                    this.ArrayInventarioMaquinariaPorSucursal = response.data
                }else{
                    this.ControllerException(response)
                }
            },

            async ObtenerInventarioHerramientaPorSucursal(){
                this.loading = true
                this.ArrayInventarioHerramientaPorSucursal = []
                let datos = {idProyecto: this.idProyecto, idSucursal: this.idSucursal}
                const response = await this.$store.dispatch('InventarioHerramientaPorProyectoAdmin', datos)
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false    
                    this.ArrayInventarioHerramientaPorSucursal = response.data
                }else{
                    this.ControllerException(response)
                }
            },
            async ObtenerInventarioMaterialInsumosPorSucursal(){
                this.loading = true
                this.ArrayInventarioMaterialInsumosPorSucursal= []
                let datos = {idProyecto: this.idProyecto, idSucursal: this.idSucursal}
                const response = await this.$store.dispatch('InventarioMaterialInsumosPorProyectoAdmin', datos)
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false       
                    this.ArrayInventarioMaterialInsumosPorSucursal = response.data
                }else{
                    this.ControllerException(response)
                }
            },

            async AbrirModalVisualizarPDFInventario(){
                this.EstadoCircularProgress = true
                this.Titulo = "PDF de inventario general"
                let datos = {idProyecto: this.idProyecto, idSucursal: this.idSucursal , rubro: 0}
                const response = await this.$store.dispatch('InventarioGeneralPDF', datos)
                if(response.status === 200){
                    this.alertError= false
                    this.loading = false       
                    this.URLPDF= process.env.VUE_APP_BACKEND_HOST + '/Proyecto/pdf/'+this.idProyecto+'/rubro/'+0+'/idSucursal/'+this.idSucursal
                    this.dialogoVisualizarPDFInventario = true
                    this.EstadoCircularProgress = false
                }else{
                    this.ControllerException(response)
                }                
            },
            CerrarModalVisualizarPDFInventario(){
                this.URLPDF= ''
                this.dialogoVisualizarPDFInventario = false
            },
            async AbrirModalVisualizarPDFCorteProyecto(){
                this.EstadoCircularProgress = true
                this.Titulo = "PDF Corte Proyecto"
                let datos = {idProyecto: this.idProyecto, idSucursal: this.idSucursal ,usuario: this.correoUsuario}
                const response = await this.$store.dispatch('CorteProyectoPDF', datos)
                if(response.status === 200){
                    this.URLPDF= ''
                    this.alertError= false
                    this.loading = false       
                    this.forceFileDownload('Corte_inventario.pdf',response)
                }else{
                    this.ControllerException(response)
                }                
            },
            forceFileDownload(fileName, response) {
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = window.URL.createObjectURL(file);
                this.URLPDF= fileURL
                this.dialogoVisualizarPDFCorteSucursal = true
                this.EstadoCircularProgress = false
                //const link = document.createElement('a')
                //link.href = fileURL;
                //link.download = fileName;
                //link.click();
            },
            CerrarModalVisualizarPDFCorteSucursal(){
                this.URLPDF= ''
                this.dialogoVisualizarPDFCorteSucursal = false
            },

            async AbrirModalVisualizarTablaCortes(){
                this.EstadoCircularProgress = true
                this.Titulo = "Tabla de cortes"
                const response = await this.$store.dispatch('ObtenerBitacoraDeCortes', this.idProyecto)
                if(response.status === 200){
                    this.ArrayBitacoraDeCortes = response.data
                    this.alertError= false
                    this.loading = false    
                    this.dialogoVisualizarTablaCortes = true
                    this.EstadoCircularProgress = false                
                }else{
                    this.ControllerException(response)
                }                                
            },
            CerrarModalVisualizarTablaCortes(){
                this.dialogoVisualizarTablaCortes = false
            },
        },
    }
</script>