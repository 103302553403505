<template>
  <v-data-table
    :headers="headers"
    :items="sucursales"
    sort-by="nombre"
    class="elevation-1"
    :loading="loanding"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="sucursalId"
    show-expand
    single-expand
    loading-text="Cargando datos ... por favor espere"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>
            <i class="fas fa-city"></i>
            Sucursales</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="#0019d8" >
            <template v-slot:activator="{ on }">
                    <v-btn color="#0019d8" rounded outlined dark @click="getAllSucursales()"
                        v-on="on" class="TamañoLetraBoton mx-2"
                    > 
                        <v-icon >fas fa-sync-alt</v-icon> 
                    </v-btn>
            </template>
            <span>Actualizar</span>
        </v-tooltip> 
        <v-btn outlined
              v-if="permisos.crear"
              color="primary"
              dark
              class="mb-2"
              @click="nueva()"
            >
            <i class="fas fa-plus"></i>
              Nueva
            </v-btn>
    <MensajeEliminar
      :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminar"
      :tituloMensajeEliminar="tituloMensajeEliminar" :loadingEliminarHerramientaMateriaPrima="loadingMensajeEliminar"
      @Eliminar="EliminarBodega"
    />            
    <MensajeEliminarSucursal
      :DialogoMensajeEliminar="DialogoMensajeEliminarSucursal" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminarSucursal"
      :tituloMensajeEliminar="tituloMensajeEliminarSucursal" :loadingEliminarHerramientaMateriaPrima="loadingMensajeEliminarSucursal"
      @Eliminar="DeleteSucursal"
    />            
        <v-dialog
            persistent
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <alertErrorGeneric :alertErrorPost="alertErrorPost" :arrayErrorPost="arrayErrorPost" />
                <v-row>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                      v-model="modelSucursal.nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                      v-model="modelSucursal.direccion"
                      label="Dirección"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-select
                        :items="Usuarios"
                        item-value="email"                                    
                        item-text="nombre"
                        label="Encargado"
                        v-model="modelSucursal.usuarioEncargado"
                        v-on:change="selectEncargado()"
                    ></v-select> 
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined color="red darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn outlined color="blue darken-1" text @click="newSucursal()" :loading="loading" :disabled="loading"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogBodega" max-width="500px" persistent>
          <v-card>
            <v-card-title class="headline">{{titleBodega}} bodega</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                <alertErrorGeneric :alertErrorPost="alertErrorPost" :arrayErrorPost="arrayErrorPost" />
                  <v-row>
                    <v-col cols="12" sm="6" md="6" >
                      <v-text-field
                        v-model="modelBodega.nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" outlined text @click="closeBodega">Cancelar</v-btn>
              <v-btn color="blue darken-1" outlined text @click="saveBodega" :loading="loading" :disabled="loading">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip left color="#d69b01" v-if="permisos.editar">
          <template v-slot:activator="{ on }">
              <v-btn small class="ml-2" outlined icon color="#d69b01" v-on="on" @click="editItem(item)">
                  <v-icon small>fas fa-edit</v-icon>
              </v-btn>                        
          </template>
          <span class="fontButton">Editar</span>
      </v-tooltip>  
      <v-tooltip left color="#E40000" v-if="permisos.editar">
          <template v-slot:activator="{ on }">
              <v-btn small class="ml-2" icon color="#E40000" v-on="on" @click="AbrirDialogoMensajeEliminarSucursal(item)">
                  <v-icon small>far fa-window-close</v-icon>
              </v-btn>                        
          </template>
          <span class="fontButton">Eliminar Sucursal</span>
      </v-tooltip>       
    </template>
    <template v-slot:no-data>
    </template>
    <template v-slot:expanded-item="{ headers, item }" >
      <td :colspan="headers.length">
        <v-card >
          <v-toolbar class="text-center" outlined dark dense color="blue">
            <v-toolbar-title >Bodegas</v-toolbar-title>
            <v-tooltip right color="blue" v-if="permisos.crear">
              <template v-slot:activator="{ on }">
                  <v-btn small class="ml-2" outlined icon color="white" v-on="on" @click="addBodega(item)" :loading="loading" :disabled="loading">
                      <v-icon small>fas fa-plus</v-icon>
                  </v-btn>                        
              </template>
              <span class="fontButton">Agregar bodega</span>
          </v-tooltip> 
          </v-toolbar>
          <template>
            <v-data-table dense :headers="headersBodegas" :items="item.bodegas" item-key="nombre" class="elevation-1">
              <template v-slot:item.actions="{ item }">
                <v-tooltip left color="#d69b01" v-if="permisos.editar">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" icon color="#d69b01" v-on="on" @click="editItemBodega(item)">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Editar Bodega</span>
                </v-tooltip> 
                <v-tooltip left color="#E40000" v-if="permisos.editar">
                    <template v-slot:activator="{ on }">
                        <v-btn small class="ml-2" icon color="#E40000" v-on="on" @click="AbrirDialogoMensajeEliminarBodega(item)">
                            <v-icon small>far fa-window-close</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Eliminar Bodega</span>
                </v-tooltip> 
              </template>
            </v-data-table>
          </template>
        </v-card>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import { showMessageError, showMessageSuccess } from '../../../services/sweetAlert'
import alertErrorGeneric from '../../../components/alertError.vue'
import MensajeEliminar from '../../../components/MensajeEliminar'
import MensajeEliminarSucursal from '../../../components/MensajeEliminar'

  export default {
    components:{
      alertErrorGeneric,
      MensajeEliminar,
      MensajeEliminarSucursal,
    },
    data: () => ({
      loading: false,
        alertErrorsBodega:[],
        alertErrorBodega:false,
        dessertsBodegas: [
          { name: 'Frozen Yogurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0, iron: '1%', }
        ],
        headersBodegas :[
          {text:'Id', align:'center',sortable:true, value:'bodegaId'},
          {text:'Nombre', align:'center',sortable:true, value:'nombre'},
          { text: 'Acciones', value: 'actions', sortable: false }
        ],
        expanded: [], 
        singleExpand: false,
        alertErrorPost:false,
        arrayErrorPost:[],
        Usuarios:[],
        loanding:true,
        dialog: false,
        dialogBodega: false,
        headers: [
            { text: 'Id', align: 'start', sortable: false, value: 'sucursalId' },
            { text: 'Nombre', value: 'nombre' },
            { text: 'Dirección', value: 'direccion' },
            { text: 'Encargado', value: 'nombreEncargado' },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        sucursales: [],
        editedIndex: -1,
        editedItem: {
            name: '',
            calories: 0,
            fat: 0,
            carbs: 0,
            protein: 0,
        },
        defaultItem: {
            name: '',
            calories: 0,
            fat: 0,
            carbs: 0,
            protein: 0,
        },
        modelSucursal:{
            sucursalId:0,
            nombre:'',
            direccion:'',
            usuarioEncargado:'',
            nombreEncargado:''
        },
        modelBodega:{
          bodegaId:0,
          sucursalId:0,
          nombre:''
        },
        titleBodega:'',
        permisos:{
          crear:false,
          editar:false,
          eliminar:false
        },
        // eliminar bodega
        DialogoMensajeEliminar: false,
        tituloMensajeEliminar:'',
        loadingMensajeEliminar:false,
        IdBodegaSucursal: 0,
        // eliminar sucursal
        IdSucursal:0,
        DialogoMensajeEliminarSucursal: false,
        tituloMensajeEliminarSucursal:'',
        loadingMensajeEliminarSucursal:false,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva sucursal' : 'Editar sucursal'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogBodega (val) {
        val || this.closeBodega()
      },
    },

    created () {
      this.getAllSucursales()
      var user = this.$store.getters['oidcStore/'+'oidcUser']
      this.validarPermisos(user)
    },

    methods: {
      async validarPermisos(usr){
        if(usr.role ==='Administrador'){
          this.permisos.crear=true
          this.permisos.editar=true
          this.permisos.eliminar=true
        }else{
          var url='sucursales'
          const response  = await this.$store.dispatch('PermisosSubMenu', url)
          if(response.status == 200){
            this.permisos=response.data
          }
        }
      },
      getBodegas(){
        // console.log('abriendo sucursal')
      },
        async newSucursal(){
          this.loading = true
            if(this.formTitle === 'Nueva sucursal'){
                var response = await this.$store.dispatch('newSucursal', this.modelSucursal);
                if(response.status === 200){
                  this.loading = false
                    this.showSuccess('Sucursal registrada correctamente')
                }else{
                  this.loading = false
                    this.parseErrors(response.data.errors)
                }
            }
            else{
                var response = await this.$store.dispatch('updateSucursal', this.modelSucursal);
                if(response.status === 200){
                  this.loading = false
                    this.showSuccess('Sucursal actualizada correctamente')
                }else{
                  this.loading = false
                    this.parseErrors(response.data.errors)
                }
            }
        },
        showSuccess(msg){
            this.dialog=false
            this.dialogBodega=false
            this.getAllSucursales()
            showMessageSuccess(msg)
        },
        parseErrors(erros){
            this.alertErrorPost=true
            this.arrayErrorPost=[]
            var object = erros
            for (var property in object){
                this.arrayErrorPost.push({description: `${object[property]}`})
            }
        },
        nueva() {
            this.dialog =true
            this.getAllUsuarios()
            this.reset()
        },
        reset(){
            this.modelSucursal = {
                sucursalId:0,
                nombre:'',
                direccion:'',
                usuarioEncargado:'',
                nombreEncargado:''
            }
            this.alertErrorPost=false
        },
        async getAllUsuarios(){
            var response = await this.$store.dispatch('getAllUsers',1);
            if(response.status == 200){
                this.Usuarios= response.data
            }
        },
        selectEncargado(usr){
            this.Usuarios.forEach(item => {
                if(this.modelSucursal.usuarioEncargado === item.email){
                    this.modelSucursal.nombreEncargado = item.nombre
                }
            });
        },
        async getAllSucursales () {
          this.loanding=true
          var sucursales = await this.$store.dispatch('AllSucursales')
          if(sucursales.status == 200){
            this.sucursales = sucursales.data
          }else{
              showMessageError('Error intentando obtener las sucursales')
          }
          this.loanding=false
      },
      editItemBodega(item){

        this.titleBodega='Editar'
        this.modelBodega.sucursalId= item.sucursalId
        this.modelBodega.bodegaId=item.bodegaId
        this.modelBodega.nombre=item.nombre
        this.dialogBodega=true
      },
      editItem (item) {
            this.getAllUsuarios()
            this.editedIndex = this.sucursales.indexOf(item)
            this.modelSucursal = Object.assign({}, item)
            this.dialog = true
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      addBodega(item){
        this.alertErrorPost=false
        this.titleBodega='Crear'
        this.modelBodega.sucursalId = item.sucursalId
        this.modelBodega.nombre=''
        this.modelSucursal=0
        this.dialogBodega=true
      },
      closeBodega(){
        this.alertErrorPost=false;this.arrayErrorPost=[];
        this.dialogBodega = false
      },
      async saveBodega(){
        this.alertErrorPost=false;this.arrayErrorPost=[];
        this.loading = true
        if(this.titleBodega == 'Crear'){
          var response = await this.$store.dispatch('addBodega', this.modelBodega)
          if(response.status == 200){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = false
            this.dialogBodega=false
            this.showSuccess('Bodega agregada correctamente')
          }else{
            this.loading = false
            this.parseErrors(response.data.errors);
          }
        }else{
          var response = await this.$store.dispatch('updateBodega', this.modelBodega)
          if(response.status == 200){
            this.alertErrorPost=false;this.arrayErrorPost=[];
            this.loading = false
            this.dialogBodega=false
            this.showSuccess('Bodega actualizada correctamente')
          }else{
            this.loading = false
            this.parseErrors(response.data.errors);
          }
        }
      },
      // eliminar bodega
      AbrirDialogoMensajeEliminarBodega(item){
        this.IdBodegaSucursal = item.bodegaId
        this.tituloMensajeEliminar = "bodega: "+item.nombre
        this.DialogoMensajeEliminar = true
      },
      CerrarDialogoMensajeEliminar(){
        this.DialogoMensajeEliminar = false
      },
      async EliminarBodega(){
        this.loadingMensajeEliminar = true
        var response = await this.$store.dispatch('EliminarBodegaSucursal', this.IdBodegaSucursal)
        if(response.status == 200){
          this.getAllSucursales()
          this.alertErrorPost=false;this.arrayErrorPost=[];
          this.DialogoMensajeEliminar = false
          this.loadingMensajeEliminar = false
          this.showSuccess('Bodega eliminada correctamente')
        }else{
          this.loadingMensajeEliminar = false
          this.parseErrors(response.data.errors);
        }
      },      
      // eliminar sucursal
      AbrirDialogoMensajeEliminarSucursal(item){
        this.IdSucursal = item.sucursalId
        this.tituloMensajeEliminarSucursal = "Sucursal: "+item.nombre
        this.DialogoMensajeEliminarSucursal = true
      },
      CerrarDialogoMensajeEliminarSucursal(){
        this.DialogoMensajeEliminarSucursal = false
      },
      async DeleteSucursal(){
        this.loadingMensajeEliminarSucursal = true
        var response = await this.$store.dispatch('EliminarSucursal', this.IdSucursal)
        if(response.status == 200){
          this.getAllSucursales()
          this.alertErrorPost=false;this.arrayErrorPost=[];
          this.DialogoMensajeEliminarSucursal = false
          this.loadingMensajeEliminarSucursal = false
          this.showSuccess('Sucursal eliminada correctamente')
        }else{
          this.loadingMensajeEliminarSucursal = false
          this.parseErrors(response.data.errors);
        }
      },      
    }
  }
</script>