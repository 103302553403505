<template>
    <div>
        
    </div>
</template>
<script>
import { showMessageError,showMessageSuccess } from '../../services/sweetAlert'

export default {
    props:{
        response: Array,
        loading: Boolean,
    },
    created() {
        this.ControlladorExcepciones()
    },
    methods: {
        // controlamos todas las excepciones http
        ControlladorExcepciones(){
            if(this.response.status === 400){
                showMessageError('Error 400','Petición mala')
                this.loading = false
            }else if(this.response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false                      
            }else if(this.response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
            }else if(this.response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
            }                
        },                
    },
}
</script>