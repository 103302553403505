import Vue from 'vue'

const store = {
    actions: {
        // listado de solicitudes de herramientas segun estado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesHerramientas: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/herramientas/byEstado/' + data.estado + '/sucursal/' + data.sucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // listado de solicitudes de herramientas segun estado para la vista del encargado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesHerramientasByEncargado: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/herramientas/encargado/byEstado/' + data.estado + "/Proyecto/" + data.Proyecto)
                return response
            } catch (error) {
                return error.response
            }
        },
        // mandamos a guardar las solicitudes del encargado de la sucursal o proyecto
        EnviarSolicitudesDeHerramientasABodega: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/herramientas', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // Autorizamos n solicitudes de herramientas a la vez
        AutorizarSolicitudesDeHerramientasSeleccionadas: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitudes/herramientas/autorizar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // mandamos a guardar las solicitudes de maquinaria
        EnviarSolicitudesDeMaquinariaABodega: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/maquinarias', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // mandamos a guardar las solicitudes de vehiculos
        EnviarSolicitudesDeVehiculosABodega: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/vehiculos', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // listado de solicitudes de maquinarias segun estado para la vista del encargado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesMaquinariaProceso: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/maquinaria/byEstado/' + data.estado + '/Proyecto/' + data.proyecto)
                return response
            } catch (error) {
                return error.response
            }
        },
        // listado de solicitudes de vehiculos segun estado para la vista del encargado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesVehiculosProceso: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/vehiculos/encargado/byEstado/' + data.estado + '/Proyecto/' + data.Proyecto)
                return response
            } catch (error) {
                return error.response
            }
        },
        // rechazar solicitud de maquinaria x motivo
        RechazarSolicitudVehiculoById: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/vehiculos/rechazar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // Autorizamos n solicitudes de maquinarias a la vez
        AutorizarSolicitudesDeVehiculosSeleccionadas: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/vehiculos/autorizar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // Autorizamos n solicitudes de maquinarias a la vez
        AutorizarSolicitudesDeMaquinariasSeleccionadas: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/maquinaria/autorizar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // rechazar solicitud de maquinaria x motivo
        RechazarSolicitudMaquinariaById: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/maquinaria/rechazar', data)
                return response
            } catch (error) {
                return error.response
            }
        },

        // Autorizamos n solicitudes de Materias primas a la vez
        AutorizarSolicitudesDeMateriasPrimasSeleccionadas: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitudes/materiales/autorizar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // rechazar solicitud de herramienta x motivo
        RechazarSolicitudHerramientaById: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/herramientas/rechazar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // listado de solicitudes de vehiculos segun estado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesVehiculos: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/vehiculos/byEstado/' + data.estado + '/sucursal/' + data.sucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // listado de solicitudes de maquinarias segun estado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesMaquinarias: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/maquinaria/byEstado/' + data.estado + '/sucursal/' + data.sucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // rechazar solicitud de materia prima x motivo
        RechazarSolicitudMateriaPrimaById: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/materiales/rechazar', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        //obtiene todas los materiales y la información se comportara dependiendo del estado
        TodasMaterialesSolicitud: async({}, data) => {
            try {
                const response = await Vue.axios.get('/MateriaPrima/bySolicitud/' + data.proyecto + '/sucursal/' + data.sucursal)
                return response
            } catch (e) {
                return e.response
                    // return console.log('Error al registrar insumo en incidente de vehículos', e)
            }
        },
        // listado de solicitudes de materiales segun estado para la vista del encargado
        //1. en proceso 2. aprobado 3. rechazado
        ListadoSolicitudesMaterialesByEncargado: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/materiales/encargado/byEstado/' + data.estado + "/Proyecto/" + data.Proyecto)
                return response
            } catch (error) {
                return error.response
            }
        },
        ListadoSolicitudesMaterialesByEstado: async({}, data) => {
            try {
                const response = await Vue.axios.get('/Bodega/solicitudes/materiales/byEstado/' + data.estado + '/sucursal/' + data.idSucursal)
                return response
            } catch (error) {
                return error.response
            }
        },
        // mandamos a guardar las solicitudes del encargado de la sucursal o proyecto
        EnviarSolicitudesDeMaterialesABodega: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/materiales', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // cancela una solicitud material, herramienta, maquinaria o vehiculo
        CancelarSolicitudABodega: async({}, ProyectoSolicitudId) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/cancelar?ProyectoSolicitudId=' + ProyectoSolicitudId)
                return response
            } catch (error) {
                return error.response
            }
        },
        // agregar una nueva material, herramienta, maquinaria o vehiculo a la solicitud
        AgregarNuevoItemASolicitud: async({}, data) => {
            try {
                const response = await Vue.axios.post('/Bodega/solicitud/agregarItem', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // eliminar un material, herramienta, maquinaria o vehiculo a la solicitud
        DeleteItemRequest: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/deleteItem', data)
                return response
            } catch (error) {
                return error.response
            }
        },
        // edita la cantidad solicitada de una herramienta o material
        ActualizarCantidadSolicitadaItemSolicitud: async({}, data) => {
            try {
                const response = await Vue.axios.put('/Bodega/solicitud/updateItem', data)
                return response
            } catch (error) {
                return error.response
            }
        },
    }
}

export default store