<template>
<div>
    <v-alert dense outlined type="error" v-if="alertError">
        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
    </v-alert>    
    <v-app-bar flat dark color="#3c8dbc" class="mt-5">
        <v-icon left>mdi-hammer-screwdriver</v-icon>
        <v-toolbar-title class="TituloDialogo">Incidente de {{TituloToolbar}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="#02abe6" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="FuenteLetra" text @click="generarPDFIncidentesGeneral()" 
                    target="_blank" v-on="on" v-bind="attrs" :disabled="!TotalIncidentes"
                >
                    <v-icon left>fas fa-file-pdf</v-icon> Generar PDF General
                </v-btn>        
            </template>
            <span class="FuenteLetra">Generar PDF de incidentes de vehículo general</span>
        </v-tooltip>        
    </v-app-bar>  
    <v-card>
    <!-- barra principal del dialogo -->
        <v-subheader class="FuenteLetra">
            Incidente de {{TituloToolbar +': '+DataVehiculo.tipo + ' ' + DataVehiculo.marca + ' '+ DataVehiculo.placa +' - ' + DataVehiculo.propietario}}
            || Total de Incidentes: {{incidentes.length}}
        </v-subheader> <v-divider class="mb-4"></v-divider>       
        <v-timeline align-top dense class="mt-0 pt-0 mb-0 pb-0">
            <v-timeline-item
                v-for="(item, i) in incidentes"
                :key="i"
                color="#0071c4"
                icon="mdi-book-variant"
                fill-dot
            >
                <v-card color="#02abe6">
                    <v-data-iterator 
                        :items="incidentes"
                        :single-expand="expand"
                        hide-default-footer
                    >
                        <template v-slot:default="{isExpanded, expand }">
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" class="mt-0 pt-0 mb-0 pb-0">
                                    <v-card>
                                        <v-toolbar class="FuenteLetra" color="#02abe6" dense dark flat>
                                            <v-icon small left class="mr-2">fas fa-calendar-alt</v-icon>Id: {{item.idIncidente}} - {{item.fecha}}
                                            <v-icon small left class="ml-3">fas fa-wrench</v-icon>
                                            <h3>{{ item.tipoIncidente.nombre }}</h3>
                                            <v-switch dark
                                                :input-value="isExpanded(item)"
                                                :label="isExpanded(item) ? 'Ver menos' : 'Ver más'"
                                                class="pl-4 mt-7"
                                                @change="(v) => expand(item, v)"
                                            ></v-switch>                            
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="generarPDF(item.idIncidente)" target="_blank">
                                                <v-icon left>fas fa-file-pdf</v-icon> Generar PDF
                                            </v-btn>
                                        </v-toolbar>                                                
                                        <v-divider></v-divider>
                                        <v-list v-if="isExpanded(item)" dense class="FuenteLetra">
                                            <v-toolbar class="text-center FuenteLetra" dark dense flat color="#d69b01">
                                                    <v-icon left>fas fa-receipt</v-icon>
                                                    <h3>Detalles del Incidente</h3>
                                                    <v-spacer></v-spacer>
                                                    <v-tooltip bottom color="#0019d8">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small fab color="#0019d8" v-on="on" v-bind="attrs"
                                                                @click="AbrirDialogoEditarIncidente(item)" v-if="permisos.editar"
                                                            >
                                                                <v-icon small>fas fa-edit</v-icon>
                                                            </v-btn>                                                    
                                                        </template>
                                                        <span class="FuenteLetra">Editar Incidente</span>
                                                    </v-tooltip>                                                            
                                                    <v-tooltip bottom color="#D80000">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn class="ml-2" small fab color="#D80000" v-on="on" v-bind="attrs"
                                                                @click="AbrirDialogoEliminarIncidente(item)" v-if="permisos.eliminar"
                                                            >
                                                                <v-icon small>fas fa-trash-alt</v-icon>
                                                            </v-btn>                                                    
                                                        </template>
                                                        <span class="FuenteLetra">Eliminar Incidente</span>
                                                    </v-tooltip>
                                            </v-toolbar>
                                            <v-list-item>
                                                <v-icon left>mdi-calendar-range</v-icon>
                                                <v-list-item-content>Fecha:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.fecha }}</v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-icon left>mdi-hammer-screwdriver</v-icon>
                                                <v-list-item-content>Tipo de Incidente:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.tipoIncidente.nombre }}</v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-icon left>mdi-map-marker</v-icon>
                                                <v-list-item-content>Ubicación:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.ubicacion }}</v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-icon left>mdi-speedometer-medium</v-icon>
                                                <v-list-item-content>Kilometraje:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.kilometraje }}</v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-icon left>mdi-account</v-icon>
                                                <v-list-item-content>Taller/Mecánico/Colaborador:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.tallerMecanicoOColaborador }}</v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-icon left>mdi-receipt</v-icon>
                                                <v-list-item-content>Descripción:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.descripcion }}</v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-icon left>mdi-receipt</v-icon>
                                                <v-list-item-content>Observación:</v-list-item-content>
                                                <v-list-item-content class="align-end">{{ item.observacion }}</v-list-item-content>
                                            </v-list-item>
                                        <!-- mostramos todos los insumos que posee el incidente del vehiculo -->
                                            <v-toolbar class="text-center FuenteLetra" dark dense flat color="#000000">
                                                    <v-icon small left>fas fa-wrench</v-icon>                                                            
                                                    <h3>Detalles de Insumos</h3>
                                                    <v-spacer></v-spacer>
                                                    <v-tooltip bottom color="#0019d8">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small fab color="#0019d8" v-on="on" v-bind="attrs"
                                                                @click="AbrirDialogoAgregarInsumoIncidente(item)" v-if="permisos.crear"
                                                            >
                                                                <v-icon>fas fa-plus-circle</v-icon>
                                                            </v-btn>                                                    
                                                        </template>
                                                        <span class="FuenteLetra">Agregar Insumo</span>
                                                    </v-tooltip>                                                            
                                            </v-toolbar>                                                
                                            <!-- validamos si es vehiculo que muestra la tabla -->
                                            <v-data-table v-if="Titulo === 'Vehiculo'"
                                                :headers="EncabezadoTablaIncidentes"
                                                :items="item.insumosIncidenteVehiculos"
                                                item-key=""
                                                class="elevation-1"
                                                :footer-props="{itemsPerPageText: 'Filas por página'}"
                                            >      
                                                <template v-slot:item.action="{ item }">
                                                    <v-tooltip left color="#d69b01">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small class="ml-2" dark fab color="#d69b01" v-on="on" 
                                                                @click="AbrirDialogoEditarInsumo(item)" v-if="permisos.editar"
                                                            >
                                                                <v-icon small>fas fa-edit</v-icon>
                                                            </v-btn>                        
                                                        </template>
                                                        <span class="fontButton">Editar Insumo</span>
                                                    </v-tooltip>                                              
                                                    <v-tooltip left color="#D80000">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small class="ml-2" dark fab color="#D80000" v-on="on"
                                                                @click="AbrirDialogoEliminarInsumoIncidente(item)" v-if="permisos.eliminar"
                                                            >
                                                                <v-icon small>fas fa-trash-alt</v-icon>
                                                            </v-btn>                        
                                                        </template>
                                                        <span class="fontButton">Eliminar Insumo</span>
                                                    </v-tooltip>                                              
                                                </template>
                                                <template v-slot:body.append>
                                                    <tr class="text-center TamañoLetraTotal">
                                                        <!-- <td></td> -->
                                                        <td colspan="6"></td>
                                                        <td>
                                                            <h2 class=""><strong>Total:</strong></h2> 
                                                        </td>
                                                        <td>
                                                            <h2><strong>Q. {{item.totalInsumosQ}}</strong></h2> 
                                                        </td>
                                                    </tr>
                                                </template>                                                
                                            </v-data-table>
                                            <!-- validamos si es maquinaria que muestra la tabla -->
                                            <v-data-table v-if="Titulo === 'Maquinaria'"
                                                :headers="EncabezadoTablaIncidentes"
                                                :items="item.insumosIncidenteMaquinarias"
                                                item-key=""
                                                class="elevation-1"
                                                :footer-props="{itemsPerPageText: 'Filas por página'}"
                                            >         
                                                <template v-slot:item.action="{ item }">
                                                    <v-tooltip left color="#d69b01">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small class="ml-2" dark fab color="#d69b01" v-on="on" 
                                                                @click="AbrirDialogoEditarInsumo(item)" v-if="permisos.editar"
                                                            >
                                                                <v-icon small>fas fa-edit</v-icon>
                                                            </v-btn>                        
                                                        </template>
                                                        <span class="fontButton">Editar Insumo</span>
                                                    </v-tooltip>                                              
                                                    <v-tooltip left color="#D80000">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small class="ml-2" dark fab color="#D80000" v-on="on"
                                                                @click="AbrirDialogoEliminarInsumoIncidente(item)" v-if="permisos.eliminar"
                                                            >
                                                                <v-icon small>fas fa-trash-alt</v-icon>
                                                            </v-btn>                        
                                                        </template>
                                                        <span class="fontButton">Eliminar Insumo</span>
                                                    </v-tooltip>                                              
                                                </template>             
                                                <template v-slot:body.append>
                                                    <tr class="text-center TamañoLetraTotal">
                                                        <!-- <td></td> -->
                                                        <td colspan="6"></td>
                                                        <td>
                                                            <h2 class=""><strong>Total:</strong></h2> 
                                                        </td>
                                                        <td>
                                                            <h2><strong>Q. {{item.totalIncidenteQ}}</strong></h2> 
                                                        </td>
                                                    </tr>
                                                </template>                                                                                                                                                                                          
                                            </v-data-table>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                        <!-- </v-container> -->
                    <!-- </v-card-text> -->
                </v-card>
            </v-timeline-item>
        </v-timeline>                         
        <!-- </v-card-text> -->
    </v-card> 
    <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
    <v-overlay :value="EstadoCircularProgress">
        <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular>
    </v-overlay>
    <DialogoEditarIncidente 
        :DialogoEditarIncidente="DialogoEditarIncidente" @CerrarDialogoEditarIncidente="CerrarDialogoEditarIncidente"
        :modeloEditarIncidente="modeloEditarIncidente" :validarfecha="validarfecha"  @actualizarTabla="ObtenerDatosIncidenteVehiculo"
        :Titulo="Titulo"
    />
    <DialogoEditarInsumo 
        :DialogEditarInsumo="DialogEditarInsumo" @CerrarDialogoEditarInsumo="CerrarDialogoEditarInsumo"
        :modeloEditarInsumoIncidente="modeloEditarInsumoIncidente"
        @actualizarTabla="ObtenerDatosIncidenteVehiculo" :Titulo="Titulo"
    />
    <DialogoEliminarInsumoIncidente 
        :DialogEliminarIncidente="DialogEliminarIncidente" @CerrarDialogoEliminarIncidente="CerrarDialogoEliminarIncidente"
        @actualizarTabla="ObtenerDatosIncidenteVehiculo" :TituloEliminar="TituloEliminar" :Titulo="Titulo"
        :DatosInsumo="DatosInsumo" :EliminarInsumoIncidente="EliminarInsumoIncidente" :DatosIncidente="DatosIncidente"
    />
    <DialogoAgregarInsumoIncidente 
        :DialogNuevoInsumoIncidente="DialogNuevoInsumoIncidente" @CerrarDialogoAgregarInsumoIncidente="CerrarDialogoAgregarInsumoIncidente"
        :modeloAgregarInsumo="modeloAgregarInsumo" @actualizarTabla="ObtenerDatosIncidenteVehiculo" :Titulo="Titulo"
        :DatosIncidente="DatosIncidente"
    />
</div>     
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'

import DialogoEditarIncidente from '../EditarIncidente'
import DialogoEditarInsumo from '../EditarInsumosIncidente'
import DialogoEliminarInsumoIncidente from '../EliminarIncidente'
import DialogoAgregarInsumoIncidente from '../RegistrarInsumo'

import { mapGetters, mapActions  } from 'vuex'

export default {
    props: {
        id: String,
        Titulo: String,
    },

    components:{
        DialogoEditarIncidente,
        DialogoEditarInsumo,
        DialogoEliminarInsumoIncidente,
        DialogoAgregarInsumoIncidente,
    },

    data: vm => ({
            TotalIncidentes: false,
            //variables para los permisos del colaborador
            namespace:'oidcStore/',
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },         
            EstadoPermisoEditar: true, 
            // alerta cuando el api deja de funcionar
            alertError:false,

            EstadoCircularProgress: false,            
            expand: false,
            IdIncidente: '',
            TituloToolbar: '',
            incidentes: [],
            DataVehiculo:{
                tipo: '',
                marca:'',
                propietario:'',
                placa:'',
            },            
            EncabezadoTablaIncidentes:[
                {text: 'Proveedor', value: 'proveedor', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Insumo', value: 'nombre', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Unidad Medida', value: 'unidadDeMedida', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Factura', value: 'factura', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Bodega donde salió', value: 'bodegaSalio', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Cantidad', value: 'cantidad', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Precio Unitario', value: 'precioUnitario', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Precio Total', value: 'precioTotal', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],   
            // variables del componente editar incidente
            DialogoEditarIncidente: false,
            validarfecha:'',
            modeloEditarIncidente: {
                idIncidente: '',
                idTIpoIncidente:'',
                fecha: new Date().toISOString().substr(0, 10),
                ubicacion:'',
                kilometraje:'',
                tallerMecanicoOColaborador:'',
                descripcion:'',
                observacion:'',
            },
            // variables para editar un insumo
            DialogEditarInsumo: false,   
            modeloEditarInsumoIncidente: {
                idInsumoIncidente:'',
                idIncidente: '',
                factura:'',
                proveedor:'',
                bodegaSalio:'',
                nombre:'',
                unidadDeMedida:'',
                cantidad:'',
                precioUnitario:'',
                precioTotal:'',
            },
            // variables para eliminar un incidente
            DialogEliminarIncidente: false,
            TituloEliminar: '',
            DatosInsumo: {
                idInsumoIncidente:'',
                nombre: '',
            },
            DatosIncidente: {
                idIncidente:'',
                nombre: '',
            },
            EliminarInsumoIncidente: false,
            //variables para agregar un insumo a un incidente
            DialogNuevoInsumoIncidente: false,
            modeloAgregarInsumo:{
                idIncidente: '',
                factura:'',
                proveedor:'',
                bodegaSalio:'',
                nombre:'',
                unidadDeMedida:'',
                cantidad:'',
                precioUnitario:'',
                precioTotal:'',
            },
    }),

    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    

    created() {
        this.ObtenerDatosVehiculo()
        this.ObtenerDatosIncidenteVehiculo()
        this.ObtenerPermisosSubmenuUsuario()
    },
    methods: {
        // metodos para obtener los permisos del colaborador a este modulo
        ...mapActions('oidcStore', ['authenticateOidcSilent',
                'signOutOidc', 'authenticateOidc']),

        ControllerException(){
            this.alertError=true
            this.loading=false
        },

        async ObtenerPermisosSubmenuUsuario(role){
            if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.permisos.crear = true
                        this.permisos.editar = true
                        this.permisos.eliminar = true
                        return 0            
                }else{
                    //preguntar al backend por los permisos del submenu del usuario
                    if(this.Titulo === 'Vehiculo'){
                        var url = 'vehiculosmantenimiento'
                        // console.log('vehiculos')
                    }else if(this.Titulo === 'Maquinaria'){                        
                        var url = 'maquinariamantenimiento'
                        // console.log('maquinaria')
                    }

                    const response  = await this.$store.dispatch('PermisosSubMenu', url)
                    if(typeof response === 'undefined'){
                        this.ControllerException()
                        this.EstadoCircularProgress = false
                        return 0
                    }                    
                    if(response.status === 200){
                        this.alertError= false
                        this.permisos = response.data      
                        // console.log(this.permisos)   
                    }else if(response.status === 400){
                        showMessageError('Error 400','Petición mala')
                    }else if(response.status === 401){
                        showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    }else if(response.status === 404){
                        showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    }else if(response.status === 500){
                        showMessageError('Error 500','Error en el servidor')            
                    }
                    return 0
                }
            }
        },        

        async ObtenerDatosIncidenteVehiculo(){
            this.EstadoCircularProgress = true
            if(this.Titulo === 'Vehiculo'){
                this.TituloToolbar = "Vehículo"
                const response = await this.$store.dispatch('ObtenerIncidentesVehiculo', this.id)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }
                if(response.status === 200){
                    this.alertError= false
                    this.incidentes = response.data
                    // console.log(response.data)
                    if(response.data.length >=1){
                        this.TotalIncidentes = true
                    }else{
                        this.TotalIncidentes = false                        
                    }
                    this.EstadoCircularProgress = false
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            }else{
                this.TituloToolbar = "Maquinaria"
                const response = await this.$store.dispatch('ObtenerIncidentesMaquinaria', this.id)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }                
                if(response.status === 200){
                    this.alertError= false
                    this.incidentes = response.data
                    if(response.data.length > 0){
                        this.TotalIncidentes = true
                    }else{
                        this.TotalIncidentes = false                        
                    }
                    this.EstadoCircularProgress = false
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            }
        },

        async ObtenerDatosVehiculo(){
            if(this.Titulo === 'Vehiculo'){
                const response = await this.$store.dispatch('ObtenerVehiculoById', this.id)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }
                if(response.status === 200){
                    this.alertError= false
                    this.DataVehiculo.tipo = response.data.tipoVehiculo.nombre
                    this.DataVehiculo.marca = response.data.vehiculoMarca.nombre
                    this.DataVehiculo.propietario = response.data.propietario
                    this.DataVehiculo.placa = response.data.placa
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            }else{
                const response = await this.$store.dispatch('ObtenerMaquinariaById', this.id)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }
                if(response.status === 200){
                    this.alertError= false
                    this.DataVehiculo.tipo = response.data.tipoMaquinaria.nombre
                    this.DataVehiculo.marca = response.data.vehiculoMarca.nombre
                    this.DataVehiculo.propietario = response.data.propietario
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            }            
        },

        generarPDF(idIncidente){
            this.IdIncidente = idIncidente
            if(this.Titulo === 'Vehiculo'){
                this.$router.push('/GenerarPDFIncidenteVehiculo/'+this.IdIncidente)
            }else{
                this.$router.push('/GenerarPDFIncidenteMaquinaria/'+this.IdIncidente)
            }
        },

        generarPDFIncidentesGeneral(){
            if(this.Titulo === 'Vehiculo'){
                this.$router.push('/GenerarPDFIncidentesVehiculosGeneral/'+this.id)
            }else{
                this.$router.push('/GenerarPDFIncidentesMaquinariasGeneral/'+this.id)
            }
        },
        // metodos para editar un incidente
        AbrirDialogoEditarIncidente(incidente){
            if(this.Titulo === 'Vehiculo' || this.Titulo === 'Maquinaria'){
                this.modeloEditarIncidente.idIncidente = incidente.idIncidente
                this.modeloEditarIncidente.idTIpoIncidente = incidente.idTIpoIncidente
                this.modeloEditarIncidente.fecha = incidente.fecha
                this.modeloEditarIncidente.ubicacion = incidente.ubicacion
                this.modeloEditarIncidente.kilometraje = incidente.kilometraje
                this.modeloEditarIncidente.tallerMecanicoOColaborador = incidente.tallerMecanicoOColaborador
                this.modeloEditarIncidente.descripcion = incidente.descripcion 
                this.modeloEditarIncidente.observacion = incidente.observacion 
                // console.log(this.modeloEditarIncidente)
                this.validarfecha = incidente.fecha
                this.DialogoEditarIncidente = true
            }
        },

        CerrarDialogoEditarIncidente(){
            this.DialogoEditarIncidente = false
        },
        // metodos para editar un insumo
        AbrirDialogoEditarInsumo(insumo){
            this.modeloEditarInsumoIncidente.idInsumoIncidente = insumo.idInsumoIncidente
            this.modeloEditarInsumoIncidente.idIncidente = insumo.idIncidente
            this.modeloEditarInsumoIncidente.factura = insumo.factura
            this.modeloEditarInsumoIncidente.proveedor = insumo.proveedor
            this.modeloEditarInsumoIncidente.bodegaSalio = insumo.bodegaSalio
            this.modeloEditarInsumoIncidente.nombre = insumo.nombre
            this.modeloEditarInsumoIncidente.unidadDeMedida = insumo.unidadDeMedida
            this.modeloEditarInsumoIncidente.cantidad = insumo.cantidad
            this.modeloEditarInsumoIncidente.precioUnitario = insumo.precioUnitario
            this.modeloEditarInsumoIncidente.precioTotal = insumo.precioTotal
            this.DialogEditarInsumo = true
        },

        CerrarDialogoEditarInsumo(){
            this.DialogEditarInsumo = false
        },
        // metodos para eliminar un insumo y un incidente
        AbrirDialogoEliminarInsumoIncidente(insumo){
            this.TituloEliminar = "Eliminar Insumo"
            this.DialogEliminarIncidente = true
            this.EliminarInsumoIncidente = true
            this.DatosInsumo.idInsumoIncidente = insumo.idInsumoIncidente
            this.DatosInsumo.nombre = insumo.nombre
            // console.log(this.DatosInsumo)
        },

        CerrarDialogoEliminarIncidente(){
            this.DialogEliminarIncidente = false
        },

        AbrirDialogoEliminarIncidente(incidente){
            this.TituloEliminar = "Eliminar Incidente"
            this.DialogEliminarIncidente = true
            this.EliminarInsumoIncidente = false
            this.DatosIncidente.idIncidente = incidente.idIncidente
            this.DatosIncidente.nombre = incidente.tipoIncidente.nombre
            // console.log(this.DatosIncidente)        
        },

        // metodos para agregar un insumo a un incidente.text-center
        AbrirDialogoAgregarInsumoIncidente(incidente){
            this.DatosIncidente.idIncidente = incidente.idIncidente
            this.DatosIncidente.nombre = incidente.tipoIncidente.nombre
            this.DialogNuevoInsumoIncidente = true
        },

        CerrarDialogoAgregarInsumoIncidente(){
            this.DialogNuevoInsumoIncidente = false
        }
    },
}
</script>

<style>
    .FuenteLetra{
        font-family: 'PT Serif';
    };
    .TamañoLetraTotal{
        size: 20px;
    }
</style>