<template>
    <div>
        <v-toolbar title elevation="1" color="blue" dark>
            <v-icon left>fas fa-dolly</v-icon>
            <v-toolbar-title> Materiales a solicitar</v-toolbar-title>
            <v-divider vertical class="ml-3"></v-divider>    

            <v-spacer></v-spacer>    
            <v-btn large color="#0019d8" rounded class="mx-2 TituloDialogo"  @click="EnviarTodasLasSolicitudes()"
                :loading="loading" :disabled="loading || !ActivarBotonEnviarSolicitud"
            >Enviar Solicitudes
                <v-icon right dark>fas fa-save</v-icon>
            </v-btn>                                       
        </v-toolbar>
        <v-alert :value="alertErrorPost"
                type="error"
                outlined
                transition="scale-transition"
                color="deep-orange">
                <h4>Errores:</h4>
                <ul>
                    <li type="disc" v-for="(i) in arrayErrorPost" :key="i.value">
                        <h6>{{i.description}}</h6>
                    </li>
                </ul>
        </v-alert>        
        <v-data-table
            :headers="EncabezadoTablaSolicitudes"
            :items="ArrayTodasLasSolicitudesAEnviar"
            item-key="idMateriaPrima"
            class="elevation-1"
            :footer-props="{itemsPerPageText: 'Filas por página'}"                 
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom color="#D80000">
                    <template v-slot:activator="{ on }">
                        <v-btn  small class="ml-2" dark fab color="#D80000" v-on="on" @click="QuitarSolicitudMateriales(item)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span class="fontButton">Quitar Solicitud de Material o Insumo</span>
                </v-tooltip>                                              
            </template>
            <template v-slot:no-data v-if="ArrayTodasLasSolicitudesAEnviar.length === 0">
                <v-alert dense outlined class="text-xs-center textoalerta" type="warning" prominent border="left" >
                    No existen solicitudes de Materiales a enviar
                </v-alert>
            </template>                    
        </v-data-table>        
    </div>
</template>

<script>
import { showMessageSuccess, showMessageError } from '../../../services/sweetAlert'

export default {
    props:{
        ArrayTodasLasSolicitudesAEnviar: Array,
        proyecto:Number, sucursal: Number, bodega: Number,
    },

    components:{

    },

    data() {
        return {
            loading: false,
            EncabezadoTablaSolicitudes:[
                {text: 'Id Material o Insumo', value: 'idMateriaPrima', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Materia Prima', value: 'nombreMaterial', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Unidad de Medida', value:'unidadDeMedida', sortable: false, align:'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'marcaMaterial', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Cantidad Solicitar', width:'300px', value: 'cantidadSolicitada', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'tituloTablaSubMenu'},                                
            ],
            ActivarBotonEnviarSolicitud:false,
            // errores registros duplicados y vacíos
            alertErrorPost:false,
            arrayErrorPost:[],                        
        }
    },
    watch: {
        ArrayTodasLasSolicitudesAEnviar(){
            // console.log('ArrayTodasLasSolicitudesAEnviar: ', this.ArrayTodasLasSolicitudesAEnviar)
            if(this.ArrayTodasLasSolicitudesAEnviar.length >0){
                this.ActivarBotonEnviarSolicitud = true
            }else{
                this.ActivarBotonEnviarSolicitud = false
            }
        },
        //proyecto(){
        //    this.$emit('ObtenerTodasLasMateriales')
        //    this.alertErrorPost=false
        //    this.arrayErrorPost=[]
        //}
    },

    methods: {
        async EnviarTodasLasSolicitudes(){
            this.loading = true
            this.alertErrorPost=false;this.arrayErrorPost=[];  
            var data = {solis:this.ArrayTodasLasSolicitudesAEnviar, proyecto: this.proyecto, sucursal:this.sucursal, bodega: this.bodega}          
            const response = await this.$store.dispatch('EnviarSolicitudesDeMaterialesABodega', data)
            if(response.status === 200){
                this.loading = false
                this.alertError= false
                this.$emit('ObtenerTodasLasMateriales')
                showMessageSuccess('¡BIEN!', response.data.message)
            }else{
                this.ControlladorExcepciones(response)
            }            
        },
        ControlladorExcepciones(response){
            if(typeof response === 'undefined'){
                this.ControllerException()
                this.alertErrorPost = false
                return 0
            }else if(response.status === 400){
                this.alertErrorPost=true
                var object = response.data.errors
                for (var property in object){
                    this.arrayErrorPost.push({description: `${object[property]}`})
                }
                this.loading = false
            }else if(response.status === 401){
                showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                this.loading = false       
                this.alertErrorPost = false
            }else if(response.status === 404){
                showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                this.loading = false
                this.alertErrorPost = false
            }else if(response.status === 500){
                showMessageError('Error 500','Error en el servidor')            
                this.loading = false
                this.alertErrorPost = false
            }                
        },        
        //quitamos la solicitud de materiales que no quiere agregar el usuario
        QuitarSolicitudMateriales(item){
            const index =this.ArrayTodasLasSolicitudesAEnviar.indexOf(item)
            this.ArrayTodasLasSolicitudesAEnviar.splice(index,1)
            // console.log('quitar licencia', this.ArrayLicenciasSeleccionadas)
        },        
    },
}
</script>